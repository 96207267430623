import {
  READ_FORMATDI_VARIABLES,
  LOAD_FORMATDI_VARIABLES,
  RESET_FORMATDI_VARIABLES
} from "types/Types";

const initialState = {
  id:0,
  typesdi_id:0,
  opt_tras_view: true,
  opt_tras_expand:true,
  opt_tras_handle:true,
  opt_tras_view_name:true,
  opt_tras_view_contactname:true,
  opt_tras_view_address:true,
  opt_tras_view_country:true, 
  opt_tras_view_community:true,
  opt_tras_view_phone:true,
  opt_tras_view_email:true,
  opt_tras_view_nima:true,
  opt_tras_view_cnae:false,
  opt_tras_view_inscription:true,
  opt_tras_view_opt:true,
  opt_tras_view_licenseplate:false,
  opt_tras_view_economicactivity:false,
  opt_tras_handle_documnettype:true,
  opt_tras_handle_documentid:true,
  opt_tras_handle_tradename:true,
  opt_tras_handle_name:true,
  opt_tras_handle_contactname:true,
  opt_tras_handle_address:true,
  opt_tras_handle_phone:true,
  opt_tras_handle_email:true,
  opt_tras_handle_nima:true,
  opt_tras_handle_cnae:true,
  opt_tras_handle_inscription:true,
  opt_tras_handle_opt:true,
  opt_tras_handle_licenseplate:true,
  opt_tras_handle_economicactivity:true,
  
  ori_tras_view:true,
  ori_tras_expand:true,
  ori_tras_handle:true,
  ori_tras_view_name:true,
  ori_tras_view_contactname:true,
  ori_tras_view_address:true,
  ori_tras_view_country:true, 
  ori_tras_view_community:true,
  ori_tras_view_phone:true,
  ori_tras_view_email:true,
  ori_tras_view_nima:true,
  ori_tras_view_cnae:true,
  ori_tras_view_inscription:true,
  ori_tras_view_opt:false,
  ori_tras_view_licenseplate:false,
  ori_tras_view_economicactivity:false, 

  ori_tras_handle_documnettype:true,
  ori_tras_handle_documentid:true,
  ori_tras_handle_tradename:true,
  ori_tras_handle_name:true,
  ori_tras_handle_contactname:true,
  ori_tras_handle_address:true,
  ori_tras_handle_phone:true,
  ori_tras_handle_email:true,
  ori_tras_handle_nima:true,
  ori_tras_handle_cnae:true,
  ori_tras_handle_inscription:true,
  ori_tras_handle_opt:true,
  ori_tras_handle_licenseplate:true,
  ori_tras_handle_economicactivity:true,

  aut_tras_view:true,
  aut_tras_expand:true,
  aut_tras_handle:true,
  aut_tras_view_name:true,
  aut_tras_view_contactname:true,
  aut_tras_view_address:true,
  aut_tras_view_country:true, 
  aut_tras_view_community:true,
  aut_tras_view_phone:true,
  aut_tras_view_email:true,
  aut_tras_view_nima:true,
  aut_tras_view_cnae:false,
  aut_tras_view_inscription:true,
  aut_tras_view_opt:false,
  aut_tras_view_licenseplate:false,
  aut_tras_view_economicactivity:false,

  aut_tras_handle_documnettype:true,
  aut_tras_handle_documentid:true,
  aut_tras_handle_tradename:true,
  aut_tras_handle_name:true,
  aut_tras_handle_contactname:true,
  aut_tras_handle_address:true,
  aut_tras_handle_phone:true,
  aut_tras_handle_email:true,
  aut_tras_handle_nima:true,
  aut_tras_handle_cnae:true,
  aut_tras_handle_inscription:true,
  aut_tras_handle_opt:true,
  aut_tras_handle_licenseplate:true,
  aut_tras_handle_economicactivity:true,

  dest_tras_view:true,
  dest_tras_expand:true,
  dest_tras_handle:true,
  dest_tras_view_name:true,
  dest_tras_view_contactname:true,
  dest_tras_view_address:true,
  dest_tras_view_country:true, 
  dest_tras_view_community:true,
  dest_tras_view_phone:true,
  dest_tras_view_email:true,
  dest_tras_view_nima:true,
  dest_tras_view_cnae:false,
  dest_tras_view_inscription:true,
  dest_tras_view_opt:false,
  dest_tras_view_licenseplate:false,
  dest_tras_view_economicactivity:false,

  dest_tras_handle_documnettype:true,
  dest_tras_handle_documentid:true,
  dest_tras_handle_tradename:true,
  dest_tras_handle_name:true,
  dest_tras_handle_contactname:true,
  dest_tras_handle_address:true,
  dest_tras_handle_phone:true,
  dest_tras_handle_email:true,
  dest_tras_handle_nima:true,
  dest_tras_handle_cnae:true,
  dest_tras_handle_inscription:true,
  dest_tras_handle_opt:true,
  dest_tras_handle_licenseplate:true,
  dest_tras_handle_economicactivity:false,

  aut_dest_view:true,
  aut_dest_expand:true,
  aut_dest_handle:true,
  aut_dest_view_name:true,
  aut_dest_view_contactname:true,
  aut_dest_view_address:true,
  aut_dest_view_country:true,  
  aut_dest_view_community:true,
  aut_dest_view_phone:true,
  aut_dest_view_email:true,
  aut_dest_view_nima:true,
  aut_dest_view_cnae:false,
  aut_dest_view_inscription:true,
  aut_dest_view_opt:false,
  aut_dest_view_licenseplate:false,
  aut_dest_view_economicactivity:false,

  aut_dest_handle_documnettype:true,
  aut_dest_handle_documentid:true,
  aut_dest_handle_tradename:true,
  aut_dest_handle_name:true,
  aut_dest_handle_contactname:true,
  aut_dest_handle_address:true,
  aut_dest_handle_phone:true,
  aut_dest_handle_email:true,
  aut_dest_handle_nima:true,
  aut_dest_handle_cnae:true,
  aut_dest_handle_inscription:true,
  aut_dest_handle_opt:true,
  aut_dest_handle_licenseplate:true,
  aut_dest_handle_economicactivity:false,

  rel_trans_view:true,
  rel_trans_expand:true,
  rel_trans_handle:true,
  rel_trans_view_name:true,
  rel_trans_view_contactname:true,
  rel_trans_view_address:true,
  rel_trans_view_country:true, 
  rel_trans_view_community:true,
  rel_trans_view_phone:true,
  rel_trans_view_email:true,
  rel_trans_view_nima:true,
  rel_trans_view_cnae:false,
  rel_trans_view_inscription:true,
  rel_trans_view_opt:false,
  rel_trans_view_licenseplate:true,
  rel_trans_view_economicactivity:false,

  rel_trans_handle_documnettype:true,
  rel_trans_handle_documentid:true,
  rel_trans_handle_tradename:true,
  rel_trans_handle_name:true,
  rel_trans_handle_contactname:true,
  rel_trans_handle_address:true,
  rel_trans_handle_phone:true,
  rel_trans_handle_email:true,
  rel_trans_handle_nima:true,
  rel_trans_handle_cnae:true,
  rel_trans_handle_inscription:true,
  rel_trans_handle_opt:true,
  rel_trans_handle_licenseplate:true,
  rel_trans_handle_economicactivity:false,

  resp_prod_view:true,
  resp_prod_expand:true,
  resp_prod_handle:true,
  resp_prod_view_name:true,
  resp_prod_view_contactname:true,
  resp_prod_view_address:true,
  resp_prod_view_country:true, 
  resp_prod_view_community:true,
  resp_prod_view_phone:true,
  resp_prod_view_email:true,
  resp_prod_view_nima:true,
  resp_prod_view_cnae:false,
  resp_prod_view_inscription:true,
  resp_prod_view_opt:false,
  resp_prod_view_licenseplate:false,
  resp_prod_view_economicactivity:false,

  resp_prod_handle_documnettype:true,
  resp_prod_handle_documentid:true,
  resp_prod_handle_tradename:true,
  resp_prod_handle_name:true,
  resp_prod_handle_contactname:true,
  resp_prod_handle_address:true,
  resp_prod_handle_phone:true,
  resp_prod_handle_email:true,
  resp_prod_handle_nima:true,
  resp_prod_handle_cnae:true,
  resp_prod_handle_inscription:true,
  resp_prod_handle_opt:true,
  resp_prod_handle_licenseplate:true,
  resp_prod_handle_economicactivity:false,

  number_dt_view:true,
  code_description_view:false,
  code_treatmenttype_origin_view:false,
  code_treatmenttype_destine_view:true,
  code_processtype_origin_view:false,
  code_processtype_destine_view:false,
  code_dangerousness_view:true,
  charac_danger_view:false,
  date_refund_view:false,
  reason_refund_view:false,
  action_refund_view:false,

  number_dt_handle:true,
  code_description_handle:false,
  code_treatmenttype_origin_handle:false,
  code_treatmenttype_destine_handle:true,
  code_processtype_origin_handle:false,
  code_processtype_destine_handle:false,
  code_dangerousness_handle:true,
  charac_danger_handle:false,
  date_refund_handle:false,
  reason_refund_handle:false,
  action_refund_handle:false,

  share_form_view:false,
  share_form_handle:false,
  share_form_opt_tras:false,
  share_form_ori_tras:false,
  share_form_aut_tras:false,
  share_form_dest_tras:false,
  share_form_aut_dest:false,
  share_form_rel_trans:false,
  share_form_resp_prod:false,

  sendbyemail_form_view:false,
  sendbyemail_form_handle:false,
  sendbyemail_form_opt_tras:false,
  sendbyemail_form_ori_tras:false,
  sendbyemail_form_aut_tras:false,
  sendbyemail_form_dest_tras:false,
  sendbyemail_form_aut_dest:false,
  sendbyemail_form_rel_trans:false,
  sendbyemail_form_resp_prod:false,

  filenameexcel:"",
  signature_default:"",
  signature_default_des_tras:""

};
 

export function formatDIReducer(state = initialState, action) {
  switch (action.type) {
    case READ_FORMATDI_VARIABLES:
      return state;
    case LOAD_FORMATDI_VARIABLES: {
      const {
        id,
        typesdi_id,
        opt_tras_view,
        opt_tras_expand,
        
        opt_tras_handle,
        opt_tras_view_name,
        opt_tras_view_contactname,
        opt_tras_view_address,
        opt_tras_view_country,
        opt_tras_view_community,
        opt_tras_view_phone,
        opt_tras_view_email,
        opt_tras_view_nima,
        opt_tras_view_cnae,
        opt_tras_view_inscription,
        opt_tras_view_opt,
        opt_tras_view_licenseplate,
        opt_tras_view_economicactivity,

        opt_tras_handle_documnettype,
        opt_tras_handle_documentid,
        opt_tras_handle_tradename,
        opt_tras_handle_name,
        opt_tras_handle_contactname,
        opt_tras_handle_address,
        opt_tras_handle_phone,
        opt_tras_handle_email,
        opt_tras_handle_nima,
        opt_tras_handle_cnae,
        opt_tras_handle_inscription,
        opt_tras_handle_opt,
        opt_tras_handle_licenseplate,
        opt_tras_handle_economicactivity,

        ori_tras_view,
        ori_tras_expand,
        ori_tras_handle,
        ori_tras_view_name,
        ori_tras_view_contactname,
        ori_tras_view_address,
        ori_tras_view_country,
        ori_tras_view_community,
        ori_tras_view_phone,
        ori_tras_view_email,
        ori_tras_view_nima,
        ori_tras_view_cnae,
        ori_tras_view_inscription,
        ori_tras_view_opt,
        ori_tras_view_licenseplate,
        ori_tras_view_economicactivity,

        ori_tras_handle_documnettype,
        ori_tras_handle_documentid,
        ori_tras_handle_tradename,
        ori_tras_handle_name,
        ori_tras_handle_contactname,
        ori_tras_handle_address,
        ori_tras_handle_phone,
        ori_tras_handle_email,
        ori_tras_handle_nima,
        ori_tras_handle_cnae,
        ori_tras_handle_inscription,
        ori_tras_handle_opt,
        ori_tras_handle_licenseplate,
        ori_tras_handle_economicactivity,
      
        aut_tras_view,
        aut_tras_expand,
        aut_tras_handle,
        aut_tras_view_name,
        aut_tras_view_contactname,
        aut_tras_view_address,
        aut_tras_view_country,
        aut_tras_view_community,
        aut_tras_view_phone,
        aut_tras_view_email,
        aut_tras_view_nima,
        aut_tras_view_cnae,
        aut_tras_view_inscription,
        aut_tras_view_opt,
        aut_tras_view_licenseplate,
        aut_tras_view_economicactivity,

        aut_tras_handle_documnettype,
        aut_tras_handle_documentid,
        aut_tras_handle_tradename,
        aut_tras_handle_name,
        aut_tras_handle_contactname,
        aut_tras_handle_address,
        aut_tras_handle_phone,
        aut_tras_handle_email,
        aut_tras_handle_nima,
        aut_tras_handle_cnae,
        aut_tras_handle_inscription,
        aut_tras_handle_opt,
        aut_tras_handle_licenseplate,
        aut_tras_handle_economicactivity,

        dest_tras_view,
        dest_tras_expand,
        dest_tras_handle,
        dest_tras_view_name,
        dest_tras_view_contactname,
        dest_tras_view_address,
        dest_tras_view_country,
        dest_tras_view_community,
        dest_tras_view_phone,
        dest_tras_view_email,
        dest_tras_view_nima,
        dest_tras_view_cnae,
        dest_tras_view_inscription,
        dest_tras_view_opt,
        dest_tras_view_licenseplate,
        dest_tras_view_economicactivity,

        dest_tras_handle_documnettype,
        dest_tras_handle_documentid,
        dest_tras_handle_tradename,
        dest_tras_handle_name,
        dest_tras_handle_contactname,
        dest_tras_handle_address,
        dest_tras_handle_phone,
        dest_tras_handle_email,
        dest_tras_handle_nima,
        dest_tras_handle_cnae,
        dest_tras_handle_inscription,
        dest_tras_handle_opt,
        dest_tras_handle_licenseplate,
        dest_tras_handle_economicactivity,

        aut_dest_view,
        aut_dest_expand,
        aut_dest_handle,
        aut_dest_view_name,
        aut_dest_view_contactname,
        aut_dest_view_address,
        aut_dest_view_country,
        aut_dest_view_community,
        aut_dest_view_phone,
        aut_dest_view_email,
        aut_dest_view_nima,
        aut_dest_view_cnae,
        aut_dest_view_inscription,
        aut_dest_view_opt,
        aut_dest_view_licenseplate,
        aut_dest_view_economicactivity,

        aut_dest_handle_documnettype,
        aut_dest_handle_documentid,
        aut_dest_handle_tradename,
        aut_dest_handle_name,
        aut_dest_handle_contactname,
        aut_dest_handle_address,
        aut_dest_handle_phone,
        aut_dest_handle_email,
        aut_dest_handle_nima,
        aut_dest_handle_cnae,
        aut_dest_handle_inscription,
        aut_dest_handle_opt,
        aut_dest_handle_licenseplate,
        aut_dest_handle_economicactivity,

        rel_trans_view,
        rel_trans_expand,
        rel_trans_handle,
        rel_trans_view_name,
        rel_trans_view_contactname,
        rel_trans_view_address,
        rel_trans_view_country,
        rel_trans_view_community,
        rel_trans_view_phone,
        rel_trans_view_email,
        rel_trans_view_nima,
        rel_trans_view_cnae,
        rel_trans_view_inscription,
        rel_trans_view_opt,
        rel_trans_view_licenseplate,
        rel_trans_view_economicactivity,

        rel_trans_handle_documnettype,
        rel_trans_handle_documentid,
        rel_trans_handle_tradename,
        rel_trans_handle_name,
        rel_trans_handle_contactname,
        rel_trans_handle_address,
        rel_trans_handle_phone,
        rel_trans_handle_email,
        rel_trans_handle_nima,
        rel_trans_handle_cnae,
        rel_trans_handle_inscription,
        rel_trans_handle_opt,
        rel_trans_handle_licenseplate,
        rel_trans_handle_economicactivity,

        resp_prod_view,
        resp_prod_expand,
        resp_prod_handle,
        resp_prod_view_name,
        resp_prod_view_contactname,
        resp_prod_view_address,
        resp_prod_view_country,
        resp_prod_view_community,
        resp_prod_view_phone,
        resp_prod_view_email,
        resp_prod_view_nima,
        resp_prod_view_cnae,
        resp_prod_view_inscription,
        resp_prod_view_opt,
        resp_prod_view_licenseplate,
        resp_prod_view_economicactivity,

        resp_prod_handle_documnettype,
        resp_prod_handle_documentid,
        resp_prod_handle_tradename,
        resp_prod_handle_name,
        resp_prod_handle_contactname,
        resp_prod_handle_address,
        resp_prod_handle_phone,
        resp_prod_handle_email,
        resp_prod_handle_nima,
        resp_prod_handle_cnae,
        resp_prod_handle_inscription,
        resp_prod_handle_opt,
        resp_prod_handle_licenseplate,
        resp_prod_handle_economicactivity,

        number_dt_view,
        code_description_view,
        code_treatmenttype_origin_view,
        code_treatmenttype_destine_view,
        code_processtype_origin_view,
        code_processtype_destine_view,
        code_dangerousness_view,
        charac_danger_view,
        date_refund_view,
        reason_refund_view,
        action_refund_view,

        number_dt_handle,
        code_description_handle,
        code_treatmenttype_origin_handle,
        code_treatmenttype_destine_handle,
        code_processtype_origin_handle,
        code_processtype_destine_handle,
        code_dangerousness_handle,
        charac_danger_handle,
        date_refund_handle,
        reason_refund_handle,
        action_refund_handle,

        share_form_view,
        share_form_handle,
        share_form_opt_tras,
        share_form_ori_tras,
        share_form_aut_tras,
        share_form_dest_tras,
        share_form_aut_dest,
        share_form_rel_trans,
        share_form_resp_prod,

        sendbyemail_form_view,
        sendbyemail_form_handle,
        sendbyemail_form_opt_tras,
        sendbyemail_form_ori_tras,
        sendbyemail_form_aut_tras,
        sendbyemail_form_dest_tras,
        sendbyemail_form_aut_dest,
        sendbyemail_form_rel_trans,
        sendbyemail_form_resp_prod,

        filenameexcel,
        signature_default,
        signature_default_des_tras
          
      } = action.payload;
       return {
        ...state,
        id:id || 0,
        typesdi_id:typesdi_id || 0,
       
        opt_tras_view:(opt_tras_view!==undefined || opt_tras_view!==null)? opt_tras_view: true,
        opt_tras_expand:(opt_tras_expand!==undefined || opt_tras_expand!==null)? opt_tras_expand: true,
        opt_tras_handle:(opt_tras_handle!==undefined || opt_tras_handle!==null)? opt_tras_handle: true,
        opt_tras_view_name:(opt_tras_view_name!==undefined || opt_tras_view_name!==null)? opt_tras_view_name: true,
        opt_tras_view_contactname:(opt_tras_view_contactname!==undefined || opt_tras_view_contactname!==null)? opt_tras_view_contactname: true,
        opt_tras_view_address:(opt_tras_view_address!==undefined || opt_tras_view_address!==null)? opt_tras_view_address: true,
        opt_tras_view_country:(opt_tras_view_country!==undefined || opt_tras_view_country!==null)? opt_tras_view_country: true,
        opt_tras_view_community: (opt_tras_view_community!==undefined || opt_tras_view_community!==null)? opt_tras_view_community: true,
        opt_tras_view_phone:(opt_tras_view_phone!==undefined || opt_tras_view_phone!==null)? opt_tras_view_phone: true,
        opt_tras_view_email:(opt_tras_view_email!==undefined || opt_tras_view_email!==null)? opt_tras_view_email: true,
        opt_tras_view_nima: (opt_tras_view_nima!==undefined || opt_tras_view_nima!==null)? opt_tras_view_nima: true,
        opt_tras_view_cnae:(opt_tras_view_cnae!==undefined || opt_tras_view_cnae!==null)? opt_tras_view_cnae: true,
        opt_tras_view_inscription:(opt_tras_view_inscription!==undefined || opt_tras_view_inscription!==null)? opt_tras_view_inscription: true,
        opt_tras_view_opt:(opt_tras_view_opt!==undefined || opt_tras_view_opt!==null)? opt_tras_view_opt: true,
        opt_tras_view_licenseplate:(opt_tras_view_licenseplate!==undefined || opt_tras_view_licenseplate!==null)? opt_tras_view_licenseplate: true,
        opt_tras_view_economicactivity:(opt_tras_view_economicactivity!==undefined || opt_tras_view_economicactivity!==null)? opt_tras_view_economicactivity: true,
        
        opt_tras_handle_documnettype:(opt_tras_handle_documnettype!==undefined || opt_tras_handle_documnettype!==null)? opt_tras_handle_documnettype: true,
        opt_tras_handle_documentid:(opt_tras_handle_documentid!==undefined || opt_tras_handle_documentid!==null)? opt_tras_handle_documentid: true,
        opt_tras_handle_tradename:(opt_tras_handle_tradename!==undefined || opt_tras_handle_tradename!==null)? opt_tras_handle_tradename: true,
        opt_tras_handle_name:(opt_tras_handle_name!==undefined || opt_tras_handle_name!==null)? opt_tras_handle_name: true,
        opt_tras_handle_contactname:(opt_tras_handle_contactname!==undefined || opt_tras_handle_contactname!==null)? opt_tras_handle_contactname: true,
        opt_tras_handle_address:(opt_tras_handle_address!==undefined || opt_tras_handle_address!==null)? opt_tras_handle_address: true,
        opt_tras_handle_phone:(opt_tras_handle_phone!==undefined || opt_tras_handle_phone!==null)? opt_tras_handle_phone: true,
        opt_tras_handle_email:(opt_tras_handle_email!==undefined || opt_tras_handle_email!==null)? opt_tras_handle_email: true,
        opt_tras_handle_nima:(opt_tras_handle_nima!==undefined || opt_tras_handle_nima!==null)? opt_tras_handle_nima: true,
        opt_tras_handle_cnae:(opt_tras_handle_cnae!==undefined || opt_tras_handle_cnae!==null)? opt_tras_handle_cnae: true,
        opt_tras_handle_inscription:(opt_tras_handle_inscription!==undefined || opt_tras_handle_inscription!==null)? opt_tras_handle_inscription: true,
        opt_tras_handle_opt:(opt_tras_handle_opt!==undefined || opt_tras_handle_opt!==null)? opt_tras_handle_opt: true,
        opt_tras_handle_licenseplate:(opt_tras_handle_licenseplate!==undefined || opt_tras_handle_licenseplate!==null)? opt_tras_handle_licenseplate: true,
        opt_tras_handle_economicactivity:(opt_tras_handle_economicactivity!==undefined || opt_tras_handle_economicactivity!==null)? opt_tras_handle_economicactivity: true,

        ori_tras_view:(ori_tras_view!==undefined || ori_tras_view!==null)? ori_tras_view: true,
        ori_tras_expand:(ori_tras_expand!==undefined || ori_tras_expand!==null)? ori_tras_expand: true,
        ori_tras_handle:(ori_tras_handle!==undefined || ori_tras_handle!==null)? ori_tras_handle: true,
        ori_tras_view_name:(ori_tras_view_name!==undefined || ori_tras_view_name!==null)? ori_tras_view_name: true,
        ori_tras_view_contactname:(ori_tras_view_contactname!==undefined || ori_tras_view_contactname!==null)? ori_tras_view_contactname: true,
        ori_tras_view_address:(ori_tras_view_address!==undefined || ori_tras_view_address!==null)? ori_tras_view_address: true,
        ori_tras_view_country:(ori_tras_view_country!==undefined || ori_tras_view_country!==null)? ori_tras_view_country: true,
        ori_tras_view_community: (ori_tras_view_community!==undefined || ori_tras_view_community!==null)? ori_tras_view_community: true,
        ori_tras_view_phone:(ori_tras_view_phone!==undefined || ori_tras_view_phone!==null)? ori_tras_view_phone: true,
        ori_tras_view_email:(ori_tras_view_email!==undefined || ori_tras_view_email!==null)? ori_tras_view_email: true,
        ori_tras_view_nima: (ori_tras_view_nima!==undefined || ori_tras_view_nima!==null)? ori_tras_view_nima: true,
        ori_tras_view_cnae:(ori_tras_view_cnae!==undefined || ori_tras_view_cnae!==null)? ori_tras_view_cnae: true,
        ori_tras_view_inscription:(ori_tras_view_inscription!==undefined || ori_tras_view_inscription!==null)? ori_tras_view_inscription: true,
        ori_tras_view_opt:(ori_tras_view_opt!==undefined || ori_tras_view_opt!==null)? ori_tras_view_opt: true,
        ori_tras_view_licenseplate:(ori_tras_view_licenseplate!==undefined || ori_tras_view_licenseplate!==null)? ori_tras_view_licenseplate: true,
        ori_tras_view_economicactivity:(ori_tras_view_economicactivity!==undefined || ori_tras_view_economicactivity!==null)? ori_tras_view_economicactivity: true,
       
        ori_tras_handle_documnettype:(ori_tras_handle_documnettype!==undefined || ori_tras_handle_documnettype!==null)? ori_tras_handle_documnettype: true,
        ori_tras_handle_documentid:(ori_tras_handle_documentid!==undefined || ori_tras_handle_documentid!==null)? ori_tras_handle_documentid: true,
        ori_tras_handle_tradename:(ori_tras_handle_tradename!==undefined || ori_tras_handle_tradename!==null)? ori_tras_handle_tradename: true,
        ori_tras_handle_name:(ori_tras_handle_name!==undefined || ori_tras_handle_name!==null)? ori_tras_handle_name: true,
        ori_tras_handle_contactname:(ori_tras_handle_contactname!==undefined || ori_tras_handle_contactname!==null)? ori_tras_handle_contactname: true,
        ori_tras_handle_address:(ori_tras_handle_address!==undefined || ori_tras_handle_address!==null)? ori_tras_handle_address: true,
        ori_tras_handle_phone:(ori_tras_handle_phone!==undefined || ori_tras_handle_phone!==null)? ori_tras_handle_phone: true,
        ori_tras_handle_email:(ori_tras_handle_email!==undefined || ori_tras_handle_email!==null)? ori_tras_handle_email: true,
        ori_tras_handle_nima:(ori_tras_handle_nima!==undefined || ori_tras_handle_nima!==null)? ori_tras_handle_nima: true,
        ori_tras_handle_cnae:(ori_tras_handle_cnae!==undefined || ori_tras_handle_cnae!==null)? ori_tras_handle_cnae: true,
        ori_tras_handle_inscription:(ori_tras_handle_inscription!==undefined || ori_tras_handle_inscription!==null)? ori_tras_handle_inscription: true,
        ori_tras_handle_opt:(ori_tras_handle_opt!==undefined || ori_tras_handle_opt!==null)? ori_tras_handle_opt: true,
        ori_tras_handle_licenseplate:(ori_tras_handle_licenseplate!==undefined || ori_tras_handle_licenseplate!==null)? ori_tras_handle_licenseplate: true,
        ori_tras_handle_economicactivity:(ori_tras_handle_economicactivity!==undefined || ori_tras_handle_economicactivity!==null)? ori_tras_handle_economicactivity: true,

        aut_tras_view:(aut_tras_view!==undefined || aut_tras_view!==null)? aut_tras_view: true,
        aut_tras_expand:(aut_tras_expand!==undefined || aut_tras_expand!==null)? aut_tras_expand: true,
        aut_tras_handle:(aut_tras_handle!==undefined || aut_tras_handle!==null)? aut_tras_handle: true,
        aut_tras_view_name:(aut_tras_view_name!==undefined || aut_tras_view_name!==null)? aut_tras_view_name: true,
        aut_tras_view_contactname:(aut_tras_view_contactname!==undefined || aut_tras_view_contactname!==null)? aut_tras_view_contactname: true,
        aut_tras_view_address:(aut_tras_view_address!==undefined || aut_tras_view_address!==null)? aut_tras_view_address: true,
        aut_tras_view_country:(aut_tras_view_country!==undefined || aut_tras_view_country!==null)? aut_tras_view_country: true,
        aut_tras_view_community: (aut_tras_view_community!==undefined || aut_tras_view_community!==null)? aut_tras_view_community: true,
        aut_tras_view_phone:(aut_tras_view_phone!==undefined || aut_tras_view_phone!==null)? aut_tras_view_phone: true,
        aut_tras_view_email:(aut_tras_view_email!==undefined || aut_tras_view_email!==null)? aut_tras_view_email: true,
        aut_tras_view_nima: (aut_tras_view_nima!==undefined || aut_tras_view_nima!==null)? aut_tras_view_nima: true,
        aut_tras_view_cnae:(aut_tras_view_cnae!==undefined || aut_tras_view_cnae!==null)? aut_tras_view_cnae: true,
        aut_tras_view_inscription:(aut_tras_view_inscription!==undefined || aut_tras_view_inscription!==null)? aut_tras_view_inscription: true,
        aut_tras_view_opt:(aut_tras_view_opt!==undefined || aut_tras_view_opt!==null)? aut_tras_view_opt: true,
        aut_tras_view_licenseplate:(aut_tras_view_licenseplate!==undefined || aut_tras_view_licenseplate!==null)? aut_tras_view_licenseplate: true,
        aut_tras_view_economicactivity:(aut_tras_view_economicactivity!==undefined || aut_tras_view_economicactivity!==null)? aut_tras_view_economicactivity: true,
        
        aut_tras_handle_documnettype:(aut_tras_handle_documnettype!==undefined || aut_tras_handle_documnettype!==null)? aut_tras_handle_documnettype: true,
        aut_tras_handle_documentid:(aut_tras_handle_documentid!==undefined || aut_tras_handle_documentid!==null)? aut_tras_handle_documentid: true,
        aut_tras_handle_tradename:(aut_tras_handle_tradename!==undefined || aut_tras_handle_tradename!==null)? aut_tras_handle_tradename: true,
        aut_tras_handle_name:(aut_tras_handle_name!==undefined || aut_tras_handle_name!==null)? aut_tras_handle_name: true,
        aut_tras_handle_contactname:(aut_tras_handle_contactname!==undefined || aut_tras_handle_contactname!==null)? aut_tras_handle_contactname: true,
        aut_tras_handle_address:(aut_tras_handle_address!==undefined || aut_tras_handle_address!==null)? aut_tras_handle_address: true,
        aut_tras_handle_phone:(aut_tras_handle_phone!==undefined || aut_tras_handle_phone!==null)? aut_tras_handle_phone: true,
        aut_tras_handle_email:(aut_tras_handle_email!==undefined || aut_tras_handle_email!==null)? aut_tras_handle_email: true,
        aut_tras_handle_nima:(aut_tras_handle_nima!==undefined || aut_tras_handle_nima!==null)? aut_tras_handle_nima: true,
        aut_tras_handle_cnae:(aut_tras_handle_cnae!==undefined || aut_tras_handle_cnae!==null)? aut_tras_handle_cnae: true,
        aut_tras_handle_inscription:(aut_tras_handle_inscription!==undefined || aut_tras_handle_inscription!==null)? aut_tras_handle_inscription: true,
        aut_tras_handle_opt:(aut_tras_handle_opt!==undefined || aut_tras_handle_opt!==null)? aut_tras_handle_opt: true,
        aut_tras_handle_licenseplate:(aut_tras_handle_licenseplate!==undefined || aut_tras_handle_licenseplate!==null)? aut_tras_handle_licenseplate: true,
        aut_tras_handle_economicactivity:(aut_tras_handle_economicactivity!==undefined || aut_tras_handle_economicactivity!==null)? aut_tras_handle_economicactivity: true,

        dest_tras_view:(dest_tras_view!==undefined || dest_tras_view!==null)? dest_tras_view: true,
        dest_tras_expand:(dest_tras_expand!==undefined || dest_tras_expand!==null)? dest_tras_expand: true,
        dest_tras_handle:(dest_tras_handle!==undefined || dest_tras_handle!==null)? dest_tras_handle: true,
        dest_tras_view_name:(dest_tras_view_name!==undefined || dest_tras_view_name!==null)? dest_tras_view_name: true,
        dest_tras_view_contactname:(dest_tras_view_contactname!==undefined || dest_tras_view_contactname!==null)? dest_tras_view_contactname: true,
        dest_tras_view_address:(dest_tras_view_address!==undefined || dest_tras_view_address!==null)? dest_tras_view_address: true,
        dest_tras_view_country:(dest_tras_view_country!==undefined || dest_tras_view_country!==null)? dest_tras_view_country: true,
        dest_tras_view_community: (dest_tras_view_community!==undefined || dest_tras_view_community!==null)? dest_tras_view_community: true,
        dest_tras_view_phone:(dest_tras_view_phone!==undefined || dest_tras_view_phone!==null)? dest_tras_view_phone: true,
        dest_tras_view_email:(dest_tras_view_email!==undefined || dest_tras_view_email!==null)? dest_tras_view_email: true,
        dest_tras_view_nima: (dest_tras_view_nima!==undefined || dest_tras_view_nima!==null)? dest_tras_view_nima: true,
        dest_tras_view_cnae:(dest_tras_view_cnae!==undefined || dest_tras_view_cnae!==null)? dest_tras_view_cnae: true,
        dest_tras_view_inscription:(dest_tras_view_inscription!==undefined || dest_tras_view_inscription!==null)? dest_tras_view_inscription: true,
        dest_tras_view_opt:(dest_tras_view_opt!==undefined || dest_tras_view_opt!==null)? dest_tras_view_opt: true,
        dest_tras_view_licenseplate:(dest_tras_view_licenseplate!==undefined || dest_tras_view_licenseplate!==null)? dest_tras_view_licenseplate: true,
        dest_tras_view_economicactivity:(dest_tras_view_economicactivity!==undefined || dest_tras_view_economicactivity!==null)? dest_tras_view_economicactivity: true,
        
        dest_tras_handle_documnettype:(dest_tras_handle_documnettype!==undefined || dest_tras_handle_documnettype!==null)? dest_tras_handle_documnettype: true,
        dest_tras_handle_documentid:(dest_tras_handle_documentid!==undefined || dest_tras_handle_documentid!==null)? dest_tras_handle_documentid: true,
        dest_tras_handle_tradename:(dest_tras_handle_tradename!==undefined || dest_tras_handle_tradename!==null)? dest_tras_handle_tradename: true,
        dest_tras_handle_name:(dest_tras_handle_name!==undefined || dest_tras_handle_name!==null)? dest_tras_handle_name: true,
        dest_tras_handle_contactname:(dest_tras_handle_contactname!==undefined || dest_tras_handle_contactname!==null)? dest_tras_handle_contactname: true,
        dest_tras_handle_address:(dest_tras_handle_address!==undefined || dest_tras_handle_address!==null)? dest_tras_handle_address: true,
        dest_tras_handle_phone:(dest_tras_handle_phone!==undefined || dest_tras_handle_phone!==null)? dest_tras_handle_phone: true,
        dest_tras_handle_email:(dest_tras_handle_email!==undefined || dest_tras_handle_email!==null)? dest_tras_handle_email: true,
        dest_tras_handle_nima:(dest_tras_handle_nima!==undefined || dest_tras_handle_nima!==null)? dest_tras_handle_nima: true,
        dest_tras_handle_cnae:(dest_tras_handle_cnae!==undefined || dest_tras_handle_cnae!==null)? dest_tras_handle_cnae: true,
        dest_tras_handle_inscription:(dest_tras_handle_inscription!==undefined || dest_tras_handle_inscription!==null)? dest_tras_handle_inscription: true,
        dest_tras_handle_opt:(dest_tras_handle_opt!==undefined || dest_tras_handle_opt!==null)? dest_tras_handle_opt: true,
        dest_tras_handle_licenseplate:(dest_tras_handle_licenseplate!==undefined || dest_tras_handle_licenseplate!==null)? dest_tras_handle_licenseplate: true,
        dest_tras_handle_economicactivity:(dest_tras_handle_economicactivity!==undefined || dest_tras_handle_economicactivity!==null)? dest_tras_handle_economicactivity: true,
        
        aut_dest_view:(aut_dest_view!==undefined || aut_dest_view!==null)? aut_dest_view: true,
        aut_dest_expand:(aut_dest_expand!==undefined || aut_dest_expand!==null)? aut_dest_expand: true,
        aut_dest_handle:(aut_dest_handle!==undefined || aut_dest_handle!==null)? aut_dest_handle: true,
        aut_dest_view_name:(aut_dest_view_name!==undefined || aut_dest_view_name!==null)? aut_dest_view_name: true,
        aut_dest_view_contactname:(aut_dest_view_contactname!==undefined || aut_dest_view_contactname!==null)? aut_dest_view_contactname: true,
        aut_dest_view_address:(aut_dest_view_address!==undefined || aut_dest_view_address!==null)? aut_dest_view_address: true,
        aut_dest_view_country:(aut_dest_view_country!==undefined || aut_dest_view_country!==null)? aut_dest_view_country: true,
        aut_dest_view_community: (aut_dest_view_community!==undefined || aut_dest_view_community!==null)? aut_dest_view_community: true,
        aut_dest_view_phone:(aut_dest_view_phone!==undefined || aut_dest_view_phone!==null)? aut_dest_view_phone: true,
        aut_dest_view_email:(aut_dest_view_email!==undefined || aut_dest_view_email!==null)? aut_dest_view_email: true,
        aut_dest_view_nima: (aut_dest_view_nima!==undefined || aut_dest_view_nima!==null)? aut_dest_view_nima: true,
        aut_dest_view_cnae:(aut_dest_view_cnae!==undefined || aut_dest_view_cnae!==null)? aut_dest_view_cnae: true,
        aut_dest_view_inscription:(aut_dest_view_inscription!==undefined || aut_dest_view_inscription!==null)? aut_dest_view_inscription: true,
        aut_dest_view_opt:(aut_dest_view_opt!==undefined || aut_dest_view_opt!==null)? aut_dest_view_opt: true,
        aut_dest_view_licenseplate:(aut_dest_view_licenseplate!==undefined || aut_dest_view_licenseplate!==null)? aut_dest_view_licenseplate: true,
        aut_dest_view_economicactivity:(aut_dest_view_economicactivity!==undefined || aut_dest_view_economicactivity!==null)? aut_dest_view_economicactivity: true,
        
        aut_dest_handle_documnettype:(aut_dest_handle_documnettype!==undefined || aut_dest_handle_documnettype!==null)? aut_dest_handle_documnettype: true,
        aut_dest_handle_documentid:(aut_dest_handle_documentid!==undefined || aut_dest_handle_documentid!==null)? aut_dest_handle_documentid: true,
        aut_dest_handle_tradename:(aut_dest_handle_tradename!==undefined || aut_dest_handle_tradename!==null)? aut_dest_handle_tradename: true,
        aut_dest_handle_name:(aut_dest_handle_name!==undefined || aut_dest_handle_name!==null)? aut_dest_handle_name: true,
        aut_dest_handle_contactname:(aut_dest_handle_contactname!==undefined || aut_dest_handle_contactname!==null)? aut_dest_handle_contactname: true,
        aut_dest_handle_address:(aut_dest_handle_address!==undefined || aut_dest_handle_address!==null)? aut_dest_handle_address: true,
        aut_dest_handle_phone:(aut_dest_handle_phone!==undefined || aut_dest_handle_phone!==null)? aut_dest_handle_phone: true,
        aut_dest_handle_email:(aut_dest_handle_email!==undefined || aut_dest_handle_email!==null)? aut_dest_handle_email: true,
        aut_dest_handle_nima:(aut_dest_handle_nima!==undefined || aut_dest_handle_nima!==null)? aut_dest_handle_nima: true,
        aut_dest_handle_cnae:(aut_dest_handle_cnae!==undefined || aut_dest_handle_cnae!==null)? aut_dest_handle_cnae: true,
        aut_dest_handle_inscription:(aut_dest_handle_inscription!==undefined || aut_dest_handle_inscription!==null)? aut_dest_handle_inscription: true,
        aut_dest_handle_opt:(aut_dest_handle_opt!==undefined || aut_dest_handle_opt!==null)? aut_dest_handle_opt: true,
        aut_dest_handle_licenseplate:(aut_dest_handle_licenseplate!==undefined || aut_dest_handle_licenseplate!==null)? aut_dest_handle_licenseplate: true,
        aut_dest_handle_economicactivity:(aut_dest_handle_economicactivity!==undefined || aut_dest_handle_economicactivity!==null)? aut_dest_handle_economicactivity: true,
        
        rel_trans_view:(rel_trans_view!==undefined || rel_trans_view!==null)? rel_trans_view: true,
        rel_trans_expand:(rel_trans_expand!==undefined || rel_trans_expand!==null)? rel_trans_expand: true,
        rel_trans_handle:(rel_trans_handle!==undefined || rel_trans_handle!==null)? rel_trans_handle: true,
        rel_trans_view_name:(rel_trans_view_name!==undefined || rel_trans_view_name!==null)? rel_trans_view_name: true,
        rel_trans_view_contactname:(rel_trans_view_contactname!==undefined || rel_trans_view_contactname!==null)? rel_trans_view_contactname: true,
        rel_trans_view_address:(rel_trans_view_address!==undefined || rel_trans_view_address!==null)? rel_trans_view_address: true,
        rel_trans_view_country:(rel_trans_view_country!==undefined || rel_trans_view_country!==null)? rel_trans_view_country: true,
        rel_trans_view_community: (rel_trans_view_community!==undefined || rel_trans_view_community!==null)? rel_trans_view_community: true,
        rel_trans_view_phone:(rel_trans_view_phone!==undefined || rel_trans_view_phone!==null)? rel_trans_view_phone: true,
        rel_trans_view_email:(rel_trans_view_email!==undefined || rel_trans_view_email!==null)? rel_trans_view_email: true,
        rel_trans_view_nima: (rel_trans_view_nima!==undefined || rel_trans_view_nima!==null)? rel_trans_view_nima: true,
        rel_trans_view_cnae:(rel_trans_view_cnae!==undefined || rel_trans_view_cnae!==null)? rel_trans_view_cnae: true,
        rel_trans_view_inscription:(rel_trans_view_inscription!==undefined || rel_trans_view_inscription!==null)? rel_trans_view_inscription: true,
        rel_trans_view_opt:(rel_trans_view_opt!==undefined || rel_trans_view_opt!==null)? rel_trans_view_opt: true,
        rel_trans_view_licenseplate:(rel_trans_view_licenseplate!==undefined || rel_trans_view_licenseplate!==null)? rel_trans_view_licenseplate: true,
        rel_trans_view_economicactivity:(rel_trans_view_economicactivity!==undefined || rel_trans_view_economicactivity!==null)? rel_trans_view_economicactivity: true,
        
        rel_trans_handle_documnettype:(rel_trans_handle_documnettype!==undefined || rel_trans_handle_documnettype!==null)? rel_trans_handle_documnettype: true,
        rel_trans_handle_documentid:(rel_trans_handle_documentid!==undefined || rel_trans_handle_documentid!==null)? rel_trans_handle_documentid: true,
        rel_trans_handle_tradename:(rel_trans_handle_tradename!==undefined || rel_trans_handle_tradename!==null)? rel_trans_handle_tradename: true,
        rel_trans_handle_name:(rel_trans_handle_name!==undefined || rel_trans_handle_name!==null)? rel_trans_handle_name: true,
        rel_trans_handle_contactname:(rel_trans_handle_contactname!==undefined || rel_trans_handle_contactname!==null)? rel_trans_handle_contactname: true,
        rel_trans_handle_address:(rel_trans_handle_address!==undefined || rel_trans_handle_address!==null)? rel_trans_handle_address: true,
        rel_trans_handle_phone:(rel_trans_handle_phone!==undefined || rel_trans_handle_phone!==null)? rel_trans_handle_phone: true,
        rel_trans_handle_email:(rel_trans_handle_email!==undefined || rel_trans_handle_email!==null)? rel_trans_handle_email: true,
        rel_trans_handle_nima:(rel_trans_handle_nima!==undefined || rel_trans_handle_nima!==null)? rel_trans_handle_nima: true,
        rel_trans_handle_cnae:(rel_trans_handle_cnae!==undefined || rel_trans_handle_cnae!==null)? rel_trans_handle_cnae: true,
        rel_trans_handle_inscription:(rel_trans_handle_inscription!==undefined || rel_trans_handle_inscription!==null)? rel_trans_handle_inscription: true,
        rel_trans_handle_opt:(rel_trans_handle_opt!==undefined || rel_trans_handle_opt!==null)? rel_trans_handle_opt: true,
        rel_trans_handle_licenseplate:(rel_trans_handle_licenseplate!==undefined || rel_trans_handle_licenseplate!==null)? rel_trans_handle_licenseplate: true,
        rel_trans_handle_economicactivity:(rel_trans_handle_economicactivity!==undefined || rel_trans_handle_economicactivity!==null)? rel_trans_handle_economicactivity: true,
        
        resp_prod_view:(resp_prod_view!==undefined || resp_prod_view!==null)? resp_prod_view: true,
        resp_prod_expand:(resp_prod_expand!==undefined || resp_prod_expand!==null)? resp_prod_expand: true,
        resp_prod_handle:(resp_prod_handle!==undefined || resp_prod_handle!==null)? resp_prod_handle: true,
        resp_prod_view_name:(resp_prod_view_name!==undefined || resp_prod_view_name!==null)? resp_prod_view_name: true,
        resp_prod_view_contactname:(resp_prod_view_contactname!==undefined || resp_prod_view_contactname!==null)? resp_prod_view_contactname: true,
        resp_prod_view_address:(resp_prod_view_address!==undefined || resp_prod_view_address!==null)? resp_prod_view_address: true,
        resp_prod_view_country:(resp_prod_view_country!==undefined || resp_prod_view_country!==null)? resp_prod_view_country: true,
        resp_prod_view_community: (resp_prod_view_community!==undefined || resp_prod_view_community!==null)? resp_prod_view_community: true,
        resp_prod_view_phone:(resp_prod_view_phone!==undefined || resp_prod_view_phone!==null)? resp_prod_view_phone: true,
        resp_prod_view_email:(resp_prod_view_email!==undefined || resp_prod_view_email!==null)? resp_prod_view_email: true,
        resp_prod_view_nima: (resp_prod_view_nima!==undefined || resp_prod_view_nima!==null)? resp_prod_view_nima: true,
        resp_prod_view_cnae:(resp_prod_view_cnae!==undefined || resp_prod_view_cnae!==null)? resp_prod_view_cnae: true,
        resp_prod_view_inscription:(resp_prod_view_inscription!==undefined || resp_prod_view_inscription!==null)? resp_prod_view_inscription: true,
        resp_prod_view_opt:(resp_prod_view_opt!==undefined || resp_prod_view_opt!==null)? resp_prod_view_opt: true,
        resp_prod_view_licenseplate:(resp_prod_view_licenseplate!==undefined || resp_prod_view_licenseplate!==null)? resp_prod_view_licenseplate: true,
        resp_prod_view_economicactivity:(resp_prod_view_economicactivity!==undefined || resp_prod_view_economicactivity!==null)? resp_prod_view_economicactivity: true,
       
        resp_prod_handle_documnettype:(resp_prod_handle_documnettype!==undefined || resp_prod_handle_documnettype!==null)? resp_prod_handle_documnettype: true,
        resp_prod_handle_documentid:(resp_prod_handle_documentid!==undefined || resp_prod_handle_documentid!==null)? resp_prod_handle_documentid: true,
        resp_prod_handle_tradename:(resp_prod_handle_tradename!==undefined || resp_prod_handle_tradename!==null)? resp_prod_handle_tradename: true,
        resp_prod_handle_name:(resp_prod_handle_name!==undefined || resp_prod_handle_name!==null)? resp_prod_handle_name: true,
        resp_prod_handle_contactname:(resp_prod_handle_contactname!==undefined || resp_prod_handle_contactname!==null)? resp_prod_handle_contactname: true,
        resp_prod_handle_address:(resp_prod_handle_address!==undefined || resp_prod_handle_address!==null)? resp_prod_handle_address: true,
        resp_prod_handle_phone:(resp_prod_handle_phone!==undefined || resp_prod_handle_phone!==null)? resp_prod_handle_phone: true,
        resp_prod_handle_email:(resp_prod_handle_email!==undefined || resp_prod_handle_email!==null)? resp_prod_handle_email: true,
        resp_prod_handle_nima:(resp_prod_handle_nima!==undefined || resp_prod_handle_nima!==null)? resp_prod_handle_nima: true,
        resp_prod_handle_cnae:(resp_prod_handle_cnae!==undefined || resp_prod_handle_cnae!==null)? resp_prod_handle_cnae: true,
        resp_prod_handle_inscription:(resp_prod_handle_inscription!==undefined || resp_prod_handle_inscription!==null)? resp_prod_handle_inscription: true,
        resp_prod_handle_opt:(resp_prod_handle_opt!==undefined || resp_prod_handle_opt!==null)? resp_prod_handle_opt: true,
        resp_prod_handle_licenseplate:(resp_prod_handle_licenseplate!==undefined || resp_prod_handle_licenseplate!==null)? resp_prod_handle_licenseplate: true,
        resp_prod_handle_economicactivity:(resp_prod_handle_economicactivity!==undefined || resp_prod_handle_economicactivity!==null)? resp_prod_handle_economicactivity: true,
       
        number_dt_view:(number_dt_view!==undefined || number_dt_view!==null)? number_dt_view: true,
        code_description_view:(code_description_view!==undefined || code_description_view!==null)? code_description_view: false,
        code_treatmenttype_origin_view:(code_treatmenttype_origin_view!==undefined || code_treatmenttype_origin_view!==null)? code_treatmenttype_origin_view: false,
        code_treatmenttype_destine_view:(code_treatmenttype_destine_view!==undefined || code_treatmenttype_destine_view!==null)? code_treatmenttype_destine_view: true,
        code_processtype_origin_view:(code_processtype_origin_view!==undefined || code_processtype_origin_view!==null)? code_processtype_origin_view: false,
        code_processtype_destine_view:(code_processtype_destine_view!==undefined || code_processtype_destine_view!==null)? code_processtype_destine_view: false,
        code_dangerousness_view:(code_dangerousness_view!==undefined || code_dangerousness_view!==null)? code_dangerousness_view: true,
        charac_danger_view:(charac_danger_view!==undefined || charac_danger_view!==null)? charac_danger_view: false,
        date_refund_view:(date_refund_view!==undefined || date_refund_view!==null)? date_refund_view: false,
        reason_refund_view:(reason_refund_view!==undefined || reason_refund_view!==null)? reason_refund_view: false,
        action_refund_view:(action_refund_view!==undefined || action_refund_view!==null)? action_refund_view: false,
        number_dt_handle:(number_dt_handle!==undefined || number_dt_handle!==null)? number_dt_handle: true,
        code_description_handle:(code_description_handle!==undefined || code_description_handle!==null)? code_description_handle: false,
        code_treatmenttype_origin_handle:(code_treatmenttype_origin_handle!==undefined || code_treatmenttype_origin_handle!==null)? code_treatmenttype_origin_handle: false,
        code_treatmenttype_destine_handle:(code_treatmenttype_destine_handle!==undefined || code_treatmenttype_destine_handle!==null)? code_treatmenttype_destine_handle: true,
        code_processtype_origin_handle:(code_processtype_origin_handle!==undefined || code_processtype_origin_handle!==null)? code_processtype_origin_handle: false,
        code_processtype_destine_handle:(code_processtype_destine_handle!==undefined || code_processtype_destine_handle!==null)? code_processtype_destine_handle: false,
        code_dangerousness_handle:(code_dangerousness_handle!==undefined || code_dangerousness_handle!==null)? code_dangerousness_handle: true,
        charac_danger_handle:(charac_danger_handle!==undefined || charac_danger_handle!==null)? charac_danger_handle: true,
        date_refund_handle:(date_refund_handle!==undefined || date_refund_handle!==null)? date_refund_handle: false,
        reason_refund_handle:(reason_refund_handle!==undefined || reason_refund_handle!==null)? reason_refund_handle: false,
        action_refund_handle:(action_refund_handle!==undefined || action_refund_handle!==null)? action_refund_handle: false,
 
        share_form_view:(share_form_view!==undefined || share_form_view!==null)? share_form_view: false,
        share_form_handle:(share_form_handle!==undefined || share_form_handle!==null)? share_form_handle: false,
        share_form_opt_tras:(share_form_opt_tras!==undefined || share_form_opt_tras!==null)? share_form_opt_tras: false,
        share_form_ori_tras:(share_form_ori_tras!==undefined || share_form_ori_tras!==null)? share_form_ori_tras: false,
        share_form_aut_tras:(share_form_aut_tras!==undefined || share_form_aut_tras!==null)? share_form_aut_tras: false,
        share_form_dest_tras:(share_form_dest_tras!==undefined || share_form_dest_tras!==null)? share_form_dest_tras: false,
        share_form_aut_dest:(share_form_aut_dest!==undefined || share_form_aut_dest!==null)? share_form_aut_dest: false,
        share_form_rel_trans:(share_form_rel_trans!==undefined || share_form_rel_trans!==null)? share_form_rel_trans: false,
        share_form_resp_prod:(share_form_resp_prod!==undefined || share_form_resp_prod!==null)? share_form_resp_prod: false,
 
        sendbyemail_form_view:(sendbyemail_form_view!==undefined || sendbyemail_form_view!==null)? sendbyemail_form_view: false,
        sendbyemail_form_handle:(sendbyemail_form_handle!==undefined || sendbyemail_form_handle!==null)? sendbyemail_form_handle: false,
        sendbyemail_form_opt_tras:(sendbyemail_form_opt_tras!==undefined || sendbyemail_form_opt_tras!==null)? sendbyemail_form_opt_tras: false,
        sendbyemail_form_ori_tras:(sendbyemail_form_ori_tras!==undefined || sendbyemail_form_ori_tras!==null)? sendbyemail_form_ori_tras: false,
        sendbyemail_form_aut_tras:(sendbyemail_form_aut_tras!==undefined || sendbyemail_form_aut_tras!==null)? sendbyemail_form_aut_tras: false,
        sendbyemail_form_dest_tras:(sendbyemail_form_dest_tras!==undefined || sendbyemail_form_dest_tras!==null)? sendbyemail_form_dest_tras: false,
        sendbyemail_form_aut_dest:(sendbyemail_form_aut_dest!==undefined || sendbyemail_form_aut_dest!==null)? sendbyemail_form_aut_dest: false,
        sendbyemail_form_rel_trans:(sendbyemail_form_rel_trans!==undefined || sendbyemail_form_rel_trans!==null)? sendbyemail_form_rel_trans: false,
        sendbyemail_form_resp_prod:(sendbyemail_form_resp_prod!==undefined || sendbyemail_form_resp_prod!==null)? sendbyemail_form_resp_prod: false,

        filenameexcel:(filenameexcel!==undefined || filenameexcel!==null)? filenameexcel:"",
        signature_default:(signature_default!==undefined || signature_default!==null)? signature_default: "",
        signature_default_des_tras:(signature_default_des_tras!==undefined || signature_default_des_tras!==null)? signature_default_des_tras: ""
       
      };
    
    }
    case RESET_FORMATDI_VARIABLES:
      return initialState;
    default:
      return state;
  }
}
