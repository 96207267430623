import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import {useTranslation} from "react-i18next"
import { useTheme } from '@mui/material/styles';
import { warningErrorAction } from "actions/ErrorActions";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon'; 
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TextField } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Toolbar,TableFooter,TablePagination,TableSortLabel,Table,TableBody,TableCell,TableContainer, TableHead,TableRow,Paper,Typography } from '@mui/material';
import PropTypes from 'prop-types';

const headCellsCodeTreatmentTypes = [
  {
    id: 'indexrowtreatmenttypes',
    numeric: false,
    disablePadding: false,
    label: 'Text_Row',
    canOrder:true,
  },
  {
    id: 'treatmenttype_code',
    numeric: false,
    disablePadding: false,
    label: 'Text_CONTRACT_TREATMENTTYPE_CODE',
    canOrder:true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Text_CONTRACT_TREATMENTTYPE_TITLE',
    canOrder:true,
  }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsCodeTreatmentTypes.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 14}} > 
            {t('Button_Delete')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
    const { numTotRegTreatmentTypes, t,LIMITREATMENTTYPECONTRACT,setOpenEditRowTreatmentTypes } = props;
    const dispatch = useDispatch();

    const handleAddNewTreatmentType = () => {
      if (numTotRegTreatmentTypes>=Number(LIMITREATMENTTYPECONTRACT)){
        dispatch(warningErrorAction(t('Text_CONTRACT_MAX_TREATMENTTYPE_TITLE')+" "+LIMITREATMENTTYPECONTRACT));
      }else{
        setOpenEditRowTreatmentTypes(true);
      }  
    };

    return (
      <Toolbar>
      <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 20}} id="tableTitle" component="div">
        {t('Text_CONTRACT_TREATMENTTYPE_CODE')} &nbsp;&nbsp;
          <IconButton
            size="small"  
            style={{color:"#e74a3b", fontSize: 14}}
            onClick={handleAddNewTreatmentType}>
              <Icon
                baseClassName="fas"
                className="fa-plus-circle"
                sx={{ color:"#4e73df"}}
              />
          </IconButton>
        
      </Typography>
      <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
        {t('Text_Num_Reg')} {(numTotRegTreatmentTypes).toLocaleString('de-DE')} {t('Text_Of')}  {(numTotRegTreatmentTypes).toLocaleString('de-DE')}
      </Typography>
    </Toolbar>
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataContracts']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingTreatmentTypes =(props)=>{
  const { dataTreatmentTypes,numTotRegTreatmentTypes, setOpenDeleteRowTreatmentTypes,setOpenEditRowTreatmentTypes,setEditRowTreatmentType,LIMITPAGESIZE,LIMITREATMENTTYPECONTRACT} = props;
  const {t,i18n} = useTranslation(['listDataContracts']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataTreatmentTypes.length) : 0);
  const [countData,setCountData] = useState(dataTreatmentTypes.length);
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);

  useEffect(() => {
    if (dataTreatmentTypes.length !== countData || dataTreatmentTypes.length <= rowsPerPage) {
      setPage(0);
    }  
    setCountData(dataTreatmentTypes.length);
    setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataTreatmentTypes.length) : 0);
  }, [dataTreatmentTypes, rowsPerPage, page, countData]);
   
  useEffect(() => {
    if (numTotRegTreatmentTypes > LIMITPAGESIZE) {
      setActiveOffset(true);
      const ranges = [
        {
          value: 1,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        },
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegTreatmentTypes; i += Number(LIMITPAGESIZE)) {
        ranges.push({
          value: (i / Number(LIMITPAGESIZE)) + 1,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(ranges);
    } else {
      setCurrencyOffset(1);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegTreatmentTypes, LIMITPAGESIZE]);

  const handleChangeOffset = (event) => {
    setCurrencyOffset(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRowTreatmentType = (row) => {
    setEditRowTreatmentType(row);
    setOpenDeleteRowTreatmentTypes(true);
  };

  const listItems= (
  stableSort(dataTreatmentTypes, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keydataTreatmentTypes) => {
    return (
      <TableRow hover tabIndex={-1} key={keydataTreatmentTypes}>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {row.indexrowtreatmenttypes}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {row.treatmenttype_code}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14}} >
              {i18n.language==="es"?row.treatmenttype_namees:row.treatmenttype_nameen}
          </Typography>  
        </TableCell>
        <TableCell align="center">
          <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalDeleteRowTreatmentType(row)}>
            <DeleteIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}>      
        <EnhancedTableToolbar numTotRegTreatmentTypes={numTotRegTreatmentTypes} t={t} LIMITREATMENTTYPECONTRACT={LIMITREATMENTTYPECONTRACT} setOpenEditRowTreatmentTypes={setOpenEditRowTreatmentTypes}/>
          <TableContainer component={Paper}>
            <Table sx={{ width: 1530 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} />
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={4} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffset && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffset"
                            name="currencyOffset"
                            value={currencyOffset}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffset
                            }}>
                            {rangeOffset.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    colSpan={12}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingTreatmentTypes.propTypes = { 
  dataTreatmentTypes: PropTypes.array.isRequired,
  numTotRegTreatmentTypes: PropTypes.number.isRequired,
  setOpenDeleteRowTreatmentTypes: PropTypes.func.isRequired, 
  setOpenEditRowTreatmentTypes: PropTypes.func.isRequired,
  setEditRowTreatmentType: PropTypes.func.isRequired, 
  LIMITPAGESIZE: PropTypes.string.isRequired,
  LIMITREATMENTTYPECONTRACT: PropTypes.number.isRequired
};

export default TableSortingTreatmentTypes;