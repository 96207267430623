import React, {  useEffect, useState,useCallback } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next" 
import { Checkbox, TextField } from '@mui/material';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { warningErrorAction } from "actions/ErrorActions";
import PropTypes from 'prop-types'; 

const initialBankAccount = {
  "id":"",
  "indexrowbankaccount":"",
  "codbic":"",
  "namebank":"",
  "iban":"",
  "entity":"",
  "office":"",
  "cd":"",
  "accountbank":"",
  "defaultaccountbank": true,
};

const StoreCreateOrEditBankAccount = ({openEditRowBankAccount,setOpenEditRowBankAccount,openEditNewBankAccount,setOpenEditNewBankAccount,formStore,setFormStore,editRowBankAccount,setEditRowBankAccount}) => {
  const {t} = useTranslation(['listDataStores']);
  const [newBankAccount,setNewBankAccount]= useState(initialBankAccount);
  const [errorsBankAccount, setErrorsBankAccount] = useState({});
  const dispatch = useDispatch(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowBankAccount) {
        setNewBankAccount(initialBankAccount);
        setErrorsBankAccount({});
        setEditRowBankAccount(initialBankAccount);
        setOpenEditNewBankAccount(false);
        setOpenEditRowBankAccount(false);
      }
    },[
      setNewBankAccount,
      setErrorsBankAccount,
      openEditRowBankAccount,
      setOpenEditRowBankAccount, 
      setOpenEditNewBankAccount,
      setEditRowBankAccount]
  );

  const handleCloseNewBankAccount = () => {
    setNewBankAccount(initialBankAccount);
    setErrorsBankAccount({});
    setEditRowBankAccount(initialBankAccount);
    setOpenEditNewBankAccount(false);
    setOpenEditRowBankAccount(false);
  };

  const handleChangeNewBankAccount = (e) => {
    const { name, value } = e.target;
    setNewBankAccount(newBankAccount => ({
      ...newBankAccount,
      [name]: value,
    }));   
  };
  
  const handleBlurNewBankAccount = (e) => {
    handleChangeNewBankAccount(e);
    setErrorsBankAccount(validateFormBankAccount());
  };
  
  
  const handleChangeEnabledDefaultBankAccount = () => {
    setNewBankAccount(newBankAccount => ({
      ...newBankAccount,
      defaultaccountbank: !newBankAccount.defaultaccountbank
    }));
  };

  const toggleDrawerNewBankAccount  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewBankAccount();
    }
  };

  const validateFormBankAccount = () => {
    let errorsBankAccount = {};
  
    if (newBankAccount.namebank===""){
        errorsBankAccount.namebank = 'Text_NameAccountRequired';
    }
    
    if (newBankAccount.iban===""){
      errorsBankAccount.iban = 'Text_IbanAccountRequired';
    }
    
    if (newBankAccount.entity===""){
      errorsBankAccount.entity = 'Text_EntityAccountRequired';
    }

    if (newBankAccount.office===""){
      errorsBankAccount.office = 'Text_OfficeAccountRequired';
    }

    if (newBankAccount.cd===""){
      errorsBankAccount.cd = 'Text_CdAccountRequired';
    }

    if (newBankAccount.accountbank===""){
      errorsBankAccount.accountbank = 'Text_AccountBankRequired';
    }

    
    if (!openEditNewBankAccount){
      if (formStore.bankaccounts.find(item => {return (item.entity.trim()=== newBankAccount.entity.trim() && item.office.trim()=== newBankAccount.office.trim() && item.cd.trim()=== newBankAccount.cd.trim() && item.accountbank.trim()=== newBankAccount.accountbank.trim() ) })){
        errorsBankAccount.bankaccounts = 'Text_AccountBankExist';
      }
    }else{
      if (formStore.bankaccounts.find((item, index) => {return (item.indexrowbankaccount!==newBankAccount.indexrowbankaccount && item.entity.trim()=== newBankAccount.entity.trim() && item.office.trim()=== newBankAccount.office.trim() && item.cd.trim()=== newBankAccount.cd.trim() && item.accountbank.trim()=== newBankAccount.accountbank.trim() ) })){
        errorsBankAccount.bankaccounts = 'Text_AccountBankExist';
      }
    }  
    
    return errorsBankAccount;
  };

  const handleSubmitNewBankAccount = () => {  

    setErrorsBankAccount(validateFormBankAccount());
    let errorsCheckBankAccount=validateFormBankAccount();

    if ((Object.keys(errorsCheckBankAccount).length === 0) 
          && newBankAccount.namebank!==''  
          && newBankAccount.iban!=='' 
          && newBankAccount.entity!==''
          && newBankAccount.office!=='' 
          && newBankAccount.cd!=='' 
          && newBankAccount.accountbank!=='' ) {

      let defaultBankAccountFound=newBankAccount.defaultaccountbank;
      
      if (formStore.bankaccounts.length <=1){
        defaultBankAccountFound=true;
      }
      
      let bankAccountToAdd={
        "id":newBankAccount.id,
        "indexrowbankaccount":openEditNewBankAccount?newBankAccount.indexrowbankaccount:formStore.bankaccounts.length>0?formStore.bankaccounts[formStore.bankaccounts.length-1].indexrowbankaccount+1:1,       
        "codbic":newBankAccount.codbic,
        "namebank":newBankAccount.namebank,
        "iban":newBankAccount.iban,
        "entity":newBankAccount.entity,
        "office":newBankAccount.office,
        "cd":newBankAccount.cd,
        "accountbank":newBankAccount.accountbank,
        "defaultaccountbank": defaultBankAccountFound
      }
      
      if (bankAccountToAdd.defaultaccountbank){
        formStore.bankaccounts.map((item)=>{
            return item.defaultaccountbank=false  
        })
        setFormStore(formStore => ({
          ...formStore,
        }))
      }
       
      if (!openEditNewBankAccount){
        formStore.bankaccounts.push(bankAccountToAdd);
      }else{
        let idx=formStore.bankaccounts.findIndex(((item) => item.indexrowbankaccount === newBankAccount.indexrowbankaccount));
        formStore.bankaccounts[idx]=bankAccountToAdd
      }
      setFormStore(formStore => ({
        ...formStore,
      }))
      handleCloseNewBankAccount();
    } else {
      if (errorsCheckBankAccount.namebank){
        dispatch(warningErrorAction(t(errorsCheckBankAccount.namebank)));
      }
      if (errorsCheckBankAccount.iban){
        dispatch(warningErrorAction(t(errorsCheckBankAccount.iban)));
      }
      if (errorsCheckBankAccount.entity){
        dispatch(warningErrorAction(t(errorsCheckBankAccount.entity)));
      }
      if (errorsCheckBankAccount.office){
        dispatch(warningErrorAction(t(errorsCheckBankAccount.office)));
      }
      if (errorsCheckBankAccount.cd){
        dispatch(warningErrorAction(t(errorsCheckBankAccount.cd)));
      }
      if (errorsCheckBankAccount.accountbank){
        dispatch(warningErrorAction(t(errorsCheckBankAccount.accountbank)));
      }
      if (errorsCheckBankAccount.bankaccounts){
        dispatch(warningErrorAction(t(errorsCheckBankAccount.bankaccounts)));
      }
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && openEditNewBankAccount){
      setNewBankAccount(newBankAccount => ({
        ...newBankAccount,
        id:editRowBankAccount.id!==null?editRowBankAccount.id:"",
        indexrowbankaccount:editRowBankAccount.indexrowbankaccount!==null?editRowBankAccount.indexrowbankaccount:"",
        codbic:editRowBankAccount.codbic!==null?editRowBankAccount.codbic:"",
        namebank:editRowBankAccount.namebank!==null?editRowBankAccount.namebank:"",
        iban:editRowBankAccount.iban!==null?editRowBankAccount.iban:"",
        entity:editRowBankAccount.entity!==null?editRowBankAccount.entity:"",
        office:editRowBankAccount.office!==null?editRowBankAccount.office:"",
        cd:editRowBankAccount.cd!==null?editRowBankAccount.cd:"",
        accountbank:editRowBankAccount.accountbank!==null?editRowBankAccount.accountbank:"",
        defaultaccountbank:editRowBankAccount.defaultaccountbank!==null?editRowBankAccount.defaultaccountbank:false,
      }));
    }
    return()=>{isMounted=false;}    
  },[editRowBankAccount,openEditNewBankAccount]);

  return(
    <>
      {openEditRowBankAccount && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewBankAccount(false)}
            onOpen={toggleDrawerNewBankAccount(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{openEditNewBankAccount?t('Text_AccountBank_Edit'):t('Text_AccountBank_New')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewBankAccount}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('Text_BankAccount_Name')} :</label>
                    <div className="form-group">
                      <TextField
                        id="namebank" 
                        name="namebank"
                        value={newBankAccount.namebank}
                        inputProps={{ style: {width: 450}, maxLength: 100 }}
                        label={errorsBankAccount.namebank!=null?t(errorsBankAccount.namebank):t('PlaceHolder_BankAccount_Name')}
                        placeholder={t('PlaceHolder_BankAccount_Name')}
                        variant="standard"
                        onBlur={handleBlurNewBankAccount}
                        onChange={handleChangeNewBankAccount}/>
                    </div>

                    <label className="text-primary">{t('Text_BankAccount_Swift')} :</label>
                    <div className="form-group">
                      <TextField
                        id="codbic" 
                        name="codbic"
                        value={newBankAccount.codbic}
                        inputProps={{ style: {width: 450}, maxLength: 15 }}
                        label={errorsBankAccount.codbic!=null?t(errorsBankAccount.codbic):t('PlaceHolder_BankAccount_Swift')}
                        placeholder={t('PlaceHolder_BankAccount_Swift')}
                        variant="standard"
                        onBlur={handleBlurNewBankAccount}
                        onChange={handleChangeNewBankAccount}/>
                    </div>

                    <label className="text-primary">{t('Text_BankAccount_Iban')} :</label>
                    <div className="form-group">
                      <TextField
                        id="iban" 
                        name="iban"
                        value={newBankAccount.iban}
                        inputProps={{ style: {width: 450}, maxLength: 4 }}
                        label={errorsBankAccount.iban!=null?t(errorsBankAccount.iban):t('PlaceHolder_BankAccount_Iban')}
                        placeholder={t('PlaceHolder_BankAccount_Iban')}
                        variant="standard"
                        onBlur={handleBlurNewBankAccount}
                        onChange={handleChangeNewBankAccount}/>
                    </div>

                    <label className="text-primary">{t('Text_BankAccount_Entity')} :</label>
                    <div className="form-group">
                      <TextField
                        id="entity" 
                        name="entity"
                        value={newBankAccount.entity}
                        inputProps={{ style: {width: 450}, maxLength: 4 }}
                        label={errorsBankAccount.entity!=null?t(errorsBankAccount.entity):t('PlaceHolder_BankAccount_Entity')}
                        placeholder={t('PlaceHolder_BankAccount_Entity')}
                        variant="standard"
                        onBlur={handleBlurNewBankAccount}
                        onChange={handleChangeNewBankAccount}/>
                    </div>

                    <label className="text-primary">{t('Text_BankAccount_Office')} :</label>
                    <div className="form-group">
                      <TextField
                        id="office" 
                        name="office"
                        value={newBankAccount.office}
                        inputProps={{ style: {width: 450}, maxLength: 4 }}
                        label={errorsBankAccount.office!=null?t(errorsBankAccount.office):t('PlaceHolder_BankAccount_Office')}
                        placeholder={t('PlaceHolder_BankAccount_Office')}
                        variant="standard"
                        onBlur={handleBlurNewBankAccount}
                        onChange={handleChangeNewBankAccount}/>
                    </div>

                    <label className="text-primary">{t('Text_BankAccount_CD')} :</label>
                    <div className="form-group">
                      <TextField
                        id="cd" 
                        name="cd"
                        value={newBankAccount.cd}
                        inputProps={{ style: {width: 450}, maxLength: 2 }}
                        label={errorsBankAccount.cd!=null?t(errorsBankAccount.cd):t('PlaceHolder_BankAccount_CD')}
                        placeholder={t('PlaceHolder_BankAccount_CD')}
                        variant="standard"
                        onBlur={handleBlurNewBankAccount}
                        onChange={handleChangeNewBankAccount}/>
                    </div>

                    <label className="text-primary">{t('Text_BankAccount_Number')} :</label>
                    <div className="form-group">
                      <TextField
                        id="accountbank" 
                        name="accountbank"
                        value={newBankAccount.accountbank}
                        inputProps={{ style: {width: 450}, maxLength: 10 }}
                        label={errorsBankAccount.accountbank!=null?t(errorsBankAccount.accountbank):t('PlaceHolder_BankAccount_Number')}
                        placeholder={t('PlaceHolder_BankAccount_Number')}
                        variant="standard"
                        onBlur={handleBlurNewBankAccount}
                        onChange={handleChangeNewBankAccount}/>
                    </div>

                    
                    <div className="form-group">
                      <label className="text-primary">{t('Text_BankAccount_IsDefault')} :</label>
                      <Checkbox  
                        style={{color:"#4e73df"}} 
                        checked={newBankAccount.defaultaccountbank} 
                        onChange={handleChangeEnabledDefaultBankAccount} 
                      />
                     
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitNewBankAccount}>{openEditNewBankAccount?t('Button_Update'):t('Button_Create')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseNewBankAccount}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}


StoreCreateOrEditBankAccount.propTypes = {

  openEditRowBankAccount: PropTypes.bool.isRequired,
  setOpenEditRowBankAccount: PropTypes.func.isRequired,
  openEditNewBankAccount: PropTypes.bool.isRequired,
  setOpenEditNewBankAccount: PropTypes.func.isRequired,
  formStore: PropTypes.object.isRequired,
  setFormStore: PropTypes.func.isRequired,
  setEditRowBankAccount: PropTypes.func.isRequired
};

export default StoreCreateOrEditBankAccount;