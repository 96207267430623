import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next"
import { TextField } from '@mui/material';
import { Checkbox} from  '@mui/material';
import { Toolbar,TableFooter,TablePagination,TableSortLabel,Table,TableBody,TableCell,TableContainer, TableHead,TableRow,Paper,Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';

const headCellsEmployee = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Text_Code', canOrder: true },
  { id: 'isScanned', numeric: false, disablePadding: false, label: 'Text_IsScanned_DI', canOrder: false },
  { id: 'preiddoc_sheet', numeric: false, disablePadding: false, label: 'Text_FORM_PREIDDOC_SHEET', canOrder: true },
  { id: 'sufiddoc_sheet', numeric: false, disablePadding: false, label: 'Text_FORM_SUFIDDOC_SHEET', canOrder: true },
  { id: 'number_dt', numeric: false, disablePadding: false, label: 'Text_FORM_NT_SHEET', canOrder: true },
  { id: 'date_sheet', numeric: false, disablePadding: false, label: 'Text_FORM_DATE', canOrder: true },
  { id: 'accept', numeric: false, disablePadding: false, label: 'Text_Accept', canOrder: true }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t,showFinishButtonFormDI,showEditButtonFormDI,showPrintButtonFormDI,showDeleteButtonFormDI,showStoreFormDI,showUserAccountFormDI,i18n,selectAll,selectAllPendig,handleChangSelectAll,handleChangSelectAllPending,typeOfList } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsEmployee.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        {showFinishButtonFormDI &&
          <TableCell
            key='pending'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 14}} >
              {typeOfList==="1"?t('Text_Finish'):t('Text_Return')}
            </Typography>
            <Checkbox checked={typeOfList==="1"?selectAll:selectAllPendig} style={{color:"#4e73df", fontSize: 14}} onClick={typeOfList==="1"?()=>handleChangSelectAll():()=>handleChangSelectAllPending()}/>
          </TableCell>
        }
        {showEditButtonFormDI &&
          <TableCell
            key='edit'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 14}} >
              {t('Button_Edit')}
            </Typography>
          </TableCell>
        }
        {showPrintButtonFormDI &&
          <TableCell
            key='print'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 14}} >
              {t('Button_Print_PDF')}
            </Typography>
          </TableCell>
        }

        {showDeleteButtonFormDI &&
          <TableCell
            key='delete'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 14}} > 
              {t('Button_Delete')}
            </Typography>
          </TableCell>
        }
        {showStoreFormDI &&
          <TableCell
            key='tradename'
            align='center'
            padding='normal'
            sortDirection={order}
          >
            <TableSortLabel
              active={orderBy === 'tradename'}
              direction={orderBy === 'tradename' ? order : 'asc'}
              onClick={createSortHandler('tradename')}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t('Text_Store_Create')}
                {orderBy === 'tradename' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>
          </TableCell>
        }
        {showUserAccountFormDI &&
          <TableCell
            key='username'
            align='center'
            padding='normal'
            sortDirection={order}
          >
            <TableSortLabel
              active={orderBy === 'username'}
              direction={orderBy === 'username' ? order : 'asc'}
              onClick={createSortHandler('username')}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t('Text_UserAcount_Create')}
                {orderBy === 'username' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>
          </TableCell>
        }
        <TableCell
          key='sharedStored'
          align='center'
          padding='normal'
          sortDirection={order}
        >
            <TableSortLabel
            active={orderBy === 'sharedStored'}
            direction={orderBy === 'sharedStored' ? order : 'asc'}
            onClick={createSortHandler('sharedStored')}
          >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
              {t('Text_Share_Form_With')}
              {orderBy === 'sharedStored' ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </Typography>
          </TableSortLabel>
        </TableCell>
        <TableCell
          key='emailStored'
          align='center'
          padding='normal'
          sortDirection={order}
        >
            <TableSortLabel
            active={orderBy === 'emailStored'}
            direction={orderBy === 'emailStored' ? order : 'asc'}
            onClick={createSortHandler('emailStored')}
          >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
              {t('Text_Send_Form_With')}
              {orderBy === 'emailStored' ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </Typography>
          </TableSortLabel>
        </TableCell>

        {(showStoreFormDI || showUserAccountFormDI) &&
          i18n.language==='es' ?
          <TableCell
            key='typesdi_namees'
            align='center'
            padding='normal'
            sortDirection={order}
          >
             <TableSortLabel
              active={orderBy === 'typesdi_namees'}
              direction={orderBy === 'typesdi_namees' ? order : 'asc'}
              onClick={createSortHandler('typesdi_namees')}
            >
               <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t('Text_Format_DI')}
                {orderBy === 'typesdi_namees' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>
          </TableCell>:
          <TableCell
          key='typesdi_nameen'
          align='center'
          padding='normal'
          sortDirection={order}
          >
          <TableSortLabel
            active={orderBy === 'typesdi_nameen'}
            direction={orderBy === 'typesdi_nameen' ? order : 'asc'}
            onClick={createSortHandler('typesdi_nameen')}
          >
            <Typography style={{color:"#4e73df", fontSize: 14}} >  
              {t('Text_Format_DI')}
              {orderBy === 'typesdi_nameen' ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </Typography>
          </TableSortLabel>
          </TableCell>
        }
       

      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegFormDI, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegFormDI).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataGeneralSettings']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingFormDI =(props)=>{
  const { 
    dataListFormDI,
    setFormFormDI,
    numTotRegFilter,
    titleTable,
    setOpenDeleteRowFormDI,
    setRowEditFormDI,
    showFinishButtonFormDI,
    showEditButtonFormDI,
    showPrintButtonFormDI,
    showDeleteButtonFormDI,
    showStoreFormDI,
    showUserAccountFormDI,
    numTotRegFormDI,
    activeOffsetFormDI,
    rangeOffsetFormDI,
    currencyOffsetFormDI,
    setCurrencyOffsetFormDI,
    setViewPDF,
    typeOfList
  } = props;
  const {t,i18n} = useTranslation(['listDataForms']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataListFormDI.length) : 0);
  const [countData,setCountData] = useState(dataListFormDI.length);
  const [selectAll,setSelectAll]= useState(false);
  const [selectAllPendig,setSelectAllPendig]= useState(false);

  useEffect(
    () => {
      if (dataListFormDI.length!==countData || dataListFormDI.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataListFormDI.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataListFormDI.length) : 0);  
    },
    [dataListFormDI,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetFormDI(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRowDepartment = (row) => {
    setRowEditFormDI(row);
    setOpenDeleteRowFormDI(true);
  };

  const openModalFormDIPDF = async (row) => {
    setRowEditFormDI(row);
    setViewPDF(true);
  };

  const handleChangSelectAll = () => {
    setSelectAll(!selectAll);
    dataListFormDI.map((item,index)=>{
      return item.isFinished=!selectAll;
    });
    setFormFormDI(formFormDI => ({
      ...formFormDI,
   }))
  };

  const handleChangSelectAllPending = () => {
    setSelectAllPendig(!selectAllPendig);
    dataListFormDI.map((item,index)=>{
      return item.isPending=!selectAllPendig;
    });
    setFormFormDI(formFormDI => ({
      ...formFormDI,
   }))
  };
  

  const handleChangeEnabledRow = (row) => {
    var i =  dataListFormDI.indexOf( row );
   
    dataListFormDI.map((item,index)=>{
      
      if ( index===i ) {
          return item.isFinished=!item.isFinished;
      } else {
          return item.isFinished;
      }
    })
    setFormFormDI(formFormDI => ({
      ...formFormDI,
   }))

   };

   const handleChangeEnabledPendingRow = (row) => {
    var i =  dataListFormDI.indexOf( row );
   
    dataListFormDI.map((item,index)=>{
      
      if ( index===i ) {
          return item.isPending=!item.isPending;
      } else {
          return item.isPending;
      }
    })
    setFormFormDI(formFormDI => ({
      ...formFormDI,
   }))

   };
  const listItems= (
  stableSort(dataListFormDI, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {row.id}
          </Typography>  
        </TableCell>
        <TableCell align="center">
          <Checkbox checked={row.isScanned} disabled/>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.preiddoc_sheet}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.sufiddoc_sheet}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.number_dt}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.date_sheet!==""?new Date(row.date_sheet).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US'):""}
           </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {t(row.accept)}
          </Typography>
        </TableCell>
        {(showFinishButtonFormDI && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <Checkbox checked={typeOfList==="1"?row.isFinished:row.isPending} style={{color:"#4e73df", fontSize: 14}} onClick={typeOfList==="1"?()=>handleChangeEnabledRow(row):()=>handleChangeEnabledPendingRow(row)}/>
          </TableCell>
        }
        {(showEditButtonFormDI && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <Link  to={typeOfList==="1"?`/formdetail/${row.id}`:`/formdetail2/${row.id}`} >
              <IconButton  size="large"  style={{color:"#4e73df", fontSize: 14}} >
                <EditIcon/>
              </IconButton>
            </Link>
          </TableCell>
        }
         {(showPrintButtonFormDI && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
              <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalFormDIPDF(row)}>
                <PictureAsPdfIcon/>
              </IconButton>
          </TableCell>
        }

        {(showDeleteButtonFormDI && row.id!=="" && row.id!==null) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalDeleteRowDepartment(row)}>
              <DeleteIcon/>
            </IconButton>
          </TableCell>}
          {showStoreFormDI  &&
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >
                {row.tradename}
              </Typography>  
            </TableCell>
        }
        {showUserAccountFormDI  &&
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 14}} >
              {row.username}
            </Typography>  
          </TableCell>
        }
         <TableCell align="right">
            <Typography style={{color:"#000000", fontSize: 14}} >
              {row.sharedStored}
            </Typography>  
          </TableCell>
          <TableCell align="right">
            <Typography style={{color:"#000000", fontSize: 14}} >
              {row.emailStored}
            </Typography>  
          </TableCell>
        {(showStoreFormDI ||  showUserAccountFormDI) &&
          <TableCell align="right">
            <Typography style={{color:"#000000", fontSize: 14}} >
              {row.defaultformatnumber}
            </Typography>  
          </TableCell>
        }
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegFormDI={numTotRegFormDI} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} showFinishButtonFormDI={showFinishButtonFormDI} showEditButtonFormDI={showEditButtonFormDI} showPrintButtonFormDI={showPrintButtonFormDI} showDeleteButtonFormDI={showDeleteButtonFormDI} showStoreFormDI={showStoreFormDI} showUserAccountFormDI={showUserAccountFormDI} i18n={i18n} selectAll={selectAll} selectAllPendig={selectAllPendig} handleChangSelectAll={handleChangSelectAll} handleChangSelectAllPending={handleChangSelectAllPending} typeOfList={typeOfList}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={16} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetFormDI && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetFormDI"
                            name="currencyOffsetFormDI"
                            value={currencyOffsetFormDI}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetFormDI
                            }}>
                            {rangeOffsetFormDI.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={16}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingFormDI.propTypes = {
  dataListFormDI: PropTypes.array.isRequired,
  setFormFormDI: PropTypes.func.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  isLoadingFormDI: PropTypes.bool.isRequired,
  setIsLoadingFormDI: PropTypes.func.isRequired,
  setOpenDeleteRowFormDI: PropTypes.func.isRequired, 
  setRowEditFormDI: PropTypes.func.isRequired,    
  showFinishButtonFormDI: PropTypes.bool.isRequired,  
  showEditButtonFormDI: PropTypes.bool.isRequired,  
  showPrintButtonFormDI: PropTypes.bool.isRequired,  
  showDeleteButtonFormDI: PropTypes.bool.isRequired,
  showStoreFormDI: PropTypes.bool.isRequired,
  showUserAccountFormDI: PropTypes.bool.isRequired, 
  activeOffsetFormDI: PropTypes.bool.isRequired,
  rangeOffsetFormDI: PropTypes.array.isRequired,
  currencyOffsetFormDI: PropTypes.number.isRequired,
  setCurrencyOffsetFormDI: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired,
  typeOfList: PropTypes.string.isRequired 
};
  
export default TableSortingFormDI;
