import React from 'react'

const Alerts = () => {
  return (
    <>
        <li className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#index.html" id="alertsDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-bell fa-fw"></i>
                {/* Counter - Alerts */}
                <span className="badge badge-danger badge-counter">0+</span>
            </a>
            {/* Dropdown - Alerts */}
            
        </li>
    </>
  )
}

export default Alerts