import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ImportScript = ({ resourceUrl }) => {
  const scriptRef = useRef(null);

  useEffect(() => {
    if (!resourceUrl) return;

    // Create and append script element
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = true;
    scriptRef.current = script;
    document.body.appendChild(script);

    // Cleanup: remove script element
    return () => {
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
      }
    };
  }, [resourceUrl]);

  return null;
};

ImportScript.propTypes = {
  resourceUrl: PropTypes.string.isRequired,
};

export default ImportScript;
