import React from 'react'
import {useTranslation} from "react-i18next"

const Footer = () => {

    const {t} = useTranslation(['sidebar']);

    return (
    <>
    {/* Footer */}
    <footer className="sticky-footer bg-white">
        <div className="container my-auto">
            <div className="copyright text-center my-auto">
                <span>{t('Text_Welcome')}</span>
            </div>
        </div>
    </footer>
    {/* End of Footer */}
    </>
  )
}

export default Footer