import {
  LOAD_SUCCESS_ERROR,
  LOAD_WARNING_ERROR,
  RESET_ERROR
} from "types/Types";

const initialState = {
  text: "",
};

export function errorReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_WARNING_ERROR:
      return {
        ...state,
        text: action.payload,
      };
    case LOAD_SUCCESS_ERROR:
      return {
        ...state,
        text: action.payload,
      };
    case RESET_ERROR:
      return initialState;
    default:
      return state;
  }
}
