import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import LoginForm from "pages/login/LoginForm";
import NotFound from "pages/404/NotFound"; 
import ErrorBoundary from "./ErrorBoundary"; // Importa el Error Boundary 
import store from "store/Store";
import "./App.css";
import Home from "pages/main/Home";   
import HomeStore from "pages/stores/HomeStore";
import HomeEmployee from 'pages/employees/HomeEmployee';
import HomeStoreEditData from "pages/stores/HomeStoreEditData";
import HomeEmployeeEditData from "pages/employees/HomeEmployeeEditData";
import HomeFormDI from "pages/forms/filterFormDI/HomeFormDI";
import HomeFormEditData from "pages/forms/editOrNewFormDI/HomeFormEditData";
import HomeTypesDIEditData from "pages/typesDIFormat/HomeTypesDIEditData";
import HomeTypesDI from "pages/typesDIFormat/HomeTypesDI";
import HomeContract from "pages/contracts/HomeContract";
import HomeContractEditData from "pages/contracts/editOrNewContract/HomeContractEditData";
import HomeWeighVehicles from "pages/weighVehicles/WeighvehiclesHome";
import HomeWeighVehiclesFormat from "pages/settings/weighVehiclesFormatSettings/HomeWeighVehiclesFormat";
import HomeWeighVehiclesFormatEditData from "pages/settings/weighVehiclesFormatSettings/HomeWeighVehiclesFormatEditData";
const App =() => {  
  return(
    
    <Provider store={store}>
      <div className="App">
        <ErrorBoundary>  
          <Suspense fallback={null}>
            <section className="App-content">            
              <BrowserRouter>
                <Routes>
                  <Route path="" element={
                    <Suspense fallback={null}>
                      <LoginForm/>
                    </Suspense>
                  } />
                  <Route path="/home" element={
                    <Suspense fallback={null}>
                      <Home/>
                    </Suspense>
                  } />
                  <Route path="/*" element={
                    <Suspense fallback={null}>
                      <NotFound/>    
                    </Suspense>
                  } />
                  <Route path="/store" element={
                    <Suspense fallback={null}>
                      <HomeStore/>
                    </Suspense>
                  } />
                  <Route path="/storedetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeStoreEditData/>
                    </Suspense>
                  } />

                  <Route path="/employee" element={
                    <Suspense fallback={null}>
                      <HomeEmployee/>
                    </Suspense>
                  } />
               
                  <Route path="/employeedetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeEmployeeEditData/>
                    </Suspense>
                  } />
                  
                  <Route path="/formdi" element={
                    <Suspense fallback={null}>
                      <HomeFormDI typeList="1"/>
                    </Suspense>
                  } />
                
                  <Route path="/formdiended" element={
                    <Suspense fallback={null}>
                      <HomeFormDI typeList="2"/>
                    </Suspense>
                  } />
                
                  <Route path="/formdetail/:id" element={
                    <Suspense fallback={null}>
                     <HomeFormEditData  typeList="1"/>
                    </Suspense>
                  } />

                  <Route path="/formdetail2/:id" element={
                    <Suspense fallback={null}>
                      <HomeFormEditData  typeList="2"/>
                    </Suspense>
                  } />

                  <Route path="/formatdi" element={
                    <Suspense fallback={null}>
                      <HomeTypesDI/>
                    </Suspense>
                  } />

                  <Route path="/formatdidetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeTypesDIEditData/>
                    </Suspense>
                  } />

                  <Route path="/weighvehiclesformat" element={
                    <Suspense fallback={null}>
                      <HomeWeighVehiclesFormat/>
                    </Suspense>
                  } />

                  <Route path="/weighvehiclesformatdetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeWeighVehiclesFormatEditData/>
                    </Suspense>
                  } />

                  <Route path="/contract" element={
                    <Suspense fallback={null}>
                      <HomeContract />
                    </Suspense>
                  } />

                  <Route path="/contractdetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeContractEditData/>
                    </Suspense>
                  } />

                  <Route path="/weighvehicles" element={
                    <Suspense fallback={null}>
                      <HomeWeighVehicles/>
                    </Suspense>
                  } /> 
  
                </Routes>
              </BrowserRouter>
            </section>
          </Suspense>
        </ErrorBoundary>
      </div>
    </Provider>
  );
};

export default App;