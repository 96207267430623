import React from "react";
import {useTranslation} from "react-i18next"
import coverVideo from "components/media/coverVideo.mp4";
import "./notFound.css";

const NotFound = () => {

  const {t} = useTranslation(['loginText']);

  return (
    <div className="cover-container">
      <video className="video" src={coverVideo} autoPlay loop muted />
        <h1>
        {t('Text_404')}
        </h1>    
    </div>
  );
};

export default NotFound;
