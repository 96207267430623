import {
  READ_LIST_VARIABLES,
  LOAD_LIST_VARIABLES,
  RESET_LIST_VARIABLES
} from "types/Types";

const initialState = {
  FOLDER_ROOT: "/opt",
  DELETE_CODE: "000000",
  DELETE_CODE_EMPLOYEE: "123456789",
  DELETE_CODE_STORE: "123456789",
  DELETE_CODE_FORMDI: "123456789",
  ENDPOINT: "https://34.247.84.215",
  //ENDPOINT: "http://localhost:8000",
  //ENDPOINT: "https://getrescan.com",
  DEFAULTEXTPHONE: "1",
  DEFAULTCOUNTRY: "1",
  DEFAULTPROVINCE: "30",
  DEFAULTCOMMUNITY: "6",
  DEFAULTSTORE: "1",
  DEFAULTEMPLOYEE: "1",
  DEFAULTTYPEOFDOCUMENT: "4",
  DEFAULTLANGUAGE: "1",
  DEFAULTCOLOR: "2",
  DEFAULTCOLOREMPLOYEE: "50",
  DEFAULTROLE: "4",
  LIMITPAGESIZE: "1000",
  LIMITPHONEPERSTORE: "5",
  LIMITEMAILPERSTORE: "5",
  LIMITADDRESSPERSTORE: "15",
  LIMITBANKACCOUNTPERSTORE: "5",
  DEFAULTTYPESDI: "1",
  LIMITPHONEPEREMPLOYEE: "5",
  LIMITEMAILPEREMPLOYEE: "5",
  LIMITADDRESSPEREMPLOYEE: "5",
  LIMITBANKACCOUNTPEREMPLOYEE: "5",
  NON_ERASABLE_CODELER_ID: "834",
  NON_ERASABLE_TREATMENTTYPE_ID: "85",
  NON_ERASABLE_PROCESSTYPE_ID: "29",
  NON_ERASABLE_DANGERROUS_ID: "15",
  NON_ERASABLE_COLOR_ID: "137",
  NON_ERASABLE_COMMUNITY_ID: "15",
  NON_ERASABLE_COUNTRY_ID: "247",
  NON_ERASABLE_DESCRIPTION_ID: "2",
  NON_ERASABLE_DOCUMENTTYPES_ID: "4",
  NON_ERASABLE_LANGUAGE_ID: "2",
  NON_ERASABLE_POSTALCODE_ID: "14617",
  NON_ERASABLE_PROVINCE_ID: "53",
  NON_ERASABLE_ROADTYPES_ID: "46",
  NON_ERASABLE_TYPESDI_ID: "3",
  NON_ERASABLE_WEIGHVEHICLESBUTTON_ID: "15",
  NON_ERASABLE_WEIGHVEHICLESFORMAT_ID: "1",
  NON_ERASABLE_USERACCOUNT_ID: "6",
  NON_ERASABLE_STORE_ID: "3",
  NON_ERASABLE_EMPLOYEE_ID: "2"
};

const findParam = (payload, field, defaultValue) => {
  const element = payload.find((element) => element.field === field);
  return element ? element.param : defaultValue;
};

export function variablesReducer(state = initialState, action) {
  switch (action.type) {
    case READ_LIST_VARIABLES:
      return state;
    case LOAD_LIST_VARIABLES: {
      return {
        ...state,
        FOLDER_ROOT: findParam(action.payload, "Folder_Root_Prod", "/opt"),
        DELETE_CODE: findParam(action.payload, "Delete_Code", "000000"),
        DELETE_CODE_EMPLOYEE: findParam(action.payload, "Delete_Code_Employee", "123456789"),
        DELETE_CODE_STORE: findParam(action.payload, "Delete_Code_Store", "123456789"),
        DELETE_CODE_FORMDI: findParam(action.payload, "Delete_Code_FormDI", "123456789"),
        ENDPOINT: findParam(action.payload, "Endpoint", "https://34.247.84.215"),
        //ENDPOINT: findParam(action.payload, "Endpoint", "http://localhost:8000"),
        DEFAULTEXTPHONE: findParam(action.payload, "Default_Ext_Phone", "1"),
        DEFAULTCOUNTRY: findParam(action.payload, "Default_Country", "1"),
        DEFAULTPROVINCE: findParam(action.payload, "Default_Province", "30"),
        DEFAULTCOMMUNITY: findParam(action.payload, "Default_Community", "6"),
        DEFAULTSTORE: findParam(action.payload, "Default_Store", "1"),
        DEFAULTEMPLOYEE: findParam(action.payload, "Default_Employee", "1"),
        DEFAULTTYPEOFDOCUMENT: findParam(action.payload, "Default_TypesOfDocument", "4"),
        DEFAULTLANGUAGE: findParam(action.payload, "Default_Language", "1"),
        DEFAULTCOLOR: findParam(action.payload, "Default_Color", "2"),
        DEFAULTCOLOREMPLOYEE: findParam(action.payload, "Default_Color_Employee", "50"),
        DEFAULTROLE: findParam(action.payload, "Default_Role", "4"),
        LIMITPAGESIZE: findParam(action.payload, "LimitPageSize", "1000"),
        LIMITPHONEPERSTORE: findParam(action.payload, "LimitPhonePerStore", "5"),
        LIMITEMAILPERSTORE: findParam(action.payload, "LimitEmailPerStore", "5"),
        LIMITADDRESSPERSTORE: findParam(action.payload, "LimitAddressPerStore", "15"),
        LIMITBANKACCOUNTPERSTORE: findParam(action.payload, "LimitBankAccountPerCustomer", "5"),
        DEFAULTTYPESDI: findParam(action.payload, "Default_TypesDI", "1"),
        LIMITPHONEPEREMPLOYEE: findParam(action.payload, "LimitPhonePerEmployee", "5"),
        LIMITEMAILPEREMPLOYEE: findParam(action.payload, "LimitEmailPerEmployee", "5"),
        LIMITADDRESSPEREMPLOYEE: findParam(action.payload, "LimitAddressPerEmployee", "5"),
        LIMITBANKACCOUNTPEREMPLOYEE: findParam(action.payload, "LimitBankAccountPerEmployee", "5"),
        NON_ERASABLE_CODELER_ID: findParam(action.payload, "LimitNonErasableCodeLerId", "834"),
        NON_ERASABLE_TREATMENTTYPE_ID: findParam(action.payload, "LimitNonErasableTreatmentTypeId", "85"),
        NON_ERASABLE_PROCESSTYPE_ID: findParam(action.payload, "LimitNonErasableProcessTypeId", "29"),
        NON_ERASABLE_DANGERROUS_ID: findParam(action.payload, "LimitNonErasableDangerousnessId", "15"),
        NON_ERASABLE_COLOR_ID: findParam(action.payload, "LimitNonErasableColorId", "137"),
        NON_ERASABLE_COMMUNITY_ID: findParam(action.payload, "LimitNonErasableCommunityId", "15"),
        NON_ERASABLE_COUNTRY_ID: findParam(action.payload, "LimitNonErasableCountryId", "247"),
        NON_ERASABLE_DESCRIPTION_ID: findParam(action.payload, "LimitNonErasableDescriptionId", "2"),
        NON_ERASABLE_DOCUMENTTYPES_ID: findParam(action.payload, "LimitNonErasableDocumentTypesId", "4"),
        NON_ERASABLE_LANGUAGE_ID: findParam(action.payload, "LimitNonErasableLanguageId", "2"),
        NON_ERASABLE_POSTALCODE_ID: findParam(action.payload, "LimitNonErasablePostalCodeId", "14617"),
        NON_ERASABLE_PROFESSION_ID: findParam(action.payload, "LimitNonErasableProfessionId", "1051"),
        NON_ERASABLE_PROVINCE_ID: findParam(action.payload, "LimitNonErasableProvinceId", "53"),
        NON_ERASABLE_ROADTYPES_ID: findParam(action.payload, "LimitNonErasableRoadTypesId", "46"),
        NON_ERASABLE_TYPESDI_ID: findParam(action.payload, "LimitNonErasableTypesDIId", "2"),
        NON_ERASABLE_WEIGHVEHICLESBUTTON_ID: findParam(action.payload, "LimitNonErasableWeighVehiclesButtonId", "15"),
        NON_ERASABLE_WEIGHVEHICLESFORMAT_ID: findParam(action.payload, "LimitNonErasableWeighVehiclesFormatId", "1"),
        NON_ERASABLE_USERACCOUNT_ID: findParam(action.payload, "LimitNonErasableUserAccountId", "6"),
        NON_ERASABLE_STORE_ID: findParam(action.payload, "LimitNonErasableStoreId", "3"),
        NON_ERASABLE_EMPLOYEE_ID: findParam(action.payload, "LimitNonErasableEmployeeId", "2")
      };
    }
    case RESET_LIST_VARIABLES:
      return initialState;
    default:
      return state;
  }
}
