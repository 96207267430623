import React, {useState, useEffect} from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { TextField } from '@mui/material';
import {Checkbox, Toolbar,TableFooter,TablePagination,TableSortLabel,Table,TableBody,TableCell,TableContainer, TableHead,TableRow,Paper,Typography} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next"

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t, headCells,showEditButton,showDeleteButton } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        {showEditButton&&
        <TableCell
          key='edit'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#4e73df", fontSize: 14}}>
            {t('Button_Edit')}
          </Typography>
        </TableCell>}
         {showDeleteButton &&
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 14}} > 
            {t('Button_Delete')}
          </Typography>
        </TableCell>}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
    const { textHead, textReg, numTotReg, numTotRegFilter, textOf } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead} 
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotReg).toLocaleString('de-DE')}
        </Typography>
      </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataGeneralSettings']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSorting =(props)=>{
  const { data, headCells,numTotRegFilter, titleTable,setOpenDeleteRow,setOpenEditRow,setRowEdit,showEditButton,showDeleteButton,typeTable,setOpenCreateProvince,showVisibilityButton,numTotReg,activeOffset,rangeOffset,currencyOffset,setCurrencyOffset,minvalue} = props;
  const {t} = useTranslation(['listDataGeneralSettings']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0);
  const [countData,setCountData] = useState(data.length);
  
  useEffect(
    () => {
      if (data.length!==countData || data.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(data.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0);  
    },
    [data,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffset(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRow = (row) => {
    setRowEdit(row);
    setOpenDeleteRow(true);
  };

  const openEditRow = (row) => {
    setRowEdit(row);
    setOpenEditRow(true);
  };

  const openProvince = (row) => {
    setRowEdit(row);
    setOpenCreateProvince(true);
  };
  const listItems= (
  stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
        {typeTable===2&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.name}
              </Typography>
            </TableCell>
          </>
        }
        {typeTable===3&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.namees}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.nameen}
              </Typography>
            </TableCell>
          </>
        }
        {typeTable===4&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
                </Typography>  
            </TableCell>
            <TableCell align="left">{row.code}</TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.namees}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.nameen}
              </Typography>
            </TableCell>
          </>
        }
         {typeTable===5&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
              </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.namees}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.nameen}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
              {row.codehex}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Box sx={{ align: 'center', width: 60, height: 15, backgroundColor: row.codehex}}/>
            </TableCell>
          </>
        }
        {typeTable===6&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {row.id}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.namees}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.nameen}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.code_iso2}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.code_iso3}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >
                {row.extphone}
              </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >
                {row.extinternet}
              </Typography>  
            </TableCell>
            <TableCell align="left">
              <Checkbox checked={row.enabled} disabled/>
            </TableCell>
            {(showVisibilityButton  && row.id!=="" && row.id!==null )?
            <TableCell align="center">
              <IconButton  size="large" style={{color:"#4e73df"}} onClick={()=>openProvince(row)}>
                <VisibilityIcon /> 
                  <Typography style={{color:"#4e73df", fontSize: 14}} >  
                    &nbsp;({(row.numTotProvinces).toLocaleString('de-DE')})
                  </Typography>
              </IconButton>
            </TableCell>:
            <TableCell align="center">
            <IconButton  size="large" style={{color:"#CCC0C0"}} disabled>
              <VisibilityOffIcon />
                <Typography style={{color:"#CCC0C0", fontSize: 14}} >  
                  &nbsp;({(row.numTotProvinces).toLocaleString('de-DE')})
                </Typography>
            </IconButton>
          </TableCell>}
          </>
        }
        {typeTable===7&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
              </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.name}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Checkbox checked={row.enabled} disabled/>
            </TableCell>
            
          </>
        }
         {typeTable===8&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
              </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.code}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.namees}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.nameen}
              </Typography>
            </TableCell>
          </>
        }
        {typeTable===10&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
              </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.cotgroup}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.namees}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.nameen}
              </Typography>
            </TableCell>
          </>
        }
        {typeTable===11&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
              </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.namees}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.nameen}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.iso}
                </Typography>  
            </TableCell>
            <TableCell align="center">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.symbolcurrency}
                </Typography>  
            </TableCell>
          </>
        }
         {typeTable===12&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
              </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.namees}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.nameen}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.default_preiddoc_sheet}
                </Typography>  
            </TableCell>
            <TableCell align="center">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.default_sufiddoc_sheet}
                </Typography>  
            </TableCell>
            <TableCell align="center">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.default_number_dt}
                </Typography>  
            </TableCell>
            <TableCell align="center">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.default_codeler_code}
                </Typography>  
            </TableCell>
            <TableCell align="center">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.default_description_code}
                </Typography>  
            </TableCell>
            {(showVisibilityButton  && row.id!=="" && row.id!==null )?
            <TableCell align="center">
              <IconButton  size="large" style={{color:"#4e73df"}} onClick={()=>openProvince(row)}>
                <VisibilityIcon />
              </IconButton>
            </TableCell>:
            <TableCell align="center">
            <IconButton  size="large" style={{color:"#CCC0C0"}} disabled>
              <VisibilityOffIcon />
            </IconButton>
          </TableCell>}
          </>
        }
        {typeTable===14&&
          <>
            <TableCell align="left">
              <Typography style={{color:"#4e73df", fontSize: 14}} >
                {row.id}
              </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.namees}
                </Typography>  
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.nameen}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.codeler_code}
                </Typography>  
            </TableCell>
            <TableCell align="center">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.codeler_namees}
                </Typography>  
            </TableCell>
            <TableCell align="center">
              <Typography style={{color:"#000000", fontSize: 14}} >  
                {row.codeler_nameen}
                </Typography>  
            </TableCell>
          </>
        }

        { (showEditButton  && row.id!=="" && row.id!==null )&&
        <TableCell align="center">
          <IconButton  size="large"  style={{color:"#4e73df", fontSize: 14}} onClick={()=>openEditRow(row)}>
            <EditIcon/>
          </IconButton>
        </TableCell>}
        {(showDeleteButton  && row.id!=="" && row.id!==null ) &&
        <TableCell align="center">
          <IconButton  size="large"  style={{ color:row.id>minvalue?"#e74a3b":"#858796", fontSize: 14}} onClick={row.id>minvalue?()=>openModalDeleteRow(row):() => {}}>
            <DeleteIcon/>
          </IconButton>
        </TableCell>}
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}>      
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotReg={numTotReg} numTotRegFilter={numTotRegFilter} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} headCells={headCells} showEditButton={showEditButton} showDeleteButton={showDeleteButton}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffset && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffset"
                            name="currencyOffset"
                            value={currencyOffset}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffset
                            }}>
                            {rangeOffset.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={12}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSorting.propTypes = {
 
  data: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  setOpenDeleteRow: PropTypes.func.isRequired,
  setOpenEditRow: PropTypes.func.isRequired,   
  setRowEdit: PropTypes.func.isRequired,  
  showEditButton: PropTypes.bool.isRequired,  
  showDeleteButton: PropTypes.bool.isRequired,  
  typeTable: PropTypes.number.isRequired,
  showVisibilityButton: PropTypes.bool.isRequired,
  setOpenCreateProvince: PropTypes.func.isRequired,  
  numTotReg: PropTypes.number.isRequired,
  activeOffset: PropTypes.bool.isRequired,
  rangeOffset: PropTypes.array.isRequired,
  currencyOffset: PropTypes.number.isRequired,
  setCurrencyOffset: PropTypes.func.isRequired,
  minvalue: PropTypes.number.isRequired
};
  
export default TableSorting;