import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import { Checkbox, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function EnhancedTableHead({ t, headCellsFormatDI }) {
  return (
    <TableHead>
      <TableRow>
        {headCellsFormatDI.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'center'}
            padding='normal'
            sortDirection={false}
          >
            <Typography sx={{ color: "#4e73df", fontSize: 12 }}>
              {t(headCell.label)}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = ({ textHead }) => (
  <Toolbar>
    <Typography sx={{ flex: '1 1 100%', color: "#4e73df", fontSize: 24 }} id="tableTitle" component="div">
      {textHead}
    </Typography>
  </Toolbar>
);

const TableSortingFormatDI = ({ data, setData, headCellsFormatDI }) => {
  const { t } = useTranslation(['listDataGeneralSettings']); 
 
  const updateField = useCallback((field) => {
   
    setData(prevData => {
      let newValue = !prevData.formatDIDTO[field];
      if ((field==='opt_tras_expand' 
          || field==='opt_tras_handle' 
          || field==='opt_tras_view_name' 
          || field==='opt_tras_view_contactname'
          || field==='opt_tras_view_address'
          || field==='opt_tras_view_country'
          || field==='opt_tras_view_community'
          || field==='opt_tras_view_phone'
          || field==='opt_tras_view_email'
          || field==='opt_tras_view_nima'
          || field==='opt_tras_view_cnae'
          || field==='opt_tras_view_inscription'
          || field==='opt_tras_view_opt'
          || field==='opt_tras_view_licenseplate'
          || field==='opt_tras_view_economicactivity'

          || field==='opt_tras_handle_documnettype'
          || field==='opt_tras_handle_documentid'
          || field==='opt_tras_handle_tradename'
          || field==='opt_tras_handle_name'
          || field==='opt_tras_handle_contactname'
          || field==='opt_tras_handle_address'
          || field==='opt_tras_handle_phone'
          || field==='opt_tras_handle_email'
          || field==='opt_tras_handle_nima'
          || field==='opt_tras_handle_cnae'
          || field==='opt_tras_handle_inscription'
          || field==='opt_tras_handle_opt'
          || field==='opt_tras_handle_licenseplate'
          || field==='opt_tras_handle_economicactivity'

      ) && (!data.formatDIDTO.opt_tras_view )){ newValue=false;}
      if (( field==='opt_tras_handle' 
        || field==='opt_tras_view_name' 
        || field==='opt_tras_view_contactname'
        || field==='opt_tras_view_address'
        || field==='opt_tras_view_country'
        || field==='opt_tras_view_community'
        || field==='opt_tras_view_phone'
        || field==='opt_tras_view_email'
        || field==='opt_tras_view_nima'
        || field==='opt_tras_view_cnae'
        || field==='opt_tras_view_inscription'
        || field==='opt_tras_view_opt'
        || field==='opt_tras_view_licenseplate'
        || field==='opt_tras_view_economicactivity'

        || field==='opt_tras_handle_documnettype'
        || field==='opt_tras_handle_documentid'
        || field==='opt_tras_handle_tradename'
        || field==='opt_tras_handle_name'
        || field==='opt_tras_handle_contactname'
        || field==='opt_tras_handle_address'
        || field==='opt_tras_handle_phone'
        || field==='opt_tras_handle_email'
        || field==='opt_tras_handle_nima'
        || field==='opt_tras_handle_cnae'
        || field==='opt_tras_handle_inscription'
        || field==='opt_tras_handle_opt'
        || field==='opt_tras_handle_licenseplate'
        || field==='opt_tras_handle_economicactivity'

        ) && (!data.formatDIDTO.opt_tras_expand )){ newValue=false;}
      if ((field==='opt_tras_handle_documnettype'
          || field==='opt_tras_handle_documentid'
          || field==='opt_tras_handle_tradename'
          || field==='opt_tras_handle_name'
          || field==='opt_tras_handle_contactname'
          || field==='opt_tras_handle_address'
          || field==='opt_tras_handle_phone'
          || field==='opt_tras_handle_email'
          || field==='opt_tras_handle_nima'
          || field==='opt_tras_handle_cnae'
          || field==='opt_tras_handle_inscription'
          || field==='opt_tras_handle_opt'
          || field==='opt_tras_handle_licenseplate'
          || field==='opt_tras_handle_economicactivity'

      ) && (!data.formatDIDTO.opt_tras_handle )){ newValue=false;}
      if ((field==='ori_tras_expand' 
        || field==='ori_tras_handle' 
        || field==='ori_tras_view_name' 
        || field==='ori_tras_view_contactname'
        || field==='ori_tras_view_address'
        || field==='ori_tras_view_country'
        || field==='ori_tras_view_community'
        || field==='ori_tras_view_phone'
        || field==='ori_tras_view_email'
        || field==='ori_tras_view_nima'
        || field==='ori_tras_view_cnae'
        || field==='ori_tras_view_inscription'
        || field==='ori_tras_view_opt'
        || field==='ori_tras_view_licenseplate'
        || field==='ori_tras_view_economicactivity'

        || field==='ori_tras_handle_documnettype'
        || field==='ori_tras_handle_documentid'
        || field==='ori_tras_handle_tradename'
        || field==='ori_tras_handle_name'
        || field==='ori_tras_handle_contactname'
        || field==='ori_tras_handle_address'
        || field==='ori_tras_handle_phone'
        || field==='ori_tras_handle_email'
        || field==='ori_tras_handle_nima'
        || field==='ori_tras_handle_cnae'
        || field==='ori_tras_handle_inscription'
        || field==='ori_tras_handle_opt'
        || field==='ori_tras_handle_licenseplate'
        || field==='ori_tras_handle_economicactivity'

      ) && (!data.formatDIDTO.ori_tras_view )){ newValue=false;}
      if (( field==='ori_tras_handle' 
        || field==='ori_tras_view_name' 
        || field==='ori_tras_view_contactname'
        || field==='ori_tras_view_address'
        || field==='ori_tras_view_country'
        || field==='ori_tras_view_community'
        || field==='ori_tras_view_phone'
        || field==='ori_tras_view_email'
        || field==='ori_tras_view_nima'
        || field==='ori_tras_view_cnae'
        || field==='ori_tras_view_inscription'
        || field==='ori_tras_view_opt'
        || field==='ori_tras_view_licenseplate'
        || field==='ori_tras_view_economicactivity'

        || field==='ori_tras_handle_documnettype'
        || field==='ori_tras_handle_documentid'
        || field==='ori_tras_handle_tradename'
        || field==='ori_tras_handle_name'
        || field==='ori_tras_handle_contactname'
        || field==='ori_tras_handle_address'
        || field==='ori_tras_handle_phone'
        || field==='ori_tras_handle_email'
        || field==='ori_tras_handle_nima'
        || field==='ori_tras_handle_cnae'
        || field==='ori_tras_handle_inscription'
        || field==='ori_tras_handle_opt'
        || field==='ori_tras_handle_licenseplate'
        || field==='ori_tras_handle_economicactivity'

        ) && (!data.formatDIDTO.ori_tras_expand )){ newValue=false;}
      if ((field==='ori_tras_handle_documnettype'
          || field==='ori_tras_handle_documentid'
          || field==='ori_tras_handle_tradename'
          || field==='ori_tras_handle_name'
          || field==='ori_tras_handle_contactname'
          || field==='ori_tras_handle_address'
          || field==='ori_tras_handle_phone'
          || field==='ori_tras_handle_email'
          || field==='ori_tras_handle_nima'
          || field==='ori_tras_handle_cnae'
          || field==='ori_tras_handle_inscription'
          || field==='ori_tras_handle_opt'
          || field==='ori_tras_handle_licenseplate'
          || field==='ori_tras_handle_economicactivity'

      ) && (!data.formatDIDTO.ori_tras_handle )){ newValue=false;}
      if ((field==='aut_tras_expand' 
        || field==='aut_tras_handle' 
        || field==='aut_tras_view_name' 
        || field==='aut_tras_view_contactname'
        || field==='aut_tras_view_address'
        || field==='aut_tras_view_country'
        || field==='aut_tras_view_community'
        || field==='aut_tras_view_phone'
        || field==='aut_tras_view_email'
        || field==='aut_tras_view_nima'
        || field==='aut_tras_view_cnae'
        || field==='aut_tras_view_inscription'
        || field==='aut_tras_view_opt'
        || field==='aut_tras_view_licenseplate'
        || field==='aut_tras_view_economicactivity'

        || field==='aut_tras_handle_documnettype'
        || field==='aut_tras_handle_documentid'
        || field==='aut_tras_handle_tradename'
        || field==='aut_tras_handle_name'
        || field==='aut_tras_handle_contactname'
        || field==='aut_tras_handle_address'
        || field==='aut_tras_handle_phone'
        || field==='aut_tras_handle_email'
        || field==='aut_tras_handle_nima'
        || field==='aut_tras_handle_cnae'
        || field==='aut_tras_handle_inscription'
        || field==='aut_tras_handle_opt'
        || field==='aut_tras_handle_licenseplate'
        || field==='aut_tras_handle_economicactivity'

    ) && (!data.formatDIDTO.aut_tras_view )){ newValue=false; }
    if (( field==='aut_tras_handle' 
      || field==='aut_tras_view_name' 
      || field==='aut_tras_view_contactname'
      || field==='aut_tras_view_address'
      || field==='aut_tras_view_country'
      || field==='aut_tras_view_community'
      || field==='aut_tras_view_phone'
      || field==='aut_tras_view_email'
      || field==='aut_tras_view_nima'
      || field==='aut_tras_view_cnae'
      || field==='aut_tras_view_inscription'
      || field==='aut_tras_view_opt'
      || field==='aut_tras_view_licenseplate'
      || field==='aut_tras_view_economicactivity'

      || field==='aut_tras_handle_documnettype'
      || field==='aut_tras_handle_documentid'
      || field==='aut_tras_handle_tradename'
      || field==='aut_tras_handle_name'
      || field==='aut_tras_handle_contactname'
      || field==='aut_tras_handle_address'
      || field==='aut_tras_handle_phone'
      || field==='aut_tras_handle_email'
      || field==='aut_tras_handle_nima'
      || field==='aut_tras_handle_cnae'
      || field==='aut_tras_handle_inscription'
      || field==='aut_tras_handle_opt'
      || field==='aut_tras_handle_licenseplate'
      || field==='aut_tras_handle_economicactivity'

      ) && (!data.formatDIDTO.aut_tras_expand )){ newValue=false; }
    if ((field==='aut_tras_handle_documnettype'
        || field==='aut_tras_handle_documentid'
        || field==='aut_tras_handle_tradename'
        || field==='aut_tras_handle_name'
        || field==='aut_tras_handle_contactname'
        || field==='aut_tras_handle_address'
        || field==='aut_tras_handle_phone'
        || field==='aut_tras_handle_email'
        || field==='aut_tras_handle_nima'
        || field==='aut_tras_handle_cnae'
        || field==='aut_tras_handle_inscription'
        || field==='aut_tras_handle_opt'
        || field==='aut_tras_handle_licenseplate'
        || field==='aut_tras_handle_economicactivity'

    ) && (!data.formatDIDTO.aut_tras_handle )){ newValue=false;}
    if ((field==='dest_tras_expand' 
      || field==='dest_tras_handle' 
      || field==='dest_tras_view_name' 
      || field==='dest_tras_view_contactname'
      || field==='dest_tras_view_address'
      || field==='dest_tras_view_country'
      || field==='dest_tras_view_community'
      || field==='dest_tras_view_phone'
      || field==='dest_tras_view_email'
      || field==='dest_tras_view_nima'
      || field==='dest_tras_view_cnae'
      || field==='dest_tras_view_inscription'
      || field==='dest_tras_view_opt'
      || field==='dest_tras_view_licenseplate'
      || field==='dest_tras_view_economicactivity'

      || field==='dest_tras_handle_documnettype'
      || field==='dest_tras_handle_documentid'
      || field==='dest_tras_handle_tradename'
      || field==='dest_tras_handle_name'
      || field==='dest_tras_handle_contactname'
      || field==='dest_tras_handle_address'
      || field==='dest_tras_handle_phone'
      || field==='dest_tras_handle_email'
      || field==='dest_tras_handle_nima'
      || field==='dest_tras_handle_cnae'
      || field==='dest_tras_handle_inscription'
      || field==='dest_tras_handle_opt'
      || field==='dest_tras_handle_licenseplate'
      || field==='dest_tras_handle_economicactivity'

    ) && (!data.formatDIDTO.dest_tras_view )){ newValue=false; }
    if (( field==='dest_tras_handle' 
      || field==='dest_tras_view_name' 
      || field==='dest_tras_view_contactname'
      || field==='dest_tras_view_address'
      || field==='dest_tras_view_country'
      || field==='dest_tras_view_community'
      || field==='dest_tras_view_phone'
      || field==='dest_tras_view_email'
      || field==='dest_tras_view_nima'
      || field==='dest_tras_view_cnae'
      || field==='dest_tras_view_inscription'
      || field==='dest_tras_view_opt'
      || field==='dest_tras_view_licenseplate'
      || field==='dest_tras_view_economicactivity'

      || field==='dest_tras_handle_documnettype'
      || field==='dest_tras_handle_documentid'
      || field==='dest_tras_handle_tradename'
      || field==='dest_tras_handle_name'
      || field==='dest_tras_handle_contactname'
      || field==='dest_tras_handle_address'
      || field==='dest_tras_handle_phone'
      || field==='dest_tras_handle_email'
      || field==='dest_tras_handle_nima'
      || field==='dest_tras_handle_cnae'
      || field==='dest_tras_handle_inscription'
      || field==='dest_tras_handle_opt'
      || field==='dest_tras_handle_licenseplate'
      || field==='dest_tras_handle_economicactivity'

      ) && (!data.formatDIDTO.dest_tras_expand )){ newValue=false; }
    if ((field==='dest_tras_handle_documnettype'
        || field==='dest_tras_handle_documentid'
        || field==='dest_tras_handle_tradename'
        || field==='dest_tras_handle_name'
        || field==='dest_tras_handle_contactname'
        || field==='dest_tras_handle_address'
        || field==='dest_tras_handle_phone'
        || field==='dest_tras_handle_email'
        || field==='dest_tras_handle_nima'
        || field==='dest_tras_handle_cnae'
        || field==='dest_tras_handle_inscription'
        || field==='dest_tras_handle_opt'
        || field==='dest_tras_handle_licenseplate'
        || field==='dest_tras_handle_economicactivity'

    ) && (!data.formatDIDTO.dest_tras_handle )){ newValue=false;}
    if ((field==='aut_dest_expand' 
      || field==='aut_dest_handle' 
      || field==='aut_dest_view_name' 
      || field==='aut_dest_view_contactname'
      || field==='aut_dest_view_address'
      || field==='aut_dest_view_country'
      || field==='aut_dest_view_community'
      || field==='aut_dest_view_phone'
      || field==='aut_dest_view_email'
      || field==='aut_dest_view_nima'
      || field==='aut_dest_view_cnae'
      || field==='aut_dest_view_inscription'
      || field==='aut_dest_view_opt'
      || field==='aut_dest_view_licenseplate'
      || field==='aut_dest_view_economicactivity'

      || field==='aut_dest_handle_documnettype'
      || field==='aut_dest_handle_documentid'
      || field==='aut_dest_handle_tradename'
      || field==='aut_dest_handle_name'
      || field==='aut_dest_handle_contactname'
      || field==='aut_dest_handle_address'
      || field==='aut_dest_handle_phone'
      || field==='aut_dest_handle_email'
      || field==='aut_dest_handle_nima'
      || field==='aut_dest_handle_cnae'
      || field==='aut_dest_handle_inscription'
      || field==='aut_dest_handle_opt'
      || field==='aut_dest_handle_licenseplate'
      || field==='aut_dest_handle_economicactivity'

    ) && (!data.formatDIDTO.aut_dest_view )){ newValue=false; }
    if (( field==='aut_dest_handle' 
      || field==='aut_dest_view_name' 
      || field==='aut_dest_view_contactname'
      || field==='aut_dest_view_address'
      || field==='aut_dest_view_country'
      || field==='aut_dest_view_community'
      || field==='aut_dest_view_phone'
      || field==='aut_dest_view_email'
      || field==='aut_dest_view_nima'
      || field==='aut_dest_view_cnae'
      || field==='aut_dest_view_inscription'
      || field==='aut_dest_view_opt'
      || field==='aut_dest_view_licenseplate'
      || field==='aut_dest_view_economicactivity'

      || field==='aut_dest_handle_documnettype'
      || field==='aut_dest_handle_documentid'
      || field==='aut_dest_handle_tradename'
      || field==='aut_dest_handle_name'
      || field==='aut_dest_handle_contactname'
      || field==='aut_dest_handle_address'
      || field==='aut_dest_handle_phone'
      || field==='aut_dest_handle_email'
      || field==='aut_dest_handle_nima'
      || field==='aut_dest_handle_cnae'
      || field==='aut_dest_handle_inscription'
      || field==='aut_dest_handle_opt'
      || field==='aut_dest_handle_licenseplate'
      || field==='aut_dest_handle_economicactivity'

      ) && (!data.formatDIDTO.aut_dest_expand )){ newValue=false; }
    if ((field==='aut_dest_handle_documnettype'
        || field==='aut_dest_handle_documentid'
        || field==='aut_dest_handle_tradename'
        || field==='aut_dest_handle_name'
        || field==='aut_dest_handle_contactname'
        || field==='aut_dest_handle_address'
        || field==='aut_dest_handle_phone'
        || field==='aut_dest_handle_email'
        || field==='aut_dest_handle_nima'
        || field==='aut_dest_handle_cnae'
        || field==='aut_dest_handle_inscription'
        || field==='aut_dest_handle_opt'
        || field==='aut_dest_handle_licenseplate'
        || field==='aut_dest_handle_economicactivity'

    ) && (!data.formatDIDTO.aut_dest_handle )){ newValue=false;}
    if ((field==='rel_trans_expand' 
      || field==='rel_trans_handle' 
      || field==='rel_trans_view_name' 
      || field==='rel_trans_view_contactname'
      || field==='rel_trans_view_address'
      || field==='rel_trans_view_country'
      || field==='rel_trans_view_community'
      || field==='rel_trans_view_phone'
      || field==='rel_trans_view_email'
      || field==='rel_trans_view_nima'
      || field==='rel_trans_view_cnae'
      || field==='rel_trans_view_inscription'
      || field==='rel_trans_view_opt'
      || field==='rel_trans_view_licenseplate'
      || field==='rel_trans_view_economicactivity'

      || field==='rel_trans_handle_documnettype'
      || field==='rel_trans_handle_documentid'
      || field==='rel_trans_handle_tradename'
      || field==='rel_trans_handle_name'
      || field==='rel_trans_handle_contactname'
      || field==='rel_trans_handle_address'
      || field==='rel_trans_handle_phone'
      || field==='rel_trans_handle_email'
      || field==='rel_trans_handle_nima'
      || field==='rel_trans_handle_cnae'
      || field==='rel_trans_handle_inscription'
      || field==='rel_trans_handle_opt'
      || field==='rel_trans_handle_licenseplate'
      || field==='rel_trans_handle_economicactivity'

    ) && (!data.formatDIDTO.rel_trans_view )){ newValue=false; }
    if (( field==='rel_trans_handle' 
      || field==='rel_trans_view_name' 
      || field==='rel_trans_view_contactname'
      || field==='rel_trans_view_address'
      || field==='rel_trans_view_country'
      || field==='rel_trans_view_community'
      || field==='rel_trans_view_phone'
      || field==='rel_trans_view_email'
      || field==='rel_trans_view_nima'
      || field==='rel_trans_view_cnae'
      || field==='rel_trans_view_inscription'
      || field==='rel_trans_view_opt'
      || field==='rel_trans_view_licenseplate'
      || field==='rel_trans_view_economicactivity'

      || field==='rel_trans_handle_documnettype'
      || field==='rel_trans_handle_documentid'
      || field==='rel_trans_handle_tradename'
      || field==='rel_trans_handle_name'
      || field==='rel_trans_handle_contactname'
      || field==='rel_trans_handle_address'
      || field==='rel_trans_handle_phone'
      || field==='rel_trans_handle_email'
      || field==='rel_trans_handle_nima'
      || field==='rel_trans_handle_cnae'
      || field==='rel_trans_handle_inscription'
      || field==='rel_trans_handle_opt'
      || field==='rel_trans_handle_licenseplate'
      || field==='rel_trans_handle_economicactivity'

      ) && (!data.formatDIDTO.rel_trans_expand )){ newValue=false; }
    if ((field==='rel_trans_handle_documnettype'
        || field==='rel_trans_handle_documentid'
        || field==='rel_trans_handle_tradename'
        || field==='rel_trans_handle_name'
        || field==='rel_trans_handle_contactname'
        || field==='rel_trans_handle_address'
        || field==='rel_trans_handle_phone'
        || field==='rel_trans_handle_email'
        || field==='rel_trans_handle_nima'
        || field==='rel_trans_handle_cnae'
        || field==='rel_trans_handle_inscription'
        || field==='rel_trans_handle_opt'
        || field==='rel_trans_handle_licenseplate'
        || field==='rel_trans_handle_economicactivity'

    ) && (!data.formatDIDTO.rel_trans_handle )){ newValue=false;}
    if ((field==='resp_prod_expand' 
      || field==='resp_prod_handle' 
      || field==='resp_prod_view_name' 
      || field==='resp_prod_view_contactname'
      || field==='resp_prod_view_address'
      || field==='resp_prod_view_country'
      || field==='resp_prod_view_community'
      || field==='resp_prod_view_phone'
      || field==='resp_prod_view_email'
      || field==='resp_prod_view_nima'
      || field==='resp_prod_view_cnae'
      || field==='resp_prod_view_inscription'
      || field==='resp_prod_view_opt'
      || field==='resp_prod_view_licenseplate'
      || field==='resp_prod_view_economicactivity'
      
      || field==='resp_prod_handle_documnettype'
      || field==='resp_prod_handle_documentid'
      || field==='resp_prod_handle_tradename'
      || field==='resp_prod_handle_name'
      || field==='resp_prod_handle_contactname'
      || field==='resp_prod_handle_address'
      || field==='resp_prod_handle_phone'
      || field==='resp_prod_handle_email'
      || field==='resp_prod_handle_nima'
      || field==='resp_prod_handle_cnae'
      || field==='resp_prod_handle_inscription'
      || field==='resp_prod_handle_opt'
      || field==='resp_prod_handle_licenseplate'
      || field==='resp_prod_handle_economicactivity'

    ) && (!data.formatDIDTO.resp_prod_view )){ newValue=false; }
    if (( field==='resp_prod_handle' 
      || field==='resp_prod_view_name' 
      || field==='resp_prod_view_contactname'
      || field==='resp_prod_view_address'
      || field==='resp_prod_view_country'
      || field==='resp_prod_view_community'
      || field==='resp_prod_view_phone'
      || field==='resp_prod_view_email'
      || field==='resp_prod_view_nima'
      || field==='resp_prod_view_cnae'
      || field==='resp_prod_view_inscription'
      || field==='resp_prod_view_opt'
      || field==='resp_prod_view_licenseplate'
      || field==='resp_prod_view_economicactivity'

      || field==='resp_prod_handle_documnettype'
      || field==='resp_prod_handle_documentid'
      || field==='resp_prod_handle_tradename'
      || field==='resp_prod_handle_name'
      || field==='resp_prod_handle_contactname'
      || field==='resp_prod_handle_address'
      || field==='resp_prod_handle_phone'
      || field==='resp_prod_handle_email'
      || field==='resp_prod_handle_nima'
      || field==='resp_prod_handle_cnae'
      || field==='resp_prod_handle_inscription'
      || field==='resp_prod_handle_opt'
      || field==='resp_prod_handle_licenseplate'
      || field==='resp_prod_handle_economicactivity'

      ) && (!data.formatDIDTO.resp_prod_expand )){ newValue=false; }
    if ((field==='resp_prod_handle_documnettype'
        || field==='resp_prod_handle_documentid'
        || field==='resp_prod_handle_tradename'
        || field==='resp_prod_handle_name'
        || field==='resp_prod_handle_contactname'
        || field==='resp_prod_handle_address'
        || field==='resp_prod_handle_phone'
        || field==='resp_prod_handle_email'
        || field==='resp_prod_handle_nima'
        || field==='resp_prod_handle_cnae'
        || field==='resp_prod_handle_inscription'
        || field==='resp_prod_handle_opt'
        || field==='resp_prod_handle_licenseplate'
        || field==='resp_prod_handle_economicactivity'

    ) && (!data.formatDIDTO.resp_prod_handle )){ newValue=false;}
    if (field==='opt_tras_view' || field==='opt_tras_expand' ){
      return {
        ...prevData,
        formatDIDTO: {
          ...prevData.formatDIDTO,
          [field]: newValue,
          opt_tras_expand :newValue,
          opt_tras_handle :newValue,
          opt_tras_view_name :newValue,
          opt_tras_view_contactname :newValue,
          opt_tras_view_address :newValue,
          opt_tras_view_country :newValue,
          opt_tras_view_community :newValue,
          opt_tras_view_phone :newValue,
          opt_tras_view_email :newValue,
          opt_tras_view_nima :newValue,
          opt_tras_view_cnae :false,
          opt_tras_view_inscription :newValue,
          opt_tras_view_opt :newValue,
          opt_tras_view_licenseplate :false,
          opt_tras_view_economicactivity :false,

          opt_tras_handle_documnettype :newValue,
          opt_tras_handle_documentid :newValue,
          opt_tras_handle_tradename :newValue,
          opt_tras_handle_name :newValue,
          opt_tras_handle_contactname :newValue,
          opt_tras_handle_address :newValue,
          opt_tras_handle_phone :newValue,
          opt_tras_handle_email :newValue,
          opt_tras_handle_nima :newValue,
          opt_tras_handle_cnae :false,
          opt_tras_handle_inscription :newValue,
          opt_tras_handle_opt :newValue,
          opt_tras_handle_licenseplate :false,
          opt_tras_handle_economicactivity :false
          
        }
      };
    } else if (field==='opt_tras_handle'){
      return {
        ...prevData,
        formatDIDTO: {
          ...prevData.formatDIDTO,
          [field]: newValue,
          opt_tras_handle_documnettype :newValue,
          opt_tras_handle_documentid :newValue,
          opt_tras_handle_tradename :newValue,
          opt_tras_handle_name :newValue,
          opt_tras_handle_contactname :newValue,
          opt_tras_handle_address :newValue,
          opt_tras_handle_phone :newValue,
          opt_tras_handle_email :newValue,
          opt_tras_handle_nima :newValue,
          opt_tras_handle_cnae :newValue,
          opt_tras_handle_inscription :newValue,
          opt_tras_handle_opt :newValue,
          opt_tras_handle_licenseplate :newValue,
          opt_tras_handle_economicactivity :newValue
        }
      }
    } else if (field==='ori_tras_view' || field==='ori_tras_expand'){
        return {
          ...prevData,
          formatDIDTO: {
            ...prevData.formatDIDTO,
            [field]: newValue,
            ori_tras_expand :newValue,
            ori_tras_handle :newValue,
            ori_tras_view_name :newValue,
            ori_tras_view_contactname :newValue,
            ori_tras_view_address :newValue,
            ori_tras_view_country :newValue,
            ori_tras_view_community :newValue,
            ori_tras_view_phone :newValue,
            ori_tras_view_email :newValue,
            ori_tras_view_nima :newValue,
            ori_tras_view_cnae :newValue,
            ori_tras_view_inscription :newValue,
            ori_tras_view_opt :false,
            ori_tras_view_licenseplate :false,
            ori_tras_view_economicactivity :false,

            ori_tras_handle_documnettype :newValue,
            ori_tras_handle_documentid :newValue,
            ori_tras_handle_tradename :newValue,
            ori_tras_handle_name :newValue,
            ori_tras_handle_contactname :newValue,
            ori_tras_handle_address :newValue,
            ori_tras_handle_phone :newValue,
            ori_tras_handle_email :newValue,
            ori_tras_handle_nima :newValue,
            ori_tras_handle_cnae :newValue,
            ori_tras_handle_inscription :newValue,
            ori_tras_handle_opt :false,
            ori_tras_handle_licenseplate :false,
            ori_tras_handle_economicactivity :false

          }
        }
      }else if (field==='ori_tras_handle'){
        return {
          ...prevData,
          formatDIDTO: {
            ...prevData.formatDIDTO,
            [field]: newValue,
            ori_tras_handle_documnettype :newValue,
            ori_tras_handle_documentid :newValue,
            ori_tras_handle_tradename :newValue,
            ori_tras_handle_name :newValue,
            ori_tras_handle_contactname :newValue,
            ori_tras_handle_address :newValue,
            ori_tras_handle_phone :newValue,
            ori_tras_handle_email :newValue,
            ori_tras_handle_nima :newValue,
            ori_tras_handle_cnae :newValue,
            ori_tras_handle_inscription :newValue,
            ori_tras_handle_opt :newValue,
            ori_tras_handle_licenseplate :newValue,
            ori_tras_handle_economicactivity :newValue

          }
        }
      }else if (field==='aut_tras_view' || field==='aut_tras_expand'){
          return {
            ...prevData,
            formatDIDTO: {
              ...prevData.formatDIDTO,
              [field]: newValue,
              aut_tras_expand :newValue,
              aut_tras_handle :newValue,
              aut_tras_view_name :newValue,
              aut_tras_view_contactname :newValue,
              aut_tras_view_address :newValue,
              aut_tras_view_country :newValue,
              aut_tras_view_community :newValue,
              aut_tras_view_phone :newValue,
              aut_tras_view_email :newValue,
              aut_tras_view_nima :newValue,
              aut_tras_view_cnae :false,
              aut_tras_view_inscription :newValue,
              aut_tras_view_opt :false,
              aut_tras_view_licenseplate :false,
              aut_tras_view_economicactivity :false,

              aut_tras_handle_documnettype :newValue,
              aut_tras_handle_documentid :newValue,
              aut_tras_handle_tradename :newValue,
              aut_tras_handle_name :newValue,
              aut_tras_handle_contactname :newValue,
              aut_tras_handle_address :newValue,
              aut_tras_handle_phone :newValue,
              aut_tras_handle_email :newValue,
              aut_tras_handle_nima :newValue,
              aut_tras_handle_cnae :false,
              aut_tras_handle_inscription :newValue,
              aut_tras_handle_opt :false,
              aut_tras_handle_licenseplate :false,
              aut_tras_handle_economicactivity :false 
            }
          }
        }else if (field==='aut_tras_handle'){
          return {
            ...prevData,
            formatDIDTO: {
              ...prevData.formatDIDTO,
              [field]: newValue,
              aut_tras_handle_documnettype :newValue,
              aut_tras_handle_documentid :newValue,
              aut_tras_handle_tradename :newValue,
              aut_tras_handle_name :newValue,
              aut_tras_handle_contactname :newValue,
              aut_tras_handle_address :newValue,
              aut_tras_handle_phone :newValue,
              aut_tras_handle_email :newValue,
              aut_tras_handle_nima :newValue,
              aut_tras_handle_cnae :newValue,
              aut_tras_handle_inscription :newValue,
              aut_tras_handle_opt :newValue,
              aut_tras_handle_licenseplate :newValue  ,
              aut_tras_handle_economicactivity :newValue 
            }
          }
        }else if (field==='dest_tras_view' || field==='dest_tras_expand'){
            return {
              ...prevData,
              formatDIDTO: {
                ...prevData.formatDIDTO,
                [field]: newValue,
                dest_tras_expand :newValue,
                dest_tras_handle :newValue,
                dest_tras_view_name :newValue,
                dest_tras_view_contactname :newValue,
                dest_tras_view_address :newValue,
                dest_tras_view_country :newValue,
                dest_tras_view_community :newValue,
                dest_tras_view_phone :newValue,
                dest_tras_view_email :newValue,
                dest_tras_view_nima :newValue,
                dest_tras_view_cnae :false,
                dest_tras_view_inscription :newValue,
                dest_tras_view_opt :false,
                dest_tras_view_licenseplate :false,
                dest_tras_view_economicactivity :false,

                dest_tras_handle_documnettype :newValue,
                dest_tras_handle_documentid :newValue,
                dest_tras_handle_tradename :newValue,
                dest_tras_handle_name :newValue,
                dest_tras_handle_contactname :newValue,
                dest_tras_handle_address :newValue,
                dest_tras_handle_phone :newValue,
                dest_tras_handle_email :newValue,
                dest_tras_handle_nima :newValue,
                dest_tras_handle_cnae :false,
                dest_tras_handle_inscription :newValue,
                dest_tras_handle_opt :false,
                dest_tras_handle_licenseplate :false,
                dest_tras_handle_economicactivity :false
                
              }
            }
        }else if (field==='dest_tras_handle'){
          return {
            ...prevData,
            formatDIDTO: {
              ...prevData.formatDIDTO,
              [field]: newValue,
              dest_tras_handle_documnettype :newValue,
              dest_tras_handle_documentid :newValue,
              dest_tras_handle_tradename :newValue,
              dest_tras_handle_name :newValue,
              dest_tras_handle_contactname :newValue,
              dest_tras_handle_address :newValue,
              dest_tras_handle_phone :newValue,
              dest_tras_handle_email :newValue,
              dest_tras_handle_nima :newValue,
              dest_tras_handle_cnae :newValue,
              dest_tras_handle_inscription :newValue,
              dest_tras_handle_opt :newValue,
              dest_tras_handle_licenseplate :newValue ,
              dest_tras_handle_economicactivity :newValue
            }
          }
        }else if (field==='aut_dest_view' || field==='aut_dest_expand'){
            return {
              ...prevData,
              formatDIDTO: {
                ...prevData.formatDIDTO,
                [field]: newValue,
                aut_dest_expand :newValue,
                aut_dest_handle :newValue,
                aut_dest_view_name :newValue,
                aut_dest_view_contactname :newValue,
                aut_dest_view_address :newValue,
                aut_dest_view_country :newValue,
                aut_dest_view_community :newValue,
                aut_dest_view_phone :newValue,
                aut_dest_view_email :newValue,
                aut_dest_view_nima :newValue,
                aut_dest_view_cnae :false,
                aut_dest_view_inscription :newValue,
                aut_dest_view_opt :false,
                aut_dest_view_licenseplate :false,
                aut_dest_view_economicactivity :false,

                aut_dest_handle_documnettype :newValue,
                aut_dest_handle_documentid :newValue,
                aut_dest_handle_tradename :newValue,
                aut_dest_handle_name :newValue,
                aut_dest_handle_contactname :newValue,
                aut_dest_handle_address :newValue,
                aut_dest_handle_phone :newValue,
                aut_dest_handle_email :newValue,
                aut_dest_handle_nima :newValue,
                aut_dest_handle_cnae :false,
                aut_dest_handle_inscription :newValue,
                aut_dest_handle_opt :false,
                aut_dest_handle_licenseplate :false,
                aut_dest_handle_economicactivity :false
              }
            }
        } else if (field==='aut_dest_handle'){
          return {
            ...prevData,
            formatDIDTO: {
              ...prevData.formatDIDTO,
              [field]: newValue,
              aut_dest_handle_documnettype :newValue,
              aut_dest_handle_documentid :newValue,
              aut_dest_handle_tradename :newValue,
              aut_dest_handle_name :newValue,
              aut_dest_handle_contactname :newValue,
              aut_dest_handle_address :newValue,
              aut_dest_handle_phone :newValue,
              aut_dest_handle_email :newValue,
              aut_dest_handle_nima :newValue,
              aut_dest_handle_cnae :newValue,
              aut_dest_handle_inscription :newValue,
              aut_dest_handle_opt :newValue,
              aut_dest_handle_licenseplate :newValue,
              aut_dest_handle_economicactivity :newValue
            }
          }
        }else if (field==='rel_trans_view' || field==='rel_trans_expand'){
          return {
            ...prevData,
            formatDIDTO: {
              ...prevData.formatDIDTO,
              [field]: newValue,
              rel_trans_expand :newValue,
              rel_trans_handle :newValue,
              rel_trans_view_name :newValue,
              rel_trans_view_contactname :newValue,
              rel_trans_view_address :newValue,
              rel_trans_view_country :newValue,
              rel_trans_view_community :newValue,
              rel_trans_view_phone :newValue,
              rel_trans_view_email :newValue,
              rel_trans_view_nima :newValue,
              rel_trans_view_cnae :false,
              rel_trans_view_inscription :newValue,
              rel_trans_view_opt :false,
              rel_trans_view_licenseplate :newValue,
              rel_trans_view_economicactivity :false,

              rel_trans_handle_documnettype :newValue,
              rel_trans_handle_documentid :newValue,
              rel_trans_handle_tradename :newValue,
              rel_trans_handle_name :newValue,
              rel_trans_handle_contactname :newValue,
              rel_trans_handle_address :newValue,
              rel_trans_handle_phone :newValue,
              rel_trans_handle_email :newValue,
              rel_trans_handle_nima :newValue,
              rel_trans_handle_cnae :false,
              rel_trans_handle_inscription :newValue,
              rel_trans_handle_opt :false,
              rel_trans_handle_licenseplate :newValue,
              rel_trans_handle_economicactivity :false
            }
          }
      }else if (field==='rel_trans_handle'){
        return {
          ...prevData,
          formatDIDTO: {
            ...prevData.formatDIDTO,
            [field]: newValue,
            rel_trans_handle_documnettype :newValue,
            rel_trans_handle_documentid :newValue,
            rel_trans_handle_tradename :newValue,
            rel_trans_handle_name :newValue,
            rel_trans_handle_contactname :newValue,
            rel_trans_handle_address :newValue,
            rel_trans_handle_phone :newValue,
            rel_trans_handle_email :newValue,
            rel_trans_handle_nima :newValue,
            rel_trans_handle_cnae :newValue,
            rel_trans_handle_inscription :newValue,
            rel_trans_handle_opt :newValue,
            rel_trans_handle_licenseplate :newValue,
            rel_trans_handle_economicactivity :newValue
          }
        }
      }else if (field==='resp_prod_view' || field==='resp_prod_expand'){
        return {
          ...prevData,
          formatDIDTO: {
            ...prevData.formatDIDTO,
            [field]: newValue,
            resp_prod_expand :newValue,
            resp_prod_handle :newValue,
            resp_prod_view_name :newValue,
            resp_prod_view_contactname :newValue,
            resp_prod_view_address :newValue,
            resp_prod_view_country :newValue,
            resp_prod_view_community :newValue,
            resp_prod_view_phone :newValue,
            resp_prod_view_email :newValue,
            resp_prod_view_nima :newValue,
            resp_prod_view_cnae :false,
            resp_prod_view_inscription :newValue,
            resp_prod_view_opt :false,
            resp_prod_view_licenseplate :false,
            resp_prod_view_economicactivity :false,

            resp_prod_handle_documnettype :newValue,
            resp_prod_handle_documentid :newValue,
            resp_prod_handle_tradename :newValue,
            resp_prod_handle_name :newValue,
            resp_prod_handle_contactname :newValue,
            resp_prod_handle_address :newValue,
            resp_prod_handle_phone :newValue,
            resp_prod_handle_email :newValue,
            resp_prod_handle_nima :newValue,
            resp_prod_handle_cnae :false,
            resp_prod_handle_inscription :newValue,
            resp_prod_handle_opt :false,
            resp_prod_handle_licenseplate :false,
            resp_prod_handle_economicactivity :false
          }
        }
    } else if (field==='resp_prod_handle'){
      return {
        ...prevData,
        formatDIDTO: {
          ...prevData.formatDIDTO,
          [field]: newValue,
          resp_prod_handle_documnettype :newValue,
          resp_prod_handle_documentid :newValue,
          resp_prod_handle_tradename :newValue,
          resp_prod_handle_name :newValue,
          resp_prod_handle_contactname :newValue,
          resp_prod_handle_address :newValue,
          resp_prod_handle_phone :newValue,
          resp_prod_handle_email :newValue,
          resp_prod_handle_nima :newValue,
          resp_prod_handle_cnae :newValue,
          resp_prod_handle_inscription :newValue,
          resp_prod_handle_opt :newValue,
          resp_prod_handle_licenseplate :newValue     ,
          resp_prod_handle_economicactivity :newValue
        }
      }
    }else{
      return {
        ...prevData,
        formatDIDTO: {
          ...prevData.formatDIDTO,
          [field]: newValue
        }
      };
        
      }
    });
  }, [data.formatDIDTO.opt_tras_view, data.formatDIDTO.opt_tras_expand, data.formatDIDTO.opt_tras_handle,
    data.formatDIDTO.ori_tras_expand, data.formatDIDTO.ori_tras_handle, data.formatDIDTO.ori_tras_view,
    data.formatDIDTO.aut_tras_expand, data.formatDIDTO.aut_tras_handle, data.formatDIDTO.aut_tras_view,
    data.formatDIDTO.dest_tras_expand, data.formatDIDTO.dest_tras_handle, data.formatDIDTO.dest_tras_view,
    data.formatDIDTO.aut_dest_expand, data.formatDIDTO.aut_dest_handle, data.formatDIDTO.aut_dest_view,
    data.formatDIDTO.rel_trans_expand, data.formatDIDTO.rel_trans_handle, data.formatDIDTO.rel_trans_view,
    data.formatDIDTO.resp_prod_expand, data.formatDIDTO.resp_prod_handle, data.formatDIDTO.resp_prod_view,
    setData
  ]);

  const fields = [
    { label: 'Text_FORMAT_DI_VIEW', values: ['opt_tras_view', 'ori_tras_view', 'aut_tras_view', 'dest_tras_view', 'aut_dest_view', 'rel_trans_view', 'resp_prod_view'] },
    { label: 'Text_FORMAT_DI_EXPAND', values: ['opt_tras_expand', 'ori_tras_expand', 'aut_tras_expand', 'dest_tras_expand', 'aut_dest_expand', 'rel_trans_expand', 'resp_prod_expand'] },
    { label: 'Text_FORMAT_DI_HANDLE', values: ['opt_tras_handle', 'ori_tras_handle', 'aut_tras_handle', 'dest_tras_handle', 'aut_dest_handle', 'rel_trans_handle', 'resp_prod_handle'] },
    { label: 'Text_FORMAT_DI_VIEW_NAME', values: ['opt_tras_view_name', 'ori_tras_view_name', 'aut_tras_view_name', 'dest_tras_view_name', 'aut_dest_view_name', 'rel_trans_view_name', 'resp_prod_view_name'] },
    { label: 'Text_FORMAT_DI_VIEW_CONTACTNAME', values: ['opt_tras_view_contactname', 'ori_tras_view_contactname', 'aut_tras_view_contactname', 'dest_tras_view_contactname', 'aut_dest_view_contactname', 'rel_trans_view_contactname', 'resp_prod_view_contactname'] },
    { label: 'Text_FORMAT_DI_VIEW_ADDRESS', values: ['opt_tras_view_address', 'ori_tras_view_address', 'aut_tras_view_address', 'dest_tras_view_address', 'aut_dest_view_address', 'rel_trans_view_address', 'resp_prod_view_address'] },
    { label: 'Text_FORMAT_DI_VIEW_COUNTRY', values: ['opt_tras_view_country', 'ori_tras_view_country', 'aut_tras_view_country', 'dest_tras_view_country', 'aut_dest_view_country', 'rel_trans_view_country', 'resp_prod_view_country'] },
    { label: 'Text_FORMAT_DI_VIEW_COMMUNINTY', values: ['opt_tras_view_community', 'ori_tras_view_community', 'aut_tras_view_community', 'dest_tras_view_community', 'aut_dest_view_community', 'rel_trans_view_community', 'resp_prod_view_community'] },
    { label: 'Text_FORMAT_DI_VIEW_PHONE', values: ['opt_tras_view_phone', 'ori_tras_view_phone', 'aut_tras_view_phone', 'dest_tras_view_phone', 'aut_dest_view_phone', 'rel_trans_view_phone', 'resp_prod_view_phone'] },
    { label: 'Text_FORMAT_DI_VIEW_EMAIL', values: ['opt_tras_view_email', 'ori_tras_view_email', 'aut_tras_view_email', 'dest_tras_view_email', 'aut_dest_view_email', 'rel_trans_view_email', 'resp_prod_view_email'] },
    { label: 'Text_FORMAT_DI_VIEW_NIMA', values: ['opt_tras_view_nima', 'ori_tras_view_nima', 'aut_tras_view_nima', 'dest_tras_view_nima', 'aut_dest_view_nima', 'rel_trans_view_nima', 'resp_prod_view_nima'] },
    { label: 'Text_FORMAT_DI_VIEW_CNAE', values: ['opt_tras_view_cnae', 'ori_tras_view_cnae', 'aut_tras_view_cnae', 'dest_tras_view_cnae', 'aut_dest_view_cnae', 'rel_trans_view_cnae', 'resp_prod_view_cnae'] },
    { label: 'Text_FORMAT_DI_VIEW_INSCRIPTION', values: ['opt_tras_view_inscription', 'ori_tras_view_inscription', 'aut_tras_view_inscription', 'dest_tras_view_inscription', 'aut_dest_view_inscription', 'rel_trans_view_inscription', 'resp_prod_view_inscription'] },
    { label: 'Text_FORMAT_DI_VIEW_OPT', values: ['opt_tras_view_opt', 'ori_tras_view_opt', 'aut_tras_view_opt', 'dest_tras_view_opt', 'aut_dest_view_opt', 'rel_trans_view_opt', 'resp_prod_view_opt'] },
    { label: 'Text_FORMAT_DI_VIEW_LICENSEPLATE', values: ['opt_tras_view_licenseplate', 'ori_tras_view_licenseplate', 'aut_tras_view_licenseplate', 'dest_tras_view_licenseplate', 'aut_dest_view_licenseplate', 'rel_trans_view_licenseplate', 'resp_prod_view_licenseplate'] },
    { label: 'Text_FORMAT_DI_VIEW_ACTIVITY', values: ['opt_tras_view_economicactivity', 'ori_tras_view_economicactivity', 'aut_tras_view_economicactivity', 'dest_tras_view_economicactivity', 'aut_dest_view_economicactivity', 'rel_trans_view_economicactivity', 'resp_prod_view_economicactivity'] },
    
    { label: 'Text_FORMAT_DI_HANDLE_DOCUMENTTYPE', values: ['opt_tras_handle_documnettype', 'ori_tras_handle_documnettype', 'aut_tras_handle_documnettype', 'dest_tras_handle_documnettype', 'aut_dest_handle_documnettype', 'rel_trans_handle_documnettype', 'resp_prod_handle_documnettype'] },
    { label: 'Text_FORMAT_DI_HANDLE_DOCUEMENTID', values: ['opt_tras_handle_documentid', 'ori_tras_handle_documentid', 'aut_tras_handle_documentid', 'dest_tras_handle_documentid', 'aut_dest_handle_documentid', 'rel_trans_handle_documentid', 'resp_prod_handle_documentid'] },
    { label: 'Text_FORMAT_DI_HANDLE_TRADENAME', values: ['opt_tras_handle_tradename', 'ori_tras_handle_tradename', 'aut_tras_handle_tradename', 'dest_tras_handle_tradename', 'aut_dest_handle_tradename', 'rel_trans_handle_tradename', 'resp_prod_handle_tradename'] },
    { label: 'Text_FORMAT_DI_HANDLE_NAME', values: ['opt_tras_handle_name', 'ori_tras_handle_name', 'aut_tras_handle_name', 'dest_tras_handle_name', 'aut_dest_handle_name', 'rel_trans_handle_name', 'resp_prod_handle_name'] },
    { label: 'Text_FORMAT_DI_HANDLE_CONTACTNAME', values: ['opt_tras_handle_contactname', 'ori_tras_handle_contactname', 'aut_tras_handle_contactname', 'dest_tras_handle_contactname', 'aut_dest_handle_contactname', 'rel_trans_handle_contactname', 'resp_prod_handle_contactname'] },
    { label: 'Text_FORMAT_DI_HANDLE_ADDRESS', values: ['opt_tras_handle_address', 'ori_tras_handle_address', 'aut_tras_handle_address', 'dest_tras_handle_address', 'aut_dest_handle_address', 'rel_trans_handle_address', 'resp_prod_handle_address'] },
    { label: 'Text_FORMAT_DI_HANDLE_PHONE', values: ['opt_tras_handle_phone', 'ori_tras_handle_phone', 'aut_tras_handle_phone', 'dest_tras_handle_phone', 'aut_dest_handle_phone', 'rel_trans_handle_phone', 'resp_prod_handle_phone'] },
    { label: 'Text_FORMAT_DI_HANDLE_EMAIL', values: ['opt_tras_handle_email', 'ori_tras_handle_email', 'aut_tras_handle_email', 'dest_tras_handle_email', 'aut_dest_handle_email', 'rel_trans_handle_email', 'resp_prod_handle_email'] },
    { label: 'Text_FORMAT_DI_HANDLE_NIMA', values: ['opt_tras_handle_nima', 'ori_tras_handle_nima', 'aut_tras_handle_nima', 'dest_tras_handle_nima', 'aut_dest_handle_nima', 'rel_trans_handle_nima', 'resp_prod_handle_nima'] },
    { label: 'Text_FORMAT_DI_HANDLE_CNAE', values: ['opt_tras_handle_cnae', 'ori_tras_handle_cnae', 'aut_tras_handle_cnae', 'dest_tras_handle_cnae', 'aut_dest_handle_cnae', 'rel_trans_handle_cnae', 'resp_prod_handle_cnae'] },
    { label: 'Text_FORMAT_DI_HANDLE_INSCRIPTION', values: ['opt_tras_handle_inscription', 'ori_tras_handle_inscription', 'aut_tras_handle_inscription', 'dest_tras_handle_inscription', 'aut_dest_handle_inscription', 'rel_trans_handle_inscription', 'resp_prod_handle_inscription'] },
    { label: 'Text_FORMAT_DI_HANDLE_OPT', values: ['opt_tras_handle_opt', 'ori_tras_handle_opt', 'aut_tras_handle_opt', 'dest_tras_handle_opt', 'aut_dest_handle_opt', 'rel_trans_handle_opt', 'resp_prod_handle_opt'] },
    { label: 'Text_FORMAT_DI_HANDLE_LICENSEPLATE', values: ['opt_tras_handle_licenseplate', 'ori_tras_handle_licenseplate', 'aut_tras_handle_licenseplate', 'dest_tras_handle_licenseplate', 'aut_dest_handle_licenseplate', 'rel_trans_handle_licenseplate', 'resp_prod_handle_licenseplate'] },
    { label: 'Text_FORMAT_DI_HANDLE_ACTIVITY', values: ['opt_tras_handle_economicactivity', 'ori_tras_handle_economicactivity', 'aut_tras_handle_economicactivity', 'dest_tras_handle_economicactivity', 'aut_dest_handle_economicactivity', 'rel_trans_handle_economicactivity', 'resp_prod_handle_economicactivity'] }

  ];
  return (
    <Box sx={{ width: '100%' }}>
      <EnhancedTableToolbar textHead={t('Text_FORMAT_DI_SELECT')} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
          <EnhancedTableHead t={t} headCellsFormatDI={headCellsFormatDI} />
          <TableBody>
            {fields.map((fieldGroup, idx) => (
              <TableRow key={idx}>
                <TableCell align="left">
                  <Typography sx={{ color: "#4e73df", fontSize: 12 }}>
                    {t(fieldGroup.label)}
                  </Typography>
                </TableCell>
                {fieldGroup.values.map((field, index) => (
                  <TableCell key={index} align="center">
                    <Checkbox
                      checked={data.formatDIDTO[field]}
                      onChange={() => updateField(field)}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
         
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

TableSortingFormatDI.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  headCellsFormatDI: PropTypes.array.isRequired
};

export default TableSortingFormatDI;
