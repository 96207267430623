import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'reducers/Index';

const store = configureStore({
  reducer: rootReducer
});

store.subscribe(() => console.log('dispatch'));

export default store;
