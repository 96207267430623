import React, {  Fragment, useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CircleIcon from '@mui/icons-material/Circle';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material'; 
import { TextField } from '@mui/material';
import { Typography} from '@mui/material';
import { Button } from '@mui/material';
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import TableSortingAddress  from "pages/employees/employeeEdit/SortingTablesAddress";
import EmployeeCreateOrEditAddress from "pages/employees/employeeEdit/EmployeeNewAddress";
import TableSortingBankAccount from "pages/employees/employeeEdit/SortingTablesBankAcount";
import EmployeeCreateOrEditBankAccount from "pages/employees/employeeEdit/EmployeeNewBankAccount"; 
import EmployeeCreateOrEditUserAccount from "pages/employees/employeeEdit/EmployeeNewUserAccount";
import EmployeeSelectUserAccount from "pages/employees/employeeEdit/EmployeeSelectUserAccount";
import PropTypes from 'prop-types';
 
const initialStateEmployee = {
  "id" : "",
  "firstname" : "",
  "lastname1" : "",
  "lastname2" : "",
  "fullname" : "", 
  "createdate":"",
  "enabled" : true,
  "birthdate" : null,
  "comments" : "",
  "useraccount_id":0,
  "useraccount_username": "",
  "useraccount_aliasuser": "",
  "useraccount_password": "",
  "useraccount_enabled": true,
  "useraccount_isAccountNonLocked": true,
  "useraccount_color_id": 0,
  "useraccount_color_codehex": "",
  "useraccount_store_id": 0,
  "useraccount_store_name": "",
  "useraccount_email": "",
  "useraccount_tyyesdi_id": 0,
  "useraccount_tyyesdi_namees": "",
  "useraccount_tyyesdi_nameen": "",
  "documnettype_id":0,
  "numberdocument" : "",
  "numbersecuritysocial" : "",
  "language_id":0,
  "extphone_id":"1",
  "phones" : [],
  "emails" : [],
  "addresses" : [],
  "bankaccounts" : [] 
};

const initialUserAccountToSelect = [{
  "useraccount_id":0,
  "useraccount_username": "",
  "useraccount_aliasuser": "",
  "useraccount_password": "",
  "useraccount_enabled": true,
  "useraccount_isAccountNonLocked": true,
  "useraccount_color_id": 0,
  "useraccount_color_codehex": "",
  "useraccount_store_id": 0,
  "useraccount_store_name": "",
  "useraccount_email": "",
  "useraccount_tyyesdi_id": 0,
  "useraccount_tyyesdi_namees": "",
  "useraccount_tyyesdi_nameen": ""
}];

const initialDocumentTypesToSelect = [{
  "id": "",
  "name": ""
}];

const initialTypesToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const initialExtInternationalToSelect = [{
  "id": "",
  "extphone": ""
}];

const initialAddresses = [{
  "id":"",
  "indexrowaddress":0,
  "street":"", 
  "defaultaddress": false,
  "fiscaladdress": false,
  "otherdata":"",
  "roadtype_id":0,
  "roadtype_namees":"",
  "roadtype_nameen":"",
  "postalcode_id":"",
  "postalcode_postalcodename":"",
  "community_id":6,
  "community_namees":"",
  "community_nameen":""
}];

const initialBankAccounts = [{
  "id":"",
  "indexrowbankaccount":0,
  "codbic":"",
  "namebank":"",
  "iban":"",
  "entity":"",
  "office":"",
  "cd":"",
  "accountbank":"",
  "defaultaccountbank": false
}];
 

const EmployeeEditOrCreate = ({id, showModalEmployeeSettings}) => {
  const {t,i18n} = useTranslation(['listDataEmployees']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DELETE_CODE = useSelector((state) => state.variablesUser.DELETE_CODE); 
  const DEFAULTEXTPHONE = useSelector((state) => state.variablesUser.DEFAULTEXTPHONE); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const LIMITPHONEPEREMPLOYEE = useSelector((state) => state.variablesUser.LIMITPHONEPEREMPLOYEE); 
  const LIMITEMAILPEREMPLOYEE = useSelector((state) => state.variablesUser.LIMITEMAILPEREMPLOYEE); 
  const LIMITADDRESSPEREMPLOYEE = useSelector((state) => state.variablesUser.LIMITADDRESSPEREMPLOYEE); 
  const LIMITBANKACCOUNTPEREMPLOYEE = useSelector((state) => state.variablesUser.LIMITBANKACCOUNTPEREMPLOYEE); 
  const DEFAULTTYPEOFDOCUMENT = useSelector((state) => state.variablesUser.DEFAULTTYPEOFDOCUMENT); 
  const DEFAULTLANGUAGE = useSelector((state) => state.variablesUser.DEFAULTLANGUAGE); 
  const DEFAULTTYPESDI = useSelector((state) => state.variablesUser.DEFAULTDEFAULTTYPESDISOCIALSTATUS); 
  const [formEmployee, setFormEmployee] = useState(initialStateEmployee);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);
  const [errorsEmployee, setErrorsEmployee] = useState({});
  const [foundNumberOfDocument, setFoundNumberOfDocument ]= useState(false);
  const [foundNumberOfSecuritySocial, setFoundNumberOfSecuritySocial ]= useState(false);
  const [newPhone,setNewPhone]= useState("");
  const [newEmail,setNewEmail]= useState("");
  const [valueTabs, setValueTabs] = useState("first");
  const [openEditNewAddress,setOpenEditNewAddress]= useState(false);
  const [openDeleteRowAddress,setOpenDeleteRowAddress]= useState(false);
  const [openEditRowAddress,setOpenEditRowAddress]= useState(false);
  const [editRowAddress,setEditRowAddress]= useState(initialAddresses);
  const [idCodeDeleteAddress,setIdCodeDeleteAddress]=useState(DELETE_CODE);
  const [openEditNewBankAccount,setOpenEditNewBankAccount]= useState(false);
  const [openDeleteRowBankAccount,setOpenDeleteRowBankAccount]= useState(false);
  const [openEditRowBankAccount,setOpenEditRowBankAccount]= useState(false);
  const [editRowBankAccount,setEditRowBankAccount]= useState(initialBankAccounts);
  const [idCodeDeleteBankAccount,setIdCodeDeleteBankAccount]=useState(DELETE_CODE);
  const [userAccountToSelect, setUserAccountToSelect]= useState(initialUserAccountToSelect);
  const [documentTypesToSelect,setDocumentTypesToSelect]= useState(initialDocumentTypesToSelect);
  const [languagesToSelect,setLanguagesToSelect]= useState(initialTypesToSelect);
  const [openEditNewUserAccount,setOpenEditNewUserAccount]= useState(false);
  const [openDeleteRowUserAccount,setOpenDeleteRowUserAccount]= useState(false);
  const [openEditRowUserAccount,setOpenEditRowUserAccount]= useState(false);
  const [openSelectUserAccount,setOpenSelectUserAccount]= useState(false);
  const [idCodeDeleteUserAccount,setIdCodeDeleteUserAccount]=useState(DELETE_CODE);
  const [extInternationalToSelect,setExtInternationalToSelect]= useState(initialExtInternationalToSelect);
  const dispatch = useDispatch();
  const navigate = useNavigate();  

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalEmployeeSettings) {
        setFormEmployee(initialStateEmployee);
        setFormEmployee(formEmployee => ({
          ...formEmployee,
          phones:[],
          emails:[],
          addresses:[],
          bankaccounts:[],
          contracts : []
        }));
        setIsLoadingData(false);
        setIsLoadingErrorData(false);
        setErrorsEmployee({});
        setFoundNumberOfDocument(false);
        setFoundNumberOfSecuritySocial(false);
        setNewPhone("");
        setNewEmail("");
        setValueTabs("first");
        setOpenEditNewAddress(false);
        setOpenDeleteRowAddress(false);
        setOpenEditRowAddress(false);
        setEditRowAddress(initialAddresses);
        setIdCodeDeleteAddress(DELETE_CODE);
        setOpenEditNewBankAccount(false);
        setOpenDeleteRowBankAccount(false);
        setOpenEditRowBankAccount(false);
        setEditRowBankAccount(initialBankAccounts);
        setIdCodeDeleteBankAccount(DELETE_CODE);
        setUserAccountToSelect(initialUserAccountToSelect);
        setDocumentTypesToSelect(initialDocumentTypesToSelect);
        setLanguagesToSelect(initialTypesToSelect);
        setOpenEditNewUserAccount(false);
        setOpenDeleteRowUserAccount(false);
        setOpenEditRowUserAccount(false);
        setOpenSelectUserAccount(false);
        setIdCodeDeleteUserAccount(DELETE_CODE);
        setExtInternationalToSelect(initialExtInternationalToSelect);
        navigate("/employee") 
        return () => {};
      }
    },
    [
      setFormEmployee,
      setIsLoadingData,
      setIsLoadingErrorData,
      setErrorsEmployee,
      setFoundNumberOfDocument,
      setFoundNumberOfSecuritySocial,
      setNewPhone,
      setNewEmail,
      setValueTabs,
      setOpenEditNewAddress,
      setOpenDeleteRowAddress,
      setOpenEditRowAddress,
      setEditRowAddress,
      setIdCodeDeleteAddress,
      setOpenEditNewBankAccount,
      setOpenDeleteRowBankAccount,
      setOpenEditRowBankAccount,
      setEditRowBankAccount,
      setIdCodeDeleteBankAccount,
      setUserAccountToSelect,
      setDocumentTypesToSelect,
      setLanguagesToSelect,
      setOpenEditNewUserAccount,
      setOpenDeleteRowUserAccount,
      setOpenEditRowUserAccount,
      setOpenSelectUserAccount,
      setIdCodeDeleteUserAccount,
      setExtInternationalToSelect,
      showModalEmployeeSettings, 
      DELETE_CODE,
      navigate]
  );
  
  const handleCloseDataEditEmployee = () => {
    setFormEmployee(initialStateEmployee);
    setFormEmployee(formEmployee => ({
      ...formEmployee,
      phones:[],
      emails:[],
      addresses:[],
      bankaccounts:[],
      contracts : []
    }));
    setIsLoadingData(false);
    setIsLoadingErrorData(false);
    setErrorsEmployee({});
    setFoundNumberOfDocument(false);
    setFoundNumberOfSecuritySocial(false);
    setNewPhone("");
    setNewEmail("");
    setValueTabs("first");
    setOpenEditNewAddress(false);
    setOpenDeleteRowAddress(false);
    setOpenEditRowAddress(false);
    setEditRowAddress(initialAddresses);
    setIdCodeDeleteAddress(DELETE_CODE);
    setOpenEditNewBankAccount(false);
    setOpenDeleteRowBankAccount(false);
    setOpenEditRowBankAccount(false);
    setEditRowBankAccount(initialBankAccounts);
    setIdCodeDeleteBankAccount(DELETE_CODE); 
    setUserAccountToSelect(initialUserAccountToSelect);
    setDocumentTypesToSelect(initialDocumentTypesToSelect);
    setLanguagesToSelect(initialTypesToSelect);
    setOpenEditNewUserAccount(false);
    setOpenDeleteRowUserAccount(false);
    setOpenEditRowUserAccount(false);
    setOpenSelectUserAccount(false);
    setIdCodeDeleteUserAccount(DELETE_CODE);
    setExtInternationalToSelect(initialExtInternationalToSelect);
    navigate("/employee")
    return () => {};
	};

  const handleChangeEnabledEmployee = (e) => {
    setFormEmployee(formEmployee => ({
        ...formEmployee,
        enabled: !formEmployee.enabled
    }));
  };

  const handleOpenNewUserAccount = () => {
    setOpenEditNewUserAccount(true);
    setOpenEditRowUserAccount(true);
  };
 
  const handleOpenSelectUserAccount = () => {
    setOpenSelectUserAccount(true);
  };

  const handleOpenEditUserAccount = () => {
    setOpenEditNewUserAccount(false);
    setOpenEditRowUserAccount(true);
  };

  const handleChangeDataEditEmployee = (e) => {
    const { name, value } = e.target;
    setFormEmployee(formEmployee => ({
      ...formEmployee,
      [name]: value,
    }));
    setFormEmployee(formEmployee => ({
      ...formEmployee,
      fullname: formEmployee.lastname1.toUpperCase()+' '+formEmployee.lastname2.toUpperCase()+", "+formEmployee.firstname.toUpperCase()
    }));  
  };

  const handleBlurDataEditEmployee = (e) => {
    handleChangeDataEditEmployee(e);
    setErrorsEmployee(validateFormEmployee());
  };
 
  const handleChangeSelectDocumentType = (event) => {
    setFormEmployee(formEmployee => ({
        ...formEmployee,
        documnettype_id:event.target.value
    }));
  };

  const handleBlurSelectDocumentType = (e) => {
    handleChangeSelectDocumentType(e);
  };
 
  const handleChangeSelectBirthday = (event) => {
    setFormEmployee(formEmployee => ({
      ...formEmployee,
      birthdate: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectBirthday = (e) => {
    handleChangeSelectBirthday(e);
    setErrorsEmployee(validateFormEmployee());
  };

  const handleChangeSelectLanguage = (event) => {
    setFormEmployee(formEmployee => ({
        ...formEmployee,
        language_id:event.target.value
    }));
  };

  const handleBlurSelectLanguage = (e) => {
    handleChangeSelectLanguage(e);
    setErrorsEmployee(validateFormEmployee());
  };
  
  const handleChangeExtInternational = (event) => {
    setFormEmployee(formEmployee => ({
      ...formEmployee,
      extphone_id:event.target.value
    }));
  }; 
  
  const handleBlurSelectExtInternational = (e) => {
    handleChangeExtInternational(e);
  };

  const handleChangeDefaultPhone = (item) => { 
    var i =  formEmployee.phones.indexOf( item );
    formEmployee.phones.map((item,index)=>{
      if ( index!==i ) {
        return item.defaultphone=false
      }else{
        return item.defaultphone=true
      }  
    })
    setFormEmployee(formEmployee => ({
      ...formEmployee,
    }))
    setNewPhone("");
  };

  const handleDeletePhone = (item) => {
    var i =  formEmployee.phones.indexOf( item );
    if ( i !== -1 ) {
      formEmployee.phones.splice( i, 1 );
    }
    setFormEmployee(formEmployee => ({
      ...formEmployee,
    }))
    if (item.defaultphone){
      if (formEmployee.phones.length>0){
        formEmployee.phones[0].defaultphone=true;
        setFormEmployee(formEmployee => ({
          ...formEmployee,
        }))
      }
    }
    setNewPhone("");
  };
    
  const handleAddPhone = (event) => {  
    
    setErrorsEmployee(validateFormEmployee());
    if (errorsEmployee.newPhone===undefined && newPhone!=="") {
      let defaultFound=false;
      if (formEmployee.phones.length<=0){
        defaultFound=true;
      }
      let phoneToAdd={
        "id":"",
        "phone":reformatPhone(newPhone),
        "defaultphone":defaultFound
      }
      formEmployee.phones.push(phoneToAdd);
      setNewPhone("");
    }
  };

  function reformatPhone(phone){

    phone=phone.replace(/[^0-9]+/g, "").replace(" ", "").toUpperCase();
    if (phone.length<9){
        for (let i = phone.length; i < 9; i++) {
            phone="0"+phone;
        }
    }
    let invertString = "";
    for (let indice = phone.length - 1; indice >= 0; indice--) {
        invertString += phone.charAt(indice);
    }
    phone = "";
    
    for (let indice = 8; indice >= 0; indice--) {
        phone += invertString.charAt(indice);
        if (indice===2 || indice===4) phone += ".";
        if (indice===6 ) phone += "-";
    }
   
    if (extInternationalToSelect!==undefined && extInternationalToSelect!==null && extInternationalToSelect.length>0){
      let extPhone=extInternationalToSelect.find((key)=>(key.id===Number(formEmployee.extphone_id))).extphone;
      return extPhone+" "+phone;
    } else {
      return phone;
    }
  }

  const handleChangeNewPhone = (event) => {
    setNewPhone(event.target.value);
  };
  
  const handleBlurNewPhone = (e) => {
    handleChangeNewPhone(e);
    setErrorsEmployee(validateFormEmployee());
  };

  const handleChangeDefaultEmail = (item) => {
    var i =  formEmployee.emails.indexOf( item );
    formEmployee.emails.map((item,index)=>{
      if ( index!==i ) {
        return item.defaultemail=false
      }else{
        return item.defaultemail=true
      }  
    })
    setFormEmployee(formEmployee => ({
       ...formEmployee,
    }))
    setNewEmail("");
   };
 
  const handleDeleteEmail = (item) => {
    var i =  formEmployee.emails.indexOf( item );
    if ( i !== -1 ) {
      formEmployee.emails.splice( i, 1 );
    }
    setFormEmployee(formEmployee => ({
      ...formEmployee,
    }))
    if (item.defaultemail){
      if (formEmployee.emails.length>0){
        formEmployee.emails[0].defaultemail=true;
        setFormEmployee(formEmployee => ({
          ...formEmployee,
        }))
      }
    }
    setNewEmail("");
  };
 
  const handleAddEmail = () => {  
    if (errorsEmployee.newEmail===undefined  && newEmail!=="") {
      let defaultFound=false;
      if (formEmployee.emails.length===0){
        defaultFound=true;
      }
      const emailToAdd={
        "id":"",
        "email":newEmail,
        "defaultemail":defaultFound
      }
      formEmployee.emails.push(emailToAdd)
      setNewEmail("");
    }  
  };

  const handleChangeNewEmail = (event) => {
    setNewEmail(event.target.value);
  };
  
  const handleBlurNewEmail = (e) => {
    handleChangeNewEmail(e);
    setErrorsEmployee(validateFormEmployee());
  };

  const handleChangeDeleteRowAddress = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteAddress(value);
  };

  const handleCancelDeleteRowAddress = () => {
    setIdCodeDeleteAddress(DELETE_CODE);
    setOpenDeleteRowAddress(false);
  };

  const handleCloseDeleteRowAddress = (e) => {
    e.preventDefault();
    if (idCodeDeleteAddress!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=formEmployee.addresses.findIndex(((item) => item.indexrowaddress === editRowAddress.indexrowaddress));
      if ( indexDelete !== -1 ) {
        formEmployee.addresses.splice( indexDelete, 1 );
      }
      setFormEmployee(formEmployee => ({
        ...formEmployee,
      }))
      if (editRowAddress.defaultaddress){
        if (formEmployee.addresses.length>0){
          formEmployee.addresses[0].defaultaddress=true;
          setFormEmployee(formEmployee => ({
            ...formEmployee,
          }))
        }
      }
      if (editRowAddress.fiscaladdress){
        if (formEmployee.addresses.length>0){
          formEmployee.addresses[0].fiscaladdress=true;
          setFormEmployee(formEmployee => ({
            ...formEmployee,
          }))
        }
      }
      setIdCodeDeleteAddress(DELETE_CODE);
      setOpenDeleteRowAddress(false);
    }
  };

  const handleChangeDeleteRowBankAccount = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteBankAccount(value);
  };

  const handleCancelDeleteRowBankAccount = () => {
    setIdCodeDeleteBankAccount(DELETE_CODE);
    setOpenDeleteRowBankAccount(false);
  };

  const handleCloseDeleteRowBankAccount = (e) => {
    e.preventDefault();
    if (idCodeDeleteBankAccount!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=formEmployee.bankaccounts.findIndex(((item) => item.indexrowbankaccount === editRowBankAccount.indexrowbankaccount));
      if ( indexDelete !== -1 ) {
        formEmployee.bankaccounts.splice( indexDelete, 1 );
      }
      setFormEmployee(formEmployee => ({
        ...formEmployee,
      }))
      if (editRowBankAccount.defaultaccountbank){
        if (formEmployee.bankaccounts.length>0){
          formEmployee.bankaccounts[0].defaultaccountbank=true;
          setFormEmployee(formEmployee => ({
            ...formEmployee,
          }))
        }
      }
      setIdCodeDeleteBankAccount(DELETE_CODE);
      setOpenDeleteRowBankAccount(false);
    }
  };
 
  const handleChangeDeleteRowUserAccount = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteUserAccount(value);
  };

  const handleCancelDeleteRowUserAccount = () => {
    setIdCodeDeleteUserAccount(DELETE_CODE);
    setOpenDeleteRowUserAccount(false);
  };

  const handleCloseDeleteRowUserAccount = (e) => {
    e.preventDefault();
    if (idCodeDeleteUserAccount!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      setFormEmployee(formEmployee => ({
        ...formEmployee,
        useraccount_id:0,
        useraccount_username:"",
        useraccount_aliasuser:"",
        useraccount_password:"",
        useraccount_enabled:true,
        useraccount_isAccountNonLocked:true,
        useraccount_color_id:0,
        useraccount_color_codehex:"",
        useraccount_store_id:0,
        useraccount_store_name:"",
        useraccount_email:"",
        useraccount_tyyesdi_id:Number(DEFAULTTYPESDI),
        useraccount_tyyesdi_namees:"",
        useraccount_tyyesdi_nameen:""
      }));
      setIdCodeDeleteUserAccount(DELETE_CODE);
      setOpenDeleteRowUserAccount(false);
    }
  };
 
  const handleChangeValueTabs = (event,newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  const validateFormEmployee = () => {
    let errorsEmployee = {}; 
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\*\\(\\)\\+\\-\\=\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;

    let regexEmail = /^([da-z_.-0-9]+)@([da-z.-]+).([a-z.]{2,6})$/;

    if (!formEmployee.firstname || formEmployee.firstname==="") {
      errorsEmployee.firstname = 'Text_FieldFirstnameRequired'; 
    } else {
      if (regexInvalidCharacters.test(formEmployee.firstname)) {
        errorsEmployee.firstname = 'Text_TextNoValid';
      }
    }    
    if (!formEmployee.lastname1 || formEmployee.lastname1==="") {
      errorsEmployee.lastname1 = 'Text_FieldLastnameRequired';
    }else {
      if (regexInvalidCharacters.test(formEmployee.lastname1)) {
        errorsEmployee.lastname1 = 'Text_TextNoValid';
      }
    }  
    if (regexInvalidCharacters.test(formEmployee.lastname2)) {
      errorsEmployee.lastname2 = 'Text_TextNoValid';
    }

    if (formEmployee.numberdocument!== undefined && formEmployee.numberdocument!==""){
      if (regexInvalidCharacters.test(formEmployee.numberdocument)) {
        errorsEmployee.numberdocument = 'Text_TextNoValid';
      }else{
        if (foundNumberOfDocument){
          errorsEmployee.numberdocument='Text_FieldnumberdocumentExist';
        }
      }
    }
    
    if (formEmployee.numbersecuritysocial!== undefined && formEmployee.numbersecuritysocial!==""){
      if (regexInvalidCharacters.test(formEmployee.numbersecuritysocial)) {
        errorsEmployee.numbersecuritysocial = 'Text_TextNoValid';
        } else {
          if (foundNumberOfSecuritySocial){
            errorsEmployee.numbersecuritysocial='Text_FieldnumbersecuritysocialExist';
          }
        }  
    }
 
    if (formEmployee.language_id===undefined || formEmployee.language_id===null ||formEmployee.language_id==="" || formEmployee.language_id===0 || formEmployee.language_id==="0") {
       errorsEmployee.language_id = 'Text_FieldLanguageRequired';
    }
    if (newPhone!=="") {
      if (formEmployee.phones.find(obj => {return obj.phone ===reformatPhone(newPhone)})){
        errorsEmployee.newPhone = 'Text_Employee_Phone';
      } else {
        if (formEmployee.phones.length>=Number(LIMITPHONEPEREMPLOYEE)){
          errorsEmployee.newPhone = 'Text_Employee_Phones_Length';
        } else {
          if (newPhone.length<=8){
            errorsEmployee.newPhone = 'Text_Employee_Phone_Length';
          } 
        }
      }
    }
    if (newEmail!=="") {
      if (formEmployee.emails.find(obj => {return obj.email ===newEmail})){
        errorsEmployee.newEmail = 'Text_Employee_Email';
      } else {
        if (formEmployee.emails.length>=Number(LIMITEMAILPEREMPLOYEE)){
          errorsEmployee.newEmail = 'Text_Employee_Emails_Length';
        } else {
          if (newEmail.length<=8){
            errorsEmployee.newEmail = 'Text_Employee_Email_Length';
          } else {
            if (newEmail!=="" && !regexEmail.test(newEmail)) {
              errorsEmployee.newEmail = 'Text_Employee_Email_Invalid';
            }  
          }
        }
      }
    }
    return errorsEmployee;
  };

  const handleSubmitDataEditEmployee = () => {
    const errors = validateFormEmployee();
    setErrorsEmployee(errors);
  
    if ((Object.keys(errors).length === 0) && formEmployee.firstname!==''  && formEmployee.lastname1!==''  
        && formEmployee.language_id!==undefined && formEmployee.language_id!==null 
        && formEmployee.language_id!=='' && formEmployee.language_id!=='0' && formEmployee.language_id!==0) {
      const fetchData = async () => {
        setIsLoadingData(true);
        let isMounted = true;
        try {
          const getData = id !== "0"
            ? await helpHttp().put(ENDPOINT, `employee/updateemployee`, token, formEmployee)
            : await helpHttp().post(ENDPOINT, `employee/saveemployee`, token, formEmployee);
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEditEmployee();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingData(false);
        } finally {
          if (isMounted && isLoadingData) {
            setIsLoadingData(false);
          }
        }
        return () => { isMounted = false;};
      };
      
      fetchData();
    } else {
      if (errors.firstname) {
        dispatch(warningErrorAction(t(errors.firstname)));
      } else if (errors.lastname1) {
        dispatch(warningErrorAction(t(errors.lastname1)));
      } else if (errors.numberdocument) {
        dispatch(warningErrorAction(t(errors.numberdocument)));
      } else if (errors.numbersecuritysocial) {
        dispatch(warningErrorAction(t(errors.numbersecuritysocial)));
      } else if (errors.language_id) {
        dispatch(warningErrorAction(t(errors.language_id)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocument = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `employee/getemployeebynumberdocument/`, token,formEmployee.numberdocument);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundNumberOfDocument(getData!==formEmployee.id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formEmployee.numberdocument!==""){
        fetchExistNumberDocument();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,formEmployee.id,formEmployee.numberdocument]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberSecuritySocial = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `employee/getemployeebynumbersecuritysocial/`, token,formEmployee.numbersecuritysocial);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundNumberOfSecuritySocial(getData!==formEmployee.id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formEmployee.numbersecuritysocial!==""){
      fetchExistNumberSecuritySocial();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,formEmployee.id,formEmployee.numbersecuritysocial]);
  
  useEffect(() => {
    let isMounted = true;

    const fetchDataUserAccount = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `employee/useraccountsemployeeorderbyusername`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setUserAccountToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataDocumentType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `documenttype/simpledocumenttypesbyname`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDocumentTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    const fetchDataLanguage = async () => {
      try {
        const getData = i18n.language==="es"
            ?await helpHttp().get(ENDPOINT, `language/languagesorderbynamees`, token)
            :await helpHttp().get(ENDPOINT, `language/languagesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setLanguagesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    const fetchDataExtInternational = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `country/simplephonesextinternational`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setExtInternationalToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    if (formEmployee.id!==null ){
      fetchDataDocumentType();
      fetchDataLanguage();
      fetchDataUserAccount();
      if (roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Add_Phone'))){
        fetchDataExtInternational();
      }
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,formEmployee.id,openSelectUserAccount,showModalEmployeeSettings]);
 
useEffect(() => {
  let isMounted = true;
  const fetchDataEmployee = async () => {
    setIsLoadingData(true);
    try {
      const getData = await helpHttp().get2(ENDPOINT, `employee/searchemployeebyid/`, token, id);
      if (getData.err || getData.message === "Load failed") {
        if (isMounted) {
          setFormEmployee(initialStateEmployee);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        }
      } else if (isMounted) {
        setFormEmployee(getData);
      }
    } catch (error) {
      dispatch(warningErrorAction(t('Text_IncorrectData')));
      setIsLoadingData(false); 
    } finally {
      if (isMounted && isLoadingData) {
        setIsLoadingData(false);
      }
    }
  };
  if (id !== "0") {
    if (formEmployee.id === "") {
      fetchDataEmployee();
    }
  } else {
    setFormEmployee(initialStateEmployee);
    setFormEmployee(formEmployee => ({
      ...formEmployee,
      documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
      language_id:Number(DEFAULTLANGUAGE),
      extphone_id:Number(DEFAULTEXTPHONE),
      phones:[],
      emails:[],
      addresses:[],
      bankaccounts:[] 
    }));
    setIsLoadingData(false);
  }
  return () => { isMounted = false };

}, [ENDPOINT,dispatch,id,t,token,formEmployee.id,isLoadingData,DEFAULTLANGUAGE,DEFAULTTYPEOFDOCUMENT,DEFAULTEXTPHONE]); 
 
 return (
      <>
        {showModalEmployeeSettings ? ( 
          <div className="container-fluid">
            <Box sx={{ bgcolor: '#ffffff',height:'1300px', width: '1600px'}}>
              <form className="createemployee" onSubmit={handleCloseDataEditEmployee}>  
                
                <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className={formEmployee.enabled?"card border-info shadow":"card border-dark shadow"}>
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div id="myDIV">
                              <h1 className={formEmployee.enabled?"h2NewStyle mb-2":"h2NewStyleDark"}>
                                  {isLoadingErrorData?
                                  <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                  (isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className={formEmployee.enabled?"fas fa-regular fa-address-card fa text-warning":"fas fa-regular fa-address-card fa text-dark"}></i>)} 
                                  &nbsp;&nbsp;{formEmployee.fullname}
                              </h1>
                              <div>
                              {isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <>
                                    <button type="button" className="buttonCreate" onClick={handleSubmitDataEditEmployee}>
                                      {id==="0"?t('Button_Create'):t('Button_Update')}
                                    </button><> </>
                                    <button type="button" className="buttonBack" onClick={handleCloseDataEditEmployee}>{t('Button_Cancel')}</button>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isLoadingData ?
                <>
                  <div className="row">    
                    <div className="col-xl-2 mb-2"> 
                      <div  className={formEmployee.enabled?"card border-info shadow":"card border-dark shadow"}>
                        <div className="card-body"> 
                          <div className="row align-items-center">
                            <div className="col mr-2">
                              <div>
                                <Typography style={{color:formEmployee.enabled?"#4e73df":"#858796", fontSize: 16, fontWeight: 'bold',textDecorationLine:formEmployee.enabled?'none':'line-through'}}>{t('Text_Id')}</Typography>
                                <Typography  style={{color:formEmployee.enabled?"#616161":"#858796", fontSize: 26, fontWeight: 'bold', textDecorationLine:formEmployee.enabled?'none':'line-through'}}> 
                                  {id==="0"?t('Text_New'): formEmployee.id}
                                </Typography>
                              </div>    
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Hability'))?
                    <div className="col-xl-2 mb-2">
                        <div  className={formEmployee.enabled?"card border-info shadow":"card border-info shadow"}>
                            <div className="card-body"> 
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        {formEmployee.enabled?
                                            <div>
                                            <Typography style={{color:"#4e73df", fontSize: 16, fontWeight: 'bold'}}>{t('Text_enabled_Upper')}</Typography>
                                            <button type="button" className="buttonLopdAcepted" onClick={handleChangeEnabledEmployee}>{t('Button_DISABLED')}</button>
                                            </div>:
                                            <div>
                                            <Typography style={{color:"#4e73df", fontSize: 16, fontWeight: 'bold'}}>{t('Text_disabled')}</Typography>
                                            <button type="button" className="buttonLopdSend"  onClick={handleChangeEnabledEmployee}>{t('Button_ENABLED')}</button>
                                            </div>
                                        }   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>:<></>}

                    {(roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Add_UserAccount')) || roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Modify_UserAccount')) || roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Enabled_UserAccount')) || roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Delete_UserAccount')))?
                      <>
                        <div className="col-xl-8 mb-2"> 
                          <div  className={formEmployee.enabled?"card border-info shadow":"card border-dark shadow"}>
                            <div className="card-body"> 
                              <div className="row align-items-center">
                                <div className="col mr-2">
                                  <div>
                                    <Typography style={{color:formEmployee.enabled?"#4e73df":"#858796", fontSize: 16, fontWeight: 'bold',textDecorationLine:formEmployee.enabled?'none':'line-through'}}>{t('Text_userAccount')}</Typography>
                                    <Typography  style={{color:formEmployee.enabled?"#616161":"#858796", fontSize: 16, textDecorationLine:formEmployee.enabled?'none':'line-through'}}> 
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {(formEmployee.useraccount_username===undefined || formEmployee.useraccount_username==="" )?t('Text_New_UserAccount'):
                                      <>
                                        <IconButton  size="small"  style={{color:formEmployee.useraccount_color_codehex, fontSize: 16}}>
                                          <CircleIcon />
                                        </IconButton>
                                        &nbsp;&nbsp;
                                        {formEmployee.useraccount_username}</>}
                                    </Typography>
                                  </div>    
                                </div>
                                <div className="col-8 text-right">
                                  {(formEmployee.useraccount_username===undefined || formEmployee.useraccount_username==="")?
                                    <div>
                                      {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Add_UserAccount')) && <button type="button" className={formEmployee.enabled?"buttonCreateUserAccount":"buttonDisabledUserAccount"} onClick={formEmployee.enabled?()=>handleOpenNewUserAccount():() => {}}>{t('Button_userAccount_Create')}</button>}
                                      &nbsp;&nbsp;
                                      {(roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Enabled_UserAccount'))) && <button type="button" className={formEmployee.enabled?"buttonCreateUserAccount":"buttonDisabledUserAccount"} onClick={formEmployee.enabled?()=>handleOpenSelectUserAccount():() => {}}>{t('Button_userAccount_Select')}</button>}
                                    </div>:
                                      <div>
                                        {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Modify_UserAccount')) && <button type="button" className={formEmployee.enabled?"buttonCreateUserAccount":"buttonDisabledUserAccount"} onClick={formEmployee.enabled?()=>handleOpenEditUserAccount():() => {}}>{t('Button_userAccount_Modify')}</button>}
                                        &nbsp;&nbsp;
                                        {(roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Enabled_UserAccount')) && userAccountToSelect.length>0) &&<button type="button" className={formEmployee.enabled?"buttonCreateUserAccount":"buttonDisabledUserAccount"} onClick={formEmployee.enabled?()=>handleOpenSelectUserAccount():() => {}}>{t('Button_userAccount_Select')}</button>}
                                        &nbsp;&nbsp;
                                        {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Delete_UserAccount')) && <button type="button" className={formEmployee.enabled?"buttonDeleteUserAccount":"buttonDisabledUserAccount"} onClick={formEmployee.enabled?()=>setOpenDeleteRowUserAccount(!openDeleteRowUserAccount):() => {}}>{t('Button_userAccount_Delete')}</button>}
                                      </div>
                                    }  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </> :
                      <>
                        <div className="col-xl-4 mb-2"> 
                          <div  className={formEmployee.enabled?"card border-info shadow":"card border-dark shadow"}>
                            <div className="card-body"> 
                              <div className="row align-items-center">
                                <div className="col mr-2">
                                  <div>
                                    <Typography style={{color:formEmployee.enabled?"#4e73df":"#858796", fontSize: 16, fontWeight: 'bold',textDecorationLine:formEmployee.enabled?'none':'line-through'}}>{t('Text_userAccount')}</Typography>
                                    <Typography  style={{color:formEmployee.enabled?"#616161":"#858796", fontSize: 26, textDecorationLine:formEmployee.enabled?'none':'line-through'}}> 
                                    &nbsp;&nbsp;&nbsp;&nbsp;{(formEmployee.useraccount_username===undefined || formEmployee.useraccount_username==="" )?t('Text_New_UserAccount'): formEmployee.useraccount_username}
                                    </Typography>
                                  </div>    
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                  <br/>

                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                    <div  className={formEmployee.enabled?"card border-info shadow":"card border-dark shadow"}>
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <h6 className={formEmployee.enabled?"h3NewStyle mb-2":"h3NewStyleDark"}>{t('Text_Personal_Data')}</h6>
                                {/* Divider */}
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_firstname')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="firstname" 
                                  name="firstname"
                                  value={formEmployee.firstname}
                                  inputProps={{ style: {width: 300, textDecorationLine:formEmployee.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsEmployee.firstname!=null?t(errorsEmployee.firstname):t('PlaceHolder_Employee_firstname')}
                                  placeholder={t('PlaceHolder_Employee_firstname')}
                                  variant="standard"
                                  onBlur={formEmployee.enabled?handleBlurDataEditEmployee:() => {}}
                                  onChange={formEmployee.enabled?handleChangeDataEditEmployee:() => {}}/>
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_lastname1')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="lastname1" 
                                  name="lastname1"
                                  value={formEmployee.lastname1}
                                  inputProps={{ style: {width: 400, textDecorationLine:formEmployee.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsEmployee.lastname1!=null?t(errorsEmployee.lastname1):t('PlaceHolder_Employee_lastname1')}
                                  placeholder={t('PlaceHolder_Employee_lastname1')}
                                  variant="standard"
                                  onBlur={formEmployee.enabled?handleBlurDataEditEmployee:() => {}}
                                  onChange={formEmployee.enabled?handleChangeDataEditEmployee:() => {}}/>
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_lastname2')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="lastname2" 
                                  name="lastname2"
                                  value={ formEmployee.lastname2!== null ? formEmployee.lastname2:""}
                                  inputProps={{ style: {width: 400, textDecorationLine:formEmployee.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsEmployee.lastname2!=null?t(errorsEmployee.lastname2):t('PlaceHolder_Employee_lastname2')}
                                  placeholder={t('PlaceHolder_Employee_lastname2')}
                                  variant="standard"
                                  onBlur={formEmployee.enabled?handleBlurDataEditEmployee:() => {}}
                                  onChange={formEmployee.enabled?handleChangeDataEditEmployee:() => {}}/>
                              </div>
                            </div>

                            <div className="row p-2"> 
                              <div className="form-group">
                                <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_documenttype')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="documnettype_id"
                                  name="documnettype_id"
                                  value={ (formEmployee.documnettype_id === undefined || formEmployee.documnettype_id ===null || formEmployee.documnettype_id ===""
                                        || formEmployee.documnettype_id ==="0" || formEmployee.documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formEmployee.documnettype_id}
                                  inputProps={{ style: {width: 150, textDecorationLine:formEmployee.enabled?'none':'line-through' }, maxLength: 100, }}
                                  variant="outlined"
                                  onBlur={formEmployee.enabled?handleBlurSelectDocumentType:() => {}}
                                  onChange={formEmployee.enabled?handleChangeSelectDocumentType:() => {}}
                                  select
                                  SelectProps={{
                                      native: true,
                                      value: (formEmployee.documnettype_id === undefined || formEmployee.documnettype_id ===null || formEmployee.documnettype_id ===""
                                          || formEmployee.documnettype_id ==="0" || formEmployee.documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formEmployee.documnettype_id
                                      }}>
                                      <option key={0} value=""/>
                                      {documentTypesToSelect.map((option) => (
                                      <option key={option.id} value={option.id}>
                                          {option.name}
                                      </option>
                                      ))}
                                </TextField>  
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_document')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="numberdocument" 
                                  name="numberdocument"
                                  value={ formEmployee.numberdocument!== null ? formEmployee.numberdocument:""}
                                  inputProps={{ style: {width: 385, textDecorationLine:formEmployee.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsEmployee.numberdocument!=null?t(errorsEmployee.numberdocument):t('PlaceHolder_DocumenType')}
                                  placeholder={t('PlaceHolder_DocumenType')}
                                  variant="standard"
                                  onBlur={formEmployee.enabled?handleBlurDataEditEmployee:() => {}}
                                  onChange={formEmployee.enabled?handleChangeDataEditEmployee:() => {}}>
                                </TextField>
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_securitySocial')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="numbersecuritysocial" 
                                  name="numbersecuritysocial"
                                  value={ formEmployee.numbersecuritysocial!== null ? formEmployee.numbersecuritysocial:""}
                                  inputProps={{ style: {width: 385, textDecorationLine:formEmployee.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsEmployee.numbersecuritysocial!=null?t(errorsEmployee.numbersecuritysocial):t('PlaceHolder_SecuritySocial')}
                                  placeholder={t('PlaceHolder_SecuritySocial')}
                                  variant="standard"
                                  onBlur={formEmployee.enabled?handleBlurDataEditEmployee:() => {}}
                                  onChange={formEmployee.enabled?handleChangeDataEditEmployee:() => {}}>
                                </TextField>

                              </div>
                            </div>
                            
                            <div className="row p-2"> 
                              <div className="form-group">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_Birthdate')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <DesktopDatePicker
                                    id="birthdate" 
                                    name="birthdate"
                                    value={ formEmployee.birthdate!== null ? new Date(formEmployee.birthdate): null}
                                    label={errorsEmployee.birthdate!=null?t(errorsEmployee.birthdate):t('PlaceHolder_Birthdate')}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"mm/DD/yyyy"}
                                    onBlur={formEmployee.enabled?handleBlurSelectBirthday:() => {}}
                                    onChange={formEmployee.enabled?handleChangeSelectBirthday:() => {}}
                                    slotProps={{
                                      textField: {
                                        placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                        sx: {
                                          color: '#1565c0',
                                          maxLength: '100',
                                          textDecorationLine:formEmployee.enabled?'none':'line-through'
                                         
                                        }
                                      },
                                    }}
                                  />
                               
                                </LocalizationProvider>
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_language')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="language_id"
                                  name="language_id"
                                  value={ (formEmployee.language_id === undefined || formEmployee.language_id ===null || formEmployee.language_id ===""
                                        || formEmployee.language_id ==="0" || formEmployee.language_id ===0)?Number(DEFAULTLANGUAGE):formEmployee.language_id}
                                inputProps={{ style: {width: 300, textDecorationLine:formEmployee.enabled?'none':'line-through' }, maxLength: 100, }}
                                  variant="outlined"
                                  onBlur={formEmployee.enabled?handleBlurSelectLanguage:() => {}}
                                  onChange={formEmployee.enabled?handleChangeSelectLanguage:() => {}}
                                  select
                                  SelectProps={{
                                    native: true,
                                    value: (formEmployee.language_id === undefined || formEmployee.language_id ===null || formEmployee.language_id ===""
                                        || formEmployee.language_id ==="0" || formEmployee.language_id ===0)?Number(DEFAULTLANGUAGE): formEmployee.language_id
                                  }}>
                                  {languagesToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                                </TextField> 
                               
                              </div>
                            </div>
                          
                            <div className="row p-2"> 
                              <div className="form-group">  
                              <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_Comments')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp;   
                              <TextField
                                id="comments" 
                                name="comments"
                                value={ formEmployee.comments!== null ? formEmployee.comments:""}
                                inputProps={{ style: {width: 1400, textDecorationLine:formEmployee.enabled?'none':'line-through'}, maxLength: 150 }}
                                label={errorsEmployee.comments!=null?t(errorsEmployee.comments):t('PlaceHolder_Comments')}
                                placeholder={t('PlaceHolder_Comments')}
                                variant="standard"
                                onBlur={formEmployee.enabled?handleBlurDataEditEmployee:() => {}}
                                onChange={formEmployee.enabled?handleChangeDataEditEmployee:() => {}}>
                              </TextField>
                              {i18n.language==="es"?<>&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                              </div>
                            </div>


                            {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Add_Phone'))&&
                              <div className="row p-2"> 
                                <div className="form-group">
                                <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_Ext_Default')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="extphone_id"
                                  name="extphone_id"
                                  value={ (formEmployee.extphone_id === undefined || formEmployee.extphone_id ===null || formEmployee.extphone_id ===""
                                        || formEmployee.extphone_id ==="0" || formEmployee.extphone_id ===0)?Number(DEFAULTEXTPHONE):formEmployee.extphone_id}
                                  inputProps={{ style: {width: 100, textDecorationLine:formEmployee.enabled?'none':'line-through' }, maxLength: 10, }}
                                  variant="outlined"
                                  onBlur={formEmployee.enabled?handleBlurSelectExtInternational:() => {}}
                                  onChange={formEmployee.enabled?handleChangeExtInternational:() => {}}
                                  select
                                  SelectProps={{
                                      native: true,
                                      value: (formEmployee.extphone_id === undefined || formEmployee.extphone_id ===null || formEmployee.extphone_id ===""
                                            || formEmployee.extphone_id ==="0" || formEmployee.extphone_id ===0)?Number(DEFAULTEXTPHONE): formEmployee.extphone_id
                                      }}>
                                      {extInternationalToSelect.map((option) => (
                                      <option key={option.id} value={option.id}>
                                          {option.extphone}
                                      </option>
                                      ))}
                                </TextField>
                              </div>
                            </div>
                            }
                            <Box sx={{ bgcolor: '#ffffff',height:(formEmployee.phones!=null && formEmployee.phones.length>0)?((Math.floor((formEmployee.phones.length-1)/4)+1)===0?60:(Math.floor((formEmployee.phones.length-1)/4)+1)*60):60, width: '1550px'}}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_phones')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Add_Phone'))?
                                  <TextField
                                    id="newPhone" 
                                    name="newPhone"
                                    value={newPhone}
                                    inputProps={{style: {width: 250, textDecorationLine:formEmployee.enabled?'none':'line-through'}, maxLength: 20}}
                                    InputProps={{ 
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PhoneIcon/>
                                      </InputAdornment>
                                      ),
                                    }}
                                    label={errorsEmployee.newPhone!=null?t(errorsEmployee.newPhone):t('PlaceHolder_NewPhone')}
                                    placeholder={t('PlaceHolder_NewPhone')}
                                    variant="standard"
                                    onBlur={formEmployee.enabled?handleBlurNewPhone:() => {}}
                                    onChange={formEmployee.enabled?handleChangeNewPhone:() => {}}>
                                  </TextField>:<></>}

                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Add_Phone'))?
                                  <IconButton
                                    size="small"  
                                    style={{color:formEmployee.enabled?"#e74a3b":"#858796", fontSize: 14}}
                                    onClick={formEmployee.enabled?handleAddPhone:() => {}}>
                                    <Icon
                                      baseClassName="fas"
                                      className="fa-plus-circle"
                                      sx={{ color:formEmployee.enabled?"#4e73df":"#858796" }}
                                    />
                                  </IconButton>:<></>}
                                </Grid>
                              
                                {formEmployee.phones.length>0 && formEmployee.phones.map((element,index) => (  
                                  <Fragment key={index} >
                                    <Grid item xs={2} key={index}>
                                      <Chip
                                        value={index}
                                        icon={<PhoneIcon style={{color:formEmployee.enabled?(element.defaultphone?"white":"#4e73df"):"white"}}/>}
                                        label={element.phone}
                                        clickable
                                        style={{color:formEmployee.enabled?(element.defaultphone?"white":"#4e73df"):"white", width:"250px", justifyContent: "space-between", textDecorationLine:formEmployee.enabled?'none':'line-through', fontSize: 14, backgroundColor:formEmployee.enabled?(element.defaultphone?"#4e73df":"white"):"#858796",borderColor:formEmployee.enabled?"#4e73df":"#858796", borderRadius: "10px", borderWidth:"1px",borderStyle:"solid"}}
                                        onClick={roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Default_Phone')) && formEmployee.enabled?()=>handleChangeDefaultPhone(element):() => {}}
                                        onDelete={formEmployee.enabled?()=>handleDeletePhone(element):() => {}}
                                        deleteIcon={roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Delete_Phone'))?<DeleteIcon style={{color:formEmployee.enabled?(element.defaultphone?"#e74a3b":"white"):"#858796",backgroundColor:formEmployee.enabled?(element.defaultphone?"white":"#e74a3b"):"white", borderRadius: "10px"}}/>:<></>}
                                      />
                                    </Grid>
                                    {(index+1)%4===0 && <Grid item xs={4}/>}
                                  </Fragment>
                                ))}
                              </Grid>  
                            </Box>
                            <Box sx={{ bgcolor: '#ffffff',height:(formEmployee.emails!=null && formEmployee.emails.length>0)?((Math.floor((formEmployee.emails.length-1)/4)+1)===0?60:(Math.floor((formEmployee.emails.length-1)/4)+1)*60):60, width: '1550px'}}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <label className={formEmployee.enabled?"text-primary":"text-secondary"}>{t('Text_emails')} :</label>
                                  {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                  {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Add_Email'))?
                                  <TextField
                                    id="newEmail" 
                                    name="newEmail"
                                    type="email"
                                    value={newEmail}
                                    inputProps={{style: {width: 250, textDecorationLine:formEmployee.enabled?'none':'line-through'}, maxLength: 75}}
                                    InputProps={{ 
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <EmailIcon/>
                                      </InputAdornment>
                                      ),
                                    }}
                                    label={errorsEmployee.newEmail!=null?t(errorsEmployee.newEmail):t('PlaceHolder_NewEmail')}
                                    placeholder={t('PlaceHolder_NewEmail')}
                                    variant="standard"
                                    onBlur={formEmployee.enabled?handleBlurNewEmail:() => {}}
                                    onChange={formEmployee.enabled?handleChangeNewEmail:() => {}}>
                                  </TextField>:<></>}
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Add_Email'))?
                                  <IconButton
                                    size="small"  
                                    style={{color:formEmployee.enabled?"#e74a3b":"#858796", fontSize: 14}}
                                    onClick={formEmployee.enabled?handleAddEmail:() => {}}>
                                    <Icon
                                      baseClassName="fas"
                                      className="fa-plus-circle"
                                      sx={{ color:formEmployee.enabled?"#4e73df":"#858796" }}
                                    />
                                  </IconButton>:<></>}      
                                </Grid>
                                {formEmployee.emails.length>0 && formEmployee.emails.map((element,index) => { return (   
                                  <Fragment key={index} >
                                    <Grid item xs={2}>
                                      <Chip
                                        value={index}
                                        icon={<EmailIcon style={{color:formEmployee.enabled?(element.defaultemail?"white":"#4e73df"):"white"}}/>}
                                        label={element.email}
                                        clickable
                                        style={{color:formEmployee.enabled?(element.defaultemail?"white":"#4e73df"):"white",width:"250px", justifyContent: "space-between" ,textDecorationLine:formEmployee.enabled?'none':'line-through', fontSize: 14, backgroundColor:formEmployee.enabled?(element.defaultemail?"#4e73df":"white"):"#858796",borderColor:formEmployee.enabled?"#4e73df":"#858796", borderRadius: "10px", borderWidth:"1px",borderStyle:"solid"}}
                                        onClick={roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Default_Email')) && formEmployee.enabled?()=>handleChangeDefaultEmail(element):() => {}}
                                        onDelete={formEmployee.enabled?()=>handleDeleteEmail(element):() => {}}
                                        deleteIcon={roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_Delete_Email'))?<DeleteIcon style={{color:formEmployee.enabled?(element.defaultemail?"#e74a3b":"white"):"#858796",backgroundColor:formEmployee.enabled?(element.defaultemail?"white":"#e74a3b"):"white", borderRadius: "10px"}}/>:<></>}
                                      />
                                    </Grid>  
                                    {(index+1)%4===0 && <Grid item xs={4}/>}
                                  </Fragment>
                                )})}
                              </Grid>
                            </Box>

                            {roles[0].listmenus.find((key)=>(key.name==='Handle_Employee_View_BankAccount')) &&
                              <div className="row p-2"> 
                                <div className="form-group"> 
                                  <Box sx={{ width: '100%' }}>
                                    <Tabs
                                      value={valueTabs}
                                      onChange={handleChangeValueTabs}
                                      style={{color:formEmployee.enabled?"#4e73df":"#858796",width:"1530px"}}
                                      indicatorColor="primary"
                                      variant="fullWidth"
                                      aria-label="secondary tabs example">
                                      <Tab value="first" label={t('Text_Address_Habitual')} />
                                      <Tab value="second" label={t('Text_Bank_Data')} />             
                                      <Tab value="third" label={t('Text_Contract_Data')} />     
                                    </Tabs>  
                                  </Box>
                                </div>
                              </div>
                            }

                            {valueTabs==="first"?
                              <div className="row p-2"> 
                                <div className="form-group">   
                                  <TableSortingAddress 
                                    dataAddress={formEmployee.addresses} 
                                    setFormEmployee={setFormEmployee}
                                    checkedRow={formEmployee.enabled}
                                    numTotRegAddress={formEmployee.addresses.length}
                                    setOpenDeleteRowAddress={setOpenDeleteRowAddress}
                                    setOpenEditNewAddress={setOpenEditNewAddress}
                                    setOpenEditRowAddress={setOpenEditRowAddress}
                                    setEditRowAddress={setEditRowAddress}
                                    roles={roles}
                                    LIMITPAGESIZE={LIMITPAGESIZE}
                                    LIMITADDRESSPEREMPLOYEE={LIMITADDRESSPEREMPLOYEE}
                                  />
                                  <EmployeeCreateOrEditAddress 
                                    openEditRowAddress={openEditRowAddress}
                                    setOpenEditRowAddress={setOpenEditRowAddress} 
                                    openEditNewAddress={openEditNewAddress} 
                                    setOpenEditNewAddress={setOpenEditNewAddress}
                                    formEmployee={formEmployee}
                                    setFormEmployee={setFormEmployee}
                                    editRowAddress={editRowAddress}
                                    setEditRowAddress={setEditRowAddress}
                                  />
                                </div>
                              </div>:valueTabs==="second"?
                              <div className="row p-2"> 
                                <div className="form-group">   
                                  <TableSortingBankAccount 
                                    dataBankAccount={formEmployee.bankaccounts} 
                                    setFormEmployee={setFormEmployee}
                                    checkedRow={formEmployee.enabled}
                                    numTotRegBankAccount={formEmployee.bankaccounts.length}
                                    setOpenDeleteRowBankAccount={setOpenDeleteRowBankAccount}
                                    setOpenEditNewBankAccount={setOpenEditNewBankAccount}
                                    setOpenEditRowBankAccount={setOpenEditRowBankAccount}
                                    setEditRowBankAccount={setEditRowBankAccount}
                                    roles={roles}
                                    LIMITPAGESIZE={LIMITPAGESIZE}
                                    LIMITBANKACCOUNTPEREMPLOYEE={LIMITBANKACCOUNTPEREMPLOYEE}
                                  />
                                  <EmployeeCreateOrEditBankAccount 
                                    openEditRowBankAccount={openEditRowBankAccount}
                                    setOpenEditRowBankAccount={setOpenEditRowBankAccount} 
                                    openEditNewBankAccount={openEditNewBankAccount} 
                                    setOpenEditNewBankAccount={setOpenEditNewBankAccount}
                                    formEmployee={formEmployee}
                                    setFormEmployee={setFormEmployee}
                                    editRowBankAccount={editRowBankAccount}
                                    setEditRowBankAccount={setEditRowBankAccount}
                                  />
                                </div>
                              </div>
                            :<></>
                               
                            }
                          </div>
                        </div>   
                      </div>
                    </div>
                </div>
              </>:
                <Loading isLoadingData={isLoadingData}/>
              }
            </form>
          </Box>
          <br/>
          <div>
            <EmployeeCreateOrEditUserAccount 
              openEditRowUserAccount={openEditRowUserAccount}
              setOpenEditRowUserAccount={setOpenEditRowUserAccount} 
              openEditNewUserAccount={openEditNewUserAccount} 
              setOpenEditNewUserAccount={setOpenEditNewUserAccount}
              formEmployee={formEmployee}
              setFormEmployee={setFormEmployee}
            />
          </div>

          <div>
            <EmployeeSelectUserAccount 
              openSelectUserAccount={openSelectUserAccount}
              setOpenSelectUserAccount={setOpenSelectUserAccount} 
              formEmployee={formEmployee}
              setFormEmployee={setFormEmployee}
              userAccountToSelect={userAccountToSelect}
            />
          </div>

         
          <div>
            <Dialog open={openDeleteRowAddress} onClose={handleCancelDeleteRowAddress}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowAddress.indexrowaddress} - {i18n.language==="es"?editRowAddress.roadtype_namees:editRowAddress.roadtype_nameen} {editRowAddress.street} {editRowAddress.number} {editRowAddress.floor}  {editRowAddress.door}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeleteAddress}
                  onChange={handleChangeDeleteRowAddress}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowAddress}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowAddress}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Dialog open={openDeleteRowBankAccount} onClose={handleCancelDeleteRowBankAccount}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowBankAccount.indexrowbankaccount} - {editRowBankAccount.codbic} {editRowBankAccount.namebank} {editRowBankAccount.iban}  {editRowBankAccount.entity} {editRowBankAccount.office} {editRowBankAccount.cd} {editRowBankAccount.accountbank}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeleteBankAccount}
                  onChange={handleChangeDeleteRowBankAccount}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowBankAccount}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowBankAccount}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>
          
          <div>
            <Dialog open={openDeleteRowUserAccount} onClose={handleCancelDeleteRowUserAccount}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {formEmployee.useraccount_id} - {formEmployee.useraccount_username} </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1_UserAccount')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeleteUserAccount}
                  onChange={handleChangeDeleteRowUserAccount}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowUserAccount}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowUserAccount}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      //* End Page Content *//
    ) :  null }
  </>
  )    
}

EmployeeEditOrCreate.propTypes = {
  id: PropTypes.string.isRequired,
  showModalEmployeeSettings: PropTypes.bool.isRequired 
};

export default EmployeeEditOrCreate;