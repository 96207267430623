import { combineReducers } from '@reduxjs/toolkit';
import { loginReducer } from "./LoginReducer";
import { errorReducer } from "./ErrorReducer";
import { variablesReducer } from "./VariablesReducer";
import { formatDIReducer } from "./FormatDIReducer";

const rootReducer = combineReducers({
  loginUser: loginReducer,
  loadError: errorReducer,
  variablesUser: variablesReducer,
  formatDIUser:formatDIReducer
});

export default rootReducer;
