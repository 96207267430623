import React, { useEffect, useCallback, useState } from 'react';
import Chip from '@mui/material/Chip';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { PictureAsPdf } from '@material-ui/icons';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import 'css/generalSettings.css';
import { successErrorAction, warningErrorAction } from 'actions/ErrorActions';
import { helpHttp } from 'service/HelpHttp';
import { Loading } from "components/utils/Loading";
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    border: '2px dashed #3f51b5',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '8px',
    textAlign: 'center',
    height: 100,
    fill: '#067330',
    color: '#067330',
    fontSize: '20px',
  },
  icon: {
    backgroundColor: 'white',
    fill: '#067330',
    color: '#067330',
  },
  text: {
    color: '#067330',
    fontSize: '20px',
    padding: '10px',
  },
  previewIcon: {
    fill: '#067330',
    color: '#067330',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));
 
const ConvertPDFsSettings = ({ showConvertPDFsSettings, setShowModalConvertPDFsSettings, setIndexShowSettings, setShowPrincipalMenu }) => {
  const { t } = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token);
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore_id = useSelector((state) => state.loginUser.defaultStore.id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT);
  const FOLDER_ROOT = useSelector((state) => state.variablesUser.FOLDER_ROOT);
  const FOLDER_RELATIVE = useSelector((state) => state.variablesUser.FOLDER_RELATIVE);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const classes = useStyles();

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showConvertPDFsSettings) {
        setFiles([]);
        setIsLoadingData(false);
        setShowModalConvertPDFsSettings(false);
        setIndexShowSettings(0);
        setShowPrincipalMenu(true);
      }
    },
    [
      setFiles,
      showConvertPDFsSettings,
      setIsLoadingData,
      setShowModalConvertPDFsSettings,
      setIndexShowSettings,
      setShowPrincipalMenu,
    ]
  );

  const closeModalConvertPDFsSettings = () => {
    setFiles([]);
    setIsLoadingData(false);
    setShowModalConvertPDFsSettings(false);
    setIndexShowSettings(0);
    setShowPrincipalMenu(true);
  };

  const handleCloseDataEditConvertPDFs = () => {
    setFiles([]);
    setIsLoadingData(false);
  };

  const handleSubmitDataEdit = () => {
    if (files.length > 0) {
      const updatedFiles = files.map((item, index) => ({
        id: index,
        pathdocument: FOLDER_ROOT,
        relativepathdocument: FOLDER_RELATIVE,
        filename: item.file.name,
        createdate: item.file.lastModified,
        datafile: splitFileBase64(item.data),
        type: item.file.type || splitFileBase64Type(item.data),
        sourcepath: item.file.path,
      }));

      const fetchData = async () => {
        try {
          const getData = await helpHttp().put3(ENDPOINT, 'convertpdf/convertpdftoformdi', token, defaultStore_id,useraccount_id, updatedFiles);
          if (getData.status === 'OK') {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEditConvertPDFs();
            setIsLoadingData(false);
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingData(false);
        } finally {
          setIsLoadingData(false);
        }
      };
    if (  defaultStore_id!==undefined && defaultStore_id!==null && defaultStore_id!=="" && defaultStore_id!=="0" && defaultStore_id!==0 &&
          useraccount_id!==undefined && useraccount_id!==null && useraccount_id!=="" && useraccount_id!=="0" && useraccount_id!==0
      ){
        setIsLoadingData(true);
        fetchData();
      }
    }
  };
  
  const splitFileBase64 = (fileToConvert) => {
    const arrayAuxiliar = fileToConvert.split(',');
    return arrayAuxiliar[1];
  }; 
  
  const splitFileBase64Type = (fileToConvert) => {
    const arrayAuxiliar = fileToConvert.split(',');
    return arrayAuxiliar[0].replace('data:', '').replace(';base64', '');
  };
 
  const handlePreviewIcon = (fileObject) => {
    const { type } = fileObject.file;
    switch (type) {
      case 'application/pdf':
        return (
          <Box sx={{ bgcolor: '#00000', width: 200, height: 150, justifyContent: 'center', padding: '4px' }}>
            <PictureAsPdf style={{ color: '#e74a3b', width: 50, height: 50 }} />
            <br />
            <br />
            <Chip
              value={fileObject.file.name}
              label={fileObject.file.name}
              clickable={false}
              style={{ color: '#e74a3b', justifyContent: 'center', fontSize: 8 }}
            />
          </Box>
        );
      default:
        return (
          <Box sx={{ bgcolor: '#00000', width: 200, height: 150, justifyContent: 'center', padding: '4px' }}>
            <PictureAsPdf style={{ color: '#e74a3b', width: 50, height: 50 }} />
            <br />
            <br />
            <Chip
              value={fileObject.file.name}
              label={fileObject.file.name}
              clickable={false}
              style={{ color: '#e74a3b', justifyContent: 'center', fontSize: 8 }}
            />
          </Box>
        );
    }
  };

  const handleAddFileContract = (newFiles) => {
    newFiles = newFiles.filter((file) => !files.find((f) => f.data === file.data));
    setFiles([...files, ...newFiles]);
  };

  const handleDeleteFileContract = (deleted) => {
    setFiles(files.filter((f) => f !== deleted));
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  return (
    <div>
      {showConvertPDFsSettings && (
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className="h9NewStyle mb-2">
              <i className="fas fa-solid fa-file-pdf text-danger"></i> {t('Text_CONVERTPDF_Config')}
            </h1>
            <div>
              <button type="button" className="buttonCreate" onClick={handleSubmitDataEdit}>
                {t('Button_Convert')}
              </button>
              <> </>
              <button type="button" className="buttonBack" onClick={closeModalConvertPDFsSettings}>
                {t('Button_Exit')}
              </button>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col-xl-12 mb-4">
              <div className="card border-info shadow">
                <div className="card-body">
                  <h6 className={'h7NewStyle'}>{t('Text_CONVERTPDF_Select_Max_Files')}</h6>
                  {/* Divider */}
                  <hr className="sidebar-divider d-none d-md-block" />
                  <br />
                  <div className="container">
                    <div className="row justify-content-center">
                      {!isLoadingData?
                        <div className="form-group">
                          <DropzoneAreaBase
                            initialFiles={files}
                            fileObjects={files}
                            acceptedFiles={['pdf/*', 'application/pdf']}
                            maxFileSize={20000000}
                            filesLimit={20}
                            onAdd={handleAddFileContract}
                            onDelete={handleDeleteFileContract}
                            showAlerts={false}
                            getPreviewIcon={handlePreviewIcon}
                            dropzoneText={t('Text_CONVERTPDF_Select_Files')}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            useChipsForPreview
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            previewChipProps={{ classes: { root: classes.previewChip } }}
                            previewText="Selected files"
                            classes={{
                              root: classes.root,
                              icon: classes.icon,
                              text: classes.text,
                            }}
                          />
                        </div>
                      :<Loading isLoadingData={isLoadingData}/>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Content Row */}
        </div>
      )}
    </div>
  );
};

ConvertPDFsSettings.propTypes = {
  showConvertPDFsSettings: PropTypes.bool.isRequired,
  setShowModalConvertPDFsSettings: PropTypes.func.isRequired,
  setIndexShowSettings: PropTypes.func.isRequired,
  setShowPrincipalMenu: PropTypes.func.isRequired,
};

export default ConvertPDFsSettings;