import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"  
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { warningErrorAction } from "actions/ErrorActions";
import { TextField } from '@mui/material';
import { helpHttp } from "service/HelpHttp";
import IconButton from '@mui/material/IconButton'; 
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types'; 
import ContractSearchCodeLers  from "pages/contracts/editOrNewContract/ContractSearchCodeLers";

const initialCodeLER = {
  "id": "",
  "indexrowcodeler":"",
  "codeler_id":"",
  "codeler_code": "",
  "codeler_namees": "",
  "codeler_nameen": ""
};

const ContractCreateCodeLER = ({openEditRowCodeLERS,setOpenEditRowCodeLERS,formContract,setFormContract}) => {
  const {t} = useTranslation(['listDataContracts']);
  const token = useSelector((state) => state.loginUser.token); 
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const [newCodeLER,setNewCodeLER]= useState(initialCodeLER);
  const [errorsCodeLER, setErrorsCodeLER] = useState({});
  const [showModalSearchCodeLERS, setShowModalSearchCodeLERS] =  useState(false);
  const dispatch = useDispatch();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowCodeLERS) {
        setNewCodeLER(initialCodeLER);
        setErrorsCodeLER({});
        setOpenEditRowCodeLERS(false);
        setShowModalSearchCodeLERS(false);
      }
    },[
      setNewCodeLER,
      setErrorsCodeLER,
      setShowModalSearchCodeLERS,
      openEditRowCodeLERS,
      setOpenEditRowCodeLERS]
  );

  const handleCloseNewCodeLER = () => {
    setNewCodeLER(initialCodeLER);
    setErrorsCodeLER({}); 
    setOpenEditRowCodeLERS(false);
    setShowModalSearchCodeLERS(false);
  };

  const handleChangeNewCodeLER = (e) => {
    const { name, value } = e.target;
    setNewCodeLER(newCodeLER => ({
      ...newCodeLER,
      [name]: value,
    }));   
  };
  
  const handleBlurNewCodeLER = (e) => {
    handleChangeNewCodeLER(e);
    setErrorsCodeLER(validateFormCodeLER());
  };
  
  const searchFormCodeLer = (event) => {
    setShowModalSearchCodeLERS(true);
  };

  const toggleDrawerNewCodeLER  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewCodeLER();
    }
  };

  const validateFormCodeLER = () => {
    let errorsCodeLER = {};
    let regexInvalidCharacters = /[\\%\\&\\=\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;

    if (newCodeLER.codeler_code===""){
      errorsCodeLER.codeler_code = 'Text_CODELER_code';
    }else {
      if (regexInvalidCharacters.test(newCodeLER.codeler_code)) {
        errorsCodeLER.codeler_code = 'Text_TextNoValid';
      }else{
        if (formContract.codelers.find((item) => {return (item.codeler_code.trim()=== newCodeLER.codeler_code.trim() ) })){
          errorsCodeLER.codeler_code = 'Text_CODELER_EXIST';
        } 
      }
    }
  
    if (newCodeLER.codeler_namees===""){
        errorsCodeLER.codeler_namees = 'Text_CODELER_NOT_EXIST';
    }else {
      if (regexInvalidCharacters.test(newCodeLER.codeler_namees)) {
        errorsCodeLER.codeler_namees = 'Text_TextNoValid';
      }
    } 

    if (newCodeLER.codeler_nameen===""){
      errorsCodeLER.codeler_nameen = 'Text_CODELER_NOT_EXIST';
    }else {
      if (regexInvalidCharacters.test(newCodeLER.codeler_nameen)) {
        errorsCodeLER.codeler_nameen = 'Text_TextNoValid';
      }
    }   
    return errorsCodeLER;
  };

  const handleSubmitNewCodeLER = () => {  

    setErrorsCodeLER(validateFormCodeLER());
    let errorsCheckCodeLER=validateFormCodeLER();

    if ((Object.keys(errorsCheckCodeLER).length === 0) 
          && newCodeLER.codeler_id!==''  
          && newCodeLER.codeler_code!==''  
          && newCodeLER.codeler_namees!=='' 
          && newCodeLER.codeler_nameen!=='' ) {

      let codeLERToAdd={
        "id":"",
        "indexrowcodeler":formContract.codelers.length>0?formContract.codelers[formContract.codelers.length-1].indexrowcodeler+1:1,  
        "codeler_id":newCodeLER.codeler_id,
        "codeler_code":newCodeLER.codeler_code,
        "codeler_namees":newCodeLER.codeler_namees,
        "codeler_nameen":newCodeLER.codeler_nameen 
      }
       
      formContract.codelers.push(codeLERToAdd);
      setFormContract(formContract => ({
        ...formContract,
      }))
      handleCloseNewCodeLER();
    } else {
      if (errorsCheckCodeLER.codeler_code){
        dispatch(warningErrorAction(t(errorsCheckCodeLER.codeler_code)));
      }
      if (errorsCheckCodeLER.codeler_namees){
        dispatch(warningErrorAction(t(errorsCheckCodeLER.codeler_namees)));
      }
      if (errorsCheckCodeLER.codeler_nameen){
        dispatch(warningErrorAction(t(errorsCheckCodeLER.codeler_nameen)));
      }
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistCodeLer = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`contract/getexistcodelerbycode/`,token,newCodeLER.codeler_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!=="" && getData>0){
            fetchCodeLerCode();
          }else{
            setNewCodeLER(newCodeLER => ({
              ...newCodeLER,
              id: "",
              indexrowcodeler:0,
              codeler_id:"",
              namees: "",
              nameen: "" 
            }));
            
          }
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchCodeLerCode = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`contract/getformcodelerfindbycode/`,token,newCodeLER.codeler_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
           
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!==""){
            setNewCodeLER(newCodeLER => ({
                ...newCodeLER,
                codeler_id: getData.codeler_id, 
                codeler_code: getData.codeler_code, 
                codeler_namees: getData.codeler_namees,
                codeler_nameen: getData.codeler_nameen    
              }));
        }  
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (newCodeLER.codeler_code!==""){
      fetchExistCodeLer();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,newCodeLER.codeler_code,setNewCodeLER]);

    
  return(
    <>
      {openEditRowCodeLERS && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewCodeLER(false)}
            onOpen={toggleDrawerNewCodeLER(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1050px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Text_CODELER_NEW')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewCodeLER}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('Text_CONTRACT_CODE_LER')} :</label>
                    <div className="form-group">
                      <TextField
                        id="codeler_code" 
                        name="codeler_code"
                        value={newCodeLER.codeler_code}
                        inputProps={{ style: {width: 450}, maxLength: 100 }}
                        label={errorsCodeLER.codeler_code!=null?t(errorsCodeLER.codeler_code):t('PlaceHolder_CONTRACT_CODE_LER')}
                        placeholder={t('PlaceHolder_CONTRACT_CODE_LER')}
                        variant="standard"
                        onBlur={handleBlurNewCodeLER}
                        onChange={handleChangeNewCodeLER}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormCodeLer}>
                            <SearchIcon/>
                        </IconButton>
                    </div>

                    <label className="text-primary">{t('Text_CONTRACT_CODE_LER_TITLEES')} :</label>
                    <div className="form-group">
                      <TextField
                        id="codeler_namees" 
                        name="codeler_namees"
                        value={newCodeLER.codeler_namees}
                        inputProps={{ style: {width: 1000}, maxLength: 255 }}
                        label={errorsCodeLER.codeler_namees!=null?t(errorsCodeLER.codeler_namees):t('Text_CONTRACT_CODE_LER_TITLEES')}
                        placeholder={t('Text_CONTRACT_CODE_LER_TITLEES')}
                        variant="standard"
                        onBlur={handleBlurNewCodeLER}
                        onChange={handleChangeNewCodeLER}
                        disabled/>
                    </div>

                    <label className="text-primary">{t('Text_CONTRACT_CODE_LER_TITLEEN')} :</label>
                    <div className="form-group">
                      <TextField
                        id="nameen" 
                        name="nameen"
                        value={newCodeLER.codeler_nameen}
                        inputProps={{ style: {width: 1000}, maxLength: 255 }}
                        label={errorsCodeLER.codeler_nameen!=null?t(errorsCodeLER.codeler_nameen):t('Text_CONTRACT_CODE_LER_TITLEEN')}
                        placeholder={t('Text_CONTRACT_CODE_LER_TITLEEN')}
                        variant="standard"
                        onBlur={handleBlurNewCodeLER}
                        onChange={handleChangeNewCodeLER}
                        disabled/>
                    </div>
                    <ContractSearchCodeLers
                      showModalSearchCodeLERS={showModalSearchCodeLERS}
                      setShowModalSearchCodeLERS={setShowModalSearchCodeLERS} 
                      setNewCodeLER={setNewCodeLER}
                    />
      
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitNewCodeLER}>{t('Button_Select')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseNewCodeLER}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

ContractCreateCodeLER.propTypes = {
  openEditRowCodeLERS: PropTypes.bool.isRequired,
  setOpenEditRowCodeLERS: PropTypes.func.isRequired,  
  formContract: PropTypes.object.isRequired,
  setFormContract: PropTypes.func.isRequired, 
};

export default ContractCreateCodeLER;