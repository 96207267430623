import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LOAD_SUCCESS_ERROR, LOAD_WARNING_ERROR, RESET_ERROR } from "types/Types";

export const warningErrorAction = (message) => (dispatch) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 800,
    hideProgressBar: false
  });
  dispatch({ type: LOAD_WARNING_ERROR, payload: message });
};

export const successErrorAction = (message) => (dispatch) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 800,
    hideProgressBar: false
  });
  dispatch({ type: LOAD_SUCCESS_ERROR, payload: message });
};

export const resetErrorAction = () => ({ type: RESET_ERROR });
