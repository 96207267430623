import {
  READ_LOGIN,
  LOAD_LOGIN,
  RESET_LOGIN
} from "types/Types";;


export const readLoginAction = () => ({ type: READ_LOGIN });
export const loadLoginAction = (data) => ({ type: LOAD_LOGIN, payload: data });
export const resetLoginAction = () => ({ type: RESET_LOGIN });
