import React from 'react'

const Messages = () => {
  return (
    <>
        <li className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#index.html" id="messagesDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-envelope fa-fw"></i>
                {/* Counter - Messages*/}
                <span className="badge badge-danger badge-counter">0+</span>
            </a>
            {/* Dropdown - Messages */}
        </li>
    </>
  )
}

export default Messages
