import React, {  useEffect, useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next" 
import { useDispatch } from "react-redux"; 
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingWeighVehiclesBudget from "pages/weighVehicles/weighVehiclesEdit/weighVehiclesBudgetsEdit/SortingTablesWeighVehiclesBudget";
import PropTypes from 'prop-types';

const initialFiltersBudgets= {
  "idFrom":"",
  "idTo":"",
  "defaultStoreIdFrom":"",
  "defaultStoreIdTo":"",
  "useraccountIdFrom":"",
  "useraccountIdTo":"",
  "isFinished":false,
  "isNotFinished":true,
  "numberbudgetFrom":"",
  "numberbudgetTo":"",
  "start_date_weighvehiclesbudgetFrom":"",
	"start_date_weighvehiclesbudgetTo":"",
  "end_date_weighvehiclesbudgetFrom":"",
  "end_date_weighvehiclesbudgetTo":"",
  "vehiclestore_licenseplateFrom":"",
  "vehiclestore_licenseplateTo":"",
  "rel_trans_store_idFrom":"",
  "rel_trans_store_idTo":"",
  "ori_tras_store_idFrom":"",
  "ori_tras_store_idTo":"", 
};
 
const initialStateBudgets = [{
  "id" : "",
  "vehiclestore_licenseplate" : "",
  "start_date_weighvehiclesbudget" : "",
  "rel_trans_tradename" : "",
  "ori_tras_tradename" : "",
  "totalfirstweight" : 0,
  "totalsecondweight" : 0,
}];

const headCellsBudgets = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Row',
    canOrder:true,
  },
  {
    id: 'vehiclestore_licenseplate',
    numeric: false,
    disablePadding: false,
    label: 'Text_WEIGHVEHICLES_LICENSEPLATE',
    canOrder:true,
  },
  {
    id: 'start_date_weighvehiclesbudget',
    numeric: false,
    disablePadding: false,
    label: 'Text_WEIGHVEHICLES_START_DATE',
    canOrder:true,
  },
  {
    id: 'rel_trans_tradename',
    numeric: false,
    disablePadding: false,
    label: 'Text_WEIGHVEHICLES_TRADENAME_TRANS',
    canOrder:true,
  },
  {
    id: 'ori_tras_tradename',
    numeric: false,
    disablePadding: false,
    label: 'Text_WEIGHVEHICLES_NAME_STORE',
    canOrder:true,
  },
  {
    id: 'totalfirstweight',
    numeric: false,
    disablePadding: false,
    label: 'Text_WEIGHVEHICLES_FIRST_WEIGHTS',
    canOrder:true,
  }
];

const initialStoreToSelect = [{
  "id": "",
  "tradename": ""
}];


const WeighVehiclesBudgetsForm = ({showModalWeighVehiclesSettings,submitNewBudget,setSubmitNewBudget}) => {
  const { t,i18n } = useTranslation(['listDataWeighVehicles']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DELETE_CODE = useSelector((state) => state.variablesUser.DELETE_CODE); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE);  
  const [dataBudget, setDataBudget] = useState(initialStateBudgets);
  const [formBudget, setFormBudget] = useState(initialStateBudgets[0]);
  const [filtersBudget, setFiltersBudget] = useState(initialFiltersBudgets);
  const [applyFiltersBudget, setApplyFiltersBudget] = useState(initialFiltersBudgets);
  const [numTotRegBudget, setNumTotRegBudget] = useState(0);
  const [numTotRegBudgetFilter, setNumTotRegBudgetFilter] = useState(0);
  const [errorsFiltersBudget, setErrorsFiltersBudget] = useState({});
  const [errorsBudget, setErrorsBudget] = useState({});
  const [inputTextBudget, setInputTextBudget] = useState(""); 
  const [openDeleteRowBudget, setOpenDeleteRowBudget] = useState(false);
  const [openEditRowBudget, setOpenEditRowBudget] = useState(false);
  const [openEditFilterBudget, setOpenEditFilterBudget] = useState(false);
  const [rowEditBudget, setRowEditBudget] = useState(initialStateBudgets[0]);
  const [isLoadingBudget, setIsLoadingBudget] = useState(true);
  const [isLoadingBudgetError, setIsLoadingBudgetError] = useState(false);
  const [idCodeDeleteBudget, setIdCodeDeleteBudget] = useState(DELETE_CODE);
  const [trasnporterToSelect,setTrasnporterToSelect]= useState(initialStoreToSelect); 
  
  const dispatch = useDispatch();
  const [activeOffsetBudget, setActiveOffsetBudget] = useState(false);
  const [currencyOffsetBudget, setCurrencyOffsetBudget] = useState(0);
  const [rangeOffsetBudget, setRangeOffsetBudget] = useState([{}]); 
 
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showModalWeighVehiclesSettings) {
        setDataBudget(initialStateBudgets);
        setFormBudget(initialStateBudgets[0]);
        setFiltersBudget(initialFiltersBudgets);
        setApplyFiltersBudget(initialFiltersBudgets);
        setNumTotRegBudget(0);
        setNumTotRegBudgetFilter(0);
        setErrorsFiltersBudget({});
        setErrorsBudget({});
        setInputTextBudget(""); 
        setOpenDeleteRowBudget(false);
        setOpenEditRowBudget(false);
        setOpenEditFilterBudget(false);
        setRowEditBudget(initialStateBudgets[0]);
        setIsLoadingBudget(false);
        setIsLoadingBudgetError(false);
        setIdCodeDeleteBudget(DELETE_CODE);
        setTrasnporterToSelect(initialStoreToSelect);
        setActiveOffsetBudget(false);
        setCurrencyOffsetBudget(0); 
        setRangeOffsetBudget([{}]); 
      }
    },
    [
      setDataBudget,
      setFormBudget,
      setFiltersBudget,
      setApplyFiltersBudget,
      setNumTotRegBudget,
      setNumTotRegBudgetFilter,
      setErrorsFiltersBudget,
      setErrorsBudget,
      setInputTextBudget, 
      setOpenDeleteRowBudget,
      setOpenEditRowBudget,
      setOpenEditFilterBudget,
      setRowEditBudget,
      setIsLoadingBudget,
      setIsLoadingBudgetError,
      setIdCodeDeleteBudget,
      setTrasnporterToSelect,
      setActiveOffsetBudget,
      setCurrencyOffsetBudget,
      setRangeOffsetBudget, 
      showModalWeighVehiclesSettings,  
      DELETE_CODE
    ]
  );

  const openEditFilterBudgets = () => {
    setFiltersBudget(applyFiltersBudget);
    setOpenEditFilterBudget(!openEditFilterBudget);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFiltersBudget(validateFormFilter());
    if (Object.keys(errorsFiltersBudget).length === 0) {
      setApplyFiltersBudget(filtersBudget);
      setFiltersBudget(initialFiltersBudgets);
      setErrorsFiltersBudget({});
      setOpenEditFilterBudget(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFiltersBudget({});
    setFiltersBudget(initialFiltersBudgets);
    setApplyFiltersBudget(initialFiltersBudgets);
    setOpenEditFilterBudget(!openEditFilterBudget);
  };

  const handleCancelFilters = () => {
    setErrorsFiltersBudget({});
    setFiltersBudget(initialFiltersBudgets);
    setOpenEditFilterBudget(!openEditFilterBudget);
  };

  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFiltersBudget(filtersBudget => ({
      ...filtersBudget,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFiltersBudget(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFiltersBudget({});
      setFiltersBudget(initialFiltersBudgets);
      setOpenEditFilterBudget(!openEditFilterBudget);
    }
  };

  const validateFormFilter = () => {

    let errorsFiltersBudget = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\*\\(\\)\\+\\-\\=\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;

    if (filtersBudget.idFrom!=="" & !regexNumber.test(filtersBudget.idFrom)) {
      errorsFiltersBudget.idFrom = 'Text_TextNoNumber';
    }
    if (filtersBudget.idTo!=="" & !regexNumber.test(filtersBudget.idTo)) {
      errorsFiltersBudget.idTo = 'Text_TextNoNumber';
    }

    if (regexInvalidCharacters.test(filtersBudget.numberbudgetFrom)) {
      errorsFiltersBudget.numberbudgetFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersBudget.numberbudgetTo)) {
      errorsFiltersBudget.numberbudgetTo = 'Text_TextNoValid';
    } 
     
    if (filtersBudget.idFrom!=="" & !errorsFiltersBudget.idFrom!==undefined){
      if (filtersBudget.idTo!=="" & !errorsFiltersBudget.idTo!==undefined){
        if (parseInt(filtersBudget.idTo)<parseInt(filtersBudget.idFrom)){
          errorsFiltersBudget.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (filtersBudget.defaultStoreIdTo!=="" & filtersBudget.defaultStoreIdTo<filtersBudget.defaultStoreIdFrom){
      errorsFiltersBudget.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filtersBudget.useraccountIdTo!=="" & filtersBudget.useraccountIdTo<filtersBudget.useraccountIdFrom){
      errorsFiltersBudget.useraccountIdTo = 'Text_TextGreater_UserAccount';
    }

    if (filtersBudget.start_date_weighvehiclesbudgetTo!=="" & filtersBudget.start_date_weighvehiclesbudgetTo<filtersBudget.start_date_weighvehiclesbudgetFrom){
      errorsFiltersBudget.start_date_weighvehiclesbudgetTo = 'Text_TextGreater_Start_Date';
    }

    if (filtersBudget.rel_trans_store_idTo!=="" & filtersBudget.rel_trans_store_idTo<filtersBudget.rel_trans_store_idFrom){
      errorsFiltersBudget.rel_trans_store_idTo = 'Text_TextGreater_Rel_Trans_Store';
    } 
    if (filtersBudget.ori_tras_store_idTo!=="" & filtersBudget.ori_tras_store_idTo<filtersBudget.ori_tras_store_idFrom){
      errorsFiltersBudget.ori_tras_store_idTo = 'Text_TextGreater_Ori_Tras_Store';
    }

    return errorsFiltersBudget;
  }  

  const filteredData = dataBudget.filter((el) => {
    if (inputTextBudget === '') {
        return el;
    }
    else {
        let dataBudgetTranslate=t(el.id+el.vehiclestore_licenseplate+el.rel_trans_tradename+el.ori_tras_tradename) ;
        return dataBudgetTranslate.toLowerCase().includes(inputTextBudget);
    }
  })
  
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextBudget(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteBudget(value);
  };

  const handleCancelDeleteRow = () => {
    setErrorsBudget({});
    setRowEditBudget(initialStateBudgets[0]);
    setFormBudget(initialStateBudgets[0]);
    setIsLoadingBudget(false);    
    setIsLoadingBudgetError(false);
    setIdCodeDeleteBudget(DELETE_CODE);
    setOpenDeleteRowBudget(false);
  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDeleteBudget !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    if (e.key !== 'Escape') {
      if (rowEditBudget.id !== '') {
        const fetchData = async () => {
          setIsLoadingBudget(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `weighvehiclesbudget/deleteweighvehiclesbudget`, token, rowEditBudget);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoadingBudget(false);
          } finally {
            setIsLoadingBudget(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
  
    setIdCodeDeleteBudget(DELETE_CODE);
    setOpenDeleteRowBudget(false);
    handleCloseDataEdit();
  };
  
  const handleSubmitDataEdit = () => {
    setErrorsBudget(validateForm());
    const errorsBudgetToCheck = validateForm();
  
    if (Object.keys(errorsBudgetToCheck).length === 0 && formBudget.id !==undefined && formBudget.id !== '' && formBudget.id !== '0' && formBudget.id !== 0) {
      const fetchData = async () => {
        setIsLoadingBudget(true);
        try {
          const getData = await helpHttp().put(ENDPOINT, `weighvehiclesbudget/savesecondweighvehiclesbudget`, token, formBudget) ;
  
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEdit();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingBudget(false);
        } finally {
          setIsLoadingBudget(false);
        }
      };
      fetchData();
    } else {
      if (errorsBudgetToCheck.id) {
        dispatch(warningErrorAction(t(errorsBudgetToCheck.id)));
      } else if (errorsBudgetToCheck.totalsecondweight) {
        dispatch(warningErrorAction(t(errorsBudgetToCheck.totalsecondweight)));
      }  else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };

  const handleCloseDataEdit = () => { 
    setOpenEditRowBudget(false);
    setErrorsBudget({});
    setRowEditBudget(openEditRowBudget?rowEditBudget:initialStateBudgets[0]);
    setFormBudget(initialStateBudgets[0]);
    setIsLoadingBudget(false);
    setIsLoadingBudgetError(false);
    toggleDrawerDataEdit(false);
	};

  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setFormBudget(formBudget => ({
      ...formBudget,
      [name]: value
    }));
  };

  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrorsBudget(validateForm());
  };
  
  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const handleChangeSelectDateFrom = (event) => {
    setFiltersBudget(filtersBudget => ({
      ...filtersBudget,
      start_date_weighvehiclesbudgetFrom: event!==null?new Date(event):null,
      start_date_weighvehiclesbudgetTo:null
    }));
  };

  const handleBlurSelectDateFrom = (e) => {
    handleChangeSelectDateFrom(e);
    setErrorsBudget(validateForm());
  };

  const handleChangeSelectDateTo = (event) => {
    setFiltersBudget(filtersBudget => ({
      ...filtersBudget,
      start_date_weighvehiclesbudgetTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDatetTo = (e) => {
    handleChangeSelectDateTo(e);
    setErrorsBudget(validateForm());
  };

  const validateForm = () => {
    let errorsBudget = {};
    //let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;

    if (!formBudget.totalsecondweight || formBudget.totalsecondweight==="" || formBudget.totalsecondweight==="0" || formBudget.totalsecondweight===0) {
      errorsBudget.namees = 'Text_FielTotalSecondWeighRequired';
    }  
    if (formBudget.totalsecondweight>formBudget.totalfirstweight) {
      errorsBudget.nameen = 'Text_TextGreater_SecondWeighRequired';
    }  
     
    return errorsBudget;
  };

  useEffect(() => {
    setFormBudget(openEditRowBudget ? rowEditBudget : initialStateBudgets[0]);
  }, [rowEditBudget, openEditRowBudget]);
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  useEffect(() => {
    if (numTotRegBudgetFilter > LIMITPAGESIZE) {
      setActiveOffsetBudget(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegBudgetFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetBudget(newRangeOffset);
    } else {
      setCurrencyOffsetBudget(0);
      setActiveOffsetBudget(false);
      setRangeOffsetBudget([{}]);
    }
  }, [numTotRegBudgetFilter, LIMITPAGESIZE]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataTransporter = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `weighvehiclesbudget/getlisttransporterbudget`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTrasnporterToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (showModalWeighVehiclesSettings || submitNewBudget) { 
      fetchDataTransporter(); 
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,applyFiltersBudget,openEditFilterBudget,showModalWeighVehiclesSettings,submitNewBudget]);
  

  useEffect(() => {
    let isMounted = true;  
    const fetchnumTotRegBudget = async () => {
      setIsLoadingBudget(true);
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Budgets_Store_Master')))
        ? await helpHttp().get2(ENDPOINT,`weighvehiclesbudget/getinformationtableweighvehiclesbudget/`,token,false)
        : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Budgets_User_Master'))
            ? await helpHttp().get8(ENDPOINT,`weighvehiclesbudget/getinformationtableweighvehiclesbudgetwithstore`,token,defaultStore.id,false)
            :await helpHttp().get7(ENDPOINT,`weighvehiclesbudget/getinformationtableweighvehiclesbudgetwithstoreanduseraccount`,token,defaultStore.id,useraccount_id,false)
            )
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataBudget(initialStateBudgets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegBudget(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingBudget(false);
      } finally {
        if (isMounted) {
          setIsLoadingBudget(false); 
        }
      }
    };
  
    const fetchnumTotRegBudgetFilter = async () => {
      setIsLoadingBudget(true);
      if (!roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Budgets_Store_Master'))){
        setApplyFiltersBudget(applyFiltersFormDI => ({
          ...applyFiltersFormDI,
          defaultStoreIdFrom: defaultStore.id,
          defaultStoreIdTo: defaultStore.id
        }));
      } 
      if (!roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Budgets_User_Master'))){
        setApplyFiltersBudget(applyFiltersBudget => ({
          ...applyFiltersBudget,
          useraccountIdFrom: useraccount_id,
          useraccountIdTo: useraccount_id
        }));
      } 
      try {
        const getData = await helpHttp().post(ENDPOINT,`weighvehiclesbudget/getcountfilterweighvehiclesbudget`,token,applyFiltersBudget);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataBudget(initialStateBudgets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegBudgetFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingBudget(false);
      } finally {
        if (isMounted) {
          setIsLoadingBudget(false);
        }
      }
    };
  
    const fetchData = async () => {
      setIsLoadingBudget(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT, `weighvehiclesbudget/getsimplelistweighvehiclesbudget`, token, currencyOffsetBudget, LIMITPAGESIZE, applyFiltersBudget);
        if (getData.err || getData.message === "Load failed") {
          setDataBudget(initialStateBudgets);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataBudget(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingBudget(false);
      } finally {
        setIsLoadingBudget(false);
      }
    };
  
    if (showModalWeighVehiclesSettings || submitNewBudget) {    
      fetchnumTotRegBudget();
      fetchnumTotRegBudgetFilter();
      fetchData();
      setSubmitNewBudget(false);
      return () => { isMounted = false; };
    }
  }, [ENDPOINT, t, token,roles, dispatch, openDeleteRowBudget, openEditRowBudget, rowEditBudget, showModalWeighVehiclesSettings, LIMITPAGESIZE, applyFiltersBudget, currencyOffsetBudget,defaultStore.id,useraccount_id,submitNewBudget,setSubmitNewBudget]);
  
  return (
    <div> 
      {showModalWeighVehiclesSettings && ( 
        <div className="card border-info shadow">
          {/* Search input */}
          <div className="card-header 2 bg-primary text-white text-center text-bold">
            <h4>
              {isLoadingBudgetError?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingBudget?<CircularProgress color={openDeleteRowBudget?"warning":"primary"}/>:<i className="fas fa-duotone fa-truck text-warning"></i>} {t('Text_WEIGHVEHICLES_SECOND_WEIGHTS')}
            </h4>
          </div>  
          <br/>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="searchStyle">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={inputTextBudget}
                  placeholder={t('Text_WEIGHVEHICLES_SECOND_WEIGHTS_Search')} 
                  onChange={inputHandler}
                  fullWidth
                  label={t('Text_WEIGHVEHICLES_SECOND_WEIGHTS_Search')}>
                </TextField>
              </div>
              <br/>
              <Typography style={{color:"#067330", fontSize: 16}} >  
                {t('Text_Filter')}&nbsp;&nbsp;&nbsp;
                  <button type="button" className="buttonFilter" onClick={openEditFilterBudgets}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersBudget.idFrom!=="" || applyFiltersBudget.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilterBudgets}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersBudget.start_date_weighvehiclesbudgetFrom!=="" || applyFiltersBudget.start_date_weighvehiclesbudgetTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_WEIGHVEHICLES_DATE')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilterBudgets}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersBudget.vehiclestore_licenseplateFrom!=="" || applyFiltersBudget.vehiclestore_licenseplateTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_WEIGHVEHICLES_LICENSEPLATE')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilterBudgets}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersBudget.rel_trans_store_idFrom!=="" || applyFiltersBudget.rel_trans_store_idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_WEIGHVEHICLES_TRADENAME_TRANS')}</button><> </> 
                  <button type="button" className="buttonFilter" onClick={openEditFilterBudgets}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersBudget.ori_tras_store_idFrom!=="" || applyFiltersBudget.ori_tras_store_idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_WEIGHVEHICLES_TRADENAME_STORE')}</button><> </> 
              </Typography>
              <br/>
              <br/>
              <br/>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingWeighVehiclesBudget
                    dataBudget={filteredData}
                    headCellsBudgets={headCellsBudgets}
                    numTotRegBudgetFilter={numTotRegBudgetFilter}
                    titleTable={t('Text_WEIGHVEHICLES_SECOND_WEIGHTS_TABLE')}
                    setOpenDeleteRowBudget={setOpenDeleteRowBudget}
                    setOpenEditRowBudget={setOpenEditRowBudget}
                    setRowEditBudget={setRowEditBudget}
                    showEditButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_CodeLer_Edit'))?true:false}
                    showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_CodeLer_Delete'))?true:false}
                    numTotRegBudget={numTotRegBudget}
                    activeOffsetBudget={activeOffsetBudget}
                    rangeOffsetBudget={rangeOffsetBudget}
                    currencyOffsetBudget={currencyOffsetBudget}
                    setCurrencyOffsetBudget={setCurrencyOffsetBudget}>
                  </TableSortingWeighVehiclesBudget>
                  {openEditFilterBudget &&
                    <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEdit(false)}
                        onOpen={toggleDrawerFilterEdit(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850px' }}>
                            <br/>
                            <div id="myDIV">
                              <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                            </div>
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <form className="createfilter" onSubmit={handleCancelFilters}>  
                              <div className="form-floating mb-3 px-4 primary">
                                <label className="text-primary">{t('Text_Filter_Code_From')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="idFrom" 
                                    name="idFrom"
                                    value={filtersBudget.idFrom}
                                    inputProps={{ style: {width: 450}, maxLength: 15 }}
                                    label={errorsFiltersBudget.idFrom!=null?t(errorsFiltersBudget.idFrom):t('PlaceHolder_Filter_Code_From')}
                                    placeholder={t('PlaceHolder_Filter_Code_From')}
                                    variant="standard"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}/>
                                </div>
                                <label className="text-primary">{t('Text_Filter_Code_To')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="idTo" 
                                    name="idTo"
                                    value={filtersBudget.idTo}
                                    inputProps={{ style: {width: 450}, maxLength: 15 }}
                                    label={errorsFiltersBudget.idTo!=null?t(errorsFiltersBudget.idTo):t('PlaceHolder_Filter_Code_to')}
                                    placeholder={t('PlaceHolder_Filter_Code_to')}
                                    variant="standard"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}/>
                                </div>
                                <label className="text-primary">{t('Text_Filter_FirstWeight_From')} :</label>
                                <div className="form-group">
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                      id="start_date_weighvehiclesbudgetFrom" 
                                      name="start_date_weighvehiclesbudgetFrom"
                                      value={ filtersBudget.start_date_weighvehiclesbudgetFrom!== null ? new Date(filtersBudget.start_date_weighvehiclesbudgetFrom): null}
                                      format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                      label={errorsFiltersBudget.start_date_weighvehiclesbudgetFrom!=null?t(errorsFiltersBudget.start_date_weighvehiclesbudgetFrom):t('PlaceHolder_Filter_FirstWeight_From')}
                                      onBlur={handleBlurSelectDateFrom}
                                      onChange={handleChangeSelectDateFrom}
                                      slotProps={{
                                        textField: {
                                          placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                          sx: {
                                            color: '#1565c0',
                                            maxLength: '100',
                                            width: 450
                                          }
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <label className="text-primary">{t('Text_Filter_FirstWeight_To')} :</label>
                                <div className="form-group">
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                      id="start_date_weighvehiclesbudgetTo" 
                                      name="start_date_weighvehiclesbudgetTo"
                                      minDate={filtersBudget.start_date_weighvehiclesbudgetFrom!== null ? new Date(filtersBudget.start_date_weighvehiclesbudgetFrom): null}
                                      value={ filtersBudget.start_date_weighvehiclesbudgetTo!== null ? new Date(filtersBudget.start_date_weighvehiclesbudgetTo): null}
                                      format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                      label={errorsFiltersBudget.start_date_weighvehiclesbudgetTo!=null?t(errorsFiltersBudget.start_date_weighvehiclesbudgetTo):t('PlaceHolder_Filter_FirstWeight_To')}
                                      onBlur={handleBlurSelectDatetTo}
                                      onChange={handleChangeSelectDateTo}
                                      slotProps={{
                                        textField: {
                                          placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                          sx: {
                                            color: '#1565c0',
                                            maxLength: '100',
                                            width: 450
                                          }
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <label className="text-primary">{t('Text_Filter_LicensePlate_From')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="vehiclestore_licenseplateFrom" 
                                    name="vehiclestore_licenseplateFrom"
                                    value={filtersBudget.vehiclestore_licenseplateFrom}
                                    inputProps={{ style: {width: 450}, maxLength: 15 }}
                                    label={errorsFiltersBudget.vehiclestore_licenseplateFrom!=null?t(errorsFiltersBudget.vehiclestore_licenseplateFrom):t('PlaceHolder_Filter_LicensePlate_From')}
                                    placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                                    variant="standard"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}/>
                                </div>
                                <label className="text-primary">{t('Text_Filter_LicensePlate_To')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="vehiclestore_licenseplateTo" 
                                    name="vehiclestore_licenseplateTo"
                                    value={filtersBudget.vehiclestore_licenseplateTo}
                                    inputProps={{ style: {width: 450}, maxLength: 15 }}
                                    label={errorsFiltersBudget.vehiclestore_licenseplateTo!=null?t(errorsFiltersBudget.vehiclestore_licenseplateTo):t('PlaceHolder_Filter_LicensePlate_To')}
                                    placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                                    variant="standard"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}/>
                                </div>
                                <label className="text-primary">{t('Text_Filter_Trans_From')} :</label>
                                <div className="form-group">
                                <TextField
                                    id="rel_trans_store_idFrom"
                                    name="rel_trans_store_idFrom"
                                    value={filtersBudget.rel_trans_store_idFrom}
                                    inputProps={{ style: {width: 450}, maxLength: 100 }}
                                    label={errorsFiltersBudget.rel_trans_store_idFrom!=null?t(errorsFiltersBudget.rel_trans_store_idFrom):t('PlaceHolder_Filter_Trans_From')}
                                    placeholder={t('PlaceHolder_Filter_Trans_From')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                    <option key={0} value=""/>
                                    {trasnporterToSelect.map((option) => (
                                        <option key={option.id} value={option.id}>
                                          {option.id+" - "+option.tradename}
                                        </option>
                                    ))}
                                  </TextField>
                                </div>
                                <label className="text-primary">{t('Text_Filter_Trans_To')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="rel_trans_store_idTo"
                                    name="rel_trans_store_idTo"
                                    value={filtersBudget.rel_trans_store_idTo}  
                                    inputProps={{ style: {width: 450}, maxLength: 100 }}
                                    label={errorsFiltersBudget.rel_trans_store_idTo!=null?t(errorsFiltersBudget.rel_trans_store_idTo):t('PlaceHolder_Filter_Trans_To')}
                                    placeholder={t('PlaceHolder_Filter_Trans_To')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                    <option key={0} value=""/>
                                    {trasnporterToSelect.filter(item=>(item.id>=filtersBudget.rel_trans_store_idFrom)).map(option => (
                                      <option key={option.id} value={option.id}>
                                          {option.id+" - "+option.tradename}
                                      </option>
                                    ))}
                                  </TextField>
                                </div>

                                

                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                                </div>
                              </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  {(openEditRowBudget)&& 
                  <div>
                    <SwipeableDrawer
                      anchor='right'
                      open={true}
                      onClose={toggleDrawerDataEdit(false)}
                      onOpen={toggleDrawerDataEdit(true)}>
                      <Container >
                        <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850px' }}>
                          <br/>
                          <div id="myDIV">
                            <h1 className="h1NewStyle px-4 mb-2">{t('Text_WEIGHVEHICLES_EDIT')}</h1>
                          </div>  
                          <hr className="sidebar-divider d-none d-md-block"/>
                          <form className="createcodeler" onSubmit={handleSubmitDataEdit}>  
                            <div className="form-floating mb-3 px-4 primary">
                              <label className="text-primary">{t('Text_Code')} :</label>
                              <div className="form-group">
                                <TextField
                                  id="id" 
                                  name="id"
                                  value={formBudget.id}
                                  inputProps={{ style: {width: 200} }}
                                  label={t('PlaceHolder_CodeLer_Code')}
                                  placeholder={t('PlaceHolder_CodeLer_Code')}
                                  variant="standard"
                                  disabled/>
                              </div>
                              <label className="text-primary">{t('Text_NameEs')} :</label>
                              <div className="form-group">
                                <TextField
                                  id="id" 
                                  name="id"
                                  value={formBudget.namees}
                                  inputProps={{ style: {width: 800}, maxLength: 250 }}
                                  label={errorsBudget.id!=null?t(errorsBudget.id):t('PlaceHolder_Dangerousness_NameEs')}
                                  placeholder={t('PlaceHolder_Dangerousness_NameEs')}
                                  variant="standard"
                                  onBlur={handleBlurDataEdit}
                                  onChange={handleChangeDataEdit}/>
                              </div>
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEdit}>{openEditRowBudget?t('Button_Update'):t('Button_Create')}</button><> </>
                                <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEdit}>{t('Button_Cancel')}</button>
                              </div>
                            </div>
                          </form>
                        </Box>
                      </Container>
                    </SwipeableDrawer>
                  </div>}
                  <Dialog open={openDeleteRowBudget} onClose={handleCancelDeleteRow}>
                    <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditBudget.id}  - {rowEditBudget.code} - {rowEditBudget.namees} - {rowEditBudget.nameen}  </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {t('Text_Line1')}<br/>
                        {t('Text_Line2')}
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDeleteBudget}
                        onChange={handleChangeDeleteRow}/>
                    </DialogContent>
                    <DialogActions>
                      <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
                      <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
                    </DialogActions>
                  </Dialog>
                  {/* End Content Row */}
                </div>             
              </div>
            </div>
          </div>
        </div>
        //* End Page Content *//
      )}
     </div>
  )
}

WeighVehiclesBudgetsForm.propTypes = {
  showModalWeighVehiclesSettings: PropTypes.bool.isRequired,
  submitNewBudget: PropTypes.bool.isRequired,
  setSubmitNewBudget: PropTypes.func.isRequired   

};

export default WeighVehiclesBudgetsForm;
