import React, {  useEffect, useState,useCallback } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { Checkbox, TextField } from '@mui/material';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { warningErrorAction } from "actions/ErrorActions";
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types'; 

const initialVehicle = {
  "id":"",
  "indexrowvehicle":0,
  "licenseplate":"",
  "defaultvehicle":false,
  "tara":0,
  "pma":0,
  "checkpma":true,
  "defaultdriver":""
};

const StoreCreateOrEditVehicle = ({openEditRowVehicle,setOpenEditRowVehicle,openEditNewVehicle,setOpenEditNewVehicle,formStore,setFormStore,editRowVehicle,setEditRowVehicle}) => {
  const {t} = useTranslation(['listDataStores']);
  const [newVehicle,setNewVehicle]= useState(initialVehicle);
  const [errorsVehicle, setErrorsVehicle] = useState({});
  const dispatch = useDispatch();
 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowVehicle) {
        setNewVehicle(initialVehicle);
        setErrorsVehicle({});
        setEditRowVehicle(initialVehicle);
        setOpenEditNewVehicle(false);
        setOpenEditRowVehicle(false);
      }
    },[
      setNewVehicle,
      setErrorsVehicle,
      openEditRowVehicle,
      setOpenEditRowVehicle,
      setOpenEditNewVehicle,
      setEditRowVehicle]
  );

  const handleCloseNewVehicle = () => {
    setNewVehicle(initialVehicle);
    setErrorsVehicle({});
    setEditRowVehicle(initialVehicle);
    setOpenEditNewVehicle(false);
    setOpenEditRowVehicle(false);
  };

  const handleChangeNewVehicle = (e) => {
    const { name, value } = e.target;
    setNewVehicle(newVehicle => ({
      ...newVehicle,
      [name]: value,
    }));   
  };
  
  const handleBlurNewVehicle = (e) => {
    handleChangeNewVehicle(e);
    setErrorsVehicle(validateFormVehicle());
  };
  
  
  const handleChangeEnabledDefaultVehicle = () => {
    setNewVehicle(newVehicle => ({
      ...newVehicle,
      defaultvehicle: !newVehicle.defaultvehicle
    }));
  };
 
  const handleChangeCheckPmaVehicle = () => {
    setNewVehicle(newVehicle => ({
      ...newVehicle,
      checkpma: !newVehicle.checkpma
    }));
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000) return true;
    return false;
  };
  const toggleDrawerNewVehicle  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewVehicle();
    }
  };

  const validateFormVehicle = () => {
    let errorsVehicle = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;
   
    if (newVehicle.licenseplate===""){
        errorsVehicle.licenseplate = 'TextLicensePlateRequired';
    }else{
      if (regexInvalidCharacters.test(formStore.licenseplate)) {
        errorsVehicle.licenseplate = 'Text_TextNoValid';
      }
    }
   
    if (regexInvalidCharacters.test(formStore.defaultdriver)) {
      errorsVehicle.defaultdriver = 'Text_TextNoValid';
    }

    if (!openEditNewVehicle){
      if (formStore.vehicles.find(item => {return (item.licenseplate.trim()=== newVehicle.licenseplate.trim()) })){
        errorsVehicle.licenseplate = 'Text_LicensePlateExist';
      }
    }else{
      if (formStore.vehicles.find((item, index) => {return (item.indexrowvehicle!==newVehicle.indexrowvehicle && item.licenseplate.trim()=== newVehicle.licenseplate.trim()  ) })){
        errorsVehicle.licenseplate = 'Text_LicensePlateExist';
      }
    }  
    
    return errorsVehicle;
  };

  const handleSubmitNewVehicle = () => {   
    setErrorsVehicle(validateFormVehicle());
    let errorsCheckVehicle=validateFormVehicle();

    if ((Object.keys(errorsCheckVehicle).length === 0) 
          && newVehicle.licenseplate!=='' ) {

      let defaultVehicleFound=newVehicle.defaultvehicle;
      
      if (formStore.vehicles.length <=1){
        defaultVehicleFound=true;
      }
      
      let vehicleToAdd={
        "id":newVehicle.id,
        "indexrowvehicle":openEditNewVehicle?newVehicle.indexrowvehicle:formStore.vehicles.length>0?formStore.vehicles[formStore.vehicles.length-1].indexrowvehicle+1:1,       
        "licenseplate":newVehicle.licenseplate,
        "defaultvehicle":defaultVehicleFound,
        "tara":newVehicle.tara!==null?Number((newVehicle.tara.toLocaleString()).replaceAll(",","")):0,
        "pma":newVehicle.pma!==null?Number((newVehicle.pma.toLocaleString()).replaceAll(",","")):0,
        "checkpma":newVehicle.checkpma,
        "defaultdriver":newVehicle.defaultdriver, 
      }
      
      if (vehicleToAdd.defaultvehicle){
        formStore.vehicles.map((item)=>{
            return item.defaultvehicle=false  
        })
        setFormStore(formStore => ({
          ...formStore,
        }))
      }
       
      if (!openEditNewVehicle){
        formStore.vehicles.push(vehicleToAdd);
      }else{
        let idx=formStore.vehicles.findIndex(((item) => item.indexrowvehicle === newVehicle.indexrowvehicle));
        formStore.vehicles[idx]=vehicleToAdd
      }
      setFormStore(formStore => ({
        ...formStore,
      }))
      handleCloseNewVehicle();
    } else {
      if (errorsCheckVehicle.licenseplate){
        dispatch(warningErrorAction(t(errorsCheckVehicle.licenseplate)));
      }
      if (errorsCheckVehicle.defaultdriver){
        dispatch(warningErrorAction(t(errorsCheckVehicle.defaultdriver)));
      }
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && openEditNewVehicle){
      setNewVehicle(newVehicle => ({
        ...newVehicle,
        id:editRowVehicle.id!==null?editRowVehicle.id:"",
        indexrowvehicle:editRowVehicle.indexrowvehicle!==null?editRowVehicle.indexrowvehicle:"",
        licenseplate:editRowVehicle.licenseplate!==null?editRowVehicle.licenseplate:"",
        defaultvehicle:editRowVehicle.defaultvehicle!==null?editRowVehicle.defaultvehicle:false,
        tara:editRowVehicle.tara!==null?editRowVehicle.tara:0,
        pma:editRowVehicle.pma!==null?editRowVehicle.pma:0,
        checkpma:editRowVehicle.checkpma!==null?editRowVehicle.checkpma:true,
        defaultdriver:editRowVehicle.defaultdriver!==null?editRowVehicle.defaultdriver:"",
      }));
    }
    return()=>{isMounted=false;}   
  },[editRowVehicle,openEditNewVehicle]);

  return(
    <>
      {openEditRowVehicle && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewVehicle(false)}
            onOpen={toggleDrawerNewVehicle(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{openEditNewVehicle?t('Text_Vehicle_Edit'):t('Text_Vehicle_New')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createVehicle" onSubmit={handleCloseNewVehicle}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('Text_Vehicle_licenseplate')} :</label>
                    <div className="form-group">
                      <TextField
                        id="licenseplate" 
                        name="licenseplate"
                        value={newVehicle.licenseplate}
                        inputProps={{ style: {width: 450}, maxLength: 50 }}
                        label={errorsVehicle.licenseplate!=null?t(errorsVehicle.licenseplate):t('PlaceHolder_Vehicle_licenseplate')}
                        placeholder={t('PlaceHolder_Vehicle_licenseplate')}
                        variant="standard"
                        onBlur={handleBlurNewVehicle}
                        onChange={handleChangeNewVehicle}/>
                    </div>
                    <div className="form-group">
                      <label className="text-primary">{t('Text_Vehicle_defaultvehicle')} :</label>
                      <Checkbox  
                        style={{color:"#4e73df"}} 
                        checked={newVehicle.defaultvehicle} 
                        onChange={handleChangeEnabledDefaultVehicle} 
                      />
                    </div>
                    
                    <label className="text-primary">{t('Text_Vehicle_tara')} :</label>
                    <div className="form-group">
                      <NumericFormat 
                        thousandSeparator={','} 
                        decimalSeparator={'.'} 
                        decimalScale= {2}
                        fixedDecimalScale= {true}
                        allowNegative= {false}
                        isAllowed={withValueCap}
                        id="tara" 
                        name="tara"
                        value={newVehicle.tara}
                        label={errorsVehicle.tara!=null?t(errorsVehicle.tara):t('PlaceHolder_Vehicle_tara')}
                        placeholder={t('PlaceHolder_Vehicle_tara')}
                        style={{width: 360}}
                        customInput={TextField}
                        onBlur={handleBlurNewVehicle}
                        onChange={handleChangeNewVehicle}
                      />
                    </div>

                    <label className="text-primary">{t('Text_Vehicle_pma')} :</label>
                    <div className="form-group">
                      <NumericFormat 
                        thousandSeparator={','} 
                        decimalSeparator={'.'} 
                        decimalScale= {2}
                        fixedDecimalScale= {true}
                        allowNegative= {false}
                        isAllowed={withValueCap}
                        id="pma" 
                        name="pma"
                        value={newVehicle.pma}
                        label={errorsVehicle.pma!=null?t(errorsVehicle.pma):t('PlaceHolder_Vehicle_pma')}
                        placeholder={t('PlaceHolder_Vehicle_pma')}
                        style={{width: 360}}
                        customInput={TextField}
                        onBlur={handleBlurNewVehicle}
                        onChange={handleChangeNewVehicle}
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-primary">{t('Text_Vehicle_checkpma')} :</label>
                      <Checkbox  
                        style={{color:"#4e73df"}} 
                        checked={newVehicle.checkpma} 
                        onChange={handleChangeCheckPmaVehicle} 
                      />
                    </div>

                    <label className="text-primary">{t('Text_Vehicle_driver')} :</label>
                    <div className="form-group">
                      <TextField
                        id="defaultdriver" 
                        name="defaultdriver"
                        value={newVehicle.defaultdriver}
                        inputProps={{ style: {width: 450}, maxLength: 100 }}
                        label={errorsVehicle.defaultdriver!=null?t(errorsVehicle.defaultdriver):t('PlaceHolder_Vehicle_driver')}
                        placeholder={t('PlaceHolder_Vehicle_driver')}
                        variant="standard"
                        onBlur={handleBlurNewVehicle}
                        onChange={handleChangeNewVehicle}/>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitNewVehicle}>{openEditNewVehicle?t('Button_Update'):t('Button_Create')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseNewVehicle}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

StoreCreateOrEditVehicle.propTypes = {

  openEditRowVehicle: PropTypes.bool.isRequired,
  setOpenEditRowVehicle: PropTypes.func.isRequired,
  openEditNewVehicle: PropTypes.bool.isRequired,
  setOpenEditNewVehicle: PropTypes.func.isRequired,
  formStore: PropTypes.object.isRequired,
  setFormStore: PropTypes.func.isRequired,
  setEditRowVehicle: PropTypes.func.isRequired
};

export default StoreCreateOrEditVehicle;