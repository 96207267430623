import React from "react";
import { useTranslation } from "react-i18next";

export const TodayIs = () => {
  const { t } = useTranslation(['nameDate']);
  const textDay = [
    'Text_Sunday',
    'Text_Monday',
    'Text_Tuesday',
    'Text_Wednesday',
    'Text_Thursday',
    'Text_Friday',
    'Text_Saturday'
  ];
  const textMonth = [
    'Text_January',
    'Text_February',
    'Text_March',
    'Text_April',
    'Text_May',
    'Text_June',
    'Text_July',
    'Text_August',
    'Text_September',
    'Text_October',
    'Text_November',
    'Text_December'
  ];

  const day = t(textDay[new Date().getDay()]); // Current Name Day of the Week
  const date = new Date().getDate(); // Current Day Date
  const month = t(textMonth[new Date().getMonth()]); // Current Name Month
  const year = new Date().getFullYear(); // Current Year

  return (
    <>
      {day} {date} - {month} - {year}
    </>
  );
};
