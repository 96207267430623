import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton'; 
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export const Loading = ({ isLoadingData }) => {
  return (
    <Dialog open={isLoadingData}>
      <DialogContent>
        <Box sx={{ m: 1, position: 'relative' }}>
          <IconButton
            aria-label="save"
            sx={{
              bgcolor: "#27a769",
              '&:hover': {
                bgcolor: "#076d3c",
              },
            }}
          >
            <SaveIcon color="primary" />
          </IconButton>
          <CircularProgress
            size={68}
            sx={{
              color: "#076d3c",
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
