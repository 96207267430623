import React, {  useEffect, useState,useCallback } from 'react';
import { useTranslation } from "react-i18next"
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types'; 

const initialUserAccount = {
  "useraccount_id":0,
  "useraccount_username": "",
  "useraccount_aliasuser": "",
  "useraccount_password": "",
  "useraccount_enabled": true,
  "useraccount_isAccountNonLocked": true,
  "useraccount_color_id": 0,
  "useraccount_color_codehex": "",
  "useraccount_store_id": 0,
  "useraccount_store_name": "",
  "useraccount_email": "",
  "useraccount_tyyesdi_id": 0,
  "useraccount_tyyesdi_namees": "",
  "useraccount_tyyesdi_nameen": "",
  "useraccount_weighvehiclesformat_id": 0,
  "useraccount_weighvehiclesformat_namees": "",
  "useraccount_weighvehiclesformat_nameen": ""
};

const EmployeeSelectUserAccount = ({openSelectUserAccount,setOpenSelectUserAccount,setFormEmployee,userAccountToSelect}) => {
  const {t} = useTranslation(['listDataEmployees']);
  const [ selectExistUserAccount, setSelectExistUserAccount ]= useState(initialUserAccount);
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openSelectUserAccount) {     
        setSelectExistUserAccount(initialUserAccount);
        setOpenSelectUserAccount(false);
      }
    },[
      setSelectExistUserAccount,
      openSelectUserAccount,
      setOpenSelectUserAccount]
  );

  const handleCloseSelectUserAccount = () => {
    setSelectExistUserAccount(initialUserAccount);
    setOpenSelectUserAccount(false);
  };

  const handleBlurSelectUserAccount = (event) => {
    handleChangeSelectUserAccount(event);
  };

  const handleChangeSelectUserAccount = (event) => {
    let indexSelected=userAccountToSelect.find(((item) => Number(item.useraccount_id) === Number(event.target.value)));
    if (indexSelected!==undefined){
      setSelectExistUserAccount(selectExistUserAccount => ({
        ...selectExistUserAccount,
        useraccount_id:indexSelected.useraccount_id,
        useraccount_username:indexSelected.useraccount_username,
        useraccount_aliasuser:indexSelected.useraccount_aliasuser,
        useraccount_password:indexSelected.useraccount_password,
        useraccount_enabled:true,
        useraccount_isAccountNonLocked:true,
        useraccount_color_id:indexSelected.useraccount_color_id,
        useraccount_color_codehex:indexSelected.useraccount_color_codehex,
        useraccount_store_id:indexSelected.useraccount_store_id,
        useraccount_store_name:indexSelected.useraccount_store_name,
        useraccount_email:indexSelected.useraccount_email,
        useraccount_tyyesdi_id: indexSelected.useraccount_tyyesdi_id,
        useraccount_tyyesdi_namees: indexSelected.useraccount_tyyesdi_namees,
        useraccount_tyyesdi_nameen: indexSelected.useraccount_tyyesdi_nameen,
        useraccount_weighvehiclesformat_id: indexSelected.useraccount_weighvehiclesformat_id,
        useraccount_weighvehiclesformat_namees: indexSelected.useraccount_weighvehiclesformat_namees,
        useraccount_weighvehiclesformat_nameen: indexSelected.useraccount_weighvehiclesformat_nameen
      }));
    }  else{
      setSelectExistUserAccount(initialUserAccount);
    }
  };

  const toggleDrawerSelectUserAccount  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseSelectUserAccount();
    }
  };

  const handleSubmitSelectUserAccount = () => {  
    setFormEmployee(formEmployee => ({
      ...formEmployee,
      useraccount_id: selectExistUserAccount.useraccount_id,
      useraccount_username: selectExistUserAccount.useraccount_username,
      useraccount_aliasuser: selectExistUserAccount.useraccount_aliasuser,
      useraccount_password: selectExistUserAccount.useraccount_password,
      useraccount_enabled: selectExistUserAccount.useraccount_enabled,
      useraccount_isAccountNonLocked: selectExistUserAccount.useraccount_isAccountNonLocked,
      useraccount_color_id: selectExistUserAccount.useraccount_color_id,
      useraccount_color_codehex: selectExistUserAccount.useraccount_color_codehex,
      useraccount_store_id: selectExistUserAccount.useraccount_store_id,
      useraccount_store_name: selectExistUserAccount.useraccount_store_name,
      useraccount_email: selectExistUserAccount.useraccount_email,
      useraccount_tyyesdi_id: selectExistUserAccount.useraccount_tyyesdi_id,
      useraccount_tyyesdi_namees: selectExistUserAccount.useraccount_tyyesdi_namees,
      useraccount_tyyesdi_nameen: selectExistUserAccount.useraccount_tyyesdi_nameen,
      useraccount_weighvehiclesformat_id: selectExistUserAccount.useraccount_weighvehiclesformat_id,
      useraccount_weighvehiclesformat_namees: selectExistUserAccount.useraccount_weighvehiclesformat_namees,
      useraccount_weighvehiclesformat_nameen: selectExistUserAccount.useraccount_weighvehiclesformat_nameen
    }));
    handleCloseSelectUserAccount();
  };

  useEffect(() => {
    if (openSelectUserAccount){
      setSelectExistUserAccount(initialUserAccount);
    } 
  }, [openSelectUserAccount,setOpenSelectUserAccount]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  return(
    <>
      {openSelectUserAccount && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSelectUserAccount(false)}
            onOpen={toggleDrawerSelectUserAccount(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Text_User_Select')}</h1>
                </div>
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createUser" onSubmit={handleSubmitSelectUserAccount}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('Text_User_UserAccounts')} :</label>
                    <div className="form-group">
                      <TextField
                          id="useraccount_id"
                          name="useraccount_id"
                          value={ (selectExistUserAccount.useraccount_id === undefined || selectExistUserAccount.useraccount_id ===null || selectExistUserAccount.useraccount_id ===""
                                || selectExistUserAccount.useraccount_id ==="0" || selectExistUserAccount.useraccount_id ===0)?0:selectExistUserAccount.useraccount_id}
                          inputProps={{ style: {width: 405}, maxLength: 100 }}
                          variant="outlined"
                          onBlur={handleBlurSelectUserAccount}
                          onChange={handleChangeSelectUserAccount}
                          select
                          SelectProps={{
                            native: true,
                            value: (selectExistUserAccount.useraccount_id === undefined || selectExistUserAccount.useraccount_id ===null || selectExistUserAccount.useraccount_id ===""
                                 || selectExistUserAccount.useraccount_id ==="0" || selectExistUserAccount.useraccount_id ===0)?0: selectExistUserAccount.useraccount_id
                          }}>
                          <option key={0} value=""/>
                          {userAccountToSelect.map((option) => (
                          <option key={option.useraccount_id} value={option.useraccount_id}>
                           {option.useraccount_username} - {option.useraccount_aliasuser} - {option.useraccount_email}
                          </option>
                          ))}
                        </TextField>
                    </div>
                    
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitSelectUserAccount}>{t('Button_Select')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseSelectUserAccount}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

EmployeeSelectUserAccount.propTypes = {
  openSelectUserAccount: PropTypes.bool.isRequired,
  setOpenSelectUserAccount: PropTypes.func.isRequired,
  setFormEmployee: PropTypes.func.isRequired 
};

export default EmployeeSelectUserAccount;