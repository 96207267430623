import React, {  useEffect, useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import ColorPicker from 'react-pick-color';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material'; 
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux"; 
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSorting from "./SortingTables";
import PropTypes from 'prop-types';

const initialFilters= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
  "codeHexFrom":"",
  "codeHexTo":"",
  "codeCotGroupFrom":"",
	"codeCotGroupTo":"",
  "code_iso2From":"",
  "code_iso2To":"",
  "code_iso3From":"",
  "code_iso3To":"",
  "extphoneFrom":"",
  "extphoneTo":"",
  "extinternetFrom":"",
  "extinternetTo":"",
  "isEnabled":true,
  "isNotEnabled":true
};

const initialState = [{
  "id" : "",
  "namees" : "",
  "nameen" : "",
  "codehex" : "#FFFFFF",
}];

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'namees',
    numeric: false,
    disablePadding: false,
    label: 'Text_NameEs',
    canOrder:true,
  },
  {
    id: 'nameen',
    numeric: false,
    disablePadding: false,
    label: 'Text_NameEn',
    canOrder:true,
  },
  {
    id: 'codehex',
    numeric: false,
    disablePadding: false,
    label: 'Text_CodeHex',
    canOrder:true,
  },
  {
    id: 'color',
    numeric: false,
    disablePadding: true,
    label: 'Text_Color',
    canOrder:false,
  }
];

const ColorSettings = ({ 
  showModalColorSettings, 
  setShowModalColorSettings,
  setIndexShowSettings, 
  setShowModalGeneralSettings, 
  setShowModalEmployeeSettings, 
  isEmployee, 
  setShowModalStoreSettings, 
  isStore 
}) => {

  const {t} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DELETE_CODE = useSelector((state) => state.variablesUser.DELETE_CODE); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const NON_ERASABLE_COLOR_ID = useSelector((state) => state.variablesUser.NON_ERASABLE_COLOR_ID); 
  const [data, setData] = useState(initialState);
  const [form, setForm] = useState(initialState[0]);
  const [filters, setFilters] = useState(initialFilters);
  const [applyFilters, setApplyFilters] = useState(initialFilters);
  const [numTotReg, setNumTotReg] = useState(0);
  const [numTotRegFilter, setNumTotRegFilter] = useState(0);
  const [errorsFilters, setErrorsFilters] = useState({});
  const [errors, setErrors] = useState({});
  const [inputText, setInputText] = useState("");
  const [openCreateData, setOpenCreateData] = useState(false);
  const [openDeleteRow, setOpenDeleteRow] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [openEditFilter, setOpenEditFilter] = useState(false);
  const [rowEdit, setRowEdit] = useState(initialState[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [idCodeDelete, setIdCodeDelete] = useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset] = useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(0);
  const [rangeOffset, setRangeOffset] = useState([{}]); 
  const [openCreateProvince,setOpenCreateProvince]=useState(false);  
 
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showModalColorSettings) {
        setData(initialState);
        setForm(initialState[0]);
        setFilters(initialFilters);
        setApplyFilters(initialFilters);
        setNumTotReg(0);
        setNumTotRegFilter(0);
        setErrorsFilters({});
        setErrors({});
        setInputText("");
        setOpenCreateData(false);
        setOpenDeleteRow(false);
        setOpenEditRow(false);
        setOpenEditFilter(false);
        setRowEdit(initialState[0]);
        setIsLoading(false);
        setIsLoadingError(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffset(false);
        setCurrencyOffset(0);
        setRangeOffset([{}]);
        setOpenCreateProvince(openCreateProvince);
        setShowModalColorSettings(false);
        setIndexShowSettings(0);
        setShowModalGeneralSettings(true);
        setShowModalEmployeeSettings(isEmployee);
        setShowModalStoreSettings(isStore);
      }
    },
    [
      setData,
      setForm,
      setFilters,
      setApplyFilters,
      setNumTotReg,
      setNumTotRegFilter,
      setErrorsFilters,
      setErrors,
      setInputText,
      setOpenCreateData,
      setOpenDeleteRow,
      setOpenEditRow,
      setOpenEditFilter,
      setRowEdit,
      setIsLoading,
      setIsLoadingError,
      setIdCodeDelete,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      openCreateProvince,
      setOpenCreateProvince,
      showModalColorSettings,
      setShowModalColorSettings,
      setIndexShowSettings,
      setShowModalGeneralSettings,
      setShowModalEmployeeSettings,
      isEmployee,
      setShowModalStoreSettings,
      isStore ,
      DELETE_CODE
    ]
  );

  const closeModalColorSettings = () => {    
    setData(initialState);
    setForm(initialState[0]);
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setNumTotReg(0);
    setNumTotRegFilter(0);
    setErrorsFilters({});
    setErrors({});
    setInputText("");
    setOpenCreateData(false);
    setOpenDeleteRow(false);
    setOpenEditRow(false);
    setOpenEditFilter(false);
    setRowEdit(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffset(false);
    setCurrencyOffset(0);
    setRangeOffset([{}]);
    setOpenCreateProvince(false);
    setShowModalColorSettings(false);
    setIndexShowSettings(0);
    setShowModalGeneralSettings(true);
    setShowModalEmployeeSettings(isEmployee);
    setShowModalStoreSettings(isStore);
  };

  const openCreateOrEditData = () => {
    setInputText("");
    setOpenCreateData(true);
  };

  const openEditFilters = () => {
    setFilters(applyFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFilters(validateFormFilter());
    if (Object.keys(errorsFilters).length === 0) {
      setApplyFilters(filters);
      setFilters(initialFilters);
      setErrorsFilters({});
      setOpenEditFilter(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleCancelFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFilters(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFilters({});
      setFilters(initialFilters);
      setOpenEditFilter(!openEditFilter);
    }
  };

  const validateFormFilter = () => {

    let errorsFilters = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\*\\(\\)\\+\\-\\=\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;

    if (filters.idFrom!=="" & !regexNumber.test(filters.idFrom)) {
      errorsFilters.idFrom = 'Text_TextNoNumber';
    }
    if (filters.idTo!=="" & !regexNumber.test(filters.idTo)) {
      errorsFilters.idTo = 'Text_TextNoNumber';
    }
     
    if (filters.idFrom!=="" & !errorsFilters.idFrom!==undefined){
      if (filters.idTo!=="" & !errorsFilters.idTo!==undefined){
        if (parseInt(filters.idTo)<parseInt(filters.idFrom)){
          errorsFilters.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (regexInvalidCharacters.test(filters.nameesFrom)) {
      errorsFilters.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameesTo)) {
      errorsFilters.nameesTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameenFrom)) {
      errorsFilters.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameenTo)) {
      errorsFilters.nameenTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.codeFrom)) {
      errorsFilters.codeFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.codeTo)) {
      errorsFilters.codeTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.codeHexFrom)) {
      errorsFilters.codeHexFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.codeHexTo)) {
      errorsFilters.codeHexTo = 'codeHexTo';
    } 
    if (filters.nameesTo!=="" & filters.nameesTo<filters.nameesFrom){
      errorsFilters.nameesTo = 'Text_TextGreater_Namees';
    }
    if (filters.nameenTo!=="" & filters.nameenTo<filters.nameenFrom){
      errorsFilters.nameenTo = 'Text_TextGreater_Nameen';
    }
    if (filters.codeTo!=="" & filters.codeTo<filters.codeFrom){
      errorsFilters.codeTo = 'Text_TextGreater_CodeTranslate';
    }

    if (filters.codeHexTo!=="" & filters.codeHexTo<filters.codeHexFrom){
      errorsFilters.codeHexTo = 'Text_TextGreater_CodeHex';
    }
    return errorsFilters;
  }  

  const filteredData = data.filter((el) => {
    if (inputText === '') {
        return el;
    }
    else {
        let dataTranslate=t(el.id+el.namees+el.nameen+el.codehex) ;
        return dataTranslate.toLowerCase().includes(inputText);
    }
  })
  
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRow = () => {
    setErrors({});
    setRowEdit(initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
  
    if (e.key !== 'Escape') {
      if (rowEdit.id !== '') {
        const fetchData = async () => {
          setIsLoading(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `color/deletecolor`, token, rowEdit);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
  
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
    handleCloseDataEdit();
  };
  
  const handleSubmitDataEdit = () => {
    setErrors(validateForm());
    const errorsToCheck = validateForm();
  
    if (Object.keys(errorsToCheck).length === 0 && form.namees !== '' && form.nameen !== '' && form.codehex !== '') {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const getData = openEditRow
            ? await helpHttp().put(ENDPOINT, `color/updatecolor`, token, form)
            : await helpHttp().post(ENDPOINT, `color/savecolor`, token, form);
  
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEdit();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      if (errorsToCheck.namees) {
        dispatch(warningErrorAction(t(errorsToCheck.namees)));
      } else if (errorsToCheck.nameen) {
        dispatch(warningErrorAction(t(errorsToCheck.nameen)));
      } else if (errorsToCheck.codehex) {
        dispatch(warningErrorAction(t(errorsToCheck.codehex)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };
  
  const handleCloseDataEdit = () => {
    setOpenCreateData(false);
    setOpenEditRow(false);
    setErrors({});
    setRowEdit(openEditRow?rowEdit:initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    toggleDrawerDataEdit(false);
	};

  const handleChangeColor = (e) => { 
    setForm(form => ({
      ...form,
      codehex: e.hex
    }));
    
  };

  const handleBlurDataEditColor = (e) => {
    handleChangeColor(e);
    setErrors(validateForm());
  };
  
  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value,
      codehex:form.codehex
    }));
  };

  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrors(validateForm());
  };
  
  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const validateForm = () => {
    let errors = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\*\\(\\)\\+\\-\\=\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;

    if (!form.namees || form.namees==="") {
      errors.namees = 'Text_FieldNameesRequired';
    } else {
      if (regexInvalidCharacters.test(form.namees)) {
        errors.namees = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.namees.toLowerCase()===form.namees.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.namees = 'Text_Color_NameesExist';
          }
        } else {
          if (data.find((key=>key.namees.toLowerCase()===form.namees.toLowerCase()))) {
            errors.namees = 'Text_Color_NameesExist';
          }
        }   
      }  
    }
    if (!form.nameen || form.nameen==="") {
      errors.nameen = 'Text_FieldNameenRequired';
    } else {
      if (regexInvalidCharacters.test(form.nameen)) {
        errors.nameen = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.nameen.toLowerCase()===form.nameen.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.nameen = 'Text_Color_NameenExist';
          }
        } else {
          if (data.find((key=>key.nameen.toLowerCase()===form.nameen.toLowerCase()))) {
            errors.nameen = 'Text_Color_NameenExist';
          }
        }  
      }     
    }
    if (!form.codehex || form.codehex==="#000000" || form.codehex==="#" || form.codehex==="#undefined") {
      errors.codehex = 'Text_FieldCodeHexRequired';
    } else {
      if (regexInvalidCharacters.test(form.codehex)) {
        errors.codehex = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.codehex.toLowerCase()===form.codehex.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.codehex = 'Text_Color_CodehexExist';
          }
        } else {  
          if (data.find((key=>key.codehex.toLowerCase()===form.codehex.toLowerCase()))) {
            errors.codehex = 'Text_Color_CodehexExist';
          }
        }
      }
    }    
    return errors;
  };

  useEffect(() => {
    setForm(openEditRow ? rowEdit : initialState[0]);
  }, [rowEdit, openEditRow]);
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (numTotRegFilter > LIMITPAGESIZE) {
      setActiveOffset(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(newRangeOffset);
    } else {
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);

  useEffect(() => {
    let ignore = false;
    
    const fetchnumTotReg = async () => {
      setIsLoading(true);
      const getData = await helpHttp().get(ENDPOINT, `color/getinformationtablecolor`, token);
      
      if (getData.err || getData.message === "Load failed") {
        setData(initialState);
        dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
      } else if (!ignore) {
        setNumTotReg(getData);
      }
      setIsLoading(false);
    };
  
    const fetchnumTotRegFilter = async () => {
      setIsLoading(true);
      const getData = await helpHttp().post(ENDPOINT, `color/getcountfiltercolors`, token, applyFilters);
      
      if (getData.err || getData.message === "Load failed") {
        setData(initialState);
        dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
      } else if (!ignore) {
        setNumTotRegFilter(getData);
      }
      setIsLoading(false);
    };
  
    const fetchData = async () => {
      setIsLoading(true);
      const getData = await helpHttp().get3(ENDPOINT, `color/colors`, token, currencyOffset, LIMITPAGESIZE, applyFilters);
      
      if (getData.err || getData.message === "Load failed") {
        setData(initialState);
        dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
      } else if (!ignore) {
        setData(getData);
      }
      setIsLoading(false);
    };
  
    if (showModalColorSettings) {
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true; };
    }
  }, [ENDPOINT, t, token, dispatch, openCreateData, openDeleteRow, openEditRow, rowEdit, showModalColorSettings, LIMITPAGESIZE, applyFilters, currencyOffset]);

  return (
    <div> 
      {showModalColorSettings && ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className="h1NewStyle mb-2">
            {isLoadingError?
            <i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>
            :isLoading?
            <CircularProgress color={openDeleteRow?"warning":"primary"}/>:
              <i className="fas fa-duotone fa-palette text-warning"></i>} {t('Text_Color_Config')}</h1>
            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Color_Create'))?
              <div>
                <button type="button" className="buttonCreate" onClick={openCreateOrEditData}>{t('Button_Create')}</button><> </>
                { isEmployee ?
                  <button type="button" className="buttonBack" onClick={closeModalColorSettings}>{t('Button_Exit')}</button>:
                  <button type="button" className="buttonBack" onClick={closeModalColorSettings}>{t('Button_Back')}</button>
                }

              </div>:
              <div>
                 { isEmployee ?
                  <button type="button" className="buttonBack" onClick={closeModalColorSettings}>{t('Button_Exit')}</button>:
                  <button type="button" className="buttonBack" onClick={closeModalColorSettings}>{t('Button_Back')}</button>
                }
              </div>
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputText}
              placeholder={t('Text_Color_Search')} 
              onChange={inputHandler}
              fullWidth
              label={t('Text_Color_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.idFrom!=="" || applyFilters.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.nameesFrom!=="" || applyFilters.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NameEs')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.nameenFrom!=="" || applyFilters.nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NameEn')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.codeHexFrom!=="" || applyFilters.codeHexTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_CodeHex')}</button><> </>
                </h1>
          </div>
          <br/>
          <div className="card shadow mb-4">    
            <div className="card-body">
              <TableSorting
                data={filteredData}
                headCells={headCells}
                numTotRegFilter={numTotRegFilter}
                titleTable={t('Text_Color_Table')}
                setOpenDeleteRow={setOpenDeleteRow}
                setOpenEditRow={setOpenEditRow}
                setRowEdit={setRowEdit}
                showEditButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Color_Edit'))?true:false}
                showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Color_Delete'))?true:false}
                typeTable={5}
                setOpenCreateProvince={setOpenCreateProvince}
                showVisibilityButton={false}
                numTotReg={numTotReg}
                activeOffset={activeOffset}
                rangeOffset={rangeOffset}
                currencyOffset={currencyOffset}
                setCurrencyOffset={setCurrencyOffset}
                minvalue={Number(NON_ERASABLE_COLOR_ID)}>
              </TableSorting>
            </div>             
          </div>
          {openEditFilter &&
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerFilterEdit(false)}
                onOpen={toggleDrawerFilterEdit(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createfilter" onSubmit={handleCancelFilters}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary">{t('Text_Filter_Code_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idFrom" 
                            name="idFrom"
                            value={filters.idFrom}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFilters.idFrom!=null?t(errorsFilters.idFrom):t('PlaceHolder_Filter_Code_From')}
                            placeholder={t('PlaceHolder_Filter_Code_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Code_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idTo" 
                            name="idTo"
                            value={filters.idTo}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFilters.idTo!=null?t(errorsFilters.idTo):t('PlaceHolder_Filter_Code_to')}
                            placeholder={t('PlaceHolder_Filter_Code_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_NameEs_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesFrom" 
                            name="nameesFrom"
                            value={filters.nameesFrom}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameesFrom!=null?t(errorsFilters.nameesFrom):t('PlaceHolder_Filter_NameEs_From')}
                            placeholder={t('PlaceHolder_Filter_NameEs_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_NameEs_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesTo" 
                            name="nameesTo"
                            value={filters.nameesTo}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameesTo!=null?t(errorsFilters.nameesTo):t('PlaceHolder_Filter_NameEs_to')}
                            placeholder={t('PlaceHolder_Filter_NameEs_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_NameEn_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenFrom" 
                            name="nameenFrom"
                            value={filters.nameenFrom}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameenFrom!=null?t(errorsFilters.nameenFrom):t('PlaceHolder_Filter_NameEn_From')}
                            placeholder={t('PlaceHolder_Filter_NameEn_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_NameEn_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenTo" 
                            name="nameenTo"
                            value={filters.nameenTo}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameenTo!=null?t(errorsFilters.nameenTo):t('PlaceHolder_Filter_NameEn_to')}
                            placeholder={t('PlaceHolder_Filter_NameEn_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_CodeHex_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="codeHexFrom" 
                            name="codeHexFrom"
                            value={filters.codeHexFrom}
                            inputProps={{ style: {width: 450}, maxLength: 20 }}
                            label={errorsFilters.codeHexFrom!=null?t(errorsFilters.codeHexFrom):t('PlaceHolder_Filter_CodeHex_From')}
                            placeholder={t('PlaceHolder_Filter_CodeHex_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_CodeHex_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="codeHexTo" 
                            name="codeHexTo"
                            value={filters.codeHexTo}
                            inputProps={{ style: {width: 450}, maxLength: 20 }}
                            label={errorsFilters.codeHexTo!=null?t(errorsFilters.codeHexTo):t('PlaceHolder_Filter_CodeHex_to')}
                            placeholder={t('PlaceHolder_Filter_CodeHex_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }  
          {(openCreateData || openEditRow)&& 
          <div>
            <SwipeableDrawer
              anchor='right'
              open={true}
              onClose={toggleDrawerDataEdit(false)}
              onOpen={toggleDrawerDataEdit(true)}>
              <Container >
                <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                  <br/>
                  <div id="myDIV">
                    <h1 className="h1NewStyle px-4 mb-2">{openEditRow?t('Text_Color_Edit'):t('Text_Color_Create')}</h1>
                  </div>  
                  <hr className="sidebar-divider d-none d-md-block"/>
                  <form className="createcolor" onSubmit={handleSubmitDataEdit}>  
                    <div className="form-floating mb-3 px-4 primary">
                      <label className="text-primary">{t('Text_Code')} :</label>
                      <div className="form-group">
                        <TextField
                          id="code" 
                          name="code"
                          value={form.id}
                          inputProps={{ style: {width: 200} }}
                          label={t('PlaceHolder_Color_Code')}
                          placeholder={t('PlaceHolder_Color_Code')}
                          variant="standard"
                          disabled/>
                      </div>
                      <label className="text-primary">{t('Text_NameEs')} :</label>
                      <div className="form-group">
                        <TextField
                          id="namees" 
                          name="namees"
                          value={form.namees}
                          inputProps={{ style: {width: 450}, maxLength: 100 }}
                          label={errors.namees!=null?t(errors.namees):t('PlaceHolder_Color_NameEs')}
                          placeholder={t('PlaceHolder_Color_NameEs')}
                          variant="standard"
                          onBlur={handleBlurDataEdit}
                          onChange={handleChangeDataEdit}/>
                      </div>
                      <label className="text-primary">{t('Text_NameEn')} :</label>
                      <div className="form-group">
                        <TextField
                          id="nameen" 
                          name="nameen"
                          value={form.nameen}
                          inputProps={{ style: {width: 450}, maxLength: 100 }}
                          label={errors.nameen!=null?t(errors.nameen):t('PlaceHolder_Color_NameEn')}
                          placeholder={t('PlaceHolder_Color_NameEn')}
                          variant="standard"
                          onBlur={handleBlurDataEdit}
                          onChange={handleChangeDataEdit}/>
                      </div>
                      <label className="text-primary">{t('Text_CodeHex')} :</label>
                      <div className="form-group">
                      <ColorPicker
                        color={form.codehex}
                        onBlur={handleBlurDataEditColor}
                        onChange={handleChangeColor}
                        theme={{
                          background: 'white',
                          inputBackground: 'white',
                          borderColor: 'darkgrey',
                          borderRadius: '8px',
                          color: 'black',
                          width: '450px'
                        }}
                      />
                      </div>
                      <hr className="sidebar-divider d-none d-md-block"/>
                      <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                        <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEdit}>{openEditRow?t('Button_Update'):t('Button_Create')}</button><> </>
                        <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEdit}>{t('Button_Cancel')}</button>
                      </div>
                    </div>
                  </form>
                </Box>
              </Container>
            </SwipeableDrawer>
          </div>}
          <Dialog open={openDeleteRow} onClose={handleCancelDeleteRow}>
            <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEdit.id}  - {rowEdit.namees} - {rowEdit.nameen} - {rowEdit.codehex} </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Text_Line1')}<br/>
                {t('Text_Line2')}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="idCode"
                label={t('Text_Label')}
                type="text"
                fullWidth
                variant="standard"
                error
                defaultValue={idCodeDelete}
                onChange={handleChangeDeleteRow}/>
            </DialogContent>
            <DialogActions>
              <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
              <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
            </DialogActions>
          </Dialog>
          {/* End Content Row */}
        </div>
        //* End Page Content *//
      )}
     </div>
  );
};

ColorSettings.propTypes = {
  showModalColorSettings: PropTypes.bool.isRequired,
  setShowModalColorSettings: PropTypes.func.isRequired,
  setIndexShowSettings: PropTypes.func.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired,
  setShowModalEmployeeSettings: PropTypes.func.isRequired,
  isEmployee: PropTypes.bool.isRequired,
  setShowModalStoreSettings: PropTypes.func.isRequired,
  isStore: PropTypes.bool.isRequired,
};

export default ColorSettings;