import React, {  useEffect, useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { SwipeableDrawer } from '@mui/material';
import { Checkbox} from  '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux";   
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingPostalCode from './SortingTablesPostalCode';
import PropTypes from 'prop-types';

const initialFiltersPostalCode= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
  "codeHexFrom":"",
  "codeHexTo":"",
  "codeCotGroupFrom":"",
	"codeCotGroupTo":"",
  "code_iso2From":"",
  "code_iso2To":"",
  "code_iso3From":"",
  "code_iso3To":"",
  "extphoneFrom":"",
  "extphoneTo":"",
  "extinternetFrom":"",
  "extinternetTo":"",
  "isEnabled":true,
  "isNotEnabled":true
};

const initialStatePostalCode = [{
  "id" : "",
  "name" : "",
  "postalcode" : "",
  "postalcodename" : "",
  "enabled":false
}];

const headCellsPostalCode = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Text_PostalCode_Name',
    canOrder:true,
  },
  {
    id: 'postalcode',
    numeric: false,
    disablePadding: false,
    label: 'Text_PostalCode_PostalCode',
    canOrder:true,
  },
  {
    id: 'postalcodename',
    numeric: false,
    disablePadding: false,
    label: 'Text_PostalCode_PostalCodeName',
    canOrder:true,
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Text_Enabled',
    canOrder:false,
  }
];
            
const PostalCodeSettings = ({openPostalCode,setOpenPotalCode,rowEditProvince}) => {
  
  const {t} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DELETE_CODE = useSelector((state) => state.variablesUser.DELETE_CODE); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const NON_ERASABLE_POSTALCODE_ID = useSelector((state) => state.variablesUser.NON_ERASABLE_POSTALCODE_ID); 

  const [dataPostalCode, setDataPostalCode] = useState(initialStatePostalCode);
  const [formPostalCode, setFormPostalCode] = useState(initialStatePostalCode[0]);
  const [filtersPostalCode, setFiltersPostalCode]= useState(initialFiltersPostalCode);
  const [applyFiltersPostalCode, setApplyFiltersPostalCode]= useState(initialFiltersPostalCode);
  const [numTotRegPostalCode,setNumTotRegPostalCode]= useState(0);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegPostalCodeByProvince,setNumTotRegPostalCodeByProvince]= useState(0);
  const [errorsFiltersPostalCode, setErrorsFiltersPostalCode] = useState({});
  const [errorsPostalCode, setErrorsPostalCode] = useState({});
  const [inputTextPostalCode, setInputTextPostalCode] = useState("");
  const [openCreateDataPostalCode,setOpenCreateDataPostalCode]= useState(false);
  const [openDeleteRowPostalCode,setOpenDeleteRowPostalCode]= useState(false);
  const [openEditRowPostalCode,setOpenEditRowPostalCode]= useState(false);  
  const [openEditFilterPostalCode,setOpenEditFilterPostalCode]= useState(false);
  const [rowEditPostalCode,setRowEditPostalCode]= useState(initialStatePostalCode[0]);
  const [isLoadingPostalCode, setIsLoadingPostalCode] = useState(true);
  const [isLoadingErrorPostalCode, setIsLoadingErrorPostalCode] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(0);
  const [rangeOffset, setRangeOffset] = useState([{}]);

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && openPostalCode) {
        setDataPostalCode(initialStatePostalCode);
        setFormPostalCode(initialStatePostalCode[0]);
        setFiltersPostalCode(initialFiltersPostalCode);
        setApplyFiltersPostalCode(initialFiltersPostalCode);
        setNumTotRegPostalCode(0);
        setNumTotRegFilter(0);
        setNumTotRegPostalCodeByProvince(0);
        setErrorsFiltersPostalCode({});
        setErrorsPostalCode({});
        setInputTextPostalCode("");
        setOpenCreateDataPostalCode(false);
        setOpenDeleteRowPostalCode(false);
        setOpenEditRowPostalCode(false);
        setOpenEditFilterPostalCode(false);
        setRowEditPostalCode(initialStatePostalCode[0]);
        setIsLoadingPostalCode(false);
        setIsLoadingErrorPostalCode(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffset(false);
        setCurrencyOffset(0);
        setRangeOffset([{}]);
        setOpenPotalCode(false); 
      }
    },
    [
      setDataPostalCode,
      setFormPostalCode,
      setFiltersPostalCode,
      setApplyFiltersPostalCode,
      setNumTotRegPostalCode,
      setNumTotRegFilter,
      setNumTotRegPostalCodeByProvince,
      setErrorsFiltersPostalCode,
      setErrorsPostalCode,
      setInputTextPostalCode,
      setOpenCreateDataPostalCode,
      setOpenDeleteRowPostalCode,
      setOpenEditRowPostalCode,
      setOpenEditFilterPostalCode,
      setRowEditPostalCode,
      setIsLoadingPostalCode,
      setIsLoadingErrorPostalCode,
      setIdCodeDelete,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      openPostalCode,
      setOpenPotalCode,
      DELETE_CODE 
    ]
  );

  const closeModalPostalCodeSettings = () => {
    setDataPostalCode(initialStatePostalCode);
    setFormPostalCode(initialStatePostalCode[0]);
    setFiltersPostalCode(initialFiltersPostalCode);
    setApplyFiltersPostalCode(initialFiltersPostalCode);
    setNumTotRegPostalCode(0);
    setNumTotRegFilter(0);
    setNumTotRegPostalCodeByProvince(0);
    setErrorsFiltersPostalCode({});
    setErrorsPostalCode({});
    setInputTextPostalCode("");
    setOpenCreateDataPostalCode(false);
    setOpenDeleteRowPostalCode(false);
    setOpenEditRowPostalCode(false);
    setOpenEditFilterPostalCode(false);
    setRowEditPostalCode(initialStatePostalCode[0]);
    setIsLoadingPostalCode(false);
    setIsLoadingErrorPostalCode(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffset(false);
    setCurrencyOffset(0);
    setRangeOffset([{}]);
    setOpenPotalCode(false); 
  };

  const openCreateOrEditDataPostalCode = () => {
    setInputTextPostalCode("");
    setOpenCreateDataPostalCode(true);
  };

  const openEditFiltersPostalCode = () => {
    setFiltersPostalCode(applyFiltersPostalCode);
    setOpenEditFilterPostalCode(!openEditFilterPostalCode);
  };

  const handleSubmitFilterEditPostalCode = () => {
    setErrorsFiltersPostalCode(validateFormFilterPostalCode());
    if (Object.keys(errorsFiltersPostalCode).length === 0) {
      setApplyFiltersPostalCode(filtersPostalCode);
      setFiltersPostalCode(initialFiltersPostalCode);
      setErrorsFiltersPostalCode({});
      setOpenEditFilterPostalCode(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersPostalCode = () => {
    setErrorsFiltersPostalCode({});
    setFiltersPostalCode(initialFiltersPostalCode);
    setApplyFiltersPostalCode(initialFiltersPostalCode);
    setOpenEditFilterPostalCode(!openEditFilterPostalCode);
  };

  const handleCancelFiltersPostalCode = () => {
    setErrorsFiltersPostalCode({});
    setFiltersPostalCode(initialFiltersPostalCode);
    setOpenEditFilterPostalCode(!openEditFilterPostalCode);
  };

  const handleChangeEnabledFilterPostalCode = (e) => {
    setFiltersPostalCode(filtersPostalCode => ({
      ...filtersPostalCode,
      isEnabled: !filtersPostalCode.isEnabled
    }));
  };

  const handleChangeNotEnabledFilterPostalCode = (e) => {
    setFiltersPostalCode(filtersPostalCode => ({
      ...filtersPostalCode,
      isNotEnabled: !filtersPostalCode.isNotEnabled
    }));
  };

  const handleChangeFilterEditPostalCode = (e) => {
    const { name, value } = e.target;
    setFiltersPostalCode(filtersPostalCode => ({
      ...filtersPostalCode,
      [name]: value
    }));
  };

  const handleBlurFilterEditPostalCode = (e) => {
    setErrorsFiltersPostalCode(validateFormFilterPostalCode());
    handleChangeFilterEditPostalCode(e);
  };

  const toggleDrawerFilterEditPostalCode = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFiltersPostalCode({});
      setFiltersPostalCode(initialFiltersPostalCode);
      setOpenEditFilterPostalCode(!openEditFilterPostalCode);
    }
  };
  
  const validateFormFilterPostalCode = () => {

    let errorsFiltersPostalCode = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\*\\=\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;

    if (filtersPostalCode.idFrom!=="" & !regexNumber.test(filtersPostalCode.idFrom)) {
      errorsFiltersPostalCode.idFrom = 'Text_TextNoNumber';
    }
    if (filtersPostalCode.idTo!=="" & !regexNumber.test(filtersPostalCode.idTo)) {
      errorsFiltersPostalCode.idTo = 'Text_TextNoNumber';
    }
    if (filtersPostalCode.idFrom!=="" & !errorsFiltersPostalCode.idFrom!==undefined){
      if (filtersPostalCode.idTo!=="" & !errorsFiltersPostalCode.idTo!==undefined){
        if (parseInt(filtersPostalCode.idTo)<parseInt(filtersPostalCode.idFrom)){
          errorsFiltersPostalCode.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (regexInvalidCharacters.test(filtersPostalCode.nameesFrom)) {
      errorsFiltersPostalCode.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPostalCode.nameesTo)) {
      errorsFiltersPostalCode.nameesTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPostalCode.nameenFrom)) {
      errorsFiltersPostalCode.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPostalCode.nameenTo)) {
      errorsFiltersPostalCode.nameenTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPostalCode.codeFrom)) {
      errorsFiltersPostalCode.codeFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPostalCode.codeTo)) {
      errorsFiltersPostalCode.codeTo = 'Text_TextNoValid';
    } 

    if (filtersPostalCode.nameesTo!=="" & filtersPostalCode.nameesTo<filtersPostalCode.nameesFrom){
      errorsFiltersPostalCode.nameesTo = 'Text_TextGreater_Location';
    }
    if (filtersPostalCode.nameenTo!=="" & filtersPostalCode.nameenTo<filtersPostalCode.nameenFrom){
      errorsFiltersPostalCode.nameenTo = 'Text_TextGreater_Description';
    }
    if (filtersPostalCode.codeTo!=="" & filtersPostalCode.codeTo<filtersPostalCode.codeFrom){
      errorsFiltersPostalCode.codeTo = 'Text_TextGreater_PostalCode';
    }
    return errorsFiltersPostalCode;
  }  

  const filteredDataPostalCode = dataPostalCode.filter((el) => {
    if (inputTextPostalCode === '') {
        return el;
    }
    else {
        let dataTranslate=t(el.id+el.name+el.postalcode+el.postalcodename) ;
        return dataTranslate.toLowerCase().includes(inputTextPostalCode);
    }
  })

  const toggleDrawerPostalCode = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown' ) {
      closeModalPostalCodeSettings();
      return;
    }
  };
  
  let inputHandlerPostalCode = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextPostalCode(lowerCase);
  };

  const handleChangeDeleteRowPostalCode = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowPostalCode = () => {
    setErrorsPostalCode({});
    setRowEditPostalCode(initialStatePostalCode[0]);
    setFormPostalCode(initialStatePostalCode[0]);
    setIsLoadingPostalCode(false);
    setIsLoadingErrorPostalCode(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowPostalCode(false);
  };

  const handleCloseDeleteRowPostalCode = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
  
    if (e.key !== 'Escape') {
      if (rowEditPostalCode.id !== '') {
        const fetchData = async () => {
          setIsLoadingPostalCode(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `postalcode/deletepostalcode`, token, rowEditPostalCode);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoadingPostalCode(false);
          } finally {
            setIsLoadingPostalCode(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
  
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowPostalCode(false);
    handleCloseDataEditPostalCode();
  };  

  const handleSubmitDataEditPostalCode = (e) => {
    setErrorsPostalCode(validateFormPostalCode());
    const errorsToCheckPostalCode = validateFormPostalCode();
  
    if (Object.keys(errorsToCheckPostalCode).length === 0 && formPostalCode.name !== '' && formPostalCode.postalcode !== '' && formPostalCode.postalcodename !== '') {
      const fetchData = async () => {
        setIsLoadingPostalCode(true);
        try {
          const getData = openEditRowPostalCode
            ? await helpHttp().put(ENDPOINT, `postalcode/updatepostalcode`, token, formPostalCode)
            : await helpHttp().post2(ENDPOINT, `postalcode/savepostalcode`, token, rowEditProvince.id, formPostalCode);
  
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEditPostalCode();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingPostalCode(false);
        } finally {
          setIsLoadingPostalCode(false);
        }
      };
      fetchData();
    } else {
      if (errorsToCheckPostalCode.name) {
        dispatch(warningErrorAction(t(errorsToCheckPostalCode.name)));
      } else if (errorsToCheckPostalCode.postalcode) {
        dispatch(warningErrorAction(t(errorsToCheckPostalCode.postalcode)));
      } else if (errorsToCheckPostalCode.postalcodename) {
        dispatch(warningErrorAction(t(errorsToCheckPostalCode.postalcodename)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };  

  const handleCloseDataEditPostalCode = () => {    
    setOpenCreateDataPostalCode(false);
    setOpenEditRowPostalCode(false);
    setErrorsPostalCode({});
    setRowEditPostalCode(openEditRowPostalCode?rowEditPostalCode:initialStatePostalCode[0]);
    setFormPostalCode(initialStatePostalCode[0]);
    setIsLoadingPostalCode(false);
    setIsLoadingErrorPostalCode(false);
    toggleDrawerPostalCode(false);  
	};

  const handleChangeDataEditPostalCode = (e) => {
    const { name, value } = e.target;
    setFormPostalCode(formPostalCode => ({
      ...formPostalCode,
      [name]: value
    }));
  };

  const handleBlurDataEditPostalCode = (e) => {
    handleChangeDataEditPostalCode(e);
    setErrorsPostalCode(validateFormPostalCode());
  };
  
  const handleChangeEnabledtPostalCode = (e) => {
    setFormPostalCode(formPostalCode => ({
      ...formPostalCode,
      enabled: !formPostalCode.enabled
    }));
  };

  const toggleDrawerDataEditPostalCode = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditPostalCode();
    }  
  };

  const validateFormPostalCode = () => {
    let errorsPostalCode = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\*\\=\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;

    if (!formPostalCode.name || formPostalCode.name==="") {
      errorsPostalCode.name = 'Text_FieldPostalCodeLocationRequired';
    } else{
      if (regexInvalidCharacters.test(formPostalCode.name)) {
        errorsPostalCode.name = 'Text_TextNoValid';
      }
    }
    if (regexInvalidCharacters.test(formPostalCode.postalcode)) {
      errorsPostalCode.postalcode = 'Text_TextNoValid';
    } else{
      if (!formPostalCode.postalcode || formPostalCode.postalcode==="") {
        errorsPostalCode.postalcode = 'Text_FieldPostalCodeRequired';
      } else {
        if (openEditRowPostalCode){
          if (dataPostalCode.find((key=>(key.postalcode.toLowerCase()===formPostalCode.postalcode.toLowerCase() && key.id!==rowEditPostalCode.id)))) {
            errorsPostalCode.postalcode = 'Text_PostalCode_PostalCodeExist';
          }
        } else {
          if (dataPostalCode.find((key=>key.postalcode.toLowerCase()===formPostalCode.postalcode.toLowerCase()))) {
            errorsPostalCode.postalcode = 'Text_PostalCode_PostalCodeExist';
          }
        }     
      }  
    }
    if (!formPostalCode.postalcodename || formPostalCode.postalcodename==="") {
      errorsPostalCode.postalcodename = 'Text_FieldPostalCodeNameRequired';
    } else {
      if (regexInvalidCharacters.test(formPostalCode.postalcodename)) {
        errorsPostalCode.postalcodename = 'Text_TextNoValid';
      } else{
        if (openEditRowPostalCode){
          if (dataPostalCode.find((key=>(key.postalcodename.toLowerCase()===formPostalCode.postalcodename.toLowerCase() && key.id!==rowEditPostalCode.id)))) {
            errorsPostalCode.postalcodename = 'Text_PostalCode_PostalCodeNameExist';
          }
        } else {
          if (dataPostalCode.find((key=>key.postalcodename.toLowerCase()===formPostalCode.postalcodename.toLowerCase()))) {
            errorsPostalCode.postalcodename = 'Text_PostalCode_PostalCodeNameExist';
          }
        }    
      }  
    }
    return errorsPostalCode;
  };

  useEffect(() => {
    setFormPostalCode(openEditRowPostalCode?rowEditPostalCode:initialStatePostalCode[0]);
  },[rowEditPostalCode,openEditRowPostalCode]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (numTotRegFilter > LIMITPAGESIZE) {
      setActiveOffset(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(newRangeOffset);
    } else {
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);
  
  useEffect(() => {
    let ignore = false;
  
    if (rowEditProvince === undefined || rowEditProvince.id === undefined) return;
  
    const fetchnumTotReg = async () => {
      setIsLoadingPostalCode(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `postalcode/getinformationtablepostalcode`, token);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingErrorPostalCode(true);
          setDataPostalCode(initialStatePostalCode);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegPostalCode(getData);
        }
      } catch (error) {
        setDataPostalCode(initialStatePostalCode);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingPostalCode(false);
      } finally {
        setIsLoadingPostalCode(false);
      }
    };
  
    const fetchnumTotRegFilter = async () => {
      setIsLoadingPostalCode(true);
      try {
        const getData = await helpHttp().post2(ENDPOINT, `postalcode/getcountfilterpostalcode`, token, rowEditProvince.id, applyFiltersPostalCode);
        if (getData.err || getData.message === "Load failed") {
          setDataPostalCode(initialStatePostalCode);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        setDataPostalCode(initialStatePostalCode);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingPostalCode(false);
      } finally {
        setIsLoadingPostalCode(false);
      }
    };
  
    const fetchnumTotRegByCountry = async () => {
      setIsLoadingPostalCode(true);
      try {
        const getData = await helpHttp().get2(ENDPOINT, `postalcode/getinformationtablepostalcodebyprovince/`, token, rowEditProvince.id);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingErrorPostalCode(true);
          setDataPostalCode(initialStatePostalCode);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegPostalCodeByProvince(getData);
        }
      } catch (error) {
        setDataPostalCode(initialStatePostalCode);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingPostalCode(false);
      } finally {
        setIsLoadingPostalCode(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoadingPostalCode(true);
      try {
        const getData = await helpHttp().get4(ENDPOINT, `postalcode/searchpostalcodesprovinces`, token, rowEditProvince.id, currencyOffset, LIMITPAGESIZE, applyFiltersPostalCode);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingErrorPostalCode(true);
          setDataPostalCode(initialStatePostalCode);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setDataPostalCode(getData);
        }
      } catch (error) {
        setDataPostalCode(initialStatePostalCode);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingPostalCode(false);
      } finally {
        setIsLoadingPostalCode(false);
      }
    };
  
    if (openPostalCode) {
      fetchnumTotReg();
      fetchnumTotRegByCountry();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true };
    }
  }, [ENDPOINT, t, token, dispatch, openCreateDataPostalCode, openDeleteRowPostalCode, openEditRowPostalCode, rowEditPostalCode, rowEditProvince, openPostalCode, LIMITPAGESIZE, applyFiltersPostalCode, currencyOffset]);
  
  return (
    <div> 
      {openPostalCode && 
       <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerPostalCode(false)}
            onOpen={toggleDrawerPostalCode(true)}
          >
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh',width: '1100px'  }}>
              <br/>
              <div id="myDIV">
                <h1 className="h1NewStyle mb-2">
                {isLoadingErrorPostalCode?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingPostalCode?<CircularProgress color={openDeleteRowPostalCode?"warning":"primary"}/>:<i className="fas fa-solid fa-flag text-warning"></i>} {t('Text_PostalCode_Config')}</h1>
                <div>
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_PostalCode_Create'))?
                <div>
                  <button type="button" className="buttonCreate" onClick={openCreateOrEditDataPostalCode}>{t('Button_Create')}</button><> </>
                  <button type="button" className="buttonBack" onClick={closeModalPostalCodeSettings}>{t('Button_Back')}</button>
                </div>:
                <div>
                  <button type="button" className="buttonBack" onClick={closeModalPostalCodeSettings}>{t('Button_Back')}</button>
                </div>}
                
                </div>
              </div>
              <div id="myDIV">  
                <h1 className="h1NewStyle mb-2">
                  {rowEditProvince.name}
                </h1>  
              </div>  
              <br/>
              <div className="searchStyle">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={inputTextPostalCode}
                  placeholder={t('Text_PostalCode_Search')}
                  onChange={inputHandlerPostalCode}
                  fullWidth
                  label={t('Text_PostalCode_Search')}>
                </TextField>
              </div>
              <br/>
              <div id="myDIV">
                <h1 className="h6NewStyle mb-2">
                {t('Text_Filter')}&nbsp;
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPostalCode}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPostalCode.idFrom!=="" || applyFiltersPostalCode.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPostalCode}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPostalCode.nameesFrom!=="" || applyFiltersPostalCode.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_PostalCode_Name')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPostalCode}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPostalCode.codeFrom!=="" || applyFiltersPostalCode.codeTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_PostalCode_PostalCode')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPostalCode}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPostalCode.nameenFrom!=="" || applyFiltersPostalCode.nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_PostalCode_PostalCodeName')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPostalCode}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPostalCode.isEnabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Enabled')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPostalCode}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPostalCode.isNotEnabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Not_Enabled')}</button><> </>
                  </h1>
              </div>
              <br/>
              <hr className="sidebar-divider d-none d-md-block"/>
              <div className="card shadow mb-4">    
              <div className="card-body">
                <TableSortingPostalCode
                  dataPostalCode={filteredDataPostalCode}
                  headCellsPostalCode={headCellsPostalCode}
                  numTotRegFilter={numTotRegFilter}
                  titleTable={t('Text_PostalCode_Table')}
                  setOpenCreateDataPostalCode={setOpenCreateDataPostalCode}
                  setOpenEditRowPostalCode={setOpenEditRowPostalCode}
                  setOpenDeleteRowPostalCode={setOpenDeleteRowPostalCode}
                  setRowEditPostalCode={setRowEditPostalCode}
                  showEditButtonPostalCode={roles[0].listmenus.find((key)=>(key.name==='SubMenu_PostalCode_Edit'))?true:false}
                  showDeleteButtonPostalCode={roles[0].listmenus.find((key)=>(key.name==='SubMenu_PostalCode_Delete'))?true:false}
                  numTotRegPostalCode={numTotRegPostalCode}
                  numTotRegPostalCodeByProvince={numTotRegPostalCodeByProvince}
                  activeOffset={activeOffset}
                  rangeOffset={rangeOffset}
                  currencyOffset={currencyOffset}
                  setCurrencyOffset={setCurrencyOffset}  
                  minvalue={Number(NON_ERASABLE_POSTALCODE_ID)}
                >
                </TableSortingPostalCode>

              </div>             
            </div>
            </Box>
            </Container>
          </SwipeableDrawer>
          {openEditFilterPostalCode &&
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerFilterEditPostalCode(false)}
                onOpen={toggleDrawerFilterEditPostalCode(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createfilter" onSubmit={handleCancelFiltersPostalCode}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary">{t('Text_Filter_Code_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idFrom" 
                            name="idFrom"
                            value={filtersPostalCode.idFrom}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFiltersPostalCode.idFrom!=null?t(errorsFiltersPostalCode.idFrom):t('PlaceHolder_Filter_Code_From')}
                            placeholder={t('PlaceHolder_Filter_Code_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditPostalCode}
                            onChange={handleChangeFilterEditPostalCode}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Code_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idTo" 
                            name="idTo"
                            value={filtersPostalCode.idTo}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFiltersPostalCode.idTo!=null?t(errorsFiltersPostalCode.idTo):t('PlaceHolder_Filter_Code_to')}
                            placeholder={t('PlaceHolder_Filter_Code_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditPostalCode}
                            onChange={handleChangeFilterEditPostalCode}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_Location_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesFrom" 
                            name="nameesFrom"
                            value={filtersPostalCode.nameesFrom}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFiltersPostalCode.nameesFrom!=null?t(errorsFiltersPostalCode.nameesFrom):t('PlaceHolder_Filter_Location_From')}
                            placeholder={t('PlaceHolder_Filter_Location_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditPostalCode}
                            onChange={handleChangeFilterEditPostalCode}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Location_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesTo" 
                            name="nameesTo"
                            value={filtersPostalCode.nameesTo}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFiltersPostalCode.nameesTo!=null?t(errorsFiltersPostalCode.nameesTo):t('PlaceHolder_Filter_Location_to')}
                            placeholder={t('PlaceHolder_Filter_Location_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditPostalCode}
                            onChange={handleChangeFilterEditPostalCode}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_PostalCode_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="codeFrom" 
                            name="codeFrom"
                            value={filtersPostalCode.codeFrom}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFiltersPostalCode.codeFrom!=null?t(errorsFiltersPostalCode.codeFrom):t('PlaceHolder_Filter_PostalCode_From')}
                            placeholder={t('PlaceHolder_Filter_PostalCode_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditPostalCode}
                            onChange={handleChangeFilterEditPostalCode}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_PostalCode_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="codeTo" 
                            name="codeTo"
                            value={filtersPostalCode.codeTo}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFiltersPostalCode.codeTo!=null?t(errorsFiltersPostalCode.codeTo):t('PlaceHolder_Filter_PostalCode_to')}
                            placeholder={t('PlaceHolder_Filter_PostalCode_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditPostalCode}
                            onChange={handleChangeFilterEditPostalCode}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_Description_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenFrom" 
                            name="nameenFrom"
                            value={filtersPostalCode.nameenFrom}
                            inputProps={{ style: {width: 450}, maxLength: 150 }}
                            label={errorsFiltersPostalCode.nameenFrom!=null?t(errorsFiltersPostalCode.nameenFrom):t('PlaceHolder_Filter_Description_From')}
                            placeholder={t('PlaceHolder_Filter_Description_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditPostalCode}
                            onChange={handleChangeFilterEditPostalCode}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Description_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenTo" 
                            name="nameenTo"
                            value={filtersPostalCode.nameenTo}
                            inputProps={{ style: {width: 450}, maxLength: 150 }}
                            label={errorsFiltersPostalCode.nameenTo!=null?t(errorsFiltersPostalCode.nameenTo):t('PlaceHolder_Filter_Description_to')}
                            placeholder={t('PlaceHolder_Filter_Description_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditPostalCode}
                            onChange={handleChangeFilterEditPostalCode}/>
                        </div>
                        <label className="text-primary">{t('Text_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="isEnabled" 
                            name="isEnabled"
                            checked={filtersPostalCode.isEnabled}
                            onChange={handleChangeEnabledFilterPostalCode}
                            variant="standard"/>

                        </div> 
                        <label className="text-primary">{t('Text_Not_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="isNotEnabled" 
                            name="isNotEnabled"
                            checked={filtersPostalCode.isNotEnabled}
                            onChange={handleChangeNotEnabledFilterPostalCode}
                            variant="standard"/>
                        </div> 
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditPostalCode}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersPostalCode}>{t('Button_Cancel_Filters')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }
          {(openCreateDataPostalCode || openEditRowPostalCode) && 
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerDataEditPostalCode(false)}
                onOpen={toggleDrawerDataEditPostalCode(true)}
              >
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{openEditRowPostalCode?t('Text_PostalCode_Edit'):t('Text_PostalCode_Create')}</h1>
                    </div>  
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createpostalcode" onSubmit={handleSubmitDataEditPostalCode}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary">{t('Text_Code')} :</label>
                        <div className="form-group">
                          <TextField
                            id="id" 
                            name="id"
                            value={formPostalCode.id}
                            inputProps={{ style: {width: 200} }}
                            label={t('PlaceHolder_PostalCode_Code')}
                            placeholder={t('PlaceHolder_PostalCode_Code')}
                            variant="standard"
                            disabled/>
                        </div>
                        <label className="text-primary">{t('Text_PostalCode_Name')} :</label>
                        <div className="form-group">
                          <TextField
                            id="name" 
                            name="name"
                            value={formPostalCode.name}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsPostalCode.name!=null?t(errorsPostalCode.name):t('PlaceHolder_PostalCode_Name')}
                            placeholder={t('PlaceHolder_PostalCode_Name')}
                            variant="standard"
                            onBlur={handleBlurDataEditPostalCode}
                            onChange={handleChangeDataEditPostalCode}/>
                        </div>
                        <label className="text-primary">{t('Text_PostalCode_PostalCode')} :</label>
                        <div className="form-group">
                          <TextField
                            id="postalcode" 
                            name="postalcode"
                            value={formPostalCode.postalcode}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsPostalCode.postalcode!=null?t(errorsPostalCode.postalcode):t('PlaceHolder_PostalCode_PostalCode')}
                            placeholder={t('PlaceHolder_PostalCode_PostalCode')}
                            variant="standard"
                            onBlur={handleBlurDataEditPostalCode}
                            onChange={handleChangeDataEditPostalCode}/>
                        </div>
                        <label className="text-primary">{t('Text_PostalCode_PostalCodeName')} :</label>
                        <div className="form-group">
                          <TextField
                            id="postalcodename" 
                            name="postalcodename"
                            value={formPostalCode.postalcodename}
                            inputProps={{ style: {width: 450}, maxLength: 150 }}
                            label={errorsPostalCode.postalcodename!=null?t(errorsPostalCode.postalcodename):t('PlaceHolder_PostalCode_PostalCodeName')}
                            placeholder={t('PlaceHolder_PostalCode_PostalCodeName')}
                            variant="standard"
                            onBlur={handleBlurDataEditPostalCode}
                            onChange={handleChangeDataEditPostalCode}
                          />
                        </div>
                        <label className="text-primary">{t('Text_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="enabled" 
                            name="enabled"
                            checked={formPostalCode.enabled}
                            onChange={handleChangeEnabledtPostalCode}
                            variant="standard"  />
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEditPostalCode}>{openEditRowPostalCode?t('Button_Update'):t('Button_Create')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEditPostalCode}>{t('Button_Cancel')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }
          <Dialog open={openDeleteRowPostalCode} onClose={handleCancelDeleteRowPostalCode}>
            <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditPostalCode.id}  - {rowEditPostalCode.name} </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Text_Line1')}<br/>
                {t('Text_Line2')}
              </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                    label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDelete}
                  onChange={handleChangeDeleteRowPostalCode}/>
            </DialogContent>
            <DialogActions>
              <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowPostalCode}>{t('Button_Cancel')}</Button>
              <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowPostalCode}>{t('Button_Delete')}</Button>
            </DialogActions>
          </Dialog>
        </div>
      } 
    </div>
  )
}

PostalCodeSettings.propTypes = {
  openPostalCode: PropTypes.bool.isRequired,
  setOpenPotalCode: PropTypes.func.isRequired,
  rowEditProvince: PropTypes.object.isRequired
};
  
export default PostalCodeSettings;