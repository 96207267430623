import React, {  useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import { Typography } from '@mui/material';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import ContractSearchStores from "pages/contracts/editOrNewContract/ContractSearchStores"; 
import ContractSearchStoresAddresses from "pages/contracts/editOrNewContract/ContractSearchStoresAddress";
import PropTypes from 'prop-types';


const initialProvincesToSelect = [{
    "id" : "",
    "name" : "",
    "enabled" : true,
    "country_id":1
}];

const initialPostalCodesToSelect = [{
    "id":"",
    "postalcode":"",
    "name":"",
    "postalcodename":"",
    "enabled":true,
    "province_id":""
}];

const ContractCreateOrEditAutDest = ({formContract,setFormContract,showModalContractSettings,setIsLoadingErrorData,errorsForm,setErrorsForm,validateFormContract,documentTypesToSelect,roadTypesToSelect,communityToSelect,countryToSelect,expanded}) => {
    const {t,i18n} = useTranslation(['listDataContracts']);
    const token = useSelector((state) => state.loginUser.token); 
    const roles = useSelector((state) => state.loginUser.roles);  
    const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
    const DEFAULTTYPEOFDOCUMENT = useSelector((state) => state.variablesUser.DEFAULTTYPEOFDOCUMENT); 
    const DEFAULTCOUNTRY = useSelector((state) => state.variablesUser.DEFAULTCOUNTRY); 
    const DEFAULTCOMMUNITY = useSelector((state) => state.variablesUser.DEFAULTCOMMUNITY); 
    const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
    const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false); 
    
    const [provinceToSelect_Aut_Dest,setProvinceToSelect_Aut_Dest]= useState(initialProvincesToSelect);
    const [postalCodeToSelect_Aut_Dest,setPostalCodeToSelect_Aut_Dest]= useState(initialPostalCodesToSelect);
    const dispatch = useDispatch(); 

    const handleChangeSelectDocumentType_Aut_Dest = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            aut_dest_documnettype_id:event.target.value
        }));
    };
    const handleBlurSelectDocumentType_Aut_Dest = (e) => {
        handleChangeSelectDocumentType_Aut_Dest(e);
    };
    
    const handleChangeSelectRoadType_Aut_Dest = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            aut_dest_roadtype_id : event.target.value 
        }));
    };
    const handleBlurSelectRoadType_Aut_Dest = (e) => {
        handleChangeSelectRoadType_Aut_Dest(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectCountry_Aut_Dest = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            aut_dest_country_id: event.target.value,
            aut_dest_province_id:"",
            aut_dest_postalcode_id:""
        })); 
    };
    const handleBlurSelectCountry_Aut_Dest = (e) => {
        handleChangeSelectCountry_Aut_Dest(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectProvince_Aut_Dest = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            aut_dest_province_id :event.target.value,
            aut_dest_postalcode_id:""
        }));
    };
    const handleBlurSelectProvince_Aut_Dest = (e) => {
        handleChangeSelectProvince_Aut_Dest(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectPostalCode_Aut_Dest = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            aut_dest_postalcode_id:event.target.value
        }));
    };
    const handleBlurSelectPostalCode_Aut_Dest = (e) => {
        handleChangeSelectPostalCode_Aut_Dest(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectCommunity_Aut_Dest = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            aut_dest_community_id : event.target.value   
        }));
    };
    const handleBlurSelectCommunity_Aut_Dest = (e) => {
        handleChangeSelectCommunity_Aut_Dest(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeDataEditContract_Aut_Dest = (e) => {
        const { name, value } = e.target;
        setFormContract(formContract => ({
          ...formContract,
          [name]: value,
        }));
    };
    const handleBlurDataEditContract_Aut_Dest = (e) => {
        handleChangeDataEditContract_Aut_Dest(e);
        setErrorsForm(validateFormContract());
    };
    
    const searchFormStore = (event) => {
        setShowModalSearchStores(true);
    };

    const searchFormStoreAddress = (event) => {
        setShowModalSearchStoresAddress(true);
    };

   
    const deleteDateAutDest = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            aut_dest_store_id:0,
            aut_dest_documnettype_id: "",
            aut_dest_documentid:"",
            aut_dest_tradename: "",
            aut_dest_name: "",
            aut_dest_contactname: "",
            aut_dest_nima: "",
            aut_dest_inscription: "",
            aut_dest_opt: "",
            aut_dest_phone: "",
            aut_dest_email: "",
            aut_dest_roadtype_id: 0,
            aut_dest_street: "",
            aut_dest_country_id: Number(DEFAULTCOUNTRY),
            aut_dest_province_id: "",
            aut_dest_postalcode_id: "",
            aut_dest_community_id: Number(DEFAULTCOMMUNITY), 
        }));
        setErrorsForm(validateFormContract());
        setErrorsForm(errorsForm => ({
            ...errorsForm,
            aut_dest_store_id:0,
            aut_dest_documnettype_id: "",
            aut_dest_documentid:"",
            aut_dest_tradename: "",
            aut_dest_name: "",
            aut_dest_contactname: "",
            aut_dest_nima: "",
            aut_dest_inscription: "",
            aut_dest_opt: "",
            aut_dest_phone: "",
            aut_dest_email: "",
            aut_dest_roadtype_id: "",
            aut_dest_street: "",
            aut_dest_country_id: "",
            aut_dest_province_id: "",
            aut_dest_postalcode_id: "",
            aut_dest_community_id: "" 
        }));
    };

    const deleteDataSteetAutDest = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            aut_dest_roadtype_id: 0,
            aut_dest_street: "",
            aut_dest_country_id: Number(DEFAULTCOUNTRY),
            aut_dest_province_id: "",
            aut_dest_postalcode_id: "",
            aut_dest_community_id: Number(DEFAULTCOMMUNITY)
        }));
        setErrorsForm(validateFormContract());
    };
 
    useEffect(() => {
        let isMounted = true;
        const fetchExistNumberDocument = async () => {
            try {
              const getData = await helpHttp().get2(ENDPOINT,`contract/getexiststorebynumberdocument/`,token,formContract.aut_dest_documentid);
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData>0 && getData!==formContract.aut_dest_store_id){
                    fetchStoreNumberDocument();
                }
              }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
 
        const fetchStoreNumberDocument = async () => {
            try {
                const getData = await helpHttp().get5(ENDPOINT,`contract/getstorebynumberdocumentpriority`,token,formContract.aut_dest_documentid,5);
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    if (getData!==undefined && getData!==null && getData!==""){
                       setFormContract(formContract => ({
                            ...formContract,
                            aut_dest_store_id: getData.store_id,
                            aut_dest_documnettype_id: getData.documnettype_id,
                            aut_dest_tradename: getData.tradename,
                            aut_dest_name: getData.name,
                            aut_dest_contactname: getData.contacname,
                            aut_dest_nima: getData.nima_aut_dest,
                            aut_dest_inscription: getData.pygr_aut_dest,
                            aut_dest_opt: getData.opt_aut_dest,
                            aut_dest_phone: getData.phone,
                            aut_dest_email: getData.email,
                            aut_dest_roadtype_id: (getData.roadtype_id===undefined 
                                                    || getData.roadtype_id===null  
                                                    || getData.roadtype_id===""
                                                    || getData.roadtype_id==="0"
                                                    || getData.roadtype_id===0)?
                                                    0:getData.roadtype_id,
                            aut_dest_street: getData.street,
                            aut_dest_country_id: (getData.country_id===undefined 
                                                    || getData.country_id===null  
                                                    || getData.country_id===""
                                                    || getData.country_id==="0"
                                                    || getData.country_id===0)?
                                                    Number(DEFAULTCOUNTRY):getData.country_id,
                            aut_dest_province_id: (getData.province_id===undefined 
                                                    || getData.province_id===null  
                                                    || getData.province_id===""
                                                    || getData.province_id==="0"
                                                    || getData.province_id===0)?
                                                    "":getData.province_id,
                            aut_dest_postalcode_id: (getData.postalcode_id===undefined 
                                                        || getData.postalcode_id===null  
                                                        || getData.postalcode_id===""
                                                        || getData.postalcode_id==="0"
                                                        || getData.postalcode_id===0)?
                                                        "":getData.postalcode_id,
                            aut_dest_community_id: (getData.community_id===undefined 
                                                        || getData.community_id===null  
                                                        || getData.community_id===""
                                                        || getData.community_id==="0"
                                                        || getData.community_id===0)?
                                                        Number(DEFAULTCOMMUNITY):getData.community_id
                        }));
                    }  
                }         
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5'){
            if (formContract.aut_dest_documentid!==""){
                fetchExistNumberDocument();
            }
        }
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,expanded,formContract.aut_dest_documentid,errorsForm.aut_dest_documentid,setIsLoadingErrorData,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,setFormContract,formContract.aut_dest_store_id]);
    
    useEffect(() => {
        let isMounted = true;
        const fetchDataProvince = async () => {
          try {
            const getData = formContract.aut_dest_country_id!==""
                ? await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,formContract.aut_dest_country_id)
                : await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setProvinceToSelect_Aut_Dest(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5'){
            fetchDataProvince();
        }      
        return () => { isMounted = false };     
    }, [ENDPOINT,t,token,dispatch,i18n,showModalContractSettings,formContract.aut_dest_country_id,expanded]);
   
    useEffect(() => {
        let isMounted = true;
        const fetchDataPostalCode = async () => {
          try {
            const getData = formContract.aut_dest_province_id!==""
                ? await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbyprovincebyname/`,token,formContract.aut_dest_province_id)
                : await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,formContract.aut_dest_country_id);
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setPostalCodeToSelect_Aut_Dest(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5'){
            fetchDataPostalCode();
        }      
        return () => { isMounted = false };  
       
    }, [ENDPOINT,t,token,dispatch,i18n,showModalContractSettings,formContract.aut_dest_country_id,formContract.aut_dest_province_id,expanded]);
 
    return(
    (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5') &&
        <>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary">{t('Text_CONTRACT_DocuentType')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="aut_dest_documnettype_id"
                    name="aut_dest_documnettype_id"
                    value={ (formContract.aut_dest_documnettype_id === undefined || formContract.aut_dest_documnettype_id ===null || formContract.aut_dest_documnettype_id ===""
                        || formContract.aut_dest_documnettype_id ==="0" || formContract.aut_dest_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formContract.aut_dest_documnettype_id}
                    inputProps={{ style: {width: 100}, maxLength: 100 }}
                    variant="outlined"
                    onBlur={handleBlurSelectDocumentType_Aut_Dest}
                    onChange={handleChangeSelectDocumentType_Aut_Dest}
                    helperText={errorsForm.aut_dest_documnettype_id!=null && errorsForm.aut_dest_documnettype_id!==""?t(errorsForm.aut_dest_documnettype_id):""}
                    select
                    SelectProps={{
                        native: true,
                        value: (formContract.aut_dest_documnettype_id === undefined || formContract.aut_dest_documnettype_id ===null || formContract.aut_dest_documnettype_id ===""
                            || formContract.aut_dest_documnettype_id ==="0" || formContract.aut_dest_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formContract.aut_dest_documnettype_id
                    }}>
                    <option key={0} value=""/>
                    {documentTypesToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </TextField>  
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary">{t('Text_CONTRACT_CIF')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="aut_dest_documentid" 
                    name="aut_dest_documentid"
                    value={formContract.aut_dest_documentid}
                    inputProps={{ style: {width: i18n.language==='es'?270:305}, maxLength: 100 }}
                    label={errorsForm.aut_dest_documentid!=null && errorsForm.aut_dest_documentid!==""?t(errorsForm.aut_dest_documentid):t('PlaceHolder_CONTRACT_CIF')}
                    placeholder={t('PlaceHolder_CONTRACT_CIF')}
                    variant="standard"
                    onBlur={handleBlurDataEditContract_Aut_Dest}
                    onChange={handleChangeDataEditContract_Aut_Dest} 
                />
                &nbsp;&nbsp;
                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDateAutDest}>
                    <DeleteIcon/>
                </IconButton>
                &nbsp;&nbsp;
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormStore}>
                        <SearchIcon/>
                    </IconButton>
                }
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary">{t('Text_CONTRACT_CONTACT')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="aut_dest_contactname" 
                    name="aut_dest_contactname"
                    value={formContract.aut_dest_contactname}
                    inputProps={{ style: {width: i18n.language==='es'?445:485}, maxLength: 100 }}
                    label={errorsForm.aut_dest_contactname!=null && errorsForm.aut_dest_contactname!==""?t(errorsForm.aut_dest_contactname):t('PlaceHolder_CONTRACT_CONTACT')}
                    placeholder={t('PlaceHolder_CONTRACT_CONTACT')}
                    variant="standard"
                    onBlur={handleBlurDataEditContract_Aut_Dest}
                    onChange={handleChangeDataEditContract_Aut_Dest} 
                />
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group"> 
                    <label className="text-primary">{t('Text_CONTRACT_TRADENAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="aut_dest_tradename" 
                        name="aut_dest_tradename"
                        value={formContract.aut_dest_tradename}
                        inputProps={{ style: {width: i18n.language==='es'?585:595}, maxLength: 100 }}  
                        label={errorsForm.aut_dest_tradename!=null && errorsForm.aut_dest_tradename!==""?t(errorsForm.aut_dest_tradename):t('PlaceHolder_CONTRACT_TRADENAME')}
                        placeholder={t('PlaceHolder_CONTRACT_TRADENAME')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Aut_Dest}
                        onChange={handleChangeDataEditContract_Aut_Dest}  
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary">{t('Text_CONTRACT_NAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_name" 
                        name="aut_dest_name"
                        value={formContract.aut_dest_name}
                        inputProps={{ style: {width: i18n.language==='es'?587:595}, maxLength: 100 }} 
                        label={errorsForm.aut_dest_name!=null && errorsForm.aut_dest_name!==""?t(errorsForm.aut_dest_name):t('PlaceHolder_CONTRACT_NAME')}
                        placeholder={t('PlaceHolder_CONTRACT_NAME')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Aut_Dest}
                        onChange={handleChangeDataEditContract_Aut_Dest} 
                    />
                </div>
            </div> 
            <Typography style={{color:"#067330", fontSize: 16}} >  
                {t('Text_CONTRACT_ADDRESS')}
            </Typography>
            <hr className="sidebar-divider d-none d-md-block"/>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary">{t('Text_CONTRACT_RoadType')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_roadtype"
                        name="aut_dest_roadtype"
                        value={ (formContract.aut_dest_roadtype_id === undefined || formContract.aut_dest_roadtype_id ===null || formContract.aut_dest_roadtype_id ===""
                            || formContract.aut_dest_roadtype_id ==="0" || formContract.aut_dest_roadtype_id ===0)?"":formContract.aut_dest_roadtype_id}
                        inputProps={{ style: {width: 100}, maxLength: 100, }}
                        variant="outlined"
                        onBlur={handleBlurSelectRoadType_Aut_Dest}
                        onChange={handleChangeSelectRoadType_Aut_Dest}
                        helperText={errorsForm.aut_dest_roadtype_id!=null && errorsForm.aut_dest_roadtype_id!==""?t(errorsForm.aut_dest_roadtype_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.aut_dest_roadtype_id === undefined || formContract.aut_dest_roadtype_id ===null || formContract.aut_dest_roadtype_id ===""
                                || formContract.aut_dest_roadtype_id ==="0" || formContract.aut_dest_roadtype_id ===0)?"": formContract.aut_dest_roadtype_id
                        }}>
                        <option key={0} value=""/>
                        {roadTypesToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary">{t('Text_CONTRACT_ADDRESS')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="aut_dest_street" 
                        name="aut_dest_street"
                        value={formContract.aut_dest_street}
                        inputProps={{ style: {width: i18n.language==='es'?500:538}, maxLength: 250 }}
                        label={errorsForm.aut_dest_street!=null && errorsForm.aut_dest_street!==""?t(errorsForm.aut_dest_street):t('PlaceHolder_CONTRACT_ADDRESS')}
                        placeholder={t('PlaceHolder_CONTRACT_ADDRESS')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Aut_Dest}
                        onChange={handleChangeDataEditContract_Aut_Dest} 
                    />
                    &nbsp;&nbsp;
                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataSteetAutDest}>
                        <DeleteIcon/>
                    </IconButton>
                    &nbsp;&nbsp;
                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                        <IconButton  size="small"  style={{
                            color:
                            (formContract.aut_dest_store_id===undefined
                            || formContract.aut_dest_store_id===""
                            || formContract.aut_dest_store_id==="0"
                            || formContract.aut_dest_store_id===0)?"#858796":"#4e73df", fontSize: 12}} 
                            disabled={formContract.aut_dest_store_id===undefined
                                    || formContract.aut_dest_store_id===""
                                    || formContract.aut_dest_store_id==="0"
                                    || formContract.aut_dest_store_id===0 } onClick={searchFormStoreAddress}>
                        <SearchIcon/>
                        </IconButton>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary">{t('Text_CONTRACT_Community')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_community_id"
                        name="aut_dest_community_id"
                        value={ (formContract.aut_dest_community_id === undefined || formContract.aut_dest_community_id ===null || formContract.aut_dest_community_id ===""
                            || formContract.aut_dest_community_id ==="0" || formContract.aut_dest_community_id ===0)?Number(DEFAULTCOMMUNITY):formContract.aut_dest_community_id}
                            inputProps={{ style: {width: 250}, maxLength: 100, }}
                        variant="outlined"
                        onBlur={handleBlurSelectCommunity_Aut_Dest}
                        onChange={handleChangeSelectCommunity_Aut_Dest}
                         helperText={errorsForm.aut_dest_community_id!=null  && errorsForm.aut_dest_community_id!==""?t(errorsForm.aut_dest_community_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.aut_dest_community_id === undefined || formContract.aut_dest_community_id ===null || formContract.aut_dest_community_id ===""
                                || formContract.aut_dest_community_id ==="0" || formContract.aut_dest_community_id ===0)?Number(DEFAULTCOMMUNITY): formContract.aut_dest_community_id
                        }}>
                        {communityToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                </div>
            </div>

            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary">{t('Text_CONTRACT_Country')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="aut_dest_country_id"
                        name="aut_dest_country_id"
                        value={ (formContract.aut_dest_country_id === undefined || formContract.aut_dest_country_id ===null || formContract.aut_dest_country_id ===""
                                || formContract.aut_dest_country_id ==="0" || formContract.aut_dest_country_id ===0)?Number(DEFAULTCOUNTRY):formContract.aut_dest_country_id}
                        inputProps={{ style: {width: i18n.language==='es'?300:292}, maxLength: 100 }}  
                        variant="outlined"
                        onBlur={handleBlurSelectCountry_Aut_Dest}
                        onChange={handleChangeSelectCountry_Aut_Dest}
                          helperText={errorsForm.aut_dest_country_id!=null && errorsForm.aut_dest_country_id!==""?t(errorsForm.aut_dest_country_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.aut_dest_country_id === undefined || formContract.aut_dest_country_id ===null || formContract.aut_dest_country_id ===""
                                || formContract.aut_dest_country_id ==="0" || formContract.aut_dest_country_id ===0)?Number(DEFAULTCOUNTRY): formContract.aut_dest_country_id
                        }}>
                        {countryToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary">{t('Text_CONTRACT_Province')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_province_id"
                        name="aut_dest_province_id"
                        value={ (formContract.aut_dest_province_id === undefined || formContract.aut_dest_province_id ===null || formContract.aut_dest_province_id ===""
                            || formContract.aut_dest_province_id ==="0" || formContract.aut_dest_province_id ===0)?"":formContract.aut_dest_province_id}
                        inputProps={{ style: {width: 300}, maxLength: 100 }}
                        variant="outlined"
                        onBlur={handleBlurSelectProvince_Aut_Dest}
                        onChange={handleChangeSelectProvince_Aut_Dest}
                         helperText={errorsForm.aut_dest_province_id!=null && errorsForm.aut_dest_province_id!==""?t(errorsForm.aut_dest_province_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.aut_dest_province_id === undefined || formContract.aut_dest_province_id ===null || formContract.aut_dest_province_id ===""
                                || formContract.aut_dest_province_id ==="0" || formContract.aut_dest_province_id ===0)?"": formContract.aut_dest_province_id
                        }}>
                        <option key={0} value=""/>
                        {provinceToSelect_Aut_Dest.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary">{t('Text_CONTRACT_PostalCode')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_postalcode_id"
                        name="aut_dest_postalcode_id"
                        value={ (formContract.aut_dest_postalcode_id === undefined || formContract.aut_dest_postalcode_id ===null || formContract.aut_dest_postalcode_id ===""
                            || formContract.aut_dest_postalcode_id ==="0" || formContract.aut_dest_postalcode_id ===0)?"":formContract.aut_dest_postalcode_id}
                        inputProps={{ style: {width: 400}, maxLength: 100 }}
                        variant="outlined"
                        onBlur={handleBlurSelectPostalCode_Aut_Dest}
                        onChange={handleChangeSelectPostalCode_Aut_Dest}
                          helperText={errorsForm.aut_dest_postalcode_id!=null && errorsForm.aut_dest_postalcode_id!==""?t(errorsForm.aut_dest_postalcode_id):""}
                        select
                        SelectProps={{
                        native: true,
                        value: (formContract.aut_dest_postalcode_id === undefined || formContract.aut_dest_postalcode_id ===null || formContract.aut_dest_postalcode_id ===""
                            || formContract.aut_dest_postalcode_id ==="0" || formContract.aut_dest_postalcode_id ===0)?"": formContract.aut_dest_postalcode_id
                        }}>
                        <option key={0} value=""/>
                        {postalCodeToSelect_Aut_Dest.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.postalcodename}
                        </option>
                        ))}
                    </TextField>
                
                </div>
            </div>
          
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary">{t('Text_CONTRACT_Phone')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_phone" 
                        name="aut_dest_phone"
                        value={formContract.aut_dest_phone}
                        inputProps={{ style: {width: 180}, maxLength: 30 }}
                        label={errorsForm.aut_dest_phone!=null && errorsForm.aut_dest_phone!==""?t(errorsForm.aut_dest_phone):t('PlaceHolder_CONTRACT_Phone')}
                        placeholder={t('PlaceHolder_CONTRACT_Phone')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Aut_Dest}
                        onChange={handleChangeDataEditContract_Aut_Dest}
                    />
                    {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                
                    <label className="text-primary">{t('Text_CONTRACT_Email')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_email" 
                        name="aut_dest_email"
                        value={formContract.aut_dest_email}
                        inputProps={{ style: {width: i18n.language==='es'?300:320}, maxLength: 100 }}  
                        label={errorsForm.aut_dest_email!=null && errorsForm.aut_dest_email!==""?t(errorsForm.aut_dest_email):t('PlaceHolder_CONTRACT_Email')}
                        placeholder={t('PlaceHolder_CONTRACT_Email')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Aut_Dest}
                        onChange={handleChangeDataEditContract_Aut_Dest}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary">{t('Text_CONTRACT_GESTOR')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_opt" 
                        name="aut_dest_opt"
                        value={formContract.aut_dest_opt}
                        inputProps={{ style: {width: i18n.language==='es'?350:340}, maxLength: 100 }}  
                        label={errorsForm.aut_dest_opt!=null && errorsForm.aut_dest_opt!==""?t(errorsForm.aut_dest_opt):t('PlaceHolder_CONTRACT_OPT')}
                        placeholder={t('PlaceHolder_CONTRACT_OPT')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Aut_Dest}
                        onChange={handleChangeDataEditContract_Aut_Dest} 
                    />  
                </div>
            </div>   
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary">{t('Text_CONTRACT_NIMA')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="aut_dest_nima" 
                        name="aut_dest_nima"
                        value={formContract.aut_dest_nima}
                        inputProps={{ style: {width: 180}, maxLength: 100 }}
                        label={errorsForm.aut_dest_nima!=null && errorsForm.aut_dest_nima!==""?t(errorsForm.aut_dest_nima):t('PlaceHolder_CONTRACT_NIMA')}
                        placeholder={t('PlaceHolder_CONTRACT_NIMA')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Aut_Dest}
                        onChange={handleChangeDataEditContract_Aut_Dest} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary">{t('Text_CONTRACT_INSCRIPTION')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="aut_dest_inscription" 
                        name="aut_dest_inscription"
                        value={formContract.aut_dest_inscription}
                        inputProps={{ style: {width: 250}, maxLength: 100 }}
                        label={errorsForm.aut_dest_inscription!=null && errorsForm.aut_dest_inscription!==""?t(errorsForm.aut_dest_inscription):t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        placeholder={t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Aut_Dest}
                        onChange={handleChangeDataEditContract_Aut_Dest} 
                    />
                </div>
            </div> 
            <ContractSearchStores
                showModalSearchStores={showModalSearchStores}
                setShowModalSearchStores={setShowModalSearchStores}
                selectStores={"Aut_Dest"}
                setFormContract={setFormContract}
            />
            <ContractSearchStoresAddresses
                showModalSearchStoresAddress={showModalSearchStoresAddress}
                setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
                selectStores={"Aut_Dest"}
                setFormContract={setFormContract}
                titleTable={formContract.aut_dest_tradename}
                store_id={formContract.aut_dest_store_id}
                priority={5}
            />
        </> 
    )
}

ContractCreateOrEditAutDest.propTypes = {
    formContract: PropTypes.object.isRequired,
    setFormContract: PropTypes.func.isRequired,
    showModalContractSettings: PropTypes.bool.isRequired,
    setIsLoadingErrorData: PropTypes.func.isRequired,
    errorsForm: PropTypes.object.isRequired,
    setErrorsForm: PropTypes.func.isRequired,
    validateFormContract: PropTypes.func.isRequired,
    documentTypesToSelect: PropTypes.array.isRequired,
    roadTypesToSelect: PropTypes.array.isRequired,
    communityToSelect: PropTypes.array.isRequired,
    countryToSelect: PropTypes.array.isRequired
  };
  
  export default ContractCreateOrEditAutDest;
