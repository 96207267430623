import React, {  useEffect, useState,useCallback } from 'react'; 
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"  
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { warningErrorAction } from "actions/ErrorActions";
import { TextField } from '@mui/material';  
import { helpHttp } from "service/HelpHttp";   
import PropTypes from 'prop-types'; 
 
const initialButtonsCodeLERToSelect = [{
  "button_id": 0,
  "indexrowbutton":"",
  "button_namees": "",
  "button_nameen": "",
  "button_codeler_id":"",
  "button_codeler_code": "",
  "button_codeler_namees": "",
  "button_codeler_nameen": "" 
}];

const WeighVehiclesCreateButtonCodeLER = ({openEditRowButtonsCodeLERS,setOpenEditRowButtonsCodeLERS,formFrom,setFormForm}) => {
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT);  
  const [newButtonCodeLER,setNewButtonCodeLER]= useState(initialButtonsCodeLERToSelect[0]); 
  const [buttonsToSelect,setButtonsToSelect]= useState(initialButtonsCodeLERToSelect);
  const [selectedButtonIds,setSelectedButtonIds] = useState([]); 
  const dispatch = useDispatch();
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowButtonsCodeLERS) {
        setNewButtonCodeLER(initialButtonsCodeLERToSelect[0]);
        setButtonsToSelect(initialButtonsCodeLERToSelect);
        setSelectedButtonIds([]);
        setOpenEditRowButtonsCodeLERS(false); 
      }
    },[
      setNewButtonCodeLER,  
      setButtonsToSelect,
      setSelectedButtonIds,
      openEditRowButtonsCodeLERS,
      setOpenEditRowButtonsCodeLERS]
  );

  const handleCloseNewCodeLER = () => {
    setNewButtonCodeLER(initialButtonsCodeLERToSelect[0]);
    setButtonsToSelect(initialButtonsCodeLERToSelect);
    setSelectedButtonIds([]);
    setOpenEditRowButtonsCodeLERS(false); 
  };

  const handleChangeNewCodeLER = (e) => {
    const { name, value } = e.target;
    setNewButtonCodeLER(newButtonCodeLER => ({
      ...newButtonCodeLER,
      [name]: value,
    }));   
  };
  
  const handleBlurNewCodeLER = (e) => {
    handleChangeNewCodeLER(e); 
  };
   

  const toggleDrawerNewCodeLER  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewCodeLER();
    }
  };
 
  const handleSubmitNewCodeLER = () => {   
    if (newButtonCodeLER.button_id!==0 ) {
      if (formFrom.codeLERButtonsList.find((item) => {return (Number(item.button_id)=== Number(newButtonCodeLER.button_id) ) })){
        dispatch(warningErrorAction(t('Text_WeighVehiclesFormat_ButtonExist'))); 
      } else{
        let indexToPush=buttonsToSelect.findIndex(((item) => Number(item.button_id) ===  Number(newButtonCodeLER.button_id)));
        if ( indexToPush !== -1 ) {
          let buttonCodeLERToAdd={
            "button_id":buttonsToSelect[indexToPush].button_id ,
            "indexrowbutton":formFrom.codeLERButtonsList.length>0?formFrom.codeLERButtonsList[formFrom.codeLERButtonsList.length-1].indexrowbutton+1:1,  
            "button_namees":buttonsToSelect[indexToPush].button_namees,
            "button_nameen":buttonsToSelect[indexToPush].button_nameen,
            "button_codeler_id":buttonsToSelect[indexToPush].button_codeler_id,
            "button_codeler_code":buttonsToSelect[indexToPush].button_codeler_code,
            "button_codeler_namees":buttonsToSelect[indexToPush].button_codeler_namees,
            "button_codeler_nameen":buttonsToSelect[indexToPush].button_codeler_nameen 
          }
          formFrom.codeLERButtonsList.push(buttonCodeLERToAdd);
          setFormForm(formFrom => ({
            ...formFrom,
          }))
        }
       
        handleCloseNewCodeLER();
      }
    } else {
      dispatch(warningErrorAction(t('Text_WeighVehiclesFormat_Button_Required'))); 
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataButtons = async () => {
      try {
        const getData = i18n.language==="es"
            ?await helpHttp().get(ENDPOINT, `weighvehiclesformat/getweighVehiclesformatbuttonorderbynamees`, token)
            :await helpHttp().get(ENDPOINT, `weighvehiclesformat/getweighVehiclesformatbuttonorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setButtonsToSelect(getData);
          setSelectedButtonIds(formFrom.codeLERButtonsList.map(button => button.button_id));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (openEditRowButtonsCodeLERS ){
      fetchDataButtons(); 
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,openEditRowButtonsCodeLERS,formFrom.codeLERButtonsList]);

  return(
    <>
      {openEditRowButtonsCodeLERS && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewCodeLER(false)}
            onOpen={toggleDrawerNewCodeLER(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '800px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Text_WeighVehiclesButton_Table_SELECT')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewCodeLER}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('Text_WeighVehiclesButton_Table_SELECT')} :</label>
                    <div className="form-group">
                      <TextField
                        id="button_id"
                        name="button_id"
                        value={ (newButtonCodeLER.button_id === undefined || newButtonCodeLER.button_id ===null || newButtonCodeLER.button_id ===""
                              || newButtonCodeLER.button_id ==="0" || newButtonCodeLER.button_id ===0)?0:newButtonCodeLER.button_id}
                        inputProps={{ style: {width: 700}, maxLength: 100 }} variant="outlined"
                        onBlur={handleBlurNewCodeLER}
                        onChange={handleChangeNewCodeLER}
                        select
                        SelectProps={{
                            native: true,
                            value: (newButtonCodeLER.button_id === undefined || newButtonCodeLER.button_id ===null || newButtonCodeLER.button_id ===""
                                || newButtonCodeLER.button_id ==="0" || newButtonCodeLER.button_id ===0)?0: newButtonCodeLER.button_id
                            }}>
                            <option key={0} value=""/>
                            {buttonsToSelect
                              .filter(item => !selectedButtonIds.includes(item.button_id))
                              .map((option) => (
                                <option key={option.button_id} value={option.button_id}>
                                  {i18n.language === 'es'
                                    ? (option.button_namees + " - " + option.button_codeler_code + " - " + option.button_codeler_namees).substring(0, 90)
                                    : (option.button_nameen + " - " + option.button_codeler_code + " - " + option.button_codeler_nameen).substring(0, 90)}
                                </option>
                          ))}
                      </TextField>  
                        
                      
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitNewCodeLER}>{t('Button_Select')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseNewCodeLER}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

WeighVehiclesCreateButtonCodeLER.propTypes = {
  openEditRowButtonsCodeLERS: PropTypes.bool.isRequired,
  setOpenEditRowButtonsCodeLERS: PropTypes.func.isRequired,  
  formFrom: PropTypes.object.isRequired,
  setFormForm: PropTypes.func.isRequired, 
};

export default WeighVehiclesCreateButtonCodeLER;