import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import ReportTemplateContractFirst from 'pages/contracts/generatePDF/ReportTemplateContactFirst'; 
import ReportTemplateContractSecond from 'pages/contracts/generatePDF/ReportTemplateContractSecond'; 

import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';

const initialStateContract = {
  id:0, 
  defaultStore_id:0, 
  useraccount_id:0, 
  defaultformatnumber:0, 
  isFinished:true,
  isScanned:false,
  number_nt:"",
  date_contract:"",
  amount_contract:0, 
  periodicity_contract:"",
  duration_contract:"",
  other_information_contract1:"",
  other_information_contract2:"",
  conditions_contract1:"",
  conditions_contract2:"",
  obligations_contract1:"",
  obligations_contract2:"",

  title_opt_tras_tradename:"",
  title_opt_tras_name:"",
  title_opt_tras_street:"",
  title_opt_tras_nima:"",
  title_opt_tras_inscription:"",
  title2_opt_tras_tradename:"",
  title2_opt_tras_documentid:"",
  title2_opt_tras_street_fiscal:"",
  title2_opt_tras_postalcode_fiscal:"",
  title2_opt_tras_locality_fiscal:"",
  title2_street_fiscal:"",

  opt_tras_documnettype:"",
  opt_tras_documentid:"",
  opt_tras_tradename:"",
  opt_tras_name:"",
  opt_tras_contactname:"",
  opt_tras_nima:"",
  opt_tras_inscription:"",
  opt_tras_opt:"",
  opt_tras_phone:"",
  opt_tras_email:"",
  opt_tras_street:"",
  opt_tras_country:"",
  opt_tras_province:"",
  opt_tras_locality:"",
  opt_tras_postalcode:"",
  opt_tras_community:"",
  opt_tras_roadtype_fiscal:"",
  opt_tras_street_fiscal:"",
  opt_tras_country_fiscal:"",
  opt_tras_province_fiscal:"",
  opt_tras_locality_fiscal:"",
  opt_tras_postalcode_fiscal:"",
  opt_tras_community_fiscal:"",

  ori_tras_documnettype:"",
  ori_tras_documentid:"",
  ori_tras_tradename:"",
  ori_tras_name:"",
  ori_tras_contactname_documentid:"",
  ori_tras_contactname_firstaname:"",
  ori_tras_contactname_lastname:"", 
  ori_tras_contactname_position:"",
  ori_tras_contactname_email:"",
  ori_tras_contactname_phone:"",
  ori_tras_nima:"",
  ori_tras_inscription:"",
  ori_tras_opt:"",
  ori_tras_phone:"",
  ori_tras_email:"",
  ori_tras_street:"",
  ori_tras_country:"",
  ori_tras_province:"",
  ori_tras_locality:"",
  ori_tras_postalcode:"",
  ori_tras_community:"",
  ori_tras_roadtype_fiscal:"",
  ori_tras_street_fiscal:"",
  ori_tras_country_fiscal:"",
  ori_tras_province_fiscal:"",
  ori_tras_locality_fiscal:"",
  ori_tras_postalcode_fiscal:"",
  ori_tras_community_fiscal:"",

  aut_tras_documnettype:"",
  aut_tras_documentid:"",
  aut_tras_tradename:"",
  aut_tras_name:"",
  aut_tras_contactname:"",
  aut_tras_nima:"",
  aut_tras_inscription:"",
  aut_tras_opt:"",
  aut_tras_phone:"",
  aut_tras_email:"",
  aut_tras_street:"",
  aut_tras_country:"",
  aut_tras_province:"",
  aut_tras_locality:"",
  aut_tras_postalcode:"",
  aut_tras_community:"" ,

  dest_tras_documnettype:"",
  dest_tras_documentid:"",
  dest_tras_tradename:"",
  dest_tras_name:"",
  dest_tras_contactname:"",
  dest_tras_contactname_position:"",
  dest_tras_nima:"",
  dest_tras_inscription:"",
  dest_tras_opt:"",
  dest_tras_phone:"",
  dest_tras_email:"",
  dest_tras_street:"",
  dest_tras_country:"",
  dest_tras_province:"",
  dest_tras_locality:"",
  dest_tras_postalcode:"",
  dest_tras_community:"" ,

  aut_dest_documnettype:"",
  aut_dest_documentid:"",
  aut_dest_tradename:"",
  aut_dest_name:"",
  aut_dest_contactname:"",
  aut_dest_nima:"",
  aut_dest_inscription:"",
  aut_dest_opt:"",
  aut_dest_phone:"",
  aut_dest_email:"",
  aut_dest_street:"",
  aut_dest_country:"",
  aut_dest_province:"",
  aut_dest_locality:"",
  aut_dest_postalcode:"",
  aut_dest_community:"" ,

  codelers:[],
  codelers_code:[],
  processtypes:[],
  treatmenttypes:[]
};
 


const ContractPDF = ({ rowEditContract, viewPDF, setViewPDF }) => {
  const { t, i18n } = useTranslation(['listDataForms']);
  const token = useSelector((state) => state.loginUser.token);
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT);
  const [dataForm, setDataForm] = useState(initialStateContract);
  const dispatch = useDispatch();
  
  useEffect(() => {
    let isMounted = true;
    const fetchDataContract = async () => {
     
      try {
        const getData = await helpHttp().get8(ENDPOINT, `contract/getpdfcontractbyid`, token, rowEditContract.id, defaulttypesdi);
        if (getData.err) {
          setDataForm(initialStateContract);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailed' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataForm(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
    if (rowEditContract!==undefined && rowEditContract.id!==undefined  && rowEditContract.id!==null  && rowEditContract.id!=="" && rowEditContract.id!=="0" && rowEditContract.id!==0
      && defaulttypesdi!==undefined  && defaulttypesdi!==null  && defaulttypesdi!=="" && defaulttypesdi!=="0" && defaulttypesdi!==0

    ) {
      fetchDataContract();
    }
    
    return () => {isMounted = false;};
  }, [defaulttypesdi, rowEditContract, ENDPOINT, dispatch, t, token,i18n.language]);  
  
  return (
    <div id="pdf-content" style={{ padding: 10 }}>
      {(viewPDF && dataForm!==undefined && dataForm.id!==undefined  && dataForm.id!==null  && dataForm.id!=="" && dataForm.id!=="0" && dataForm.id!==0 
                && defaulttypesdi!==undefined  &&defaulttypesdi!==null  &&defaulttypesdi!=="" && defaulttypesdi!=="0" && defaulttypesdi!==0
      ) &&
      (defaulttypesdi===1?
        <ReportTemplateContractFirst dataForm={dataForm}  initialStateContract={initialStateContract} setDataForm={setDataForm} setViewPDF={setViewPDF}/>:
        <ReportTemplateContractSecond dataForm={dataForm}  initialStateContract={initialStateContract} setDataForm={setDataForm} setViewPDF={setViewPDF}/>)}
    </div>
  );
};


ContractPDF.propTypes = {
  rowEditContract: PropTypes.object.isRequired,
  viewPDF: PropTypes.bool.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default ContractPDF;
