import React from 'react';
import { useSelector } from "react-redux";  
import { useTranslation } from "react-i18next" 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';   
import { Checkbox } from '@mui/material'; 
import "css/generalSettings.css";   
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types'; 

const ContractShareEdit = ({ openShareContract,setOpenShareContract,formContract,setFormContract,setIsLoadingData,handleCloseDataEditContract,handleConfirmFetchDataEditContract}) => {
    const { t } = useTranslation(['listDataForms']);
    const share_form_handle = useSelector((state) => state.formatDIUser.share_form_handle);
  
    const handleCancelShareRowContract = () => {    
      setFormContract(formContract => ({
        ...formContract,
        opt_tras_shared:false,
        ori_tras_shared:false,
        aut_tras_shared:false,
        dest_tras_shared:false,
        aut_dest_shared:false
      }));
      setOpenShareContract(false);
      setIsLoadingData(false); 
      handleConfirmFetchDataEditContract();
      handleCloseDataEditContract();
    }; 
 
    const handleCloseShareContract = (e) => {
      setOpenShareContract(false);
      setIsLoadingData(false); 
      handleConfirmFetchDataEditContract();
      handleCloseDataEditContract(); 
    };
    
    const handleChangeEnabledDefaultOptions = (e) => {
      const { name, checked } = e.target;
      setFormContract(formContract => ({
        ...formContract,
        [name]: checked,
      }));
  };

    return (
        <Dialog  PaperProps={{
            sx: {
              minWidth: 900 
            }
            }}
          open={openShareContract} onClose={handleCancelShareRowContract}
        >
          <DialogTitle style={{color:"#067330", fontSize: 24}}>{t('Text_Share_Form')}</DialogTitle>
          <DialogContent dividers>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 850 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}} >{t('Text_FORM_Share')}</TableCell>
                    <TableCell style={{color:"#067330", fontSize: 16}} align="left">{t('Text_FORM_Select')}</TableCell>
                    <TableCell style={{color:"#067330", fontSize: 16}} align="left">{t('Text_FORM_CIF')}</TableCell>
                    <TableCell style={{color:"#067330", fontSize: 16}} align="left">{t('Text_FORM_TRADENAME')}</TableCell> 
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_OPT_TRAS')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                          id="opt_tras_shared"
                          name="opt_tras_shared"
                          style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                          checked={formContract.opt_tras_shared} 
                          onChange={handleChangeEnabledDefaultOptions} 
                          disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formContract.opt_tras_documentid}</TableCell>
                    <TableCell align="left">{formContract.opt_tras_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_ORI_TRAS')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="ori_tras_shared"
                        name="ori_tras_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formContract.ori_tras_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formContract.ori_tras_documentid}</TableCell>
                    <TableCell align="left">{formContract.ori_tras_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_ORI_TRAS_EMPR')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="aut_tras_shared"
                        name="aut_tras_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formContract.aut_tras_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formContract.aut_tras_documentid}</TableCell>
                    <TableCell align="left">{formContract.aut_tras_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_DEST_TRAS')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="dest_tras_shared"
                        name="dest_tras_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formContract.dest_tras_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formContract.dest_tras_documentid}</TableCell>
                    <TableCell align="left">{formContract.dest_tras_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_Carrier')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="aut_dest_shared"
                        name="aut_dest_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formContract.aut_dest_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formContract.aut_dest_documentid}</TableCell>
                    <TableCell align="left">{formContract.aut_dest_tradename}</TableCell> 
                  </TableRow> 
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button style={{color:"#067330",fontSize: 16 }} onClick={handleCancelShareRowContract}>{t('Button_Not_Shared')}</Button>
            <Button variant="contained" style={{color:"#ffffff", background:"#067330",fontSize: 16}} onClick={handleCloseShareContract}>{t('Button_Shared')}</Button>
          </DialogActions>
        </Dialog> 
      )
    }
    
    ContractShareEdit.propTypes = {
      openShareContract: PropTypes.bool.isRequired,
      setOpenShareContract: PropTypes.func.isRequired,
      formContract: PropTypes.object.isRequired,
      setFormContract: PropTypes.func.isRequired,
      setIsLoadingData: PropTypes.func.isRequired,
      handleCloseDataEditContract: PropTypes.func.isRequired,
      handleConfirmFetchDataEditContract: PropTypes.func.isRequired
    };
    
    export default ContractShareEdit;