import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the jsPDF autoTable plugin
import PropTypes from 'prop-types';
import Seal from "components/photos/formdi/seal.png";
import Sign from "components/photos/formdi/signature.png";

const ReportTemplateSecond = ({ dataForm, initialStateForm, setDataForm, setViewPDF }) => {
  const { t } = useTranslation(['listDataForms']);
  useEffect(() => {
    const generatePDF = () => {
      const doc = new jsPDF({
        format: 'a4',
        unit: 'pt',
      });
 
      // Title
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text('DOCUMENTO DE IDENTIFICACIÓN', 40, 40);
     
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      doc.text('(Artículo 6 y Anexo III del RD 553/2020, BOE 19/06/2020)', 40, 50);
 
      // Main information in a table
      doc.cell(40, 60, 200, 12, `Nº DI: ${dataForm.numberDI_sheet}`, 1, 'center');
      doc.cell(40, 72, 200, 12, `Nº NT:`, 2, 'center');
      doc.cell(40, 84, 200, 12, `Fecha inicio traslado: ${dataForm.date_sheet}`, 3, 'center');
      doc.cell(40, 96, 200, 12, ``, 4, 'center');

      // OPT TRAS
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 104, 515, 11, 'FD'); // filled red square with black borders
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);

      doc.text('Firma responsable envío', 250, 57);
      doc.addImage(Sign, 'png', 280, 60,70, 40);
      doc.addImage(Seal, 'png', 400, 40,150, 50);

      doc.text('OPERADOR DE TRASLADO', 300, 113, 'center');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`Razón social: ${dataForm.opt_tras_tradename}`, 40, 126, 'left');
      doc.text(`CIF/NIF:  ${dataForm.opt_tras_documentid}`, 415, 126, 'left');
      doc.text(`Nombre del centro:  ${dataForm.opt_tras_name}`, 40, 136, 'left');
      doc.text(`Contacto: ${dataForm.opt_tras_contactname}`, 415, 136, 'left');
      doc.text(`Dirección:  ${dataForm.opt_tras_street} `, 40, 146, 'left');
      doc.text(`CP:  ${dataForm.opt_tras_postalcode}`, 415, 146, 'left');
      doc.text(`Com. Autónoma: ${dataForm.opt_tras_community}`, 40, 156, 'left');
      doc.text(`País: ${dataForm.opt_tras_country}`, 240, 156, 'left');
      doc.text(`Teléfono/FAX: ${dataForm.opt_tras_phone}`, 40, 166, 'left');
      doc.text(`Correo-e: ${dataForm.opt_tras_email}`, 200, 166, 'left');
      doc.text(`NIMA: ${dataForm.opt_tras_nima}`, 415, 166, 'left');
      doc.text(`Nº inscripción Registro de PyGR: ${dataForm.opt_tras_inscription}`, 40, 176, 'left');
      doc.text(`Tipo de Operador: ${dataForm.opt_tras_opt}`, 415, 176, 'left');
      // ORI TRAS
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 180, 515, 11, 'FD'); // filled red square with black borders
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('ORIGEN DE TRASLADO', 300, 189, 'center');
      doc.setFont('helvetica', 'italic');
      doc.setFontSize(8);
      doc.setTextColor('gray');
      doc.text(`Centro productor o poseedor de residuos o de la instalación origen de traslado`, 40, 199, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setTextColor('black');
      doc.text(`Razón social: ${dataForm.ori_tras_tradename}`, 40, 210, 'left');
      doc.text(`CIF/NIF:  ${dataForm.ori_tras_documentid}`, 415, 210, 'left');
      doc.text(`Nombre del centro:  ${dataForm.ori_tras_name}`, 40, 220, 'left');
      doc.text(`Contacto: ${dataForm.ori_tras_contactname}`, 415, 220, 'left');
      doc.text(`Dirección:  ${dataForm.ori_tras_street} `, 40, 230, 'left');
      doc.text(`CP:  ${dataForm.ori_tras_postalcode}`, 415, 230, 'left');
      doc.text(`Com. Autónoma: ${dataForm.ori_tras_community}`, 40, 240, 'left');
      doc.text(`País: ${dataForm.ori_tras_country}`, 240, 240, 'left');
      doc.text(`NIMA: ${dataForm.ori_tras_nima}`, 415, 240, 'left');
      doc.text(`Teléfono/FAX: ${dataForm.ori_tras_phone}`, 40, 250, 'left');
      doc.text(`Correo-e: ${dataForm.ori_tras_email}`, 200, 250, 'left');
      doc.text(`Nº inscripción Registro de PyGR: ${dataForm.ori_tras_inscription}`, 40, 260, 'left');
      doc.text(`Código CNAE: ${dataForm.ori_tras_cnae}`, 40, 270, 'left');
      // AUT TRAS
      doc.setFont('helvetica', 'italic');
      doc.setTextColor('gray');
      doc.line(40, 275, 555, 275, 'F')
      doc.setFontSize(8);
      doc.text(`Empresa autorizada para realizar las operaciones de tratamiento de residuos`, 40, 285, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setTextColor('black');
      doc.setFontSize(10);
      doc.text(`Razón social: ${dataForm.aut_tras_tradename}`, 40, 296, 'left');
      doc.text(`CIF/NIF:  ${dataForm.aut_tras_documentid}`, 415, 296, 'left');
      doc.text(`Nombre del centro:  ${dataForm.aut_tras_name}`, 40, 306, 'left');
      doc.text(`Contacto: ${dataForm.aut_tras_contactname}`, 415, 306, 'left');
      doc.text(`Dirección:  ${dataForm.aut_tras_street} `, 40, 316, 'left');
      doc.text(`CP:  ${dataForm.aut_tras_postalcode}`, 415, 316, 'left');
      doc.text(`Com. Autónoma: ${dataForm.aut_tras_community}`, 40, 326, 'left');
      doc.text(`País: ${dataForm.aut_tras_country}`, 240, 326, 'left');
      doc.text(`NIMA: ${dataForm.aut_tras_nima}`, 415, 326, 'left');
      doc.text(`Teléfono/FAX: ${dataForm.aut_tras_phone}`, 40, 336, 'left');
      doc.text(`Correo-e: ${dataForm.aut_tras_email}`, 200, 336, 'left');
      doc.text(`Nº inscripción Registro de PyGR: ${dataForm.aut_tras_inscription}`, 40, 346, 'left'); 

       // DEST TRAS
       doc.setDrawColor(0, 0, 0);
       doc.setLineWidth(0.5);   
       doc.setFillColor('#DDDDDD');
       doc.setFontSize(10);
       doc.rect(40, 350, 515, 11, 'FD'); // filled red square with black borders
       doc.setFont('helvetica', 'bold');
       doc.text('DESTINO DE TRASLADO', 300, 359, 'center');
       doc.setFont('helvetica', 'italic');
       doc.setFontSize(8);
       doc.setTextColor('gray');
       doc.text(`Instalación de destino`, 40, 369, 'left');
       doc.setFont('helvetica', 'normal');
       doc.setFontSize(10);
       doc.setTextColor('black');
       doc.text(`Razón social: ${dataForm.dest_tras_tradename}`, 40, 380, 'left');
       doc.text(`CIF/NIF:  ${dataForm.dest_tras_documentid}`, 415, 380, 'left');
       doc.text(`Nombre del centro:  ${dataForm.dest_tras_name}`, 40, 390, 'left');
       doc.text(`Contacto: ${dataForm.dest_tras_contactname}`, 415, 390, 'left');
       doc.text(`Dirección:  ${dataForm.dest_tras_street} `, 40, 400, 'left');
       doc.text(`CP:  ${dataForm.dest_tras_postalcode}`, 415, 400, 'left');
       doc.text(`Com. Autónoma: ${dataForm.dest_tras_community}`, 40, 410, 'left');
       doc.text(`País: ${dataForm.dest_tras_country}`, 240, 410, 'left');
       doc.text(`NIMA: ${dataForm.dest_tras_nima}`, 415, 410, 'left');
       doc.text(`Teléfono/FAX: ${dataForm.dest_tras_phone}`, 40, 420, 'left');
       doc.text(`Correo-e: ${dataForm.dest_tras_email}`, 200, 420, 'left');
       doc.text(`Nº inscripción Registro de PyGR: ${dataForm.dest_tras_inscription}`, 40, 430, 'left');
       doc.text(`Código CNAE: ${dataForm.dest_tras_cnae}`, 40, 440, 'left');
      
      // AUT TRAS
      doc.setFont('helvetica', 'italic');
      doc.setTextColor('gray');
      doc.line(40, 445, 555, 445, 'F')
      doc.setFontSize(8);
      doc.text(`Empresa autorizada a realizar el tratamiento del residuo`, 40, 455, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setTextColor('black');
      doc.setFontSize(10);
      doc.text(`Razón social: ${dataForm.aut_dest_tradename}`, 40, 466, 'left');
      doc.text(`CIF/NIF:  ${dataForm.aut_dest_documentid}`, 415, 466, 'left');
      doc.text(`Nombre del centro:  ${dataForm.aut_dest_name}`, 40, 476, 'left');
      doc.text(`Contacto: ${dataForm.aut_dest_contactname}`, 415, 476, 'left');
      doc.text(`Dirección:  ${dataForm.aut_dest_street} `, 40, 486, 'left');
      doc.text(`CP:  ${dataForm.aut_dest_postalcode}`, 415, 486, 'left');
      doc.text(`Com. Autónoma: ${dataForm.aut_dest_community}`, 40, 496, 'left');
      doc.text(`País: ${dataForm.aut_dest_country}`, 240, 496, 'left');
      doc.text(`NIMA: ${dataForm.aut_dest_nima}`, 415, 496, 'left');
      doc.text(`Teléfono/FAX: ${dataForm.aut_dest_phone}`, 40, 506, 'left');
      doc.text(`Correo-e: ${dataForm.aut_dest_email}`, 200, 506, 'left');
      doc.text(`Nº inscripción Registro de PyGR: ${dataForm.aut_dest_inscription}`, 40, 516, 'left'); 

      // CHARAC
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 520, 515, 11, 'FD'); // filled red square with black borders
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('CARACTERÍSTICAS DEL RESIDUO QUE SE TRASLADA', 300, 529, 'center');
      doc.setFont('helvetica', 'normal');
      doc.setTextColor('black');
      doc.setFontSize(8);
      doc.text(`Código LER: ${dataForm.codeLER}`, 40, 540, 'left');
      doc.text(`Descripción: ${dataForm.description}`, 40, 550, 'left');
      doc.text(`Características de peligrosidad: ${dataForm.dangerousness_name}`, 40, 560, 'left');
      doc.text(`Tratamiento: ${dataForm.treatmenttypedestine_code}`, 270, 560, 'left');
      doc.text(`Cantidad: ${dataForm.amount} kg`, 439, 560, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setTextColor('black');
      doc.setFontSize(10);

      // TRANSPORTISTA
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 564, 515, 11, 'FD'); // filled red square with black borders
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('TRANSPORTISTA', 300, 573, 'center');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setTextColor('black');
      doc.text(`Razón social: ${dataForm.rel_trans_tradename}`, 40, 585, 'left');
      doc.text(`CIF/NIF:  ${dataForm.rel_trans_documentid}`, 415, 585, 'left');
      doc.text(`Nombre del centro:  ${dataForm.rel_trans_name}`, 40, 595, 'left');
      doc.text(`Contacto: ${dataForm.rel_trans_contactname}`, 415, 595, 'left');
      doc.text(`Dirección:  ${dataForm.rel_trans_street} `, 40, 605, 'left');
      doc.text(`CP:  ${dataForm.rel_trans_postalcode}`, 415, 605, 'left');
      doc.text(`Com. Autónoma: ${dataForm.rel_trans_community}`, 40, 615, 'left');
      doc.text(`País: ${dataForm.rel_trans_country}`, 240, 615, 'left');
      doc.text(`Vehículo: ${dataForm.rel_trans_licenseplate}`, 415, 615, 'left');
      doc.text(`Teléfono/FAX: ${dataForm.rel_trans_phone}`, 40, 625, 'left');
      doc.text(`Correo-e: ${dataForm.rel_trans_email}`, 200, 625, 'left');
      doc.text(`Nº inscripción Registro de PyGR: ${dataForm.rel_trans_inscription}`, 40, 635, 'left');
     
       // SRAP
       doc.setDrawColor(0, 0, 0);
       doc.setLineWidth(0.5);   
       doc.setFillColor('#DDDDDD');
       doc.rect(40, 639, 515, 11, 'FD'); // filled red square with black borders
       doc.setFont('helvetica', 'bold');
       doc.setFontSize(10);
       doc.text('SRAP (SISTEMA DE RESPONSABILIDAD AMPLIADA DEL PRODUCTOR)', 300, 648, 'center');
       doc.setFont('helvetica', 'normal');
       doc.setTextColor('black');
       doc.setFontSize(10);
       doc.text(`Razón social: ${dataForm.resp_prod_tradename}`, 40, 660, 'left');
       doc.text(`CIF/NIF:  ${dataForm.resp_prod_documentid}`, 415, 660, 'left');
       doc.text(`Nombre del centro:  ${dataForm.resp_prod_name}`, 40, 670, 'left');
       doc.text(`Contacto: ${dataForm.resp_prod_contactname}`, 415, 670, 'left');
       doc.text(`Dirección:  ${dataForm.resp_prod_street} `, 40, 680, 'left');
       doc.text(`CP:  ${dataForm.resp_prod_postalcode}`, 415, 680, 'left');
       doc.text(`Com. Autónoma: ${dataForm.resp_prod_community}`, 40, 690, 'left');
       doc.text(`País: ${dataForm.resp_prod_country}`, 240, 690, 'left');
       doc.text(`NIMA: ${dataForm.resp_prod_nima}`, 415, 690, 'left');
       doc.text(`Teléfono/FAX: ${dataForm.resp_prod_phone}`, 40, 700, 'left');
       doc.text(`Correo-e: ${dataForm.resp_prod_email}`, 200, 700, 'left');
       doc.text(`Nº inscripción Registro de PyGR: ${dataForm.resp_prod_inscription}`, 40, 710, 'left');
      
      // DATA ACCEPT
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.setFontSize(10);
      doc.rect(40, 714, 515, 11, 'FD'); // filled red square with black borders
      doc.setFont('helvetica', 'bold');
      doc.text('DATOS DE ACEPTACIÓN O RECHAZO', 300, 723, 'center');
      doc.setFont('helvetica', 'normal');
      doc.setTextColor('black');
      doc.setFontSize(10);
      doc.text(`Fecha de entrega de residuos: ${dataForm.deliver_date}`, 40, 736, 'left');
      doc.text(`Cantidad:  ${dataForm.delivery_quantity}`, 415, 736, 'left')
      doc.text(`Aceptación o rechazo de residuos:  ${t(dataForm.accept)}`, 40, 746, 'left');
      doc.text(`Fecha: ${dataForm.date_accepted}`, 415, 746, 'left');
     
      // OTHERS 
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 750, 515, 11, 'FD'); // filled red square with black borders
      doc.setFillColor('white');
      doc.rect(40, 761, 515, 22, 'FD'); // filled red square with black borders

      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('OTRAS INFORMACIONES', 300, 759, 'center');
      doc.setFont('helvetica', 'normal');
      doc.setTextColor('black');
      doc.setFontSize(6);
      doc.text(`Obervaciones: ${dataForm.observations1}`, 40, 770, 'left');
      doc.text(`${dataForm.observations2}`, 80, 780, 'left');
      doc.text(`¿Se opta por que sea la autoridad competente de la comunidad autónoma ante que se presenta el documento de identificación la que remita dicho documento, a la autoridad competente de la`, 40, 790, 'left');
      doc.text(`comunidad autónoma de origen del traslado?`, 40, 800, 'left');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(6);
      doc.text(`SÍ`, 165, 800, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setTextColor('black');
      doc.setFontSize(10);
      doc.text(`Firma recepción residuos:`, 50, 815, 'left');
      // Define position and size (x, y, width, height)
      if (dataForm.signature_dest_tras!==undefined && dataForm.signature_dest_tras!==""){
        doc.addImage(`${dataForm.signature_dest_tras}`, 'PNG', 180, 805, 20, 20);
      } 
    // Open PDF in a new tab
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');
    setViewPDF(false);
    setDataForm(initialStateForm);

  };

  if (dataForm) {
    generatePDF();
    

  }
}, [dataForm, t,setViewPDF, setDataForm, initialStateForm]);
  return null; // This component does not render anything in the DOM
};

ReportTemplateSecond.propTypes = {
  dataForm: PropTypes.object.isRequired, 
  setDataForm: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default ReportTemplateSecond;
