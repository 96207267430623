import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material'; 
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp"; 
import TableSortingTypesDIAddress from './SortingTablesFormAddress'; 
import PropTypes from 'prop-types'; 
 
const initialStateStoreAddresses = [{
  "id":0,
  "roadtype_id":0,
  "roadtype_namees":"", 
  "roadtype_nameen":"",
  "street":"",
  "country_id":0,
  "country_namees":"",
  "country_nameen":"",
  "province_id":0,
  "province_name":"",
  "community_id":0,
  "community_namees":"",
  "community_nameen":"",
  "postalcode_id":0,
  "postalcode_postalcodename":""
}];

const FormSearchStoresAddresses = ({ showModalSearchStoresAddress, setShowModalSearchStoresAddress,selectStores,setFormForm,titleTable,store_id,priority}) => {
  const {t,i18n} = useTranslation(['listDataForms']);
  const state = useSelector((state) => state);
  const {token} = state.loginUser
  const {ENDPOINT,LIMITPAGESIZE} = state.variablesUser;
  const [dataStoreAddresses, setDataStoreAddresses] = useState(initialStateStoreAddresses);
  const [numTotRegStoreAddresses,setNumTotRegStoreAddresses]= useState(0); 
 
  const dispatch = useDispatch(); 
   
  const keyPress = useCallback(
    (e) => {
     
      if (e.key === 'Escape' && showModalSearchStoresAddress) {
        setDataStoreAddresses(initialStateStoreAddresses);
        setNumTotRegStoreAddresses(0);
        setShowModalSearchStoresAddress(false);
      }
    },
    [ setDataStoreAddresses,
      setNumTotRegStoreAddresses,
      showModalSearchStoresAddress,
      setShowModalSearchStoresAddress
    ]
  );

  const handleCloseDataEditStoreAdresses = () => {
    setDataStoreAddresses(initialStateStoreAddresses);
    setNumTotRegStoreAddresses(0);
    setShowModalSearchStoresAddress(false);
	};

  const toggleDrawerSearchFormStoreAddresses  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseDataEditStoreAdresses();
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
       
  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegStoreAdresses = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `store/getinformationtablestoreaddress/`, token,store_id);
        if (getData.err) {
          setDataStoreAddresses(initialStateStoreAddresses);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegStoreAddresses(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
  
    const fetchDataStoreAdresses = async () => {
      try {
        const getData = await helpHttp().get8(ENDPOINT,`store/getaddressesstoresbystoreid`,token,store_id,priority);
        if (getData.err || getData.message === "Load failed") {
          setDataStoreAddresses(initialStateStoreAddresses);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataStoreAddresses(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    
    if (showModalSearchStoresAddress){
      fetchnumTotRegStoreAdresses(); 
      fetchDataStoreAdresses();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,i18n,token,dispatch,LIMITPAGESIZE,showModalSearchStoresAddress,store_id,priority]);
 
  return(
    <>
      {showModalSearchStoresAddress && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSearchFormStoreAddresses(false)}
            onOpen={toggleDrawerSearchFormStoreAddresses(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'1300px', width: '800px'}}>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2"> {t('Text_FORM_Stores_Address_Search')}</h1>
                  <div>
                    <button type="button" className="buttonCreate" onClick={handleCloseDataEditStoreAdresses}>{t('Button_Exit')}</button><> </>
                  </div> 
                </div>  
                <br/>
                
                <div className="card shadow mb-4">    
                  <div className="card-body">
                    <TableSortingTypesDIAddress
                      dataStoreAddresses={dataStoreAddresses}
                      numTotRegStoreAddresses={numTotRegStoreAddresses}
                      titleTable={titleTable}
                      selectStores={selectStores}
                      setFormForm={setFormForm}
                      handleCloseDataEditStoreAdresses={handleCloseDataEditStoreAdresses}
                    />
                  </div>             
                </div>
                {/* End Content Row */}                
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

FormSearchStoresAddresses.propTypes = {
  showModalSearchStoresAddress : PropTypes.bool.isRequired,
  setShowModalSearchStoresAddress : PropTypes.func.isRequired,
  selectStores : PropTypes.string.isRequired,
  setFormForm : PropTypes.func.isRequired,
  titleTable : PropTypes.string.isRequired,
  store_id : PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired
};

export default FormSearchStoresAddresses;
