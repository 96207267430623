import {
  READ_LOGIN,
  LOAD_LOGIN,
  RESET_LOGIN
} from "types/Types";

const initialState = {
  useraccount_id: "",
  username: "",
  password: "",
  firstName: "",
  lastName1: "",
  lastName2: "",
  email: "",
  enabled: false,
  isAccountNonLocked: true,
  aliasuser: "",
  createdate: "",
  language: {},
  defaultStore: {},  
  defaulttypesdi: {},
  defaultweighvehiclesformat:{},
  roles: [],
  token: "",
  authorities: []
};

export function loginReducer(state = initialState, action) {
  switch (action.type) {
    case READ_LOGIN:
      return state;
    case LOAD_LOGIN: {
      const {
        id,
        username,
        password,
        firstName,
        lastName1,
        lastName2,
        email,
        enabled,
        isAccountNonLocked,
        aliasuser,
        createdate,
        language,
        defaultStore,
        defaulttypesdi,
        defaultweighvehiclesformat,
        roles,
        token
      } = action.payload;
      
      return {
        ...state,
        useraccount_id: id || "",
        username: username || "",
        password: password || "",
        firstName: firstName || "",
        lastName1: lastName1 || "",
        lastName2: lastName2 || "",
        email: email || "",
        enabled: enabled !== null ? enabled : true,
        isAccountNonLocked: isAccountNonLocked !== null ? isAccountNonLocked : true,
        aliasuser: aliasuser || "",
        createdate: createdate || "",
        language: language || {},
        defaultStore: defaultStore || {},
        defaulttypesdi: defaulttypesdi || {},
        defaultweighvehiclesformat: defaultweighvehiclesformat || {}, 
        roles: roles || [],
        token: token || ""
      };
    }
    case RESET_LOGIN:
      return initialState;
    default:
      return state;
  }
}
