import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the jsPDF autoTable plugin
import PropTypes from 'prop-types';
import Seal from "components/photos/formdi/seal.png"; 
import Sign from "components/photos/formdi/signature.png";

const ReportTemplateContractSecond = ({ dataForm, initialStateForm, setDataForm, setViewPDF }) => {
  const { t } = useTranslation(['listDataForms']);
  useEffect(() => {
    const generatePDF = () => {
      const doc = new jsPDF({
        format: 'a4',
        unit: 'pt',
      });
      let numPages =dataForm.codelers_code.length;

      if (numPages===0){
        numPages=1;
      }
      
      for (let i = 0; i < numPages; i++) {
        // Title
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(18);
        doc.text('CONTRATO DE TRATAMIENTO', 40, 40);
      
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.text('(Artículo 5 del RD 553/2020, BOE 19/06/2020)', 40, 50);
  
        doc.setLineWidth(0.5);   
        doc.setFillColor('#FFFFFF');
        doc.rect(40, 60, 200, 12, 'FD');
        doc.rect(40, 72, 200, 12, 'FD');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text(`Nº CT: ${dataForm.number_nt}`, 42, 69, 'left');
        doc.text(`Fecha: ${dataForm.date_contract}`, 42, 81, 'left');

        // OPT TRAS
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 94, 515, 11, 'FD'); // filled red square with black borders
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);

        doc.addImage(Seal, 'png', 400, 40,150, 50);

        doc.text('OPERADOR', 300, 103, 'center');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.text(`Razón social: ${dataForm.opt_tras_tradename}`, 40, 116, 'left');
        doc.text(`Nombre del centro:  ${dataForm.opt_tras_name}`, 40, 126, 'left');
        doc.text(`Dirección:  ${dataForm.opt_tras_street} `, 40, 136, 'left');
        doc.text(`Municipio:  ${dataForm.opt_tras_locality} `, 40, 146, 'left');
        doc.text(`Teléfono/FAX: ${dataForm.opt_tras_phone}`, 40, 156, 'left');
        doc.text(`Correo-e: ${dataForm.opt_tras_email}`, 200, 156, 'left');
        doc.text(`Tipo de Operador: ${dataForm.opt_tras_opt}`, 40, 166, 'left');
        doc.text(`Nº inscripción Registro de PyGR: ${dataForm.opt_tras_inscription}`, 40, 176, 'left');
        doc.text(`CIF/NIF:  ${dataForm.opt_tras_documentid}`, 415, 116, 'left');
        doc.text(`CP:  ${dataForm.opt_tras_postalcode}`, 415, 126, 'left');
        doc.text(`Provincia:  ${dataForm.opt_tras_province} `, 415, 136, 'left');
        doc.text(`Contacto: ${dataForm.opt_tras_contactname}`, 415, 146, 'left');
        doc.text(`NIMA: ${dataForm.opt_tras_nima}`, 415, 166, 'left');
      
        // ORI TRAS
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 170, 515, 11, 'FD'); // filled red square with black borders
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('ORIGEN DE TRASLADO', 300, 179, 'center');
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(8);
        doc.setTextColor('gray');
        doc.text(`Centro productor o poseedor de residuos o de la instalación origen de traslado`, 40, 189, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.setTextColor('black');
        doc.text(`Razón social: ${dataForm.ori_tras_tradename}`, 40, 200, 'left');
        doc.text(`Nombre del centro:  ${dataForm.ori_tras_name}`, 40, 210, 'left');
        doc.text(`Dirección:  ${dataForm.ori_tras_street} `, 40, 220, 'left');
        doc.text(`Municipio:  ${dataForm.ori_tras_locality} `, 40, 230, 'left');
        doc.text(`Teléfono/FAX: ${dataForm.ori_tras_phone}`, 40, 240, 'left');
        doc.text(`Nº inscripción Registro de PyGR: ${dataForm.ori_tras_inscription}`, 40, 250, 'left');
        doc.text(`CIF/NIF:  ${dataForm.ori_tras_documentid}`, 415, 200, 'left');
        doc.text(`CP:  ${dataForm.ori_tras_postalcode}`, 415, 210, 'left');
        doc.text(`Provincia:  ${dataForm.ori_tras_province} `, 415, 220, 'left');
        doc.text(`Contacto: ${dataForm.ori_tras_contactname_lastname}`, 415, 230, 'left');
        doc.text(`NIMA: ${dataForm.ori_tras_nima}`, 415, 250, 'left');
        doc.text(`Correo-e: ${dataForm.ori_tras_email}`, 200, 240, 'left');

        // AUT TRAS
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(10); 
        doc.setTextColor('gray');
        doc.line(40, 255, 555, 255, 'FD') 
        doc.setFontSize(8);
        doc.text(`Empresa autorizada para realizar las operaciones de tratamiento de residuos`, 40, 265, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setTextColor('black');
        doc.setFontSize(8);
        doc.text(`Razón social: ${dataForm.aut_tras_tradename}`, 40, 276, 'left');
        doc.text(`Nombre del centro:  ${dataForm.aut_tras_name}`, 40, 286, 'left');
        doc.text(`Dirección:  ${dataForm.aut_tras_street} `, 40, 296, 'left');
        doc.text(`Municipio:  ${dataForm.aut_tras_locality} `, 40, 306, 'left');
        doc.text(`Teléfono/FAX: ${dataForm.aut_tras_phone}`, 40, 316, 'left');
        doc.text(`Nº inscripción Registro de PyGR: ${dataForm.aut_tras_inscription}`, 40, 326, 'left'); 
        doc.text(`CIF/NIF:  ${dataForm.aut_tras_documentid}`, 415, 276, 'left');
        doc.text(`CP:  ${dataForm.aut_tras_postalcode}`, 415, 286, 'left');
        doc.text(`Provincia:  ${dataForm.aut_tras_province} `, 415, 296, 'left');
        doc.text(`Contacto: ${dataForm.aut_tras_contactname}`, 415, 306, 'left');
        doc.text(`NIMA: ${dataForm.aut_tras_nima}`, 415, 326, 'left');
        doc.text(`Correo-e: ${dataForm.aut_tras_email}`, 200, 316, 'left');
        
        // DEST TRAS
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);   
        doc.setFillColor('#DDDDDD');
        doc.setFontSize(10);
        doc.rect(40, 330, 515, 11, 'FD'); // filled red square with black borders
        doc.setFont('helvetica', 'bold');
        doc.text('DESTINO DE TRASLADO', 300, 339, 'center');
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(8);
        doc.setTextColor('gray');
        doc.text(`Instalación de destino`, 40, 349, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.setTextColor('black');
        doc.text(`Razón social: ${dataForm.dest_tras_tradename}`, 40, 360, 'left');
        doc.text(`Nombre del centro:  ${dataForm.dest_tras_name}`, 40, 370, 'left');
        doc.text(`Dirección:  ${dataForm.dest_tras_street} `, 40, 380, 'left');
        doc.text(`Municipio:  ${dataForm.dest_tras_locality} `, 40, 390, 'left');
        doc.text(`Teléfono/FAX: ${dataForm.dest_tras_phone}`, 40, 400, 'left');
        doc.text(`Nº inscripción Registro de PyGR: ${dataForm.dest_tras_inscription}`, 40, 410, 'left');
        doc.text(`CIF/NIF:  ${dataForm.dest_tras_documentid}`, 415, 360, 'left');
        doc.text(`CP:  ${dataForm.dest_tras_postalcode}`, 415, 370, 'left');
        doc.text(`Provincia:  ${dataForm.dest_tras_province} `, 415, 380, 'left');
        doc.text(`Contacto: ${dataForm.dest_tras_contactname}`, 415, 390, 'left');
        doc.text(`NIMA: ${dataForm.dest_tras_nima}`, 415, 410, 'left');
        doc.text(`Correo-e: ${dataForm.dest_tras_email}`, 200, 400, 'left');
              
        // AUT TRAS
        doc.setFont('helvetica', 'italic');
        doc.setTextColor('gray');
        doc.line(40, 415, 555, 415, 'FD')
        doc.setFontSize(8);
        doc.text(`Empresa autorizada a realizar el tratamiento del residuo`, 40, 425, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setTextColor('black');
        doc.setFontSize(8);
        doc.text(`Razón social: ${dataForm.aut_dest_tradename}`, 40, 436, 'left');
        doc.text(`Nombre del centro:  ${dataForm.aut_dest_name}`, 40, 446, 'left');
        doc.text(`Dirección:  ${dataForm.aut_dest_street} `, 40, 456, 'left');
        doc.text(`Municipio:  ${dataForm.aut_dest_locality} `, 40, 466, 'left');
        doc.text(`Teléfono/FAX: ${dataForm.aut_dest_phone}`, 40, 476, 'left');
        doc.text(`Nº inscripción Registro de PyGR: ${dataForm.aut_dest_inscription}`, 40, 486, 'left'); 
        doc.text(`CIF/NIF:  ${dataForm.aut_dest_documentid}`, 415, 436, 'left');
        doc.text(`CP:  ${dataForm.aut_dest_postalcode}`, 415, 446, 'left');
        doc.text(`Provincia:  ${dataForm.aut_dest_province} `, 415, 456, 'left');
        doc.text(`Contacto: ${dataForm.aut_dest_contactname}`, 415, 466, 'left');
        doc.text(`NIMA: ${dataForm.aut_dest_nima}`, 415, 486, 'left');
        doc.text(`Correo-e: ${dataForm.aut_dest_email}`, 200, 476, 'left');
        
        // OTHERS INFORMATIONS 
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 490, 515, 11, 'FD'); // filled red square with black borders
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('CARACTERÍSTICAS DEL RESIDUO QUE SE TRASLADA', 300, 499, 'center');
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(8);
        doc.text(`Código LER:`, 40, 509, 'left');
        
        if (dataForm.codelers_code.length>0){
          if (dataForm.codelers_code[i]!==""){
            doc.text(`${dataForm.codelers[i]}`, 90, 509, 'left');
            if ((i+1)<dataForm.codelers_code.length){
              if (dataForm.codelers_code[i+1]===""){
                doc.text(`${dataForm.codelers[i+1]}`, 90, 519, 'left');
              }
            }
          } 
        }  
     
        doc.text(`Descriptión:`, 40, 529, 'left');
        doc.text(`Caract. peligrosidad:`, 40, 539, 'left');
        doc.text(`Trat.:`, 285, 539, 'left');
        if (dataForm.processtypes!==undefined && dataForm.processtypes.length>i){
          if (dataForm.processtypes[i].processtype_code!==""){
            doc.text(`${dataForm.processtypes[i].processtype_code}`, 308, 539, 'left'); 
          }
        }
        doc.text(`Trat. final:`, 350, 539, 'left');
        if (dataForm.treatmenttypes!==undefined && dataForm.treatmenttypes.length>i){
          if (dataForm.treatmenttypes[i].treatmenttype_code!==""){
            doc.text(`${dataForm.treatmenttypes[i].treatmenttype_code}`, 390, 539, 'left'); 
          }
        }
      
        
        doc.text(`Cantidad: ${dataForm.amount_contract}`, 450, 539, 'left'); 
        doc.text(`Datos adicionales:`, 40, 549, 'left');
        doc.text(`Periodicidad estimada: ${dataForm.periodicity_contract}`, 45, 559, 'left');
        doc.text(`Duración del contrato: ${dataForm.duration_contract}`, 45, 569, 'left');
        doc.text(`Otros datos:`, 40, 579, 'left'); 
        doc.text(`${dataForm.other_information_contract1}`, 45, 589, 'left');
        doc.text(`${dataForm.other_information_contract2}`, 45, 599, 'left');
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 603, 515, 11, 'FD'); // filled red square with black borders
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('OBLIGACIONES DE LAS PARTES EN CASO DE RECHAZO DEL RESIDUO', 300, 612, 'center');
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(8);
        doc.text(`${dataForm.conditions_contract1}`, 40, 622, 'left');
        doc.text(`${dataForm.conditions_contract2}`, 40, 632, 'left');
        doc.text(`${dataForm.obligations_contract1}`, 40, 642, 'left');
        doc.text(`${dataForm.obligations_contract2}`, 40, 652, 'left');
        
        doc.text('Firma operador:', 100, 690, 'center'); 
        doc.text('Firma del productor (si no es el operador):', 300, 690, 'center'); 
        doc.text('Firma responsable destino:', 475, 690, 'center'); 
        doc.addImage(Sign, 'png', 50, 700,150, 70);
        if (i<numPages-1){
          doc.addPage();
        }   
      }
    // Open PDF in a new tab
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');
    setViewPDF(false);
    setDataForm(initialStateForm);

  };

  if (dataForm) {
    generatePDF();
    

  }
}, [dataForm, t,setViewPDF, setDataForm, initialStateForm]);
  return null; // This component does not render anything in the DOM
};

ReportTemplateContractSecond.propTypes = {
  dataForm: PropTypes.object.isRequired, 
  setDataForm: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default ReportTemplateContractSecond;
