import React, {  useEffect, useState, useCallback } from 'react';  
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import { useNavigate } from 'react-router-dom'; 
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Box } from '@mui/material'; 
import { helpHttp } from "service/HelpHttp";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions"; 
import IconButton from '@mui/material/IconButton'
import ScaleIcon from '@mui/icons-material/Scale';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import "css/generalSettings.css";  
import { experimentalStyled as styled } from '@mui/material/styles'; 
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import WeighVechiclesSearchStores from "pages/weighVehicles/weighVehiclesEdit/WeighVehiclesSearchStores";
import WeighVehiclesSearchStoresAddresses from "pages/weighVehicles/weighVehiclesEdit/WeighVehiclesSearchStoresAddress";
import WeighVechiclesSearchVehicles from "pages/weighVehicles/weighVehiclesEdit/WeighVehiclesSearchVehicles";
import TableSortingCodeLERS from "pages/weighVehicles/weighVehiclesEdit/WeighVehiclesTablesCodeLers";
import WeighVehiclesCreateCodeLER from "pages/weighVehicles/weighVehiclesEdit/WeighVehiclesNewCodeLER";  
import WeighVehiclesBudgetsForm from "pages/weighVehicles/weighVehiclesEdit/weighVehiclesBudgetsEdit/WeighVehiclesBudgets";
import PropTypes from 'prop-types';

const initialState = [{
  "id" : "",
  "defaultStore_id":0, 
  "tradename": "",
  "useraccount_id":0,
  "username": "",
  "defaultformatnumber":1,
  "isFinished":false, 
  "numberbudget":0, 
  "start_date_weighvehiclesbudget": "",
  "start_time_weighvehiclesbudget": "",
  "end_date_weighvehiclesbudget": "",
  "end_time_weighvehiclesbudget": "",
  "vehiclestore_id": 0,
  "vehiclestore_licenseplate": "",
  "vehiclestore_tara": 0,
  "vehiclestore_pma": 0,
  "vehiclestore_checkpma": true,
  "vehiclestore_defaultdriver": "",
  "vehiclestore_store_id": 0,
  "rel_trans_store_id": 0,
  "rel_trans_documnettype_id": 0,
  "rel_trans_documentid": "",
  "rel_trans_documnettype_name": "",
  "rel_trans_tradename": "",
  "rel_trans_name": "",
  "rel_trans_contacperson": "",
  "rel_trans_roadtype_id": 0,
  "rel_trans_street": "",
  "rel_trans_country_id": 1,
  "rel_trans_province_id": 30,
  "rel_trans_postalcode_id": 0,
  "ori_tras_store_id": 0,
  "ori_tras_documnettype_id": 0,
  "ori_tras_documnettype_name": "",
  "ori_tras_documentid": "",
  "ori_tras_tradename": "",
  "ori_tras_name": "",
  "ori_tras_contacperson": "",
  "ori_tras_roadtype_id": 0,
  "ori_tras_street": "",
  "ori_tras_country_id": 1,
  "ori_tras_province_id": 30,
  "ori_tras_postalcode_id": 0,
  "observations": "",
  "totalfirstweight": 0,
  "totalsecondweight": 0,
  "weighvehiclescodelers":[],
 
}];

const initialProvincesToSelect = [{
  "id" : "",
  "name" : "",
  "enabled" : true,
  "country_id":1
}];

const initialPostalCodesToSelect = [{
  "id":"",
  "postalcode":"",
  "name":"",
  "postalcodename":"",
  "enabled":true,
  "province_id":""
}];

const initialCodeLERToSelect = [{
  "id": "",
  "indexrowcodeler":0, 
  "codeler_id":"",
  "codeler_code": "",
  "codeler_namees": "",
  "codeler_nameen": "",
  "firstweight":0,
  "secondweight":0
}];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const WeighVehiclesEditOrCreate = ({showModalWeighVehiclesSettings}) => {
  const {t,i18n} = useTranslation(['listDataWeighVehicles']); 
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles); 
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DELETE_CODE = useSelector((state) => state.variablesUser.DELETE_CODE); 
  const DEFAULTCOUNTRY = useSelector((state) => state.variablesUser.DEFAULTCOUNTRY); 
  const DEFAULTPROVINCE = useSelector((state) => state.variablesUser.DEFAULTPROVINCE); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const LIMITCODELERSWEIGHT = useSelector((state) => state.loginUser.defaultweighvehiclesformat.limitscodelerperweight);
  const codeLERButtonsList = useSelector((state) => state.loginUser.defaultweighvehiclesformat.codeLERButtonsList);
  const [selectedButtonIds,setSelectedButtonIds] = useState([]); 
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  const [form, setForm] = useState(initialState[0]);
  const [errorsForm, setErrorsForm] = useState({});
  const [provinceToSelect,setProvinceToSelect]= useState(initialProvincesToSelect);
  const [postalCodeToSelect,setPostalCodeToSelect]= useState(initialPostalCodesToSelect);
  const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
  const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false);  
  const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false);  
  const [tetxtSearch, setTetxtSearch] = useState("Rel_Trans");   
  const [openDeleteRowCodeLERS, setOpenDeleteRowCodeLERS] = useState(false);
  const [openEditRowCodeLERS, setOpenEditRowCodeLERS] = useState(false);
  const [editRowCodeLER, setEditRowCodeLER] = useState(initialCodeLERToSelect);
  const [idCodeDeleteCodeLER,setIdCodeDeleteCodeLER]=useState(DELETE_CODE);
  const [foundLicensePlateAndCheckPma,setFoundLicensePlateAndCheckPma]=useState(true);
  const [submitNewBudget,setSubmitNewBudget]=useState(false);

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalWeighVehiclesSettings) {
       
        setErrorsForm({});
        setForm(form => ({
          ...form,
          weighvehiclescodelers: [],
        }));
        setForm(initialState[0]);
        setProvinceToSelect(initialPostalCodesToSelect);
        setPostalCodeToSelect(initialPostalCodesToSelect);
        setShowModalSearchStores(false);
        setShowModalSearchStoresAddress(false);
        setShowModalSearchVehicles(false);
        setTetxtSearch("Rel_Trans "); 
        setOpenDeleteRowCodeLERS(false);
        setOpenEditRowCodeLERS(false);
        setFoundLicensePlateAndCheckPma(true);
        setEditRowCodeLER(initialCodeLERToSelect);
        setSelectedButtonIds([]);
        setIdCodeDeleteCodeLER(DELETE_CODE);
        setSubmitNewBudget(false);
        navigate("/home") 
        return () => {};
      }
    },
    [
      setForm,
      setErrorsForm,
      setProvinceToSelect,
      setPostalCodeToSelect,
      setShowModalSearchStores,
      setShowModalSearchStoresAddress,
      setShowModalSearchVehicles,
      setTetxtSearch, 
      setOpenDeleteRowCodeLERS,
      setOpenEditRowCodeLERS,
      setEditRowCodeLER,
      setSelectedButtonIds,
      setIdCodeDeleteCodeLER,
      setFoundLicensePlateAndCheckPma,
      showModalWeighVehiclesSettings, 
      setSubmitNewBudget,
      navigate,
      DELETE_CODE]
  );
  
  const handleCloseDataEditWeighVehicles = () => {
    setErrorsForm({});
    setForm(form => ({
      ...form,
      weighvehiclescodelers: [],
    }));
    setForm(initialState[0]);
    setProvinceToSelect(initialPostalCodesToSelect);
    setPostalCodeToSelect(initialPostalCodesToSelect);
    setShowModalSearchStores(false);
    setShowModalSearchStoresAddress(false);
    setShowModalSearchVehicles(false);
    setTetxtSearch("Rel_Trans"); 
    setOpenDeleteRowCodeLERS(false);
    setOpenEditRowCodeLERS(false);
    setFoundLicensePlateAndCheckPma(true);
    setEditRowCodeLER(initialCodeLERToSelect);
    setIdCodeDeleteCodeLER(DELETE_CODE);
    setSelectedButtonIds([]);
    setSubmitNewBudget(false);
    navigate("/home") 
    return () => {};
	};

  const beginDataEditWeighVehicles = () => {
    setErrorsForm({});
    setForm(form => ({
      ...form,
      weighvehiclescodelers: [],
    }));
    setForm(initialState[0]);
    setProvinceToSelect(initialPostalCodesToSelect);
    setPostalCodeToSelect(initialPostalCodesToSelect);
    setShowModalSearchStores(false);
    setShowModalSearchStoresAddress(false);
    setShowModalSearchVehicles(false);
    setTetxtSearch("Rel_Trans"); 
    setOpenDeleteRowCodeLERS(false);
    setOpenEditRowCodeLERS(false);
    setFoundLicensePlateAndCheckPma(true);
    setEditRowCodeLER(initialCodeLERToSelect);
    setIdCodeDeleteCodeLER(DELETE_CODE);
    setSelectedButtonIds([]);   
    setSubmitNewBudget(true);
    return () => {};
	};

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 1000000) return true;
    return false;
  };

  const handleChangeWeighVehiclesWeight = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","")):0;
    setForm(form => ({
      ...form,
      [name]: newValue,
    }));
  };

  const handleBlurWeighVehiclesWeight = (e) => {
    handleChangeWeighVehiclesWeight(e);
    setErrorsForm(validateWeighVehicles());
  };

  const handleChangeWeighVehicles = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value,
    }));
  };

  const handleBlurWeighVehicles = (e) => {
    handleChangeWeighVehicles(e);
    setErrorsForm(validateWeighVehicles());
  };

  const searchFormVehicles = (event) => { 
    setShowModalSearchVehicles(true);
  };

  const deleteDateVehicles = (event) => {
    setForm(form => ({
      ...form,
      vehiclestore_id:0,
      vehiclestore_licenseplate: "",
      vehiclestore_tara:0,
      vehiclestore_pma: 0,
      vehiclestore_checkpma: false,
      vehiclestore_defaultdriver: "",
      vehiclestore_store_id: 0
    }));  
    form.weighvehiclescodelers.map((item)=>{
        return item.secondweight=0
    })
    setForm(form => ({
      ...form,
    })) 
    setErrorsForm(validateWeighVehicles());

  };

  const searchFormStoreRelTrans = (event) => {
    setTetxtSearch("Rel_Trans")
    setShowModalSearchStores(true);
  };

  const searchFormStoreOriTras = (event) => {
    setTetxtSearch("Ori_Tras")
    setShowModalSearchStores(true);
  };

  const deleteDateStoreRelTrans = (event) => {
    setForm(form => ({
      ...form,
      rel_trans_store_id: 0,
      rel_trans_documnettype_id: 0,
      rel_trans_documnettype_name: "",
      rel_trans_documentid: "",
      rel_trans_tradename: "",
      rel_trans_name: "",
      rel_trans_contacperson: "",
      rel_trans_roadtype_id: 0,
      rel_trans_street: "",
      rel_trans_country_id: Number(DEFAULTCOUNTRY),
      rel_trans_province_id:  Number(DEFAULTPROVINCE),
      rel_trans_postalcode_id: 0,
    }));  
    setErrorsForm(validateWeighVehicles());
  };

  const deleteDateStoreOriTras = (event) => {
    setForm(form => ({
      ...form,
      ori_tras_store_id: 0,
      ori_tras_documnettype_id: 0,
      ori_tras_documnettype_name: "",
      ori_tras_documentid: "",
      ori_tras_tradename: "",
      ori_tras_name: "",
      ori_tras_contacperson: "",
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
    }));  
    setErrorsForm(validateWeighVehicles());
  };

  const deleteDateStoreAddressOriTras = (event) => {
    setForm(form => ({
      ...form,
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
    }));  
    setErrorsForm(validateWeighVehicles());
  };

  const searchFormStoreAddress = (event) => {
    setShowModalSearchStoresAddress(true);
  };
 
  const selectCodeLER = (post) => {
    let codeLERToAdd={
      "id":"",
      "indexrowcodeler":form.weighvehiclescodelers.length>0?form.weighvehiclescodelers[form.weighvehiclescodelers.length-1].indexrowcodeler+1:1,  
      "codeler_id":post.button_codeler_id,
      "codeler_code":post.button_codeler_code,
      "codeler_namees":post.button_namees,
      "codeler_nameen":post.button_codeler_nameen,
      "firstweight":0,
      "secondweight":!foundLicensePlateAndCheckPma?form.vehiclestore_tara:0
    }
    form.weighvehiclescodelers.push(codeLERToAdd);
    setForm(form => ({
      ...form,
    }))
    setSelectedButtonIds(form.weighvehiclescodelers.map(item => Number(item.codeler_id))); 
  };

  const handleCancelDeleteRowCodeLER = () => {
    setIdCodeDeleteCodeLER(DELETE_CODE);
    setOpenDeleteRowCodeLERS(false);
  };

  const handleChangeDeleteRowCodeLER = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteCodeLER(value);
  };

  const handleCloseDeleteRowCodeLER = (e) => {
    e.preventDefault();
    if (idCodeDeleteCodeLER!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=form.weighvehiclescodelers.findIndex(((item) => item.indexrowcodeler === editRowCodeLER.indexrowcodeler));
      if ( indexDelete !== -1 ) {
        form.weighvehiclescodelers.splice( indexDelete, 1 );
      }
      setForm(form => ({
        ...form,
      }))
      let sumTotal=0;
      form.weighvehiclescodelers.map((item)=>{
        return sumTotal+=item.firstweight; 
      })
      setForm(form => ({
        ...form,
        totalfirstweight:sumTotal
       }));
      setSelectedButtonIds(form.weighvehiclescodelers.map(item => Number(item.codeler_id))); 
      setIdCodeDeleteCodeLER(DELETE_CODE);
      setOpenDeleteRowCodeLERS(false);
    }
  };

  const validateWeighVehicles = () => {
    let errorsForm = {};
    //let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;
    
    if (regexInvalidCharacters.test(form.rel_trans_documentid)) {
      errorsForm.rel_trans_documentid = 'Text_TextNoValid';
    }
    
    if (regexInvalidCharacters.test(form.ori_tras_documentid)) {
      errorsForm.ori_tras_documentid = 'Text_TextNoValid';
    }
   
    if (regexInvalidCharacters.test(form.ori_tras_street)) {
      errorsForm.ori_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(form.observations)) {
      errorsForm.observations = 'Text_TextNoValid';
    } 

    if ( !form.vehiclestore_licenseplate ||  form.vehiclestore_licenseplate==="") {
      errorsForm.vehiclestore_licenseplate = 'Text_FieldVehicleStoreLicensePlateRequired';
    } else {
      if (regexInvalidCharacters.test(form.vehiclestore_licenseplate)) {
        errorsForm.vehiclestore_licenseplate = 'Text_TextNoValid';
      }  
    }

    if (!form.rel_trans_tradename ||  form.rel_trans_tradename==="") {
      errorsForm.rel_trans_tradename = 'Text_FieldRelTrasTradenameRequired';
    } else {
      if (regexInvalidCharacters.test(form.rel_trans_tradename)) {
        errorsForm.rel_trans_tradename = 'Text_TextNoValid';
      }  
    }

    if (!form.ori_tras_tradename || form.ori_tras_tradename==="") {
      errorsForm.ori_tras_tradename = 'Text_FieldOriTrasTradenameRequired';
    } else {
      if (regexInvalidCharacters.test(form.ori_tras_tradename)) {
        errorsForm.ori_tras_tradename = 'Text_TextNoValid';
      }  
    }

    if (form.weighvehiclescodelers!==undefined  && form.weighvehiclescodelers.length<=0) {
        errorsForm.weighvehiclescodelers = 'Text_FieldCodeLERsRequired';
    }
     return errorsForm;
  };

  const handleSubmitDataEditWeighVehiclesBudget = () => {
    const errors = validateWeighVehicles();
    setErrorsForm(errors);
    if ((Object.keys(errors).length === 0) && form.vehiclestore_licenseplate!=='' && form.rel_trans_tradename!==''
      && form.ori_tras_tradename!==''   ) {
      const fetchData = async () => {
        let isMounted = true;
        try {
          const getData = (form.id === undefined || form.id === "" || form.id === "0" || form.id === 0)
            ? await helpHttp().post(ENDPOINT, `weighvehiclesbudget/saveweighvehiclesbudget`, token,form)
            : await helpHttp().put(ENDPOINT, `weighvehiclesbudget/updateweighvehicleformat`, token,form);
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            beginDataEditWeighVehicles();
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      }; 
      fetchData();
    } else {
      if (errors.vehiclestore_licenseplate){
        dispatch(warningErrorAction(t(errors.vehiclestore_licenseplate)));
      }else{
        if (errors.rel_trans_tradename){
          dispatch(warningErrorAction(t(errors.rel_trans_tradename)));
        }else{
          if (errors.ori_tras_tradename){
            dispatch(warningErrorAction(t(errors.ori_tras_tradename)));
          }else{
            if (errors.weighvehiclescodelers){
              dispatch(warningErrorAction(t(errors.weighvehiclescodelers)));
            }else{
              dispatch(warningErrorAction(t('Text_IncorrectData')));
            }
          }
        }   
      }
    }
  };
                
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentRelTrans = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`weighvehicles/getexistemployeebynumberdocument/`,token,form.rel_trans_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==form.rel_trans_documentid){
            fetchStoreNumberDocumentRelTrans();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchStoreNumberDocumentRelTrans = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`weighvehicles/getstorebynumberdocumentpriority`,token,form.rel_trans_documentid,6);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                  setForm(form => ({
                      ...form,
                      rel_trans_store_id: getData.rel_trans_store_id,
                      rel_trans_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      rel_trans_documentid: getData.rel_trans_documentid,
                      rel_trans_documnettype_name: getData.rel_trans_documnettype_name,
                      rel_trans_tradename: getData.rel_trans_tradename,
                      rel_trans_name: getData.rel_trans_name,
                      rel_trans_contacperson: getData.rel_trans_contacperson,
                      rel_trans_roadtype_id: (getData.rel_trans_roadtype_id===undefined 
                                            || getData.rel_trans_roadtype_id===null  
                                            || getData.rel_trans_roadtype_id===""
                                            || getData.rel_trans_roadtype_id==="0"
                                            || getData.rel_trans_roadtype_id===0)?
                                            0:getData.rel_trans_roadtype_id,
                      rel_trans_street: getData.rel_trans_street,
                      rel_trans_country_id: (getData.rel_trans_country_id===undefined 
                                            || getData.rel_trans_country_id===null  
                                            || getData.rel_trans_country_id===""
                                            || getData.rel_trans_country_id==="0"
                                            || getData.rel_trans_country_id===0)?
                                            Number(DEFAULTCOUNTRY):getData.rel_trans_country_id,
                      rel_trans_province_id: (getData.rel_trans_province_id===undefined 
                                            || getData.rel_trans_province_id===null  
                                            || getData.rel_trans_province_id===""
                                            || getData.rel_trans_province_id==="0"
                                            || getData.rel_trans_province_id===0)?
                                            Number(DEFAULTPROVINCE):getData.rel_trans_province_id,
                      rel_trans_postalcode_id: (getData.rel_trans_postalcode_id===undefined 
                                                || getData.rel_trans_postalcode_id===null  
                                                || getData.rel_trans_postalcode_id===""
                                                || getData.rel_trans_postalcode_id==="0"
                                                || getData.rel_trans_postalcode_id===0)?
                                                "":getData.rel_trans_postalcode_id 
                  }));
              }  
          }         
        } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
    };
    if (form.rel_trans_documentid!==""){
      fetchExistNumberDocumentRelTrans();
    } 
    return () => { isMounted = false };
}, [ENDPOINT,t,token,dispatch,form.rel_trans_documentid,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setForm]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentOriTras = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`weighvehicles/getexistemployeebynumberdocument/`,token,form.ori_tras_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==form.ori_tras_documentid){
            fetchStoreNumberDocumentOriTras();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchStoreNumberDocumentOriTras = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`weighvehicles/getstorebynumberdocumentpriority`,token,form.ori_tras_documentid,2);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                  setForm(form => ({
                      ...form,
                      ori_tras_store_id: getData.rel_trans_store_id,
                      ori_tras_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      ori_tras_documnettype_name: getData.rel_trans_documnettype_name,
                      ori_tras_documentid: getData.rel_trans_documentid,
                      ori_tras_tradename: getData.rel_trans_tradename,
                      ori_tras_name: getData.rel_trans_name,
                      ori_tras_contacperson: getData.rel_trans_contacperson,
                      ori_tras_roadtype_id: (getData.rel_trans_roadtype_id===undefined 
                                            || getData.rel_trans_roadtype_id===null  
                                            || getData.rel_trans_roadtype_id===""
                                            || getData.rel_trans_roadtype_id==="0"
                                            || getData.rel_trans_roadtype_id===0)?
                                            0:getData.rel_trans_roadtype_id,
                      ori_tras_street: getData.rel_trans_street,
                      ori_tras_country_id: (getData.rel_trans_country_id===undefined 
                                            || getData.rel_trans_country_id===null  
                                            || getData.rel_trans_country_id===""
                                            || getData.rel_trans_country_id==="0"
                                            || getData.rel_trans_country_id===0)?
                                            Number(DEFAULTCOUNTRY):getData.rel_trans_country_id,
                      ori_tras_province_id: (getData.rel_trans_province_id===undefined 
                                            || getData.rel_trans_province_id===null  
                                            || getData.rel_trans_province_id===""
                                            || getData.rel_trans_province_id==="0"
                                            || getData.rel_trans_province_id===0)?
                                            Number(DEFAULTPROVINCE):getData.rel_trans_province_id,
                      ori_tras_postalcode_id: (getData.rel_trans_postalcode_id===undefined 
                                                || getData.rel_trans_postalcode_id===null  
                                                || getData.rel_trans_postalcode_id===""
                                                || getData.rel_trans_postalcode_id==="0"
                                                || getData.rel_trans_postalcode_id===0)?
                                                "":getData.rel_trans_postalcode_id 
            }));
          }  
        }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (form.ori_tras_documentid!=="" && form.ori_tras_tradename===""){
      fetchExistNumberDocumentOriTras();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,form.ori_tras_documentid,form.ori_tras_tradename,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setForm]);


  useEffect(() => {
    let isMounted = true;
    const fetchExistLicensePlata = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`weighvehicles/getexistvehicleylicenseplate/`,token,form.vehiclestore_licenseplate);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==form.vehiclestore_licenseplate){
            fetchLicensePlate();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchLicensePlate = async () => {
      try {
          const getData = await helpHttp().get2(ENDPOINT,`weighvehicles/getvehiclebylicenseplate/`,token,form.vehiclestore_licenseplate);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setForm(form => ({
                  ...form,
                  vehiclestore_id: getData.id,
                  vehiclestore_tara: getData.tara,
                  vehiclestore_pma:  getData.pma,
                  vehiclestore_checkpma: getData.checkpma,
                  vehiclestore_defaultdriver: getData.defaultdriver,
                  vehiclestore_store_id: (getData.store_id===undefined 
                                        || getData.store_id===null  
                                        || getData.store_id===""
                                        || getData.store_id==="0"
                                        || getData.store_id===0)?
                                        0:getData.store_id,
              }));
              setFoundLicensePlateAndCheckPma(form.vehiclestore_checkpma);
              if (form.rel_trans_documentid===undefined || form.rel_trans_documentid===null || form.rel_trans_documentid==="" ){
                if (form.rel_trans_tradename===undefined || form.rel_trans_tradename===null || form.rel_trans_tradename===""){
                  setForm(form => ({
                    ...form,
                    rel_trans_documentid: getData.store_documentid 
                  }));
                }
              } 
            }
          }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    
    if (form.vehiclestore_licenseplate!==""){
      setFoundLicensePlateAndCheckPma(true);
      fetchExistLicensePlata();
    }else{
      setFoundLicensePlateAndCheckPma(true);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,form.vehiclestore_licenseplate,form.rel_trans_documentid,form.rel_trans_tradename,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setForm,form.vehiclestore_checkpma]);

  useEffect(() => {
    if (!foundLicensePlateAndCheckPma){
      form.weighvehiclescodelers.map((item)=>{
        return item.secondweight=form.vehiclestore_tara
      })
      setForm(form => ({
        ...form,
      })) 
    }else{
      form.weighvehiclescodelers.map((item)=>{
        return item.secondweight=0
      })
      setForm(form => ({
        ...form,
      })) 
    }
  }, [ENDPOINT,t,token,dispatch,i18n,showModalWeighVehiclesSettings,DEFAULTCOUNTRY,form.weighvehiclescodelers,form.vehiclestore_tara,foundLicensePlateAndCheckPma]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataProvince = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`weighvehicles/simpleprovincesbycountrybyname/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProvinceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalWeighVehiclesSettings){
      fetchDataProvince();
    }

    return () => { isMounted = false };     
  }, [ENDPOINT,t,token,dispatch,i18n,showModalWeighVehiclesSettings,DEFAULTCOUNTRY]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = form.ori_tras_province_id!==""
            ? await helpHttp().get2(ENDPOINT,`weighvehicles/simplepostalcodesbyprovincebyname/`,token,form.ori_tras_province_id)
            : await helpHttp().get2(ENDPOINT,`weighvehicles/simplepostalcodesbycountry/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPostalCodeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalWeighVehiclesSettings){
      fetchDataPostalCode(); 
    }
    if (form.id === "") { 
      setForm(form => ({
        ...form,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        isFinished:false,
        rel_trans_country_id: Number(DEFAULTCOUNTRY),
        rel_trans_province_id:  Number(DEFAULTPROVINCE),
        rel_trans_postalcode_id: 0,
        ori_tras_country_id: Number(DEFAULTCOUNTRY),
        ori_tras_province_id: Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id: 0,
        weighvehiclescodelers: [] 
      }));
    }
    return () => { isMounted = false };  
  }, [ENDPOINT,t,token,dispatch,i18n,showModalWeighVehiclesSettings,form.ori_tras_province_id,DEFAULTCOUNTRY,DEFAULTPROVINCE,form.id,defaultStore.id,useraccount_id]);

  return (
    <div>
      {showModalWeighVehiclesSettings && (
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff',width: '1800px'}}>
            <form className="createbudget" onSubmit={handleCloseDataEditWeighVehicles}>  
              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className="card border-info shadow">
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">
                            <h1 className="h1NewStyle mb-2">
                              <i className="fas fa-solid fa-weight text-primary"></i> {t('Text_WEIGHVEHICLES_TITLE')}
                            </h1>
                            <div>
                              <button type="button" className="buttonBack" onClick={handleCloseDataEditWeighVehicles}>
                                {t('Button_Exit')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>

              <div className="row">
                <div className="col-xl-12 mb-4"> 
                  <div className="card border-info shadow">
                    <div className="card-body">
                      <div className="row justify-content-center">
                        {/* SELECT */}
                        <div className="col-6 mb-2">
                          <div className="card border-info shadow">
                            <div className="card-header 2 bg-primary text-white text-center text-bold">
                              <h4><i className="fas fa-duotone fa-truck text-warning"></i> {t('Text_WEIGHVEHICLES_TITLE')}</h4>
                            </div>
                            <div className="card-body">
                              <div className="container">
                                
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_WEIGHVEHICLES_LICENSEPLATE')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="vehiclestore_licenseplate" 
                                      name="vehiclestore_licenseplate"
                                      value={form.vehiclestore_licenseplate}
                                      inputProps={{ style: {width: i18n.language==='es'?161:121}, maxLength: 100 }}
                                      style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                      label={errorsForm.vehiclestore_licenseplate!=null?t(errorsForm.vehiclestore_licenseplate):t('Text_WEIGHVEHICLES_LICENSEPLATE')}
                                      placeholder={t('Text_WEIGHVEHICLES_LICENSEPLATE')}
                                      variant="standard"
                                      onBlur={handleBlurWeighVehicles}
                                      onChange={handleChangeWeighVehicles}
                                    /> 
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDateVehicles}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                                        <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormVehicles}>
                                            <SearchIcon/>
                                        </IconButton>
                                    }
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label className="text-primary">{t('Text_WEIGHVEHICLES_CIF_TRANS')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="rel_trans_documentid" 
                                      name="rel_trans_documentid"
                                      value={form.rel_trans_documentid}
                                      inputProps={{ style: {width: i18n.language==='es'?161:122}, maxLength: 100 }}
                                      label={errorsForm.rel_trans_documentid!=null?t(errorsForm.rel_trans_documentid):t('Text_WEIGHVEHICLES_CIF_TRANS')}
                                      style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                      placeholder={t('Text_WEIGHVEHICLES_CIF_TRANS')}
                                      variant="standard"
                                      onBlur={handleBlurWeighVehicles}
                                      onChange={handleChangeWeighVehicles}
                                    /> 
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDateStoreRelTrans}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                                      <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormStoreRelTrans}>
                                          <SearchIcon/>
                                      </IconButton>
                                    }
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                      <label className="text-primary">{t('Text_WEIGHVEHICLES_TRADENAME_TRANS')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="rel_trans_tradename" 
                                        name="rel_trans_tradename"
                                        value={form.rel_trans_tradename}
                                        inputProps={{ style: {width: i18n.language==='es'?641:653}, maxLength: 100 }} 
                                        label={errorsForm.rel_trans_tradename!=null?t(errorsForm.rel_trans_tradename):t('Text_WEIGHVEHICLES_TRADENAME_TRANS')}
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        placeholder={t('Text_WEIGHVEHICLES_TRADENAME_TRANS')}
                                        variant="standard"
                                        onBlur={handleBlurWeighVehicles}
                                        onChange={handleChangeWeighVehicles}
                                      /> 
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <Typography style={{color:"#067330", fontSize: 16}} >  
                                      {t('Text_WEIGHVEHICLES_TRADENAME_STORE')} 
                                  </Typography>
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="row p-0"> 
                                  <div className="form-group">    
                                    <label className="text-primary">{t('Text_WEIGHVEHICLES_CIF_STORE')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="ori_tras_documentid" 
                                        name="ori_tras_documentid"
                                        value={form.ori_tras_documentid}
                                        inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }}
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        label={errorsForm.ori_tras_documentid!=null?t(errorsForm.ori_tras_documentid):t('Text_WEIGHVEHICLES_CIF_STORE')}
                                        placeholder={t('Text_WEIGHVEHICLES_CIF_STORE')}
                                        variant="standard"
                                        onBlur={handleBlurWeighVehicles}
                                        onChange={handleChangeWeighVehicles}
                                      /> 
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDateStoreOriTras}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                                      <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormStoreOriTras}>
                                          <SearchIcon/>
                                      </IconButton>
                                    } 
                                  </div>
                                </div> 
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_WEIGHVEHICLES_NAME_STORE')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="ori_tras_tradename" 
                                        name="ori_tras_tradename"
                                        value={form.ori_tras_tradename}
                                        inputProps={{ style: {width: i18n.language==='es'?655:673}, maxLength: 100 }} 
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        label={errorsForm.ori_tras_tradename!=null?t(errorsForm.ori_tras_tradename):t('Text_WEIGHVEHICLES_NAME_STORE')}
                                        placeholder={t('Text_WEIGHVEHICLES_NAME_STORE')}
                                        variant="standard"
                                        onBlur={handleBlurWeighVehicles}
                                        onChange={handleChangeWeighVehicles} 
                                      />  
                                  </div>                              
                                </div>  
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_WEIGHVEHICLES_TRADENAME_STORE_ADDRESS')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="ori_tras_street" 
                                        name="ori_tras_street"
                                        value={form.ori_tras_street}
                                        inputProps={{ style: {width: i18n.language==='es'?621:629}, maxLength: 100 }} 
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        label={errorsForm.ori_tras_street!=null?t(errorsForm.ori_tras_street):t('Placeholder_WEIGHVEHICLES_TRADENAME_STORE_ADDRESS')}
                                        placeholder={t('Placeholder_WEIGHVEHICLES_TRADENAME_STORE_ADDRESS')}
                                        variant="standard"
                                        onBlur={handleBlurWeighVehicles}
                                        onChange={handleChangeWeighVehicles} 
                                      />  
                                      &nbsp;&nbsp;
                                      <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDateStoreAddressOriTras}>
                                          <DeleteIcon/>
                                      </IconButton>
                                      &nbsp;&nbsp;
                                      {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                                          <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormStoreAddress}>
                                            <SearchIcon/>
                                          </IconButton>
                                      } 
                                  </div>                              
                                </div>  
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_WEIGHVEHICLES_TRADENAME_STORE_PROVINCE')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="ori_tras_province_id"
                                      name="ori_tras_province_id"
                                      value={ (form.ori_tras_province_id === undefined || form.ori_tras_province_id ===null || form.ori_tras_province_id ===""
                                          || form.ori_tras_province_id ==="0" || form.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE):form.ori_tras_province_id}
                                      inputProps={{ style: {width: i18n.language==='es'?227:235}, maxLength: 100 }}
                                      variant="outlined"
                                      onBlur={handleBlurWeighVehicles}
                                      onChange={handleChangeWeighVehicles}
                                      helperText={errorsForm.ori_tras_province_id!=null && errorsForm.ori_tras_province_id!==""?t(errorsForm.ori_tras_province_id):""}
                                      select
                                      SelectProps={{
                                          native: true,
                                          value: (form.ori_tras_province_id === undefined || form.ori_tras_province_id ===null || form.ori_tras_province_id ===""
                                              || form.ori_tras_province_id ==="0" || form.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE): form.ori_tras_province_id
                                      }}>
                                      <option key={0} value=""/>
                                      {provinceToSelect.map((option) => (
                                      <option key={option.id} value={option.id}>
                                          {option.name}
                                      </option>
                                      ))}
                                  </TextField>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <label className="text-primary">{t('Text_WEIGHVEHICLES_TRADENAME_STORE_POSTALCODE')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="ori_tras_postalcode_id"
                                    name="ori_tras_postalcode_id"
                                    value={ (form.ori_tras_postalcode_id === undefined || form.ori_tras_postalcode_id ===null || form.ori_tras_postalcode_id ===""
                                        || form.ori_tras_postalcode_id ==="0" || form.ori_tras_postalcode_id ===0)?"":form.ori_tras_postalcode_id}
                                    inputProps={{ style: {width: i18n.language==='es'?227:235}, maxLength: 100 }}
                                    variant="outlined"
                                    onBlur={handleBlurWeighVehicles}
                                    onChange={handleChangeWeighVehicles}
                                    helperText={errorsForm.ori_tras_postalcode_id!=null && errorsForm.ori_tras_postalcode_id!==""?t(errorsForm.ori_tras_postalcode_id):""}
                                    select
                                    SelectProps={{
                                    native: true,
                                    value: (form.ori_tras_postalcode_id === undefined || form.ori_tras_postalcode_id ===null || form.ori_tras_postalcode_id ===""
                                        || form.ori_tras_postalcode_id ==="0" || form.ori_tras_postalcode_id ===0)?"": form.ori_tras_postalcode_id
                                    }}>
                                    <option key={0} value=""/>
                                    {postalCodeToSelect.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.postalcodename}
                                    </option>
                                    ))}
                                  </TextField>
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_WEIGHVEHICLES_TRADENAME_STORE_OTHERS')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="observations" 
                                        name="observations"
                                        value={form.observations}
                                        inputProps={{ style: {width: i18n.language==='es'?637:650}, maxLength: 100 }} 
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        label={errorsForm.observations!=null?t(errorsForm.observations):t('Placeholder_WEIGHVEHICLES_TRADENAME_STORE_OTHERS')}
                                        placeholder={t('Placeholder_WEIGHVEHICLES_TRADENAME_STORE_OTHERS')}
                                        variant="standard"
                                        onBlur={handleBlurWeighVehicles}
                                        onChange={handleChangeWeighVehicles}
                                      />  
                                  </div>                              
                                </div>  
                                <div className="row p-0"> 
                                  <Typography style={{color:"#067330", fontSize: 16}} >  
                                      {t('Text_WEIGHVEHICLES_CODE_LERS')} ({t('Text_WEIGHVEHICLES_MAX_CODE_LERs')} {LIMITCODELERSWEIGHT})
                                  </Typography>
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="row p-0"> 
                                  <div className="form-group">  
                                    <TableSortingCodeLERS 
                                      dataCodeLERS={form.weighvehiclescodelers} 
                                      numTotRegCodeLERS={form.weighvehiclescodelers.length}
                                      setOpenDeleteRowCodeLERS={setOpenDeleteRowCodeLERS}
                                      setOpenEditRowCodeLERS={setOpenEditRowCodeLERS}
                                      setEditRowCodeLER={setEditRowCodeLER} 
                                      setForm={setForm}
                                      LIMITPAGESIZE={LIMITPAGESIZE}
                                      LIMITCODELERSWEIGHT={LIMITCODELERSWEIGHT}
                                    />
                                    <WeighVehiclesCreateCodeLER 
                                      openEditRowCodeLERS={openEditRowCodeLERS}
                                      setOpenEditRowCodeLERS={setOpenEditRowCodeLERS} 
                                      form={form}
                                      setForm={setForm}
                                      setSelectedButtonIds={setSelectedButtonIds} 
                                      foundLicensePlateAndCheckPma={foundLicensePlateAndCheckPma}
                                    /> 
                                  </div>
                                </div> 
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg " type="button"  onClick={handleSubmitDataEditWeighVehiclesBudget}>{t('Button_Add')}</button><> </>
                                </div>

                                <hr className="sidebar-divider d-none d-md-block"/>
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {codeLERButtonsList
                                     .filter(post => !selectedButtonIds.includes(Number(post.button_codeler_id)))
                                     .map((post, index) => (
                                      <Grid item xs={2} sm={4} md={4} key={index}>
                                        <Item>
                                          <Button variant="contained" style={{color:"#ffffff", background:form.weighvehiclescodelers.length>=LIMITCODELERSWEIGHT?"#858796":"#4e73df",fontSize: 16}} onClick={()=>selectCodeLER(post)} disabled={form.weighvehiclescodelers.length>=LIMITCODELERSWEIGHT}>
                                            {i18n.language==='es'?post.button_namees:post.button_nameen}
                                          </Button>
                                        </Item>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* END SELECT */}
                        {/* SCALE */}
                        <div className="col-6 mb-2">
                          <div className="card border-info shadow">
                            <div className="card-header 2 bg-primary text-white text-center text-bold">
                              <h4>{t('Text_WEIGHVEHICLES_SCALES')}</h4>
                            </div>
                            <div className="card-body">
                              <div className="row align-items-center">
                                <div className="col-4 mb-2">
                                </div>
                                <div className="col-6 mb-2">
                                  <NumericFormat 
                                    thousandSeparator={','} 
                                    decimalSeparator={'.'} 
                                    decimalScale= {2}
                                    fixedDecimalScale= {true}
                                    allowNegative= {false}
                                    isAllowed={withValueCap}
                                    id="totalfirstweight" 
                                    name="totalfirstweight"
                                    value={form.totalfirstweight}
                                    style={{width:300, color:"red", background:"black",fontSize: 38, textAlign:'right', paddingRight:10}} 
                                    onBlur={handleBlurWeighVehiclesWeight}
                                    onChange={handleChangeWeighVehiclesWeight}
                                  /> 
                                  </div>
                                  <div className="col-2 mb-2">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <IconButton 
                                      style={{color:"#ffffff", background:"#4e73df"}} 
                                      aria-label="weight vehicles">
                                      <ScaleIcon />
                                    </IconButton>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <WeighVehiclesBudgetsForm 
                            showModalWeighVehiclesSettings={showModalWeighVehiclesSettings}
                            submitNewBudget={submitNewBudget}
                            setSubmitNewBudget={setSubmitNewBudget}
                          />
                        </div>
                        {/* END SCALE */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Content Row */}
            </form>
          </Box>
          <br/>
          <WeighVechiclesSearchStores
            showModalSearchStores={showModalSearchStores}
            setShowModalSearchStores={setShowModalSearchStores}
            selectStores={tetxtSearch}
            setForm={setForm}
          />
          <WeighVehiclesSearchStoresAddresses
                showModalSearchStoresAddress={showModalSearchStoresAddress}
                setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
                setForm={setForm}
                titleTable={form.ori_tras_tradename}
                store_id={form.ori_tras_store_id}
                priority={2}
          />
           <WeighVechiclesSearchVehicles
              showModalSearchVehicles={showModalSearchVehicles}
              setShowModalSearchVehicles={setShowModalSearchVehicles}
              setForm={setForm}
              form={form}
          />
          <div>
            <Dialog open={openDeleteRowCodeLERS} onClose={handleCancelDeleteRowCodeLER}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowCodeLER.codeler_code} - {i18n.language==='es'?editRowCodeLER.codeler_namees:editRowCodeLER.codeler_nameen} </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeleteCodeLER}
                  onChange={handleChangeDeleteRowCodeLER}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowCodeLER}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowCodeLER}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        
      )}
    </div>
  );
};

WeighVehiclesEditOrCreate.propTypes = { 
  showModalWeighVehiclesSettings: PropTypes.bool.isRequired 
};

export default WeighVehiclesEditOrCreate;